import { FunctionComponent } from "react";

import { DateTime } from "@bps/utils";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { CancelAppointmentFormValues } from "./CancelAppointmentForm.types.ts";
import { CancelAppointmentFormFields } from "./CancelAppointmentFormFields.tsx";
import { CancelAppointmentFormValidator } from "./CancelAppointmentFormValidator.ts";

export interface CancelAppointmentFormProps {
  onSubmitted?: (values: CancelAppointmentFormValues) => void;
  onCancel?: () => void;
  calendarEvent: CalendarEvent;
}
export const CancelAppointmentForm: FunctionComponent<
  CancelAppointmentFormProps
> = ({ onSubmitted, calendarEvent, onCancel }) => {
  const { booking, userExperience } = useStores();

  const captureApptCancellationReason =
    userExperience.orgUnitSettingForLocation?.preferenceSetting
      .captureApptCancellationReason;

  const handleSubmitAction = async (values: CancelAppointmentFormValues) => {
    await booking.cancelCalendarEvent({
      id: calendarEvent.id,
      cancellationDateTime: DateTime.now().toISO(),
      reason: { episodeOfCareId: calendarEvent?.reason?.episodeOfCareId },
      ...values,
      cancellationReasonId: values.cancellationReasonId || undefined
    });

    if (calendarEvent.startDateTime >= DateTime.today()) {
      booking.ui.setCancelledAppointmentDetails(
        {
          endTime: calendarEvent.endDateTime,
          startTime: calendarEvent.startDateTime
        },
        calendarEvent.userId,
        calendarEvent.orgUnitId
      );
    }
  };

  const otherCancellationReasonId = Array.from(
    booking.appointmentCancellationReasonsMap.values()
  ).find(x => x.text === "Other")?.id;

  return (
    <SubmissionForm
      formName="cancel-appointment"
      styles={{ fields: { overflowY: "visible" } }}
      initialValues={{ cancellationReasonId: "" }}
      validate={(values: CancelAppointmentFormValues) => {
        return new CancelAppointmentFormValidator(
          otherCancellationReasonId,
          captureApptCancellationReason
        ).validate(values);
      }}
      buttonsProps={{
        submitButtonProps: {
          text: "Cancel appointment",
          iconProps: { hidden: true }
        },
        onCancel,
        hideButtonsSeparator: true,
        disableSubmitOnPristine: captureApptCancellationReason
      }}
      disableRoutePrompt
      onSubmit={handleSubmitAction}
      onSubmitSucceeded={onSubmitted}
    >
      {() => {
        return (
          <Fieldset>
            <CancelAppointmentFormFields />
          </Fieldset>
        );
      }}
    </SubmissionForm>
  );
};
