import { decode } from "base64-arraybuffer";

import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TemplateRenderDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { Document } from "@ui-components/document-viewer/DocumentViewerDialog.tsx";

export class ExportPatientRecordHelper {
  constructor(private root: RootStore) {}

  getExportPatientDocument = async (
    contentType: DocumentContentType,
    patientId: string | undefined
  ): Promise<Document> => {
    const demographicData =
      this.root.practice.ui.exportRecordValues?.demographicData ?? [];

    const templates = await this.root.document.getTemplates({
      names: [
        "Patient Export-Header",
        "Patient Export-Demographic-General",
        ...demographicData
      ]
    });

    // Sort templates to match the order of 'names'
    const sortedTemplates = templates.slice().sort((a, b) => {
      const order = [
        "Patient Export-Header",
        "Patient Export-Demographic-General",
        ...demographicData
      ];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });

    const documentTemplate: TemplateRenderDto =
      await this.root.document.renderTemplate(
        sortedTemplates.length ? sortedTemplates[0].id : "",
        {
          contentType,
          skipMerge: false,
          isPreview: false,
          context: {
            PatientId: patientId ?? "",
            EnrichForPatientExport: true
          },
          // Order by name
          templateAppendOptions: sortedTemplates
            .map(x => ({
              appendTemplateId: x.id,
              lineBreakSpacing: 1
            }))
            .slice(1)
        }
      );

    return {
      documentId: "",
      extension: DocumentContentType.Pdf,
      entityId: patientId ?? "",
      entityType: DocumentEntityType.Patient,
      downloadUri: undefined,
      previewUri: new Uint8Array(decode(documentTemplate.content)),
      name: "Patient Record Export"
    };
  };
}
