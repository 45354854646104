import { action, computed, observable } from "mobx";
import { FileRejection } from "react-dropzone";

import { IGroup, IObjectWithKey, ISelection } from "@bps/fluent-ui";
import { DateTime, newGuid } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  InboxTasksFilterValues,
  Instructions,
  OutcomeDataItemDto,
  ReceptionTaskDto,
  UserActionType,
  UserTaskStatus
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { IDialogOptions, InboxDocumentKey } from "../inbox/Inbox.types.ts";
import { TaskActionFormValues } from "../tasks/components/Tasks.types.ts";
import {
  UserInboxActionFormValues,
  VisibilityAndConfidentiality
} from "../user-inbox/components/user-inbox-form/UserInboxActionForm.types.ts";

export class InboxScreenHelper {
  constructor(private root: RootStore) {}

  private get core() {
    return this.root.core;
  }

  private get inbox() {
    return this.root.inbox;
  }

  //Task Inbox screen
  @observable taskId: string | undefined;
  @observable selectedInboxDocument: InboxDocument | undefined;

  @observable nextItemAfterUpdate: string | undefined;

  @action
  setTaskId = (value?: string) => {
    this.taskId = value;
  };

  @action
  setSelectedInboxDocument = (value?: InboxDocument | undefined) => {
    this.selectedInboxDocument = value;
  };

  @action
  setNextItemAfterUpdate = (value?: string) => {
    this.nextItemAfterUpdate = value;
  };

  clearOnLeave = () => {
    this.setSelectedInboxDocument();
    this.setSelectedInboxDocument();
  };

  @action
  fetchAndSetInboxDocument = async (selectedInboxDocument: InboxDocument) => {
    if (!selectedInboxDocument.previewUri) {
      const docWithPreview = await this.inbox.getInboxDocument(
        {
          id: selectedInboxDocument.id,
          documentDetailId: selectedInboxDocument.documentDetailId
        },
        {
          ignoreCache: true
        }
      );
      this.setSelectedInboxDocument(docWithPreview);
      return docWithPreview;
    }

    return selectedInboxDocument;
  };
  getPatientGroups = (sortedDocs: InboxDocument[]): IGroup[] => {
    const groups: IGroup[] = [];
    let currentGroup: IGroup = { key: "", name: "", startIndex: 0, count: 0 };
    sortedDocs.forEach((doc, index) => {
      if (currentGroup?.key !== doc.patientId) {
        if (currentGroup) currentGroup.count = index - currentGroup.startIndex;
        currentGroup = {
          key: doc.patientId,
          name: doc.contact?.name ?? ContactType.Patient,
          startIndex: index,
          count: 0
        };
        groups.push(currentGroup);
      }
    });

    if (groups.length > 0)
      currentGroup.count = sortedDocs.length - currentGroup.startIndex;

    return groups;
  };

  getNextItemInList = (taskId?: string) => {
    if (
      taskId &&
      this.lastKnownSearchedTasks &&
      this.lastKnownSearchedTasks.length > 1
    ) {
      const indexOfTask = this.lastKnownSearchedTasks.findIndex(
        x => x.id === taskId
      );

      if (indexOfTask >= 0) {
        const nextTask = this.lastKnownSearchedTasks[indexOfTask + 1];
        return nextTask;
      }
    }

    return undefined;
  };

  navigateDownwardsIfPossible = (taskId?: string) => {
    const nextTask = this.getNextItemInList(taskId);

    if (nextTask) {
      this.setNextItemAfterUpdate(nextTask.id);
    }
  };

  handleTaskActionSubmit = async (
    values: TaskActionFormValues,
    task: UserTask
  ) => {
    const actionDate = values.currentOutcome?.date;
    const actiontime = values.currentOutcome?.time;
    const outcomeStatus = values.currentOutcome?.outcomeStatus;
    const outcomeLog = values.outcomeLog ?? [];
    let actionDateTime: DateTime | undefined;

    this.navigateDownwardsIfPossible(task.id);

    if (actionDate && actiontime) {
      actionDateTime = DateTime.fromJSDateAndTime(actionDate, actiontime);
    }

    const addedOutcome: OutcomeDataItemDto = {
      id: values.currentOutcome?.id ?? newGuid(),
      outcomeDateTime: actionDateTime?.toISO(),
      outcomeStatus,
      createdByUser: values.currentOutcome?.createdByUser,
      comment: values.currentOutcome?.comment
    };

    outcomeLog.unshift(addedOutcome);

    const request = {
      assignedToUserId: task.assignedToUserId,
      closingComments: values.currentOutcome?.comment,
      dueDateTime: task.dueDateTime?.toISO(),
      instructionCode: task.instructionCode,
      userActionId: task.userActionId,
      userAction: task.userAction,
      changeLog: task.changeLog,
      outcomeLog,
      outcome: outcomeStatus,
      subject: task.subject,
      status: task.status,
      eTag: task.eTag,
      id: task.id,
      rootEntityETag: task.rootEntityETag
    };

    await this.inbox.updateUserTask(request);
  };

  @observable selectedInboxDocKey: InboxDocumentKey | undefined;

  @action
  setSelectedInboxDocKey = (value?: InboxDocumentKey) => {
    this.selectedInboxDocKey = value;
  };

  //upload screen
  @observable private dropzoneDropInProgress: boolean;
  @computed get showUploadScreenSpinner() {
    return (
      this.dropzoneDropInProgress ||
      (!this.selectedInboxDocument && this.inbox.pendingUploadMap.size)
    );
  }

  @observable uploadFileError: FileRejection[] = [];

  @action
  setDropzoneEventInProgress = (value: boolean) => {
    this.dropzoneDropInProgress = value;
  };

  @action
  setUploadFileError = (value: FileRejection[]) => {
    this.uploadFileError = value;
  };

  canDeleteDocument = (document: InboxDocument) =>
    document.assignedToUserId === this.core.userId ||
    this.core.hasPermissions(Permission.DocWorkflowDelete);

  onInboxActionSubmit = async (values: UserInboxActionFormValues) => {
    try {
      const data = {
        id: values.documentId,
        storeInDestination: values.storeIn!,
        reportType: values.reportType,
        userActionType: UserActionType.NoAction
      };

      this.navigateDownwardsIfPossible(this.taskId);

      if (this.selectedInboxDocument) {
        await this.inbox.updateAssignUserInboxDocument({
          ...this.selectedInboxDocument.dto,
          extraInfo: values.extraInfo
        });
      }

      if (values.instructionCode !== UserActionType.NoAction) {
        await this.inbox.addInboxUserAction(
          { ...data, userActionType: UserActionType.ReceptionToAdvise },
          {
            instructionCode: values.instructionCode,
            dueDateTime: DateTime.jsDateToISODate(values.dueDate)!,
            showOnTimeline:
              values.clinicalProperty === VisibilityAndConfidentiality.Timeline,
            secGroupId:
              values.clinicalProperty ===
              VisibilityAndConfidentiality.Confidential
                ? this.core.user?.privateSecGroupId
                : undefined
          } as ReceptionTaskDto,
          true
        );
      } else {
        await this.inbox.addInboxUserAction(
          data,
          {
            status: UserTaskStatus.Completed,
            instructionCode: Instructions.NoAction,
            showOnTimeline:
              values.clinicalProperty === VisibilityAndConfidentiality.Timeline,
            secGroupId:
              values.clinicalProperty ===
              VisibilityAndConfidentiality.Confidential
                ? this.core.user?.privateSecGroupId
                : undefined
          } as ReceptionTaskDto,
          true
        );
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // clinical activities
  @observable selectedClinicalActivities: ClinicalActivity[] = [];

  @observable patientClinicalActivities: ClinicalActivity[] = [];

  @observable activityDialogVisible: IDialogOptions = {
    edit: false,
    delete: false,
    complete: false
  };

  @action
  setSelectedClinicalActivities = (value: ClinicalActivity[]) => {
    this.selectedClinicalActivities = value;
  };

  @action
  setPatientClinicalActivites = (value: ClinicalActivity[]) => {
    this.patientClinicalActivities = value;
  };

  @action
  setActivityDialogVisible = (value: IDialogOptions) => {
    this.activityDialogVisible = value;
  };

  //clinical tasks
  @observable selectedClinicalTasks: ClinicalTask[] = [];
  @observable patientClinicalTasks: ClinicalTask[] = [];
  @observable taskDialogVisible: IDialogOptions = {
    edit: false,
    delete: false,
    complete: false
  };

  @action
  setTaskDialogVisible = (value: IDialogOptions) => {
    this.taskDialogVisible = value;
  };

  @action
  setSelectedClinicalTasks = (value: ClinicalTask[]) => {
    this.selectedClinicalTasks = value;
  };

  @action
  setPatientClinicalTasks = (value: ClinicalTask[]) => {
    this.patientClinicalTasks = value;
  };

  lastKnownSearchedTasks: UserTask[] | undefined;

  getFollowupUserTasks = async (props: {
    query: PagingOptions;
    filter: InboxTasksFilterValues;
    userId?: string;
  }) => {
    const { query, filter, userId } = props;

    const userTasks = await this.inbox.getUserTasks({
      ...query,
      ...filter,
      checkedDateTime: DateTime.jsDateToISODate(filter.checkedDateTime),
      userId
    });

    // Make a record of the last known searched items, to save on callbacks.
    this.lastKnownSearchedTasks = userTasks.results;

    return userTasks;
  };

  updateSelectionOnSearch = (selection?: ISelection<IObjectWithKey>) => {
    if (selection && this.lastKnownSearchedTasks) {
      const selectedItems = selection.getSelectedIndices();

      if (selectedItems.length > 0) {
        const selectedItemKey = selectedItems[0];
        const item = selection.getItems()[selectedItemKey] as UserTask;

        if (this.nextItemAfterUpdate && item) {
          const indexOfNext = this.lastKnownSearchedTasks.findIndex(
            x => x.id === this.nextItemAfterUpdate
          );

          // Deselect the current and set the next one.
          selection.setIndexSelected(selectedItemKey, false, false);
          selection.setIndexSelected(indexOfNext, true, true);
          this.setNextItemAfterUpdate(undefined);

          this.setTaskId(this.lastKnownSearchedTasks[indexOfNext].id);
        } else {
          if (item && this.taskId !== item.id) {
            this.setTaskId(item.id);
          }
        }
      }
    }
  };

  //misc
  @observable isFormActive: boolean | undefined = false;

  @observable showDocumentViewerDialog: boolean | undefined = false;

  @action
  setShowDocumentViewerDialog = (value: boolean) => {
    this.showDocumentViewerDialog = value;
  };

  @action
  setActiveForm = (value: boolean) => {
    this.isFormActive = value;
  };

  @observable removedInboxDocId: string | undefined;

  @action onRemovingInboxDocFromList = (id: string) => {
    this.removedInboxDocId = id;
  };
}
