import { observer } from "mobx-react-lite";
import React from "react";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ActivitiesClinical } from "./ActivitiesClinical.tsx";
import TasksClinical from "./TasksClinical.tsx";

export interface ClinicalActivityOrTaskListProps {
  clinicalRecord: ClinicalRecord;
}

export const ActivityOrTaskList: React.FC<ClinicalActivityOrTaskListProps> =
  observer(() => {
    const { core } = useStores();

    if (core.hasPermissions(Permission.ClinActivityAllowed)) {
      return <ActivitiesClinical />;
    }

    return <TasksClinical />;
  });

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default ActivityOrTaskList;
