import { observer, Observer } from "mobx-react-lite";
import React, { ReactNode, useRef } from "react";
import { Selection } from "ui-components/ShimmeredDetailsList/Selection.ts";

import {
  DetailsRow,
  DirectionalHint,
  FontIcon,
  FontSizes,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  Persona,
  PersonaSize,
  RESET_CELLS_PADDING_CLASSNAME,
  Shimmer,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { NotFoundError } from "@bps/http-client";
import { DateTime, isGUID } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClaimDataFetcher } from "@modules/acc/screens/shared-components/ClaimDataFetcher.tsx";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  ClinicalActivityDescriptionCodeText,
  ClinicalActivityTypeText,
  PriorityText
} from "@ui-components/RefText.tsx";

import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { ClinicalActivityActionMenu } from "./ClinicalActivityActionMenu.tsx";
import { ClinicalActivityTypeIcon } from "./ClinicalActivityTypeIcon.tsx";
import { ClinicalActivityTableLabels } from "./types/clinical-activity-table-labels.enum.ts";
import { ActivityPriorityType } from "./types/clinical-priority.type.ts";

export interface ChildrenProps {
  selection: Selection<
    ClinicalActivity & {
      key: string;
    }
  >;
  columns: IColumn[];
  renderRow: (props: IDetailsRowProps) => JSX.Element | null;
  renderDetailsHeader: (
    props: IDetailsHeaderProps,
    defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
  ) => JSX.Element;
}

export interface ClinicalActivityTableProps {
  showType?: boolean;
  showPriority?: boolean;
  showPatientName?: boolean;
  showActions?: boolean;
  onSelectionChanged?: (items: ClinicalActivity[]) => void;
  children: (props: ChildrenProps) => ReactNode;
}

export const ClinicalActivityTable: React.FC<ClinicalActivityTableProps> =
  observer(
    ({
      showPriority,
      showType,
      showPatientName,
      showActions,
      onSelectionChanged,
      children
    }) => {
      const { core, comms, clinical } = useStores();
      const theme = useTheme();

      const clinicalReminderReasons =
        comms.clinicalReminderPreference?.clinicalReminderReasons;

      const isItemSelectable = (item: ClinicalActivity) => {
        const isAllowedToChange =
          !item.isLocked ||
          item.lockedBy === core.userId ||
          core.hasPermissions([Permission.ClinTaskUnlock]);
        return (
          isAllowedToChange &&
          item.activityStatus !== ClinicalActivityStatusText.Completed
        );
      };

      const renderStatusBadgeAndIcon = (item: ClinicalActivity) => {
        const activityStatus = item.activityStatus;
        let badgeColor = TextBadgeColor.lightGrey;
        if (activityStatus === ClinicalActivityStatusText.Upcoming) {
          badgeColor = TextBadgeColor.blue;
        }
        if (activityStatus === ClinicalActivityStatusText.Today) {
          badgeColor = TextBadgeColor.yellow;
        }
        if (activityStatus === ClinicalActivityStatusText.Overdue) {
          badgeColor = TextBadgeColor.red;
        }

        const showWarningIcon =
          activityStatus === ClinicalActivityStatusText.Overdue;

        return (
          <Observer>
            {() => (
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextBadge
                  badgeColor={badgeColor}
                  badgeSize={TextBadgeSize.medium}
                  hasBorder={
                    activityStatus === ClinicalActivityStatusText.Today
                  }
                  styles={{
                    root: {
                      fontSize: FontSizes.size12,
                      width: 100
                    }
                  }}
                >
                  <Stack horizontal horizontalAlign="center">
                    {showWarningIcon && (
                      <FontIcon
                        iconName="AlertSolid"
                        styles={{ root: { marginRight: 4 } }}
                      />
                    )}
                    {activityStatus}
                  </Stack>
                </TextBadge>
                {item.activityStatus ===
                  ClinicalActivityStatusText.Completed && (
                  <TooltipHost
                    styles={{ root: { alignSelf: "center" } }}
                    calloutProps={{
                      directionalHint: DirectionalHint.bottomLeftEdge
                    }}
                    content={
                      <DataFetcher<string | undefined>
                        fetch={async ({ core }) => {
                          if (item.completedBy) {
                            return (await core.getUser(item.completedBy))
                              ?.fullName;
                          } else {
                            if (item.changeLog?.updatedBy) {
                              return (
                                await core.getUserByUserName(
                                  item.changeLog?.updatedBy
                                )
                              )?.fullName;
                            }
                          }
                          return undefined;
                        }}
                      >
                        {name => (
                          <Stack
                            tokens={{ childrenGap: 8 }}
                            styles={{ root: { margin: "10px 12px" } }}
                          >
                            <Stack>
                              <Text bold>Completed</Text>
                              <Text>{name}</Text>
                              <Text>{item.completedDate}</Text>
                            </Stack>
                            {item.completionNotes && (
                              <Stack>
                                <Text bold>Notes</Text>
                                <Text>{item.completionNotes}</Text>
                              </Stack>
                            )}
                          </Stack>
                        )}
                      </DataFetcher>
                    }
                  >
                    <FontIcon
                      styles={{
                        root: {
                          fontSize: FontSizes.size16,
                          color: theme.palette.themePrimary
                        }
                      }}
                      iconName={item.completionNotes ? "CannedChat" : "Info"}
                    />
                  </TooltipHost>
                )}

                {!showType && (
                  <Stack verticalAlign="center">
                    {item.isLocked &&
                      item.activityStatus !==
                        ClinicalActivityStatusText.Completed && (
                        <DataFetcher<User>
                          fetch={() => core.getUser(item.lockedBy!)}
                          fallback={<Shimmer width={120} />}
                          renderError={(error: Error) => {
                            if (error instanceof NotFoundError) {
                              return renderActivityTypeLock(
                                "This is locked to unknown user"
                              );
                            }

                            return renderActivityTypeLock();
                          }}
                        >
                          {(user: User) =>
                            renderActivityTypeLock(
                              ` This is locked to ${user.fullName}`
                            )
                          }
                        </DataFetcher>
                      )}
                  </Stack>
                )}
              </Stack>
            )}
          </Observer>
        );
      };

      const toSimpleDateString = (dueDate: string | undefined) => {
        if (!dueDate) return "";

        return DateTime.fromISO(dueDate).toDayDefaultFormat();
      };

      const renderRow = (props: IDetailsRowProps): JSX.Element | null => {
        const item = props.item as ClinicalActivity;
        const renderRowProps = { ...props };

        const disableRow =
          !isItemSelectable(item) || !core.hasAccessToSecGroup(item.secGroupId);

        if (
          !core.hasAccessToSecGroup(item.secGroupId) ||
          (item.lockedBy !== core.userId && item.isLocked && !item.isCompleted)
        ) {
          renderRowProps.onRenderCheck = () => (
            <ConfidentialToolTipFontIcon
              isShowConfidentialIcon
              summaryStyling
            />
          );
        }

        return (
          <DetailsRow
            {...renderRowProps}
            styles={{
              root: {
                "&.is-selected:hover": {
                  backgroundColor:
                    theme.semanticColors.listItemBackgroundChecked
                }
              },
              fields: {
                display: "inline-flex",
                alignItems: "center"
              },
              checkCell: {
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 48
              }
            }}
            disabled={disableRow}
          />
        );
      };

      const checkConfidentailAccess = (
        item: ClinicalActivity,
        renderColumn: JSX.Element
      ) => {
        return core.hasAccessToSecGroup(item.secGroupId) && renderColumn;
      };

      const renderVisitsNumber = (item: ClinicalActivity) => {
        if (item.remainingVisits === undefined) {
          return undefined;
        }

        const remainingVisits = item.remainingVisits;

        const visitsText = remainingVisits === 1 ? "consult" : "consults";

        return `${remainingVisits} ${visitsText}`;
      };

      const onRenderCreated = (item: ClinicalActivity) => {
        if (!item.changeLog) {
          return undefined;
        }

        // Changelog seems to record the patient Id but in the backend it records as an email...
        const user = Array.from(core.userMap.values()).find(
          x =>
            item.changeLog &&
            ((isGUID(item.changeLog.createdBy) &&
              x.id === item.changeLog.createdBy) ||
              x.email === item.changeLog?.createdBy)
        );

        if (!item.dto.changeLog?.createdDate) {
          return undefined;
        }

        const date = toSimpleDateString(item.dto.changeLog?.createdDate);

        return (
          <Stack>
            <Stack>
              <Text variant="medium">{date}</Text>
            </Stack>
            <Text
              variant="small"
              styles={{ root: { color: theme.palette.neutralSecondary } }}
            >
              by {user?.fullName}
            </Text>
          </Stack>
        );
      };

      const renderActivityTypeLock = (tooltipContent?: string) => {
        if (!tooltipContent) {
          return (
            <FontIcon
              styles={{
                root: {
                  fontSize: FontSizes.size14,
                  color: theme.palette.neutralSecondaryAlt
                }
              }}
              iconName="LockSolid"
            />
          );
        }

        return (
          <TooltipHost
            styles={{ root: { alignSelf: "center" } }}
            content={<>{tooltipContent}</>}
          >
            <FontIcon
              styles={{
                root: {
                  fontSize: FontSizes.size16,
                  color: theme.palette.neutralSecondaryAlt
                }
              }}
              iconName="LockSolid"
            />
          </TooltipHost>
        );
      };

      const renderActivityType = (activity: ClinicalActivity) => {
        let priorityColour = theme.palette.neutralSecondaryAlt;

        switch (activity.activityPriority) {
          case ActivityPriorityType.HIGH:
            priorityColour = theme.palette.redDark;
            break;

          case ActivityPriorityType.MED:
            priorityColour = theme.palette.yellowDark;
            break;

          case ActivityPriorityType.LOW:
            priorityColour = theme.palette.neutralSecondaryAlt;
            break;
        }

        const clinicalReminderReason = clinicalReminderReasons?.find(
          x => x.reason === activity.descriptionCode
        );

        const isClinicallySignificant =
          clinicalReminderReason?.clinicallySignificant;

        return (
          <Stack horizontal verticalAlign="start">
            <ClinicalActivityTypeIcon activityType={activity.activityType} />

            <Stack styles={{ root: { width: "75%" } }}>
              <Stack horizontal>
                <Text variant="medium">
                  <ClinicalActivityTypeText code={activity.activityType} />
                </Text>
                <Stack
                  styles={{ root: { marginRight: 0, marginLeft: "auto" } }}
                >
                  {activity.isLocked &&
                    activity.activityStatus !==
                      ClinicalActivityStatusText.Completed && (
                      <DataFetcher<User>
                        fetch={() => core.getUser(activity.lockedBy!)}
                        fallback={<Shimmer width={120} />}
                        renderError={(error: Error) => {
                          if (error instanceof NotFoundError) {
                            return renderActivityTypeLock(
                              "This is locked to unknown user"
                            );
                          }

                          return renderActivityTypeLock();
                        }}
                      >
                        {(user: User) =>
                          renderActivityTypeLock(
                            ` This is locked to ${user.fullName}`
                          )
                        }
                      </DataFetcher>
                    )}
                </Stack>
              </Stack>
              {activity.activityPriority && (
                <Text
                  variant="small"
                  styles={{ root: { color: priorityColour } }}
                >
                  <PriorityText code={activity.activityPriority} /> priority
                </Text>
              )}

              {isClinicallySignificant && (
                <Text
                  variant="small"
                  styles={{ root: { color: theme.palette.themePrimary } }}
                >
                  Clinically Significant
                </Text>
              )}
            </Stack>
          </Stack>
        );
      };

      const getColumns = (): IColumn[] => {
        const columns: IColumn[] = [];

        if (showActions) {
          columns.push({
            key: ClinicalActivityTableLabels.Actions,
            name: ClinicalActivityTableLabels.Actions,
            minWidth: 50,
            maxWidth: 50,
            className: RESET_CELLS_PADDING_CLASSNAME,
            onRender: (record: ClinicalActivity) => (
              <ClinicalActivityActionMenu item={record} />
            )
          });
        }

        if (showType) {
          columns.push({
            key: ClinicalActivityTableLabels.Type,
            isRowHeader: true,
            minWidth: 160,
            maxWidth: 160,
            name: ClinicalActivityTableLabels.Type,
            isMultiline: true,
            onRender: renderActivityType
          });
        }

        if (showPatientName) {
          columns.push({
            key: ClinicalActivityTableLabels.Patient,
            minWidth: 100,
            maxWidth: 150,
            name: ClinicalActivityTableLabels.Patient,
            isMultiline: true,
            onRender: (item: ClinicalActivity) => (
              <Observer>
                {() => (
                  <ContactFetcher
                    fallback={<Spinner />}
                    contactId={item.patientId}
                  >
                    {(patient: Contact) => {
                      return checkConfidentailAccess(
                        item,
                        <TooltipHost content={patient.name}>
                          <Persona
                            size={PersonaSize.size24}
                            id={patient.id}
                            text={patient.name}
                            imageUrl={patient.profilePictureUrl}
                            imageInitials={patient.initials}
                            onRenderPrimaryText={() => (
                              <Text>{`${patient.firstName} ${patient.lastName}`}</Text>
                            )}
                          />
                        </TooltipHost>
                      );
                    }}
                  </ContactFetcher>
                )}
              </Observer>
            )
          });
        }

        columns.push({
          key: ClinicalActivityTableLabels.Status,
          isRowHeader: true,
          minWidth: 100,
          maxWidth: 130,
          name: ClinicalActivityTableLabels.Status,
          isMultiline: true,
          onRender: renderStatusBadgeAndIcon
        });

        if (
          core.hasPermissions([
            Permission.ClaimLodgementAllowed,
            Permission.ClaimRead
          ])
        ) {
          columns.splice(3, 0, {
            key: ClinicalActivityTableLabels.ClaimId,
            minWidth: 80,
            maxWidth: 80,
            name: ClinicalActivityTableLabels.ClaimId,
            onRender: (activity: ClinicalActivity) => {
              // Render either the claimNumber or fetch the claim from the actual Id.
              const claimNumber = activity.metadata?.find(
                x => x.key === "ClaimNumber"
              );
              if (claimNumber) {
                return <>{claimNumber.value}</>;
              }

              const claimId = activity.metadata?.find(x => x.key === "ClaimId");
              if (claimId) {
                return (
                  <ClaimDataFetcher claimId={claimId.value}>
                    {claim => {
                      return <>{claim.claimNumber}</>;
                    }}
                  </ClaimDataFetcher>
                );
              }

              return undefined;
            }
          });
        }

        columns.push(
          ...[
            {
              key: ClinicalActivityTableLabels.Due,
              minWidth: 80,
              maxWidth: 80,
              name: ClinicalActivityTableLabels.Due,
              isMultiline: true,
              onRender: (item: ClinicalActivity) =>
                checkConfidentailAccess(
                  item,
                  <Observer>
                    {() => {
                      let fontColour = theme.palette.neutralPrimary;

                      if (
                        item.activityStatus ===
                        ClinicalActivityStatusText.Overdue
                      ) {
                        fontColour = theme.semanticColors.errorText;
                      }

                      if (
                        item.activityStatus ===
                        ClinicalActivityStatusText.Completed
                      ) {
                        fontColour = theme.palette.neutralSecondaryAlt;
                      }

                      return (
                        <Text styles={{ root: { color: fontColour } }}>
                          {item.dueDate
                            ? toSimpleDateString(item.dueDate)
                            : renderVisitsNumber(item)}
                        </Text>
                      );
                    }}
                  </Observer>
                )
            },
            {
              key: ClinicalActivityTableLabels.Details,
              minWidth: 120,
              maxWidth: 200,
              name: ClinicalActivityTableLabels.Details,
              isMultiline: true,
              onRender: (item: ClinicalActivity) => {
                const providerName = core.getUserByPrivateSecGroupId(
                  item.secGroupId
                )?.fullName;

                const activityDescription =
                  clinical.ref.clinicalActivityDescriptions.get(
                    item.descriptionCode
                  );

                const clinicalReminderReason =
                  activityDescription?.text ??
                  clinicalReminderReasons?.find(
                    x => x.reason === item.descriptionCode
                  )?.reason;

                return core.hasAccessToSecGroup(item.secGroupId) ? (
                  <Observer>
                    {() => (
                      <Stack horizontal>
                        {core.hasPermissions(
                          Permission.ClinicalReminderPreferenceAllowed
                        ) ? (
                          <Text>
                            {item.descriptionCode === "OTH"
                              ? item.freeText
                              : clinicalReminderReason}
                          </Text>
                        ) : (
                          <ClinicalActivityDescriptionCodeText
                            code={item.descriptionCode}
                            styles={{
                              root: {
                                minHeight: 18,
                                "& .clampTwoLines": {}
                              }
                            }}
                          />
                        )}
                        <ConfidentialToolTipFontIcon
                          isShowConfidentialIcon={item.secGroupId !== undefined}
                        />
                      </Stack>
                    )}
                  </Observer>
                ) : (
                  <Text>{`Confidential (by ${providerName} )`}</Text>
                );
              }
            }
          ]
        );

        if (showPriority) {
          columns.push({
            key: ClinicalActivityTableLabels.Priority,
            minWidth: 80,
            maxWidth: 80,
            name: ClinicalActivityTableLabels.Priority,
            onRender: (activity: ClinicalActivity) => {
              return <PriorityText code={activity.activityPriority} />;
            }
          });
        }

        columns.push(
          ...[
            {
              key: ClinicalActivityTableLabels.Comments,
              minWidth: 120,
              targetWidthProportion: 1,
              name: ClinicalActivityTableLabels.Comments,
              isMultiline: true,
              onRender: (item: ClinicalActivity) =>
                checkConfidentailAccess(
                  item,
                  <Stack styles={{ root: { whiteSpace: "initial" } }}>
                    {item.comment}
                  </Stack>
                )
            },
            {
              key: ClinicalActivityTableLabels.Created,
              minWidth: 170,
              maxWidth: 170,
              name: ClinicalActivityTableLabels.Created,
              isMultiline: true,
              onRender: onRenderCreated
            }
          ]
        );

        return columns;
      };

      const selection = useRef(
        new Selection<ClinicalActivity & { key: string }>({
          canSelectItem: item => {
            return (
              isItemSelectable(item) &&
              core.hasAccessToSecGroup(item.secGroupId)
            );
          },
          onSelectionChanged: async () => {
            if (onSelectionChanged) {
              const selectedActivities = selection.current.getSelection();
              onSelectionChanged(selectedActivities);
            }
          }
        })
      );

      const renderDetailsHeader = (
        props: IDetailsHeaderProps,
        defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
      ) => {
        return defaultRender!({
          ...props!,
          styles: {
            root: {
              selectors: {
                "div.ms-DetailsHeader-check .ms-Check": {
                  display: "none"
                }
              }
            }
          }
        });
      };

      return (
        <>
          {children({
            columns: getColumns(),
            selection: selection.current,
            renderRow,
            renderDetailsHeader
          })}
        </>
      );
    }
  );
