import { useEffect, useRef } from "react";

import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

export const usePersistDraftFilter = () => {
  const { userExperience, routing, core } = useStores();
  const mounted = useRef(false);

  const saveFilter = async (filterSearch: string) => {
    const jsonData = filterSearch;
    const prev = await userExperience.getUserStorage(
      UserStorageKeys.DraftItemsFilters
    );
    if (prev) {
      await userExperience.updateUserStorage(
        UserStorageKeys.DraftItemsFilters,
        { ...prev?.dto, jsonData }
      );
    } else {
      await userExperience.addUserStorage(UserStorageKeys.DraftItemsFilters, {
        key: UserStorageKeys.DraftItemsFilters,
        userId: core.userId,
        jsonData
      });
    }
  };

  const debounceSaveFilter = useDebounce(saveFilter);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    debounceSaveFilter(routing.location.search);
  }, [debounceSaveFilter, routing.location.search]);
};
