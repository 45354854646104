import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import {
  greaterThan,
  maxLength,
  predicate,
  required,
  todayOrLater
} from "@libs/validation/fieldValidators.ts";
import { messageWithData } from "@libs/validation/messageWithData.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivityFormValues } from "@shared-types/clinical/clinical-activity-values.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";

import { ClinicalActivityDue } from "./types/clinical-activity-due.type.ts";
import { ClinicalActivityType } from "./types/clinical-activity.type.ts";

const nameOf = nameOfFactory<ClinicalActivityFormValues>();

export class ClinicalActivityValidator extends Validator<ClinicalActivityFormValues> {
  constructor(
    clinicalActivity?: ClinicalActivity,
    root?: IRootStore,
    ptClinicalActivities?: ClinicalActivity[]
  ) {
    super();

    this.forField(nameOf("descriptionCode"), [
      required(),
      (val, values) => {
        if (
          this.duplicateCheck({
            activities: ptClinicalActivities,
            values,
            id: clinicalActivity?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalActivitiesAllowed;
        } else return undefined;
      }
    ]);

    this.forField(nameOf("dueChoice"), [required()]);

    this.forField(
      nameOf("dueDate"),
      predicate(
        (val, values) => values?.dueChoice === ClinicalActivityDue.Date,
        required()
      )
    );

    this.forField(nameOf("comment"), [required(), maxLength(250)], {
      when: (_value, parent) => parent.descriptionCode === "OTH"
    });

    const initialDate = DateTime.jsDateFromISO(clinicalActivity?.dueDate);

    this.forField(nameOf("dueDate"), [
      predicate(
        (val, values) => values?.dueDate?.getTime() !== initialDate?.getTime(),
        todayOrLater
      ),
      (val, values) => {
        if (
          this.duplicateCheck({
            activities: ptClinicalActivities,
            values,
            id: clinicalActivity?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      }
    ]);

    this.forField(nameOf("dueInVisits"), [
      predicate(
        (val, values) => values?.dueChoice === ClinicalActivityDue.Consult,
        required(),
        greaterThan(0)
      ),
      (val, values) => {
        if (
          this.duplicateCheck({
            activities: ptClinicalActivities,
            values,
            id: clinicalActivity?.id
          })
        ) {
          return ValidationMessages.noDuplicateClinicalTasksAllowed;
        } else return undefined;
      },

      (value: number | undefined) => {
        if (!value) return undefined;

        if (value <= 0) {
          // Can't use greaterThan(0) to allow ZERO value.

          return messageWithData(ValidationMessages.greaterThan, 0);
        }

        if (value >= 100) {
          // Can't use lessThan(100) to allow ZERO value.

          return messageWithData(ValidationMessages.lessThan, 100);
        }

        return undefined;
      }
    ]);

    this.forField(nameOf("activityPriority"), [
      predicate(
        (_v, values) => values?.activityType === ClinicalActivityType.Task,
        required()
      )
    ]);
    this.forField(nameOf("comment"), [maxLength(250)]);
    this.forField(nameOf("isLocked"), [
      (val, values) => {
        if (
          val &&
          values?.activityStatus === ClinicalActivityStatusText.Completed &&
          !root?.core?.hasPermissions(Permission.ClinActivityUnlock)
        ) {
          return ValidationMessages.notAbleToCompleteLockedActivities;
        }

        return undefined;
      }
    ]);
  }

  duplicateCheck(options?: {
    activities?: ClinicalActivity[];
    values?: ClinicalActivityFormValues;
    id?: string;
  }) {
    const { activities, values, id } = options!;
    if (activities && (values?.dueDate || values?.dueInVisits)) {
      return activities.some(
        t =>
          t.id !== id &&
          t.activityStatus !== ClinicalActivityStatusText.Completed &&
          !t.isDeleted &&
          values.descriptionCode === t.descriptionCode &&
          values.activityType === t.activityType &&
          ((values.dueInVisits && values.dueInVisits === t.remainingVisits) ||
            (values?.dueDate &&
              t?.dueDate &&
              DateTime.fromJSDate(values.dueDate).equals(
                DateTime.fromISO(t.dueDate)
              )))
      );
    }

    return false;
  }
}
