import { observer } from "mobx-react-lite";
import { FC } from "react";

import { PresetButtonType } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType,
  OrganisationRoleType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import {
  categoriesItem,
  searchFilterItem,
  statusFilterItem
} from "@modules/practice/screens/address-book/components/contacts-filters/contact-filter-items.ts";
import { useParseContactFilters } from "@modules/practice/screens/address-book/components/contacts-filters/useParseContactFilters.ts";
import { IndividualAndOrgContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import {
  FilterBarProps,
  Item
} from "@ui-components/filter-bar/FilterBar.types.ts";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

export const defaultIndividualAndOrganisationFilter: IndividualAndOrgContactFilter =
  {
    categories: [],
    search: "",
    statuses: [ContactStatus.Active],
    organisationRoleTypeCodes: [],
    disciplines: []
  };

const filterKeys = {
  organisationRoleTypeCodes: "organisationRoleTypeCodes",
  isHealthProviderOnly: "isHealthProviderOnly",
  disciplines: "disciplines",
  categories: "categories",
  statuses: "statuses",
  search: "search",
  types: "types"
};

const IndividualAndOrganisationListFilterBase: FC<
  Pick<FilterBarProps<IndividualAndOrgContactFilter>, "children"> & {
    contactType: ContactType;
    presets?: PresetButtonType<IndividualAndOrgContactFilter>[] | undefined;
    disciplineItem?: Item<IndividualAndOrgContactFilter>;
  }
> = observer(({ contactType, presets, disciplineItem, ...props }) => {
  const { practice, core } = useStores();

  const { parseArrayFilter, setLocationFromFilter } =
    useParseContactFilters(filterKeys);

  const initialState = usePersistedHelper(() => {
    const defaultFilter: IndividualAndOrgContactFilter = {
      ...defaultIndividualAndOrganisationFilter
    };

    const filterQuery = { ...defaultFilter, ...parseArrayFilter() };

    const types: ContactType[] = [contactType];

    return {
      types,
      search: filterQuery?.search,
      categories: filterQuery?.categories ?? [],
      disciplines: filterQuery?.disciplines ?? [],
      statuses: filterQuery?.statuses ?? [],
      organisationRoleTypeCodes: filterQuery?.isHealthProviderOnly
        ? [OrganisationRoleType.NzHealthProvider]
        : filterQuery?.organisationRoleTypeCodes,
      isHealthProviderOnly: filterQuery?.isHealthProviderOnly
    };
  });

  const filterItems: Item<IndividualAndOrgContactFilter>[] = [
    searchFilterItem(),
    categoriesItem(practice.ref.organisationCategories.keyTextValues),

    statusFilterItem(
      practice.ref.contactStatuses.keyTextValues.filter(
        x => x.key !== ContactStatus.Deceased && x.key !== ContactStatus.Merged
      )
    )
  ];

  if (
    contactType === ContactType.Organisation &&
    core.hasPermissions(Permission.OrgsRedesignAllowed) &&
    disciplineItem
  ) {
    filterItems.splice(filterItems.length - 1, 0, disciplineItem);
  }

  return (
    <FilterBar<IndividualAndOrgContactFilter>
      {...props}
      onChange={({ field, value }) => setLocationFromFilter(field, value)}
      items={filterItems}
      initialValues={initialState}
      presets={presets}
    />
  );
});

export const IndividualAndOrganisationListFilter = withFetch(
  x => [
    x.practice.ref.contactDisciplines.load(),
    x.practice.ref.organisationCategories.load()
  ],
  IndividualAndOrganisationListFilterBase
);
