import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import {
  ClinicalNoteFormat,
  ClinicalNotesSubHeadings
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

import { EncounterFormContext } from "../clinical-form/models/EncounterFormContext.ts";
import { FreeTextNote } from "./FreeTextNote/FreeTextNote.tsx";
import { TodaysNoteHeading } from "./TodaysNoteHeading.tsx";

const NOTES_UPDATE_DELAY: number = 1000;
export const TodaysNotes: React.FunctionComponent = observer(() => {
  const { values } = useFormState<EncounterFormValues>();
  const {
    clinicalRecord,
    updateEncounterClinicalNote,
    clinical,
    isRecordUpdate
  } = useContext(EncounterFormContext);

  const debounceUpdateEncounterClinicalNote = useDebounce(
    updateEncounterClinicalNote,
    NOTES_UPDATE_DELAY
  );

  const tab = clinical.ui.tabs.currentPatientRecordTab;
  const { userExperience } = useStores();
  const notesFormat = userExperience.settings.ClinicalNotesFormat;

  return (
    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { paddingRight: 16 } }}>
      {(isRecordUpdate ||
        (notesFormat !== ClinicalNoteFormat.SOTAP &&
          notesFormat !== ClinicalNoteFormat.SOAP)) && (
        <FreeTextNote
          heading="Consultation"
          boldHeading
          defaultValue={clinicalRecord?.mainFreeText ?? ""}
          onChange={value => {
            debounceUpdateEncounterClinicalNote(
              value,
              toJS(clinicalRecord.notesHeadings),
              values
            );
          }}
        />
      )}
      {clinicalRecord.notesHeadings.map((heading, index) => {
        const perDMRUserSetting = tab?.getTodaysNotesUserSettings(
          heading,
          ClinicalNotesSubHeadings.DMRImages
        );

        const perCNSUserSetting = tab?.getTodaysNotesUserSettings(
          heading,
          ClinicalNotesSubHeadings.CNSImages
        );

        const perUserSettings = tab?.getTodaysNotesUserSettings(heading);
        const showImages = perUserSettings ? perUserSettings.showImage : true;
        const showDMRImages = perDMRUserSetting
          ? perDMRUserSetting.showImage
          : true;

        const showCNSImages = perCNSUserSetting
          ? perCNSUserSetting.showImage
          : true;

        return (
          <TodaysNoteHeading
            key={heading.code}
            heading={heading}
            onChange={async value => {
              const notesHeadings = toJS(clinicalRecord?.notesHeadings);
              notesHeadings[index].freeText = value;
              debounceUpdateEncounterClinicalNote(
                toJS(clinicalRecord?.mainFreeText),
                notesHeadings,
                values
              );
            }}
            showImages={showImages}
            showDMRImages={showDMRImages}
            showCNSImages={showCNSImages}
            setShowImages={(val: boolean, key: string) => {
              tab?.setTodaysNotesUserSettings(key, { showImage: val });
            }}
          />
        );
      })}
    </Stack>
  );
});
