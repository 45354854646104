import {
  IColumn,
  IRenderFunction,
  mergeStyles,
  noWrap,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactStatus,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { IndividualAndOrganisationListFilter } from "@modules/practice/screens/address-book/components/contacts-filters/IndividualAndOrganisationListFilter.tsx";
import {
  categoryColumn,
  emailColumn,
  nameColumn,
  orgCategoryColumn,
  phyAddressColumn,
  prePhoneColumn,
  statusColumn
} from "@modules/practice/screens/address-book/components/contacts-lists/contact-list-columns.tsx";
import { ContactList } from "@modules/practice/screens/address-book/components/contacts-lists/ContactList.tsx";
import { IndividualAndOrgAddressColumn } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationAddressColumn.tsx";
import { IndividualAndOrganisationEmailColumn } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationEmailColumn.tsx";
import { IndividualAndOrgNameColumn } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualAndOrganisationNameColumn.tsx";
import { IndividualOrOrganisationPhoneColumn } from "@modules/practice/screens/address-book/components/contacts-lists/Individual-organisation-columns/IndividualOrOrganisationPhoneColumn.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { NewIndividualAndOrganisationListFilter } from "./components/contacts-filters/NewIndividualAndOrganisationListFilter.tsx";

type ExtractRenderFunctionProp<T> = T extends IRenderFunction<infer U>
  ? U
  : never;
type IDetailsColumnFieldProps = ExtractRenderFunctionProp<
  IColumn["onRenderField"]
>;
type DefaultRender = Parameters<IRenderFunction<IDetailsColumnFieldProps>>[1];

const IndividualAndOrganisationScreenBase = () => {
  const { routing } = useStores();

  const type = !!routes.addressBook.organisations.match(
    routing.location.pathname
  )
    ? ContactType.Organisation
    : ContactType.Individual;

  const cols = [
    nameColumn(),
    prePhoneColumn(),
    emailColumn(),
    phyAddressColumn(),
    categoryColumn(),
    statusColumn()
  ];

  return (
    <IndividualAndOrganisationListFilter key={type} contactType={type}>
      {({ values: filter }) => <ContactList columns={cols} filter={filter} />}
    </IndividualAndOrganisationListFilter>
  );
};

export const NewIndividualAndOrganisationScreenBase = () => {
  const { routing, core } = useStores();
  const theme = useTheme();
  const type = !!routes.addressBook.organisations.match(
    routing.location.pathname
  )
    ? ContactType.Organisation
    : ContactType.Individual;

  const onRenderField = (
    props?: IDetailsColumnFieldProps,
    defaultRender?: DefaultRender
  ) => {
    if (props && defaultRender) {
      if (props.item.status === ContactStatus.Inactive) {
        return defaultRender({
          ...props,
          column: {
            ...props.column,
            className: mergeStyles({
              ...noWrap,
              fontStyle: "italic",
              display: "block"
            })
          }
        });
      } else {
        return defaultRender(props)!;
      }
    }
    return null;
  };

  const cols: IColumn[] = [
    {
      ...nameColumn(),
      onRenderField,
      onRender: (contact: Contact) => (
        <IndividualAndOrgNameColumn contact={contact} />
      )
    },
    {
      ...prePhoneColumn(),
      onRenderField,
      onRender: (contact: Contact) => (
        <IndividualOrOrganisationPhoneColumn contact={contact} />
      )
    },
    {
      ...emailColumn(),
      onRenderField,
      onRender: (contact: Contact) => (
        <IndividualAndOrganisationEmailColumn contact={contact} />
      )
    },
    {
      ...phyAddressColumn(),
      onRenderField,
      onRender: (contact: Contact) => (
        <IndividualAndOrgAddressColumn contact={contact} />
      )
    },
    type === ContactType.Organisation &&
    core.hasPermissions(Permission.OrgsRedesignAllowed)
      ? {
          ...orgCategoryColumn(theme),
          onRenderField
        }
      : categoryColumn()
  ];

  return (
    <NewIndividualAndOrganisationListFilter key={type} contactType={type}>
      {({ values: filter }) => <ContactList columns={cols} filter={filter} />}
    </NewIndividualAndOrganisationListFilter>
  );
};

const IndividualAndOrganisationScreen = () => {
  const { core } = useStores();

  return core.hasPermissions(Permission.OrgsRedesignAllowed) ? (
    <NewIndividualAndOrganisationScreenBase />
  ) : (
    <IndividualAndOrganisationScreenBase />
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default IndividualAndOrganisationScreen;
