import { DateTime } from "@bps/utils";
import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityDto,
  ClinicalActivityMetadataItem,
  ClinicalTaskDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalTaskFormValues } from "@shared-types/clinical/clinical-task-values.type.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityType
} from "../clinical-activity/types/clinical-activity.type.ts";
import { ClinicalTaskDue } from "./types/clinical-task-due.enum.ts";

export class ClinicalTaskFormModel {
  constructor(
    private clinical: ClinicalStore,
    private core: CoreStore,
    private clinicalTask?: ClinicalTask
  ) {}

  get initialValues() {
    return this.clinicalTask
      ? {
          id: this.clinicalTask.id,
          taskType: this.clinicalTask.taskType,
          dueChoice: this.clinicalTask.dueDate
            ? ClinicalTaskDue.Date
            : ClinicalTaskDue.Consult,
          dueDate: DateTime.jsDateFromISO(this.clinicalTask.dueDate),
          dueInVisits: this.clinicalTask.dueInVisits ?? 0,
          remainingVisits: this.clinicalTask.remainingVisits,
          priority: this.clinicalTask.priority,
          comment: this.clinicalTask.comment,
          lockedBy: this.clinicalTask.lockedBy,
          isLocked: !!this.clinicalTask.lockedBy,
          isCompleted: this.clinicalTask.isCompleted,
          isSystemGenerated: this.clinicalTask.isSystemGenerated,
          claimNumber: this.clinicalTask.claimNumber,
          secGroupId: this.clinicalTask.secGroupId,
          selectedClaim: this.clinicalTask.claimNumber,
          confidential: !!this.clinicalTask.secGroupId
        }
      : {
          taskType: "",
          dueDate: undefined,
          dueInVisits: 0,
          remainingVisits: 0,
          priority: "",
          isLocked: false,
          isCompleted: false,
          isSystemGenerated: false
        };
  }

  public onSubmit = async (values: ClinicalTaskFormValues) => {
    if (this.clinicalTask) {
      let { dueInVisits, remainingVisits, dueDate } = values;

      if (values.dueChoice === ClinicalTaskDue.Date) {
        dueInVisits = undefined;
        remainingVisits = undefined;
      } else {
        dueDate = undefined;
      }

      const dueDateVal = dueDate
        ? DateTime.jsDateToISODate(dueDate)
        : undefined;

      const item: ClinicalTaskDto = {
        id: this.clinicalTask.id,
        taskType: values.taskType ?? "",
        dueDate: dueDateVal,
        dueInVisits,
        remainingVisits,
        priority: values.priority ?? "",
        comment: values.comment,
        isCompleted: this.clinicalTask.isCompleted,
        completionNotes: this.clinicalTask.completionNotes,
        isDeleted: this.clinicalTask.isDeleted,
        deletedComment: this.clinicalTask.deletedComment,
        isSystemGenerated: this.clinicalTask.isSystemGenerated,
        lockedBy: values.lockedBy,
        claimNumber: values.claimNumber,
        businessRole: values.businessRole,
        patientId: this.clinicalTask.patientId,
        eTag: this.clinicalTask.eTag,
        secGroupId: values.confidential
          ? this.core.user?.privateSecGroupId
          : undefined
      };

      if (
        (this.clinicalTask.dueDate !== undefined &&
          dueInVisits !== undefined) ||
        dueInVisits !== this.clinicalTask.dueInVisits
      ) {
        item.remainingVisits = dueInVisits;
      } else {
        item.remainingVisits = this.clinicalTask.remainingVisits;
      }

      // Time to also attempt to update the clinical activity

      const patientClinicalActivities =
        await this.clinical.getPatientClinicalActivities(
          this.clinicalTask.patientId
        );
      if (patientClinicalActivities && patientClinicalActivities.length > 0) {
        const clinicalActivitiesAssociated = patientClinicalActivities.filter(
          x =>
            x.patientId === this.clinicalTask?.patientId &&
            (x.dueDate === this.clinicalTask?.dueDate ||
              x.dueInVisits === this.clinicalTask?.dueInVisits) &&
            x.activityType === ClinicalActivityType.Task &&
            (x.descriptionCode === this.clinicalTask?.taskType ||
              (x.descriptionCode === ClinicalActivityDescriptionCode.Other &&
                x.freeText &&
                x.freeText === this.clinicalTask.taskType)) &&
            !x.reasonForDelete &&
            x.activityStatus !== ClinicalActivityStatusText.Completed &&
            x.dueInVisits === this.clinicalTask?.dueInVisits &&
            x.dueDate === this.clinicalTask?.dueDate
        );

        if (
          clinicalActivitiesAssociated &&
          clinicalActivitiesAssociated.length > 0
        ) {
          const associatedActivity = clinicalActivitiesAssociated[0];
          if (associatedActivity) {
            const convertedActivity = this.updateActivityBasedOnTask(
              item,
              associatedActivity
            );

            await this.clinical.updateClinicalActivity(
              this.clinicalTask.patientId,
              [convertedActivity]
            );
          }
        }
      }

      await this.clinical.updateClinicalTask(this.clinicalTask.patientId, [
        item
      ]);
    }
  };

  private updateActivityBasedOnTask = (
    clinicalTask: ClinicalTaskDto,
    associatedActivity?: ClinicalActivityDto
  ): ClinicalActivityDto => {
    const newMetadata: ClinicalActivityMetadataItem[] = [];

    if (clinicalTask.claimNumber) {
      newMetadata.push({ key: "ClaimNumber", value: clinicalTask.claimNumber });
    }

    let noMatchingDescription = false;

    const description = this.matchTaskTypeWithActivityDescription(
      clinicalTask.taskType
    );

    if (!description) {
      noMatchingDescription = true;
    }

    const result: ClinicalActivityDto = {
      id: associatedActivity?.id ?? EMPTY_GUID,
      descriptionCode: description ?? ClinicalActivityDescriptionCode.Other,
      activityPriority: clinicalTask.priority,
      activityType: ClinicalActivityType.Task,
      activityStatus: clinicalTask.isCompleted
        ? ClinicalActivityStatus.Completed
        : ClinicalActivityStatus.InProgress,
      isDeleted: clinicalTask.isDeleted,
      eTag: associatedActivity?.eTag ?? "",
      patientId: clinicalTask.patientId,
      dueDate: clinicalTask.dueDate,
      dueInVisits: clinicalTask.dueInVisits,
      remainingVisits: clinicalTask.remainingVisits,
      comment: clinicalTask.comment,
      lockedBy: clinicalTask.lockedBy,
      deletedComment: clinicalTask.deletedComment,
      isSystemGenerated: clinicalTask.isSystemGenerated,
      businessRole: clinicalTask.businessRole,
      secGroupId: clinicalTask.secGroupId,
      completedBy: clinicalTask.completedBy,
      completedDate: clinicalTask.completedDate,
      freeText: noMatchingDescription ? clinicalTask.taskType : undefined,
      metadata: newMetadata
    };

    return result;
  };

  public matchTaskTypeWithActivityDescription = (
    activityTaskCode: string | undefined
  ) => {
    if (!activityTaskCode) {
      return undefined;
    }

    const descriptions =
      this.clinical.ref.clinicalActivityDescriptions.keyTextValues;

    const attemptedMatches = descriptions.filter(
      x => x.key === activityTaskCode
    );

    if (attemptedMatches && attemptedMatches.length > 0) {
      return attemptedMatches[0].key;
    } else {
      // Assign just a basic "Other"
      return undefined;
    }
  };
}
