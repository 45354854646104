import { DateTime } from "@bps/utils";
import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityDto,
  ClinicalActivityMetadataItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityFormValues } from "@shared-types/clinical/clinical-activity-values.type.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { ClinicalActivityStatus } from "../../../../../../shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalActivityDue } from "./types/clinical-activity-due.type.ts";
import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityType
} from "./types/clinical-activity.type.ts";

export class ClinicalActivityFormModel {
  constructor(
    private clinical: ClinicalStore,
    private core: CoreStore,
    private activity?: ClinicalActivity
  ) {}

  get initialValues() {
    if (this.activity) {
      let claimIdString = "";

      if (this.activity.metadata) {
        const claimMetadataItems = this.activity.metadata.filter(
          x => x.key === "ClaimNumber"
        );
        if (claimMetadataItems && claimMetadataItems.length > 0) {
          claimIdString = claimMetadataItems[0].value;
        }
      }

      return {
        id: this.activity.id,
        activityType: this.activity.activityType,
        dueChoice: this.activity.dueDate
          ? ClinicalActivityDue.Date
          : ClinicalActivityDue.Consult,
        dueDate: DateTime.jsDateFromISO(this.activity.dueDate),
        dueInVisits: this.activity.dueInVisits ?? 0,
        activityPriority: this.activity.activityPriority,
        activityStatus: ClinicalActivityStatus.New,
        remainingVisits: this.activity.remainingVisits,
        comment: this.activity.comment,
        lockedBy: this.activity.lockedBy,
        isLocked: !!this.activity.lockedBy,
        isSystemGenerated: this.activity.isSystemGenerated,
        secGroupId: this.activity.secGroupId,
        confidential: !!this.activity.secGroupId,
        descriptionCode: this.activity.descriptionCode,
        freeText: this.activity.freeText,
        isDeleted: false,
        taskSelectedClaim: claimIdString ?? undefined
      };
    } else {
      return {
        activityType: ClinicalActivityType.Task,
        dueDate: undefined,
        dueInVisits: 0,
        descriptionCode: "",
        activityPriority: "",
        isLocked: false,
        isSystemGenerated: false,
        activityStatus: ClinicalActivityStatus.New,
        isDeleted: false,
        taskSelectedClaim: undefined
      };
    }
  }

  public onSubmit = async (values: ClinicalActivityFormValues) => {
    if (this.activity) {
      let { dueInVisits, remainingVisits, dueDate, isSystemGenerated } = values;

      if (values.dueChoice === ClinicalActivityDue.Date) {
        dueInVisits = undefined;
        remainingVisits = undefined;
      } else {
        dueDate = undefined;

        isSystemGenerated = this.activity.isSystemGenerated;
        if (this.activity.dueInVisits !== values.dueInVisits)
          remainingVisits = values.dueInVisits;
      }

      const metadata: ClinicalActivityMetadataItem[] = values.metadata ?? [];

      if (values.taskSelectedClaim) {
        metadata.push({ key: "ClaimNumber", value: values.taskSelectedClaim });
      }

      const dueDateVal = dueDate
        ? DateTime.jsDateToISODate(dueDate)
        : undefined;

      const item: ClinicalActivityDto = {
        id: values.id ?? EMPTY_GUID,
        patientId: this.activity?.patientId,
        activityType: values.activityType,
        descriptionCode: values.freeText
          ? ClinicalActivityDescriptionCode.Other
          : values.descriptionCode,
        freeText: values.freeText,
        activityStatus: values.activityStatus,
        dueDate: dueDateVal,
        dueInVisits,
        remainingVisits,
        activityPriority: values.activityPriority,
        comment: values.comment,
        lockedBy: values.lockedBy,
        isSystemGenerated,
        metadata,
        businessRole: values.businessRole,
        secGroupId: values.confidential
          ? this.core.user?.privateSecGroupId
          : undefined,
        isDeleted: false,
        eTag: this.activity.eTag
      };

      await this.clinical.updateClinicalActivity(this.activity.patientId, [
        item
      ]);
    }
  };
}
