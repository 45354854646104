import { observer } from "mobx-react-lite";

import { CenteredLargeSpinner, Dialog, Heading } from "@bps/fluent-ui";
import { compareDatesPredicate } from "@bps/utils";
import { useDialogOpenedAnalytics } from "@libs/analytics/hooks/useDialogOpenedAnalytics.ts";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentInformationModalContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalContext.ts";
import { AppointmentInformationModalHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/appointment-information/AppointmentInformationModalHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePersistedHelper } from "@ui-components/hooks/usePersistedHelper.ts";

import { AppointmentInformationModalPrintWrapper } from "./AppointmentInformationModalPrintWrapper.tsx";
import { AppointmentInformationTable } from "./AppointmentInformationTable.tsx";

type AppointmentInformationModalProps = {
  contactId: string;
  patientName: string;
  isHidden?: boolean;
  selectedId?: string;
  onDismissInfoModal: () => void;
};
export const AppointmentInformationModal: React.FunctionComponent<AppointmentInformationModalProps> =
  observer(
    ({ contactId, isHidden, selectedId, onDismissInfoModal, patientName }) => {
      const root = useStores();
      const helper = usePersistedHelper(
        () => new AppointmentInformationModalHelper(root)
      );
      useDialogOpenedAnalytics(
        "Appointment information dialog",
        root.core.user?.fullName,
        isHidden
      );

      const title = (
        <Heading variant="modal-heading">
          Appointment information for {patientName}
        </Heading>
      );

      return (
        <Dialog
          hidden={isHidden}
          onDismiss={onDismissInfoModal}
          dialogContentProps={{
            title,
            showCloseButton: true,
            styles: {
              content: { height: "100%" },
              inner: {
                paddingLeft: 24,
                paddingRight: 24
              }
            }
          }}
          minWidth={900}
        >
          <AppointmentInformationModalContext.Provider value={helper}>
            <AppointmentInformationModalPrintWrapper title={title}>
              <DataFetcher
                fetch={async ({ booking }) => {
                  const calendarEvents = await booking.getCalendarEvents({
                    statuses: [
                      CalendarEventStatus.Confirmed,
                      CalendarEventStatus.Cancelled
                    ],
                    attendees: [contactId]
                  });

                  return Array.from(calendarEvents.results).sort((a, b) =>
                    compareDatesPredicate(
                      a.startDateTime,
                      b.startDateTime,
                      true
                    )
                  );
                }}
                fallback={<CenteredLargeSpinner />}
              >
                {appointments => (
                  <AppointmentInformationTable
                    selectedId={selectedId}
                    onDismiss={onDismissInfoModal}
                    items={appointments}
                  />
                )}
              </DataFetcher>
            </AppointmentInformationModalPrintWrapper>
          </AppointmentInformationModalContext.Provider>
        </Dialog>
      );
    }
  );
