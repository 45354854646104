import { DateTime, newGuid, upsertItem } from "@bps/utils";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  AddDocumentDto,
  ClinicalActivityClinicalDataItemDto,
  ClinicalTaskClinicalDataItemDto,
  ClinicalTaskDescription,
  ClinicalTaskPriority,
  CorrespondenceDirection,
  CorrespondenceStatus,
  CorrespondenceType,
  CorrespondenceVisibility,
  DiagnosisDataItemDto,
  DocumentContentType,
  DocumentCreateOptions,
  DocumentEnum,
  DocumentExtensionType,
  DocumentMetadataItem,
  DocumentSource,
  EncounterClinicalDataDto,
  ImagingModalityDataItemDto,
  ImagingRequestDataItemDto,
  RequestStatus,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  TemplateArgs,
  TemplateNameDescription,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { DocumentEntityType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityPriority,
  ClinicalActivityType
} from "../../clinical-activity/types/clinical-activity.type.ts";
import {
  ImagingRequestFormValues,
  Modality
} from "./ImagingRequestDialog.types.ts";

export class ImagingRequestHelper {
  constructor(
    public clinicalRecord: ClinicalRecord,
    public root: RootStore
  ) {}
  documentId: string | undefined;
  buildDiagnosisData = (diagnosis: string) => {
    const keyAndText = diagnosis.split(".");

    if (keyAndText.length !== 2) {
      throw new Error(
        `Diagnosis data is malformed. Expected two elements. ${diagnosis}`
      );
    }

    const key = keyAndText[0];
    const text = keyAndText[1];

    const diagnosisKey = this.root.clinical.getTerminologyKey(key, text);
    const item = this.root.clinical.getTerminologyFromMap(diagnosisKey);
    return {
      diagnosisCode: {
        code: key,
        originalText: text,
        codeSystem: item?.codeSystem,
        diagnosisKey,
        version: item?.version,
        diagnosis: item?.code,
        readCodes: item?.readCodes
      }
    };
  };

  getClinicalDocument = async () => {
    const documentId = this.documentId;
    if (documentId) {
      return await this.root.correspondence.getInvestigationByDocumentId(
        this.clinicalRecord.id,
        documentId
      );
    }
    return;
  };
  submitImagingRequest = async (values: ImagingRequestFormValues) => {
    if (!this.clinicalRecord || !this.clinicalRecord.patient) return;

    let diagnosis: DiagnosisDataItemDto[] = [];
    const patientId = this.clinicalRecord.patient.id;
    const userId = this.root.core!.user!.id;
    const documentId = newGuid();

    const secGroupId = this.clinicalRecord.core.user?.privateSecGroupId;

    this.documentId = documentId;

    if (values.provisionalDiagnosis) {
      diagnosis = values.provisionalDiagnosis?.map(this.buildDiagnosisData);
    }

    const sidesOfBody = this.root.clinical.ref.sidesOfBody;

    const modalities: ImagingModalityDataItemDto[] = values.modalities.map(
      m => {
        return {
          modality: m.modality,
          regions: m.regions
            .filter(x => x.region !== "")
            .map(r => {
              return {
                region: r.region,
                side: r.side
                  .filter(s => s !== "")
                  .map(s => {
                    const side = sidesOfBody.map.get(s);
                    const sideOfBody = {
                      id: newGuid(),
                      isActive: true,
                      ...side
                    } as RefDataDto<string>;
                    return sideOfBody!;
                  }),
                customViews: r.customViewsDetails,
                weightBearing: r.weightBearing,
                other: r.otherDetails
              };
            })
        };
      }
    );

    const showOnTimeline =
      values.visibility === CorrespondenceVisibility.DisplayOnTimeline;

    const confidential =
      values.visibility === CorrespondenceVisibility.Confidential;

    const newImagingRequest: Omit<ImagingRequestDataItemDto, "id"> = {
      requestDate: DateTime.fromJSDate(values.requestDate).toISODate(),
      urgent: values.urgent,
      patientId,
      to: values.to,
      from: userId,
      location: this.root.core.location.id,
      modalities,
      additionalServiceInformation: values.additionalServiceInformation,
      clinicalHistory: values.clinicalHistory,
      provisionalDiagnoses: diagnosis,
      currentMedications: values.currentMedications,
      documentId,
      status: RequestStatus.Requested,
      secGroupId: confidential ? secGroupId : undefined,
      episodeOfCareId: values.episodeOfCareId,
      pregnant: values.pregnant
    };

    const encounterClinicalData: EncounterClinicalDataDto = {
      imagingRequests: {
        eTag: this.clinicalRecord.clinicalData?.imagingRequests?.eTag,
        imagingRequests: [
          ...this.clinicalRecord.imagingRequests,
          newImagingRequest
        ]
      }
    };

    let clinicalData = await this.clinicalRecord.saveClinicalData(
      encounterClinicalData
    );

    if (values.createClinicalTask) {
      if (this.root.core.hasPermissions(Permission.ClinActivityAllowed)) {
        clinicalData = await this.createClinicalActivity(values);
      } else {
        clinicalData = await this.createClinicalTask(values);
      }
    }

    if (clinicalData) {
      await this.createDocument(clinicalData, documentId, showOnTimeline);
    }
  };

  createDocument = async (
    clinicalData: EncounterClinicalDataDto,
    documentId: string,
    showOnTimeline?: boolean
  ) => {
    const encounterId = this.clinicalRecord.openEncounter?.id;
    const patientId = this.clinicalRecord!.patient!.id;
    const userId = this.root.core!.user!.id;

    if (encounterId) {
      const imageRequest = clinicalData.imagingRequests?.imagingRequests?.find(
        x => x.documentId === documentId
      );

      if (imageRequest) {
        // get templates to get the needed templateId
        const templateArgs: TemplateArgs = {
          name: TemplateNameDescription.imagingRequest
        };

        const templates = await this.root.document.getTemplates(templateArgs);
        const imageRequestTemplate = templates.find(
          x => x.name === TemplateNameDescription.imagingRequest
        );

        const context: { [id: string]: string } = {};
        context["PatientId"] = patientId;
        context["UserId"] = userId;

        const condition = this.clinicalRecord.getConditionByEocId(
          imageRequest.episodeOfCareId
        );

        if (condition?.claim?.id) context["ClaimId"] = condition?.claim?.id;

        if (this.clinicalRecord.openEncounter) {
          context["EncounterId"] = encounterId;
        }

        if (imageRequest.to) {
          context["ContactId"] = imageRequest.to;
        }

        context["ImagingRequestDocumentId"] = imageRequest.documentId!;

        const parameters: { [id: string]: string } = {};

        const renderOptions: TemplateRenderOptions = {
          context,
          contentType: DocumentContentType.Sfdt,
          parameters
        };
        if (imageRequestTemplate) {
          const renderedDocument = await this.root.document.renderTemplate(
            imageRequestTemplate.id,
            renderOptions
          );

          const metadata: DocumentMetadataItem[] = [
            {
              key: DocumentEnum.Date,
              value: DateTime.now().toISODate()
            },
            {
              key: DocumentEnum.Extension,
              value: DocumentExtensionType.Docx
            },
            {
              key: DocumentEnum.Name,
              value: this.getFileNameClinicalData(imageRequest.modalities)
            },
            {
              key: DocumentEnum.TemplateId,
              value: imageRequestTemplate.id
            },
            {
              key: DocumentEnum.EncounterId,
              value: encounterId
            },
            {
              key: DocumentEnum.ContentType,
              value: DocumentContentType.Sfdt
            },
            {
              key: DocumentEnum.SecGroupId,
              value: imageRequest.secGroupId
            }
          ];

          if (showOnTimeline) {
            metadata.push({
              key: DocumentEnum.ShowOnTimeline,
              value: true.toString()
            });
          }

          const dto: AddDocumentDto = {
            id: documentId,
            patientId,
            to: imageRequest.to,
            from: userId,
            type: CorrespondenceType.Referral,
            direction: CorrespondenceDirection.Out,
            status: CorrespondenceStatus.Done,
            content: renderedDocument.content ?? "",
            store: StoreType.Investigations,
            metadata,
            secGroupId: imageRequest.secGroupId
          };

          const options: DocumentCreateOptions = {
            source: DocumentSource.Content,
            documents: [dto]
          };

          await this.root.correspondence.addDocuments(encounterId, options);
        }
      }
    }
  };

  createClinicalActivity = async (values: ImagingRequestFormValues) => {
    const dueDateVal = values.clinicalTaskDueDate
      ? DateTime.jsDateToISODate(values.clinicalTaskDueDate)
      : undefined;

    const comment = this.getFileName(values.modalities);

    const item: ClinicalActivityClinicalDataItemDto = {
      activityType: ClinicalActivityType.Task,
      descriptionCode: ClinicalActivityDescriptionCode.FollowUpRequest,
      id: EMPTY_GUID,
      patientId: this.clinicalRecord.id,
      dueDate: dueDateVal,
      activityPriority: ClinicalActivityPriority.High,
      activityStatus: ClinicalActivityStatus.InProgress,
      comment,
      isSystemGenerated: false
    };

    const clinicalActivity = this.clinicalRecord.clinicalData?.clinicalActivity;

    const clinicalData = await this.clinicalRecord.saveClinicalData({
      clinicalActivity: {
        ...clinicalActivity,
        clinicalActivities: [
          ...(clinicalActivity?.clinicalActivities || []),
          item
        ]
      }
    });

    this.clinicalRecord.loadClinicalActivities();
    return clinicalData;
  };

  createClinicalTask = async (values: ImagingRequestFormValues) => {
    const dueDateVal = values.clinicalTaskDueDate
      ? DateTime.jsDateToISODate(values.clinicalTaskDueDate)
      : undefined;

    const comment = this.getFileName(values.modalities);

    const item: ClinicalTaskClinicalDataItemDto = {
      taskType: ClinicalTaskDescription.FollowUpRequest,
      id: EMPTY_GUID,
      patientId: this.clinicalRecord.id,
      dueDate: dueDateVal,
      priority: ClinicalTaskPriority.High,
      comment,
      isSystemGenerated: false,
      isCompleted: false
    };

    const clinicalTask = this.clinicalRecord.clinicalData?.clinicalTask;

    const clinicalData = await this.clinicalRecord.saveClinicalData({
      clinicalTask: {
        ...clinicalTask,
        clinicalTasks: upsertItem({
          item,
          array: clinicalTask?.clinicalTasks ?? [],
          predicate: () => false
        })
      }
    });
    this.clinicalRecord.setPatientClinicalTasksPromise();
    return clinicalData;
  };

  getSasUri = async () => {
    if (this.documentId) {
      return await this.root.inbox.createPdf(
        {
          entityType: DocumentEntityType.Patient,
          entityId: this.clinicalRecord!.patient!.id,
          documentId: this.documentId
        },
        DocumentExtensionType.Docx
      );
    }
    return;
  };

  getFileNameClinicalData = (modalities: ImagingModalityDataItemDto[]) => {
    let name = "";
    modalities.forEach(modality => {
      if (modality.modality) {
        name = `${name}${this.getModalityText(modality.modality)} -`;
        modality.regions?.forEach(region => {
          if (region.region) {
            name = `${name} ${this.getRegionText(region.region)}`;
            if (region.side && region.side.length !== 0) {
              name = `${name},`;
            }
            if (region.side?.length) {
              name += "(";
              name += region.side.map(x => x.code).join(",");
              name += ")";
            }
          }
        });
      }
    });
    return name;
  };

  getFileName = (modalities: Modality[]) => {
    let name = "";
    modalities.forEach(modality => {
      if (modality.modality) {
        name = `${name}${this.getModalityText(modality.modality)} -`;
        modality.regions?.forEach(region => {
          if (region.region) {
            name = `${name}${this.getRegionText(region.region)}`;
            if (region.side && region.side.length !== 0) {
              name = `${name},`;
            }
            if (region.side?.length) {
              name += "(";
              name += region.side.join(",");
              name += ") ";
            }
          }
        });
      }
    });
    return name;
  };

  getModalityText = (modality: string) => {
    const refModalities =
      this.root.clinical.ref.imagingModalities.keyTextValues;
    return refModalities.find(x => x.key === modality)?.text;
  };

  getRegionText = (region: string) => {
    const refRegions = this.root.clinical.ref.imagingRegions.keyTextValues;
    return refRegions.find(x => x.key === region)?.text;
  };
}
