import React from "react";
import { useForm } from "react-final-form";

import { Heading, Link, Separator, Stack } from "@bps/fluent-ui";
import {
  clinicalInformationValues,
  clinicalOptions,
  demographicDataValues,
  demographicOptions,
  exportPatientRecordFormNameOf,
  formatOptions
} from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordFormFields.types.ts";
import { ExportPatientRecordFormValues } from "@shared-types/practice/export-patient-record-form-values.types.ts";
import { CheckboxGroupField } from "@ui-components/form/CheckboxGroupField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";

const SELECT_ALL = "Select all";
export const ExportPatientRecordModalFormFields = () => {
  const form = useForm<ExportPatientRecordFormValues>();

  const onDemographicDataSelectAllClick = () => {
    form.change(
      exportPatientRecordFormNameOf("demographicData"),
      demographicDataValues
    );
  };

  const onClinicalInformationSelectAllClick = () => {
    form.change(
      exportPatientRecordFormNameOf("clinicalInformation"),
      clinicalInformationValues
    );
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Heading variant="section-sub-heading">Demographic data</Heading>
        <Link onClick={onDemographicDataSelectAllClick}>{SELECT_ALL}</Link>
      </Stack>
      <CheckboxGroupField
        name={exportPatientRecordFormNameOf("demographicData")}
        horizontal
        wrap
        checkboxWidth={265}
        options={demographicOptions}
      />
      <Separator />
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Heading variant="section-sub-heading">Clinical information</Heading>
        <Link onClick={onClinicalInformationSelectAllClick}>{SELECT_ALL}</Link>
      </Stack>
      <CheckboxGroupField
        name={exportPatientRecordFormNameOf("clinicalInformation")}
        horizontal
        wrap
        checkboxWidth={265}
        options={clinicalOptions}
      />
      <Separator />
      <Heading variant="section-sub-heading">Export details</Heading>
      <Stack
        tokens={{ childrenGap: 8 }}
        horizontal
        horizontalAlign="space-between"
      >
        <Stack.Item grow={1} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("format")}
            placeholder="Format"
            label="Format"
            options={formatOptions}
          />
        </Stack.Item>
        <Stack.Item grow={2} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("method")}
            placeholder="Method"
            label="Method"
            options={[]}
          />
        </Stack.Item>
        <Stack.Item grow={2} basis={0}>
          <DropdownField
            name={exportPatientRecordFormNameOf("contactDetails")}
            placeholder="Contact details"
            label="Contact details"
            options={[]}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
