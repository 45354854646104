import { observer } from "mobx-react-lite";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";

import { ScheduleFields } from "./ScheduleFields.types.ts";

interface UserWorkingHoursWarningMessagesProps {
  displayAppointmentWarning: boolean;
}

export const UserWorkingHoursWarningMessages = observer(
  (props: UserWorkingHoursWarningMessagesProps) => {
    const { core } = useStores();

    return (
      <>
        {core.hasMultiLocationOrgUnit && (
          <FieldCondition
            when="userWorkingHours"
            is={(v: ScheduleFields[]) => v.length > 1}
          >
            <MessageBar messageBarType={MessageBarType.warning}>
              <span>
                When adding subsequent working hours, please check for overlaps.
              </span>
            </MessageBar>
          </FieldCondition>
        )}
        {props.displayAppointmentWarning && (
          <MessageBar messageBarType={MessageBarType.warning}>
            There may be appointments affected by this change in working hours
            which may need to be manually rescheduled
          </MessageBar>
        )}
      </>
    );
  }
);
