import { FontSizes, FontWeights, ITheme } from "@bps/fluent-ui";

export const getConditionsButtonStyles = (theme: ITheme) => {
  return {
    defaultButton: {
      root: {
        width: "100%",
        borderColor: theme.palette.neutralLight,
        height: "auto",
        textAlign: "left",
        padding: 0,
        marginBottom: 8
      }
    },
    diagnosis: {
      root: {
        lineHeight: 20
      }
    },
    subText: {
      root: {
        color: theme.palette.neutralSecondary,
        lineHeight: 20,
        fontSize: 12,
        marginRight: 8
      }
    },
    visitsRemaining: {
      root: {
        alignSelf: "initial",
        fontWeight: FontWeights.regular,
        fontSize: 10,
        padding: "4px 8px",
        width: "fit-content",
        marginTop: 4,
        lineHeight: 13.62
      }
    }
  };
};

export const getConditionsSidePanelStyles = () => {
  return {
    heading: { root: { padding: "5px 0" } },
    headingRender: { headerCount: { display: "none" } },
    groupList: {
      root: {
        paddingRight: 16
      }
    }
  };
};

export const getDischargeStyles = (theme: ITheme) => {
  return {
    dischargeBox: {
      root: {
        fontSize: FontSizes.size14,
        border: "1px solid",
        borderColor: theme.palette.neutralLight,
        padding: "12px 16px",
        marginBottom: 8
      }
    }
  };
};
