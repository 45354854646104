import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  IColumn,
  NoDataTile,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { RecentPatientDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { RecentPatientsAreaCell } from "./RecentPatientsAreaCell.tsx";
import { RecentPatientsDetailsCell } from "./RecentPatientsDetailsCell.tsx";
import { RecentPatientsFilter } from "./RecentPatientsFilter.tsx";
import { recentPatientFilterOptions } from "./RecentPatientsFilter.types.ts";

type RecentPatientDtoWithName = RecentPatientDto & { name: string | undefined };

export const RecentPatientsList: FC = observer(() => {
  const columns: IColumn[] = [
    {
      name: "When",
      fieldName: nameof<RecentPatientDto>("eventTime"),
      key: nameof<RecentPatientDto>("eventTime"),
      onRender: (item: RecentPatientDtoWithName) => (
        <Text>
          {DateTime.fromISOOrNow(item.eventTime).toFormat(
            DATE_FORMATS.LONG_DATE_TIME_FORMAT_REVERSED
          )}
        </Text>
      ),
      minWidth: 180,
      maxWidth: 180
    },
    {
      name: "Patient",
      fieldName: nameof<RecentPatientDto>("patientId"),
      key: nameof<RecentPatientDto>("patientId"),
      onRender: (item: RecentPatientDtoWithName) => <Text>{item.name}</Text>,
      minWidth: 200,
      maxWidth: 320
    },
    {
      name: "Event",
      fieldName: nameof<RecentPatientDto>("eventType"),
      key: nameof<RecentPatientDto>("eventType"),
      onRender: (item: RecentPatientDtoWithName) => (
        <RecentPatientsAreaCell recentPatient={item} />
      ),
      minWidth: 200,
      maxWidth: 320
    },
    {
      name: "Details",
      fieldName: nameof<RecentPatientDto>("eventVerb"),
      key: nameof<RecentPatientDtoWithName>("eventVerb"),
      minWidth: 200,
      onRender: (item: RecentPatientDtoWithName) => (
        <RecentPatientsDetailsCell recentPatient={item} />
      )
    }
  ];

  return (
    <Stack
      grow
      styles={{
        root: {
          height: "100%"
        }
      }}
    >
      <RecentPatientsFilter>
        {({ values: filter }, { reset }) => (
          <DataFetcher<RecentPatientDtoWithName[]>
            refetchId={JSON.stringify(filter)}
            fetch={async ({ userExperience, practice }) => {
              const option = filter.eventCode
                ? recentPatientFilterOptions.find(
                    option => option.key === filter.eventCode
                  )
                : undefined;

              const recentPatients = await userExperience.getRecentPatients({
                patientIds: filter.patientIds,
                eventVerb: option?.data?.verb,
                eventType: option?.data?.type,
                take: 40
              });

              const contacts = await practice.getContactsById(
                recentPatients.results.map(x => x.patientId)
              );

              return recentPatients.results.map(x => ({
                ...x,
                name: contacts.find(c => c.id === x.patientId)?.reversedName
              }));
            }}
            noExceptionsHandlers
          >
            {(result, loading, error) =>
              loading || error || result?.length ? (
                <div style={{ position: "relative", flexGrow: 1, margin: 16 }}>
                  <ScrollablePane
                    scrollbarVisibility={ScrollbarVisibility.auto}
                  >
                    <div data-people-loading={loading}>
                      <ShimmeredDetailsList
                        stickyHeader
                        errorMessage={error?.message}
                        enableShimmer={loading}
                        columns={columns}
                        items={result ?? []}
                      />{" "}
                    </div>
                  </ScrollablePane>
                </div>
              ) : (
                <NoDataTile
                  styles={{ root: { boxShadow: "none" } }}
                  textProps={{
                    text: "No record recent interactions with patient records"
                  }}
                  linkProps={
                    filter.patientIds || filter.eventCode
                      ? {
                          text: "Clear filter",
                          onClick: () => reset()
                        }
                      : { hidden: true }
                  }
                />
              )
            }
          </DataFetcher>
        )}
      </RecentPatientsFilter>
    </Stack>
  );
});
