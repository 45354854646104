import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";

import { Spinner } from "@bps/fluent-ui";
import { unique } from "@bps/utils";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { LocationsSelect } from "@ui-components/selects/LocationsSelect.tsx";

interface ProviderLocationsSelectProps {
  orgUnitIds: string[];
  setOrgUnitIds: (ids: string[]) => void;
}

export const ProviderLocationsSelect: FC<ProviderLocationsSelectProps> =
  observer(({ orgUnitIds, setOrgUnitIds }) => {
    const { selectedProviders } = useContext(AppointmentFormContext);

    const { core } = useStores();

    if (!core.hasMultiLocationOrgUnit) return null;

    return (
      <DataFetcher
        fetch={({ core }) => core.getUsersByIds(selectedProviders)}
        refetchId={selectedProviders.join()}
        fallback={<Spinner />}
      >
        {(users: User[]) => {
          const userOrgUnits = unique(
            users.flatMap(s => s.availableOrgUnitIds)
          );

          return (
            <LocationsSelect
              onFilter={
                userOrgUnits.length > 0
                  ? options =>
                      options.filter(option =>
                        userOrgUnits.includes(option.key)
                      )
                  : undefined
              }
              placeholder="Select location(s)"
              label="Locations"
              hideSearchOption
              selectedKeys={orgUnitIds}
              required
              multiSelect
              showAllSelected
              onChangeSelectedKeys={(selected: string[]) => {
                setOrgUnitIds(selected);
              }}
            />
          );
        }}
      </DataFetcher>
    );
  });
