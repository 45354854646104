import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  Heading,
  Stack
} from "@bps/fluent-ui";
import { getOriginalInvoiceItemsWithChangeType } from "@modules/billing/screens/billing-history/utils.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

import { InvoiceItemList } from "./InvoiceItemList.tsx";
import { getInvoiceListItems } from "./utils.ts";

export const InvoiceViewItemsList: FunctionComponent = observer(() => {
  const { invoice, accountId, adjustedInvoice } =
    useContext(InvoiceViewContext);

  const originalItems: InvoiceItemFormValue[] = getInvoiceListItems(
    invoice.items
  );

  let invoiceItems: InvoiceItemFormValue[] = [];

  if (adjustedInvoice) {
    const adjustedItems: InvoiceItemFormValue[] = getInvoiceListItems(
      adjustedInvoice.items
    );

    invoiceItems = getOriginalInvoiceItemsWithChangeType(
      originalItems,
      adjustedItems
    );
  } else {
    invoiceItems = originalItems;
  }

  const headingLabel = adjustedInvoice
    ? InvoiceFormLabels.originalInvoiceItems
    : InvoiceFormLabels.invoicedItems;

  return (
    <Stack styles={{ root: { padding: "16px 0" } }}>
      <Heading
        {...dataAttribute(DataAttributes.Element, "original-invoice-label")}
        labelPaddings
        styles={{ root: { fontSize: FontSizes.large } }}
      >
        {headingLabel} ({invoiceItems.length})
      </Heading>

      <Stack.Item
        {...dataAttribute(DataAttributes.Element, "original-items-list")}
      >
        <InvoiceItemList
          invoice={invoice}
          invoiceItems={invoiceItems}
          accountId={accountId}
        />
      </Stack.Item>
    </Stack>
  );
});
