import React from "react";

import { Heading, Spinner, Stack } from "@bps/fluent-ui";
import { DateTime, groupBy } from "@bps/utils";
import {
  IChartProps,
  ILineChartDataPoint,
  ILineChartPoints,
  LineChart
} from "@fluentui/react-charting";
import { MeasurementsFilter } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Measurement } from "@stores/clinical/models/Measurement.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ObservationGraphViewProps {
  clinicalRecord: ClinicalRecord;
}

export const ObservationGraphView: React.FunctionComponent<
  ObservationGraphViewProps
> = ({ clinicalRecord }) => {
  const args: MeasurementsFilter = {
    patientId: clinicalRecord.patient?.id
  };

  const getChartData = (measurements: Measurement[]): ILineChartPoints[] => {
    const measurementsByType = groupBy(measurements, x => x.type);

    const chartData = measurementsByType.map(m => {
      const [key, items] = m;

      const data = items.map(i => {
        const point: ILineChartDataPoint = {
          x: DateTime.jsDateFromISO(i.timestamp),
          y: i.value
        };

        return point;
      });

      return {
        data,
        legend: key,
        lineOptions: {
          lineBorderWidth: "4"
        }
      };
    });

    return chartData;
  };

  const getChartProps = (measurements: Measurement[]): IChartProps => {
    const data = getChartData(measurements);

    const chartProps: IChartProps = {
      chartTitle: "Observations",
      lineChartData: data
    };

    return chartProps;
  };

  return (
    <>
      <Heading variant="section-heading">Observations</Heading>
      <Stack>
        <DataFetcher
          fetch={async x => {
            const result = await x.clinical.getMeasurements(args);
            return result.results;
          }}
          fallback={<Spinner />}
        >
          {measurements => (
            <LineChart
              data={getChartProps(measurements)}
              legendProps={{
                canSelectMultipleLegends: true,
                allowFocusOnLegends: true
              }}
              tickFormat="%d/%m/%y"
            />
          )}
        </DataFetcher>
      </Stack>
    </>
  );
};
