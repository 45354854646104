import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import { DefaultButton, LabeledText, Stack } from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import { OrganisationRoleType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { OrganisationRoleTypeText } from "@ui-components/RefText.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { NoDetailsButton } from "../../shared-components/view/NoDetailsButton.tsx";
import { isPrivateInsurer } from "../edit/EditOrganisationType.tsx";
import { OrganisationLabels } from "../organisation.types.ts";
import { AccreditedEmployersList } from "./AccreditedEmployersList.tsx";
import { ViewEmployerFields } from "./ViewEmployerFields.tsx";
import { ViewPrivateInsurerFields } from "./ViewPrivateInsurerFields.tsx";

interface OrganisationTypeListProps {
  showEditIcon: boolean;
  organisation: Contact;
  onHandleEditModal: (cardId?: string) => void;
}

const OrganisationTypeListBase: FC<OrganisationTypeListProps> = observer(
  ({ onHandleEditModal, showEditIcon, organisation }) => {
    const { practice } = useStores();

    const organisationRoleRank = (type: string) => {
      switch (type) {
        case OrganisationRoleType.NzEmployer:
          return 1;
        case OrganisationRoleType.NzPrivateInsurer:
          return 2;
        case OrganisationRoleType.NzHealthProvider:
          return 3;
        case OrganisationRoleType.AuEmployer:
          return 4;
        case OrganisationRoleType.AuPrivateInsurer:
          return 5;
        case OrganisationRoleType.AuHealthProvider:
          return 6;
        default:
          return 7;
      }
    };

    const organisationRoles = Array.from(organisation.organisationRoles).sort(
      (a, b) =>
        organisationRoleRank(a.organisationRoleTypeCode) -
        organisationRoleRank(b.organisationRoleTypeCode)
    );

    const privateInsurer = organisationRoles.find(isPrivateInsurer);

    const [hideModal, setHideModal] = useState(true);

    return (
      <DataFetcher<Contact[]>
        fetch={() => practice.getLinkedAccreditedEmployers(organisation.id)}
        noExceptionsHandlers
        refetchId={organisation.id}
      >
        {(data = [], loading, error) => {
          const accreditedEmployersExist = data && data.length > 0;

          return (
            <>
              <Stack
                tokens={{ childrenGap: 8 }}
                styles={{ root: { paddingLeft: 10, paddingRight: 10 } }}
              >
                {showEditIcon ? (
                  <Stack tokens={{ childrenGap: 16 }}>
                    {organisationRoles.map(item => (
                      <Fieldset frame key={item.organisationRoleTypeCode}>
                        <WhenCountry is={Country.NewZealand}>
                          {item.organisationRoleTypeCode ===
                            OrganisationRoleType.NzEmployer && (
                            <ViewEmployerFields
                              employer={organisation.employer!}
                              organisationRoleTypeCode={
                                item.organisationRoleTypeCode
                              }
                            />
                          )}
                          {item.organisationRoleTypeCode ===
                            OrganisationRoleType.NzPrivateInsurer && (
                            <Stack>
                              <ViewPrivateInsurerFields
                                privateInsurer={organisation.privateInsurer!}
                                organisationRoleTypeCode={
                                  item.organisationRoleTypeCode
                                }
                              />
                              {privateInsurer && accreditedEmployersExist && (
                                <Stack styles={{ root: { paddingTop: 16 } }}>
                                  <DefaultButton
                                    onClick={() => setHideModal(false)}
                                    styles={{
                                      root: { maxWidth: 260 }
                                    }}
                                  >
                                    {`${OrganisationLabels.ViewAccreditedEmployers} (${data.length})`}
                                  </DefaultButton>
                                </Stack>
                              )}
                            </Stack>
                          )}
                        </WhenCountry>
                        {item.organisationRoleTypeCode !==
                          OrganisationRoleType.NzEmployer &&
                          item.organisationRoleTypeCode !==
                            OrganisationRoleType.NzPrivateInsurer && (
                            <Stack>
                              <LabeledText
                                noSemicolon
                                label={OrganisationLabels.Type}
                                styles={{
                                  text: {
                                    whiteSpace: "normal",
                                    wordBreak: "break-all"
                                  }
                                }}
                              >
                                <OrganisationRoleTypeText
                                  code={item.organisationRoleTypeCode}
                                />
                              </LabeledText>
                            </Stack>
                          )}
                      </Fieldset>
                    ))}
                  </Stack>
                ) : (
                  <NoDetailsButton
                    secondaryText={
                      OrganisationLabels.PrivateInsurerEmployerHealthProvider
                    }
                    sectionId={OrganisationCardIds.OrganisationPeople}
                    actionTitle={OrganisationLabels.AddAnOrganisationType}
                    noPermissionText={OrganisationLabels.NoTypes}
                    onHandleEditModal={onHandleEditModal}
                  />
                )}
              </Stack>
              <AccreditedEmployersList
                onDismissModal={() => setHideModal(true)}
                organisations={data}
                error={error}
                loading={loading}
                isHidden={hideModal}
              />
            </>
          );
        }}
      </DataFetcher>
    );
  }
);

export const OrganisationTypeList = withFetch(
  x => [x.practice.ref.organisationRoleType.load()],
  OrganisationTypeListBase
);
