import { runInAction } from "mobx";
import { FunctionComponent, MouseEvent } from "react";

import {
  CommandBar,
  dataAttribute,
  DataAttributes,
  DetailsListLayoutMode,
  Heading,
  IColumn,
  Link,
  noWrap,
  ScrollablePane,
  Stack,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { formatCalendarDate } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Print } from "@ui-components/printing/Print.tsx";
import { PrintContentWrapper } from "@ui-components/printing/PrintContentWrapper.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { renderCalendarEventWrapper } from "../booking-calendar/components/utils.tsx";

interface AgendaListProps {
  loading: boolean;
  error?: Error;
  items: CalendarEvent[];
}

export const AgendaListScreen: FunctionComponent<AgendaListProps> = ({
  loading,
  error,
  items
}) => {
  const { setCurrentEventData, showCalendarEventDialog } =
    useBookingCalendarScreenContext();

  const theme = useTheme();
  const { core, booking } = useStores();
  const patientLabel = usePatientLabel(false);
  const onClickEditThisEvent = (calendarEvent: CalendarEvent) => {
    showCalendarEventDialog({
      type: calendarEvent.type,
      id: calendarEvent.id
    });
    runInAction(() => {
      booking.ui.isEditSingleEvent = true;
    });
  };

  const onClickEditAppointment = (calendarEvent: CalendarEvent) => {
    showCalendarEventDialog({
      type: calendarEvent.type,
      id: calendarEvent.id
    });
  };

  const renderContactLink = ({ id, name }: Contact) => {
    return (
      <Navigate href={routes.contacts.contact.path({ id })}>{name}</Navigate>
    );
  };

  const getAppointmentTypeText = (record: CalendarEvent) => {
    if (
      record.typeRef &&
      record.typeRef.code === CalendarEventType.Appointment &&
      record.appointmentType !== undefined
    ) {
      return record.appointmentType.dto?.text;
    }

    return record.typeRef?.text;
  };

  const getDescription = (event: CalendarEvent) => {
    const text = event.groupAppointmentName;

    return (
      <Stack
        horizontal
        styles={{
          root: {
            width: "inherit",
            ...noWrap
          }
        }}
      >
        {core.hasPermissions(Permission.CalendarEventWrite) ? (
          <Link
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              if (
                event.type === CalendarEventType.Appointment &&
                !!event.calendarEventRecurrenceId
              ) {
                onClickEditThisEvent(event);
              } else {
                onClickEditAppointment(event);
              }
            }}
          >
            {text}
          </Link>
        ) : (
          text
        )}
      </Stack>
    );
  };

  const onRenderName = (event: CalendarEvent) => {
    if (event.canShowPatientMatchReview)
      return (
        <Link
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            booking.ui.startPatientMatchWorkflow(event.id);
          }}
        >
          {`Review unmatched ${patientLabel}`}
        </Link>
      );
    return (
      <span data-calendar-event-id={event.id}>
        {!event.isGroupAppointment
          ? event.contact && renderContactLink(event.contact)
          : getDescription(event)}
      </span>
    );
  };

  const getColumns = () => {
    const columns: IColumn[] = [
      {
        fieldName: "date",
        key: "date",
        minWidth: 180,
        maxWidth: 180,
        name: "Date",
        onRender: (record: CalendarEvent) => {
          return formatCalendarDate(record.startDateTime);
        },
        isResizable: true
      },
      {
        fieldName: "Name",
        key: "name",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 400,
        name: "Name",
        onRender: onRenderName,
        isResizable: true
      },
      {
        fieldName: "birthDate",
        key: "birthDate",
        minWidth: 80,
        maxWidth: 100,
        name: "Date of birth",
        onRender: (record: CalendarEvent) => {
          return record.contact?.birthDate?.toDayDefaultFormat();
        },
        isResizable: true
      },
      {
        fieldName: "type",
        key: "type",
        minWidth: 80,
        maxWidth: 140,
        name: "Type",
        onRender: (record: CalendarEvent) => (
          <span {...dataAttribute(DataAttributes.Data, record.type)}>
            {getAppointmentTypeText(record)}
          </span>
        ),
        isResizable: true
      },
      {
        fieldName: "provider",
        key: "provider",
        minWidth: 100,
        maxWidth: 200,
        name: "Provider",
        onRender: (record: CalendarEvent) => {
          return record.user && record.user.fullName;
        },
        isResizable: true
      }
    ];
    if (core.hasMultiLocationOrgUnit) {
      columns.push({
        fieldName: "location",
        key: "location",
        minWidth: 160,
        maxWidth: 200,
        name: "Location",
        onRender: (record: CalendarEvent) => {
          return record.locationName;
        }
      });
    }

    return columns;
  };

  const printColumns: IColumn[] = [
    {
      fieldName: "date",
      key: "date",
      minWidth: 170,
      maxWidth: 170,
      name: "Date",
      onRender: (record: CalendarEvent) => {
        return formatCalendarDate(record.startDateTime);
      },
      isResizable: true
    },
    {
      fieldName: "Name",
      key: "name",
      isRowHeader: true,
      minWidth: 175,
      maxWidth: 175,
      name: "Name",
      onRender: (event: CalendarEvent) => {
        return (
          <span data-calendar-event-id={event.id}>
            {event.contact && renderContactLink(event.contact)}
          </span>
        );
      },
      isResizable: true
    },
    {
      fieldName: "birthDate",
      key: "birthDate",
      minWidth: 80,
      maxWidth: 80,
      name: "DOB",
      onRender: (record: CalendarEvent) => {
        return record.contact?.birthDate?.toDayDefaultFormat();
      },
      isResizable: true
    },
    {
      fieldName: "type",
      key: "type",
      minWidth: 90,
      maxWidth: 140,
      name: "Type",
      onRender: (record: CalendarEvent) => getAppointmentTypeText(record),
      isResizable: true
    },
    {
      fieldName: "provider",
      key: "provider",
      minWidth: 175,
      maxWidth: 175,
      name: "Provider",
      onRender: (record: CalendarEvent) => {
        return record.user && record.user.fullName;
      },
      isResizable: true
    }
  ];

  const dataSource: CalendarEvent[] = items
    .filter(x => x.type !== CalendarEventType.Unavailable)
    .sort((a, b) => a.startDateTime.diff(b.startDateTime, "minutes").minutes);

  const onShowCallout = (evt: MouseEvent, event: CalendarEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setCurrentEventData({
      event,
      calloutPosition: evt.nativeEvent
    });
  };

  const onShowContext = (evt: MouseEvent, event: CalendarEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setCurrentEventData({
      event,
      contextualMenuPosition: evt.nativeEvent
    });
  };

  const content =
    dataSource.length > 0 || loading ? (
      <Print
        pageStyle={`@media print {
          .ms-link.ms-link {
            color:${theme.palette.neutralSecondary};
          }
        }`}
      >
        {print => (
          <Stack
            styles={{
              root: { overflowY: "auto", position: "relative", height: "100%" }
            }}
          >
            <CommandBar
              items={[
                {
                  key: "print",
                  name: "Print",
                  onClick: print.print,
                  iconProps: {
                    iconName: "Print"
                  }
                }
              ]}
              styles={{
                root: {
                  paddingLeft: 0
                }
              }}
            />
            <Stack.Item>
              <PrintContentWrapper>
                <ShimmeredDetailsList
                  items={dataSource}
                  setKey="items"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  columns={printColumns}
                  enableShimmer={loading}
                  errorMessage={error?.message}
                />
              </PrintContentWrapper>
            </Stack.Item>
            <Stack.Item>
              <ScrollablePane styles={{ root: { marginTop: 45 } }}>
                <ShimmeredDetailsList
                  items={dataSource}
                  setKey="items"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderRow={props =>
                    renderCalendarEventWrapper(props, {
                      onShowCallout,
                      onShowContext
                    })
                  }
                  columns={getColumns()}
                  enableShimmer={loading}
                  errorMessage={error?.message}
                  detailsListStyles={{
                    root: {
                      overflow: "visible"
                    }
                  }}
                />
              </ScrollablePane>
            </Stack.Item>
          </Stack>
        )}
      </Print>
    ) : (
      <Heading labelPaddings>No Records to display</Heading>
    );

  return <Tile styles={{ root: { flexGrow: 1 } }}>{content}</Tile>;
};
