import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { dataAttribute, DataAttributes, Heading } from "@bps/fluent-ui";
import { DocumentContentType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ExportPatientRecordModalFormFields } from "@modules/practice/screens/shared-components/export-patient-record-modal/ExportPatientRecordModalFormFields.tsx";
import { ExportPatientRecordFormValues } from "@shared-types/practice/export-patient-record-form-values.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ExportPatientRecordContext } from "./context/ExportPatientRecordContext.ts";

export const ExportPatientRecordModal = observer(() => {
  const { practice, userExperience } = useStores();

  const {
    masterPatient,
    setShowExportPatientRecordModal,
    setShowExportPatientRecordViewerDialog,
    setExportRecordValues
  } = practice.ui;

  const { getExportPatientDocument } = useContext(ExportPatientRecordContext);
  const { localisedConfig } = userExperience;
  const patientLabel = localisedConfig("patientDisplay");

  const patientId = masterPatient?.id;

  const closeModal = () => setShowExportPatientRecordModal(false);
  const onSubmit = async (values: ExportPatientRecordFormValues) => {
    setExportRecordValues(values);
    if (values.format === DocumentContentType.Pdf) {
      setShowExportPatientRecordViewerDialog(true);
    } else {
      await getExportPatientDocument(DocumentContentType.Html, patientId);
    }
    closeModal();
  };

  const initialValues: ExportPatientRecordFormValues = {
    format: DocumentContentType.Pdf
  };

  return (
    <SubmissionFormDialog<ExportPatientRecordFormValues>
      dialogName={`Export ${patientLabel} ${masterPatient?.fullName}`}
      dialogProps={{
        onDismiss: closeModal,
        minWidth: 600,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {`Export ${patientLabel} ${masterPatient?.fullName}`}
            </Heading>
          )
        }
      }}
      initialValues={initialValues}
      onSubmitSucceeded={closeModal}
      onSubmit={onSubmit}
      buttonsProps={{
        submitButtonProps: {
          text: "Export",
          iconProps: { hidden: true }
        },
        cancelButtonProps: {
          ...dataAttribute(DataAttributes.Element, "export-cancel-button")
        },
        disableSubmitOnPristine: false
      }}
    >
      {() => <ExportPatientRecordModalFormFields />}
    </SubmissionFormDialog>
  );
});
