import { useField } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { ClaimDiagnosisDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";

import { sideFilter, terminologyFilter } from "../utils.ts";
import { DiagnosisField } from "./DiagnosisField.tsx";
import { DiagnosisHeader } from "./DiagnosisHeader.tsx";

interface PrimaryDiagnosisFormFieldsProps {
  name: string;
  showStatusCol: boolean;
  diagnoses?: ClaimDiagnosisDto[];
}

export const PrimaryDiagnosisFormFields: React.FC<
  PrimaryDiagnosisFormFieldsProps
> = props => {
  const {
    input: { value: primaryDiagnosis }
  } = useField(props.name, {
    subscription: { value: true }
  });

  return (
    <>
      <Heading variant="section-heading-light">
        {ClaimFormLabels.primaryDiagnosis}
      </Heading>
      <Fieldset frame styles={{ root: { padding: "0px 0px 8px" } }}>
        <DiagnosisHeader showStatusCol={props.showStatusCol} />
        <Stack
          styles={{
            root: {
              paddingLeft: 8,
              paddingRight: 8
            }
          }}
        >
          <DiagnosisField
            filters={{
              terminologyFilter: terminologyFilter(
                props.diagnoses
                  ? [...props.diagnoses, primaryDiagnosis]
                  : [primaryDiagnosis],
                primaryDiagnosis
              ),
              sideFilter: sideFilter(
                props.diagnoses
                  ? [...props.diagnoses, primaryDiagnosis]
                  : [primaryDiagnosis],
                primaryDiagnosis
              )
            }}
            name={props.name}
            showStatusCol={props.showStatusCol}
          />
        </Stack>
      </Fieldset>
    </>
  );
};
