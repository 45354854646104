import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { dataAttribute, DataAttributes, Heading, Text } from "@bps/fluent-ui";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

export const AllocationFormLocationField = observer(() => {
  const { core } = useStores();

  const {
    input: { value: accountContactId }
  } = useField(allocationNameOf("accountContactId"), {
    subscription: { value: true }
  });

  const {
    input: { value: locationId }
  } = useField(allocationNameOf("locationId"), {
    subscription: { value: true }
  });

  if (!accountContactId) return null;

  if (core.hasMultiLocationOrgUnit) {
    return (
      <LocationsSelectField
        name={allocationNameOf("locationId")}
        label="Location"
        required
        {...dataAttribute(DataAttributes.Element, "location-select")}
      />
    );
  }

  const locationName = core.getLocationName(locationId);

  return (
    <div>
      <Heading
        labelPaddings
        {...dataAttribute(DataAttributes.Element, "location-label")}
      >
        Location
      </Heading>
      <Text {...dataAttribute(DataAttributes.Element, "location-value")}>
        {locationName}
      </Text>
    </div>
  );
});
