import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  CommandBar,
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  IButtonProps,
  IButtonStyles,
  ICommandBarProps,
  IComponentAsProps,
  IContextualMenuItem,
  IIconProps,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import {
  isAppointmentDeletable,
  isAppointmentEditable
} from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { invoiceStatusIcon } from "../BookingCalendarEvent.types.ts";
import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { InvoiceAttendeeItem } from "./InvoiceAttendeeItem.tsx";
import { ReminderAttendeeItem } from "./ReminderAttendeeItem.tsx";

export const BookingEventCalloutCommandBar = observer(() => {
  const root = useStores();
  const { routing, booking, core } = root;
  const patientLabel = usePatientLabel(false);
  const {
    setCurrentOccurrenceId,
    setCurrentRecurrenceId,
    setReminderArgs,
    isGeneralInvoiceCompleted,
    showCalendarEventDialog
  } = useBookingCalendarScreenContext();

  const {
    calendarEvent,
    isRecurrenceDeleted,
    isSeries,
    isUnavailableType,
    getClinicalMenuItems,
    enabled,
    setNoChargeDialogVisibility,
    invoiceItems,
    startPatientMatchWorkflow
  } = useContext(BookingCalendarEventContext);

  const {
    invoiceId,
    startDateTime,
    type,
    appointmentStatus,
    hasEncounter,
    contact,
    sortedAttendeesAsContacts,
    isGroupAppointment,
    invoiceStatus
  } = calendarEvent;

  const isAppointmentValidForEdit = isAppointmentEditable(
    startDateTime,
    appointmentStatus
  );

  const canWriteCalenderEvent = core.hasPermissions(
    Permission.CalendarEventWrite
  );

  const isPastDayAppointment = startDateTime < DateTime.now();
  const getInvoiceStatusIcon = (invoiceStatus?: InvoiceStatus) =>
    invoiceStatus ? invoiceStatusIcon[invoiceStatus] : "M365InvoicingLogo";

  const commandBarItems: ICommandBarProps["items"] = [];
  const theme = useTheme();
  const getInvoicingSubmenuItems = () => {
    if (isGroupAppointment)
      return sortedAttendeesAsContacts.map((c): IContextualMenuItem => {
        return {
          key: c.id,
          onRenderContent: () => (
            <InvoiceAttendeeItem attendee={c} calendarEvent={calendarEvent} />
          )
        };
      });
    return [
      {
        key: "noChargeForThisInvoice",
        id: "booking-event-callout-noCharge-btn",
        text: ContextMenuItemsEnum.NoCharge,
        onClick: () => setNoChargeDialogVisibility(true),
        disabled: invoiceItems && invoiceItems.length > 0,
        iconProps: calendarEvent.noChargeComment
          ? {
              iconName: "completed",
              styles: { root: { color: theme.palette.redDark } }
            }
          : undefined
      }
    ];
  };
  /* SMS Button */
  if (
    core.hasPermissions(Permission.SendApptRemindersAllowed) &&
    calendarEvent.type !== CalendarEventType.Unavailable
  ) {
    const enabled =
      calendarEvent.type === CalendarEventType.Appointment &&
      calendarEvent.activeAttendees.length > 0;
    if (calendarEvent.type !== CalendarEventType.Meeting || enabled) {
      //sub menus
      const subMenuItems: IContextualMenuItem[] = [];

      subMenuItems.push({
        key: "all",
        text: "All participants",
        onClick: () => {
          setReminderArgs({ calendarEventId: calendarEvent.id });
        }
      });

      sortedAttendeesAsContacts.forEach(c => {
        subMenuItems.push({
          key: c.id,
          onRenderContent: () => (
            <ReminderAttendeeItem attendee={c} calendarEvent={calendarEvent} />
          )
        });
      });

      enabled &&
        commandBarItems.push({
          key: "sms",
          id: "booking-event-callout-sms-btn",
          text: ContextMenuItemsEnum.SendApptReminder,
          disabled: !enabled,
          iconProps: { iconName: "CellPhone" },
          onClick: () => {
            if (!isGroupAppointment) {
              setReminderArgs({ calendarEventId: calendarEvent.id });
            }
          },
          subMenuProps: isGroupAppointment
            ? {
                items: subMenuItems
              }
            : undefined
        });
    }
  }

  const isAppointmentValidForDelete = isAppointmentDeletable(
    startDateTime,
    appointmentStatus,
    hasEncounter
  );

  /* Invoice Button */
  if (
    core.hasPermissions(Permission.InvoiceCreate) &&
    calendarEvent.type !== CalendarEventType.Unavailable
  ) {
    const isFutureDayAppointment =
      startDateTime.startOf("day") > DateTime.today();

    const enabled =
      type === CalendarEventType.Appointment &&
      !isFutureDayAppointment &&
      contact &&
      contact.type === ContactType.Patient;

    if (type !== CalendarEventType.Meeting || enabled) {
      let iconProps: IIconProps = {
        iconName: "M365InvoicingLogo",
        ...dataAttribute(DataAttributes.Element, "invoice-uncompleted-icon")
      };

      const allInvoiceExist = invoiceId && isGeneralInvoiceCompleted;

      if (enabled && allInvoiceExist) {
        iconProps = {
          iconName: getInvoiceStatusIcon(
            isGroupAppointment ? InvoiceStatus.Settled : invoiceStatus
          ),
          ...dataAttribute(DataAttributes.Element, "invoice-completed-icon")
        };
      }

      enabled &&
        commandBarItems.push({
          key: "invoice",
          id: "booking-event-callout-invoice-btn",
          text: ContextMenuItemsEnum.Invoice,
          iconProps,
          disabled: !enabled,
          split: !isGroupAppointment,
          onClick: () => {
            if (allInvoiceExist && invoiceId) {
              routing.push(
                routes.accounts.invoices.invoice.path({ id: invoiceId }),
                routing.getStateWithFromQuery()
              );
            } else {
              openNewInvoiceFromCalendar(root, calendarEvent.id);
            }
          },
          subMenuProps: {
            items: getInvoicingSubmenuItems()
          }
        });
    }
  }

  if (calendarEvent.canShowPatientMatchReview) {
    commandBarItems.push({
      key: "review-patient-match-callout",
      text: `${ContextMenuItemsEnum.ReviewUnmatchedPatient} ${patientLabel}`,
      iconProps: {
        iconName: "ProfileSearch",
        styles: {
          root: {
            color: theme.palette.redDark
          }
        }
      },
      onClick: startPatientMatchWorkflow
    });
  }

  /* Clinical button */
  if (
    core.hasPermissions([Permission.ClinicalRead, Permission.EncounterRead]) &&
    calendarEvent.type !== CalendarEventType.Unavailable
  ) {
    if (type !== CalendarEventType.Meeting || enabled) {
      const clinicalAttendees: IContextualMenuItem[] = [];
      sortedAttendeesAsContacts.forEach(x => {
        clinicalAttendees?.push({
          key: x.id,
          text: x.preferredFullName,
          isBeakVisible: true,
          subMenuProps: {
            items: getClinicalMenuItems(x.id)
          }
        });
      });

      if (contact) {
        commandBarItems.push({
          key: "record",
          id: "booking-event-callout-record-btn",
          text: ContextMenuItemsEnum.OpenPatientRecord,
          disabled: !enabled,
          iconProps: { iconName: "ContactCard" },
          subMenuProps: {
            calloutProps: {
              calloutMaxWidth: 200
            },
            items: isGroupAppointment
              ? clinicalAttendees
              : getClinicalMenuItems(contact.id)
          }
        });
      }
    }
  }

  const editSubMenuOptions = [
    {
      key: "editThisEvent",
      text: "This event",
      onClick: () => {
        if (isUnavailableType) {
          setCurrentOccurrenceId(calendarEvent.id);
        } else {
          showCalendarEventDialog({
            type: calendarEvent.type,
            id: calendarEvent.id
          });
          runInAction(() => {
            booking.ui.isEditSingleEvent = true;
          });
        }
      }
    }
  ];

  if (isUnavailableType) {
    editSubMenuOptions.push({
      key: "editAllEventsInTheSeries",
      text: "All events in the series",
      onClick: () => {
        runInAction(() => {
          setCurrentRecurrenceId(calendarEvent.calendarEventRecurrenceId);
        });
      }
    });
  } else if (!isPastDayAppointment) {
    editSubMenuOptions.push({
      key: "editThisAndFollowing",
      text: "This and the following events ",
      onClick: () => {
        showCalendarEventDialog({
          type: calendarEvent.type,
          id: calendarEvent.id
        });
      }
    });
  }

  if (canWriteCalenderEvent) {
    commandBarItems.push(
      isSeries && !isRecurrenceDeleted.get()
        ? {
            key: "edit",
            id: "booking-event-callout-edit-btn",
            text: ContextMenuItemsEnum.Edit,
            iconProps: { iconName: "Edit" },
            subMenuProps: {
              items: [...editSubMenuOptions]
            }
          }
        : {
            /* Edit Button */
            key: "edit",
            id: "booking-event-callout-edit-btn",
            text: ContextMenuItemsEnum.Edit,
            iconProps: { iconName: "Edit" },
            disabled: isAppointmentValidForEdit,
            onClick: () => {
              showCalendarEventDialog({
                type: calendarEvent.type,
                id: calendarEvent.id
              });
            }
          }
    );
  }

  const cancelSubMenuOptions = [
    {
      key: "cancelThisEvent",
      text: "This event",
      onClick: () => {
        if (
          isUnavailableType ||
          calendarEvent.type === CalendarEventType.Appointment
        ) {
          booking.ui.setShowCancelCalendarEventDialog(true, calendarEvent.id);
        }
      }
    }
  ];

  if (isUnavailableType) {
    cancelSubMenuOptions.push({
      key: "cancelAllEventsInTheSeries",
      text: "All events in the series",
      onClick: () => {
        booking.ui.setCancelCalendarEventRecurrenceId(
          calendarEvent.calendarEventRecurrenceId
        );
        booking.ui.setShowCancelCalendarEventDialog(true, calendarEvent.id);
      }
    });
  } else if (!isPastDayAppointment) {
    cancelSubMenuOptions.push({
      key: "cancelThisAndFollowing",
      text: "This and the following events",
      onClick: () => {
        booking.ui.setCancelCalendarEventRecurrenceId(
          calendarEvent.calendarEventRecurrenceId
        );
        booking.ui.setShowCancelCalendarEventDialog(true, calendarEvent.id);
      }
    });
  }

  if (canWriteCalenderEvent) {
    commandBarItems.push(
      isSeries && !isRecurrenceDeleted.get()
        ? {
            key: "delete",
            id: "booking-event-callout-delete-btn",
            text: ContextMenuItemsEnum.Cancel,
            iconProps: { iconName: "Delete" },
            subMenuProps: {
              items: [...cancelSubMenuOptions]
            }
          }
        : {
            /* Delete Button */
            key: "delete",
            id: "booking-event-callout-delete-btn",
            text: ContextMenuItemsEnum.Cancel,
            disabled: isAppointmentValidForDelete,
            onClick: () => {
              booking.ui.setShowCancelCalendarEventDialog(
                true,
                calendarEvent.id
              );
            },
            iconProps: { iconName: "Delete" }
          }
    );
  }

  const renderCommandBarButton = ({
    ...props
  }: IComponentAsProps<IButtonProps>) => {
    const styles: IButtonStyles = {
      root: {
        backgroundColor: "transparent",
        flexGrow: 1,
        textAlign: "left"
      },
      rootDisabled: {
        backgroundColor: "transparent"
      },
      splitButtonMenuButton: { backgroundColor: "transparent" },
      splitButtonMenuButtonDisabled: { backgroundColor: "transparent" }
    };

    return <CommandBarButton {...props} styles={styles} />;
  };

  if (!commandBarItems.length) return null;

  return (
    <CommandBar
      styles={({ theme }) => ({
        root: {
          backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
          flex: 1,
          padding: 0
        },
        primarySet: {
          ".ms-OverflowSet-item": { flexGrow: 1 }
        }
      })}
      onReduceData={() => undefined}
      buttonAs={renderCommandBarButton}
      items={commandBarItems}
    />
  );
});
