import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontSizes,
  Heading,
  Stack
} from "@bps/fluent-ui";
import { getAdjustedInvoiceItemsWithChangeType } from "@modules/billing/screens/billing-history/utils.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { InvoiceFormLabels } from "@modules/billing/screens/shared-components/types/invoice-form-labels.enum.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";

import { InvoiceItemList } from "./InvoiceItemList.tsx";
import { getInvoiceListItems } from "./utils.ts";

export const InvoiceViewAdjustedItemsList: FunctionComponent = observer(() => {
  const { invoice, accountId, adjustedInvoice } =
    useContext(InvoiceViewContext);

  if (!adjustedInvoice) {
    return null;
  }

  const adjustedItems: InvoiceItemFormValue[] = getInvoiceListItems(
    adjustedInvoice.items
  );

  const originalItems: InvoiceItemFormValue[] = getInvoiceListItems(
    invoice.items
  );

  const invoiceItems = getAdjustedInvoiceItemsWithChangeType(
    originalItems,
    adjustedItems
  );

  return (
    <Stack>
      <Heading
        {...dataAttribute(DataAttributes.Element, "adjusted-invoice-label")}
        styles={{ root: { fontSize: FontSizes.large } }}
      >
        {InvoiceFormLabels.invoicedItems}
        <span style={{ fontStyle: "italic" }}>{" after adjustment"}</span>
        {` (${invoiceItems.length})`}
      </Heading>
      <Stack.Item>
        <InvoiceItemList
          invoice={invoice}
          invoiceItems={invoiceItems}
          accountId={accountId}
        />
      </Stack.Item>
    </Stack>
  );
});
