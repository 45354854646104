import { FunctionComponent } from "react";

import { FontWeights, NativeList, Stack } from "@bps/fluent-ui";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

interface ClinicalNotificationListProps {
  notifications?: ClinicalActivity[];
}

const ClinicalNotificationListBase: FunctionComponent<
  ClinicalNotificationListProps
> = ({ notifications }) => {
  const { clinical } = useStores();
  if (!notifications || notifications.length === 0) return null;

  return (
    <NativeList>
      {notifications.map((notification, index) => {
        const isLast = index === notifications.length - 1;
        const description = clinical.ref.clinicalActivityDescriptions.get(
          notification.descriptionCode
        )?.text;

        const clinActivityType = clinical.ref.clinicalActivityTypes.get(
          notification.activityType
        )?.text;

        const activityType = clinActivityType?.toLowerCase();

        return (
          <li key={notification.id}>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 4 }}
            >
              <Stack
                styles={{
                  root: { fontWeight: FontWeights.semibold }
                }}
              >
                {description}
              </Stack>
              <Stack>
                {activityType}
                {!isLast ? ", " : "?"}
              </Stack>
            </Stack>
          </li>
        );
      })}
    </NativeList>
  );
};

export const ClinicalNotificationList = withFetch(
  x => [
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.clinicalActivityDescriptions.load()
  ],
  ClinicalNotificationListBase
);
