import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";

import {
  CommandBarButton,
  dataAttribute,
  DataAttributes,
  Dropdown,
  FontIcon,
  FontSizes,
  Heading,
  IconButton,
  IDropdownOption,
  ISelectableOption,
  mergeStyleSets,
  Overlay,
  Stack,
  TooltipHost,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

import { CalendarEventView } from "../types/CalendarEventView.types.ts";
import { formatDateRange } from "./utils.tsx";

interface CalendarPeriodProps {
  name: string;
  text: string;
  key: string;
}

export const DayNavigator: FunctionComponent = observer(() => {
  const {
    setCalendarView,
    calendarView,
    dayOrWeekView,
    startDate,
    setStartDate,
    getWorkWeekRange,
    isInitialLoadingCalendarView
  } = useBookingCalendarScreenContext();

  let weekClickCount = 0;
  const theme = useTheme();
  const SCREEN_MED = 1650;
  const screenSize = useScreenSize();
  const dateLabel = (day: DateTime): string => {
    switch (calendarView) {
      case CalendarEventView.Week: {
        const start = day.startOf("week");
        const end = day.startOf("week").plus({ days: 6 });
        return formatDateRange(start, end);
      }

      case CalendarEventView.WorkWeek: {
        const workWeekRange = getWorkWeekRange(day);

        if (workWeekRange.length === 0) {
          return "";
        }

        const start = DateTime.fromJSDate(workWeekRange[0]);
        const end = DateTime.fromJSDate(
          workWeekRange[workWeekRange.length - 1]
        );

        return formatDateRange(start, end);
      }

      case CalendarEventView.Day: {
        return screenSize.width < SCREEN_MED
          ? day.toDayDefaultFormat()
          : day.toFormat(DATE_FORMATS.LONG_DATE_WITHOUT_TIME);
      }
      default:
        return "";
    }
  };

  // Debounce call for previous, today, and next day
  const handleCalendarPickerDateChangeDebounce = useDebounce((date: DateTime) =>
    calendarPickerOnSelectDate(date)
  );

  const calendarPickerOnSelectDate = (date: DateTime | undefined) => {
    if (!date) {
      return;
    }
    if (dayOrWeekView === CalendarEventView.Week) {
      if (date.weekNumber === startDate.weekNumber) {
        return;
      }
    }

    const dateToSet =
      dayOrWeekView === CalendarEventView.Week ? date.startOf("week") : date;

    setStartDate(dateToSet);
  };

  const previousDay = startDate.minus(
    dayOrWeekView === CalendarEventView.Day ? { days: 1 } : { weeks: 1 }
  );

  const nextDay = startDate.plus(
    dayOrWeekView === CalendarEventView.Day ? { days: 1 } : { weeks: 1 }
  );

  const backClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    weekClickCount -= 1;
    handleCalendarPickerDateChangeDebounce(
      startDate.plus(
        dayOrWeekView === CalendarEventView.Day
          ? { days: weekClickCount }
          : { weeks: weekClickCount }
      )
    );
  };

  const forwardClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    weekClickCount += 1;
    handleCalendarPickerDateChangeDebounce(
      startDate.plus(
        dayOrWeekView === CalendarEventView.Day
          ? { days: weekClickCount }
          : { weeks: weekClickCount }
      )
    );
  };

  const todayClick = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    calendarPickerOnSelectDate(DateTime.today());
  };

  const todayDateLabel = dateLabel(startDate);

  const todayLabel = `Go to ${
    dayOrWeekView === CalendarEventView.Day ? "today" : "current week"
  }\n${todayDateLabel}`;

  const nextDayLabel = `Go to next ${
    dayOrWeekView === CalendarEventView.Day ? "day" : "week"
  }\n${dateLabel(nextDay)}`;

  const previousDayLabel = `Go to previous ${
    dayOrWeekView === CalendarEventView.Day ? "day" : "week"
  }\n${dateLabel(previousDay)}`;

  const getDropdownStyles = {
    root: {
      width: 130,
      selectors: {
        "&:hover": {
          backgroundColor: theme.palette.neutralLighter
        }
      }
    },

    title: {
      border: "none",
      background: "transparent"
    }
  };

  const calendarPeriodIcons: CalendarPeriodProps[] = [
    {
      name: "CalendarDay",
      text: "Day",
      key: CalendarEventView.Day
    },
    {
      name: "CalendarWeek",
      text: "Week",
      key: CalendarEventView.Week
    },
    {
      name: "CalendarWeek",
      text: "Work week",
      key: CalendarEventView.WorkWeek
    }
  ];

  const renderIconTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];
    return (
      <Stack horizontal verticalAlign="center">
        {option.data && option.data.icon && (
          <FontIcon
            styles={{
              root: {
                width: FontSizes.large,
                color: theme.palette.themePrimary,
                verticalAlign: "middle"
              }
            }}
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        {option.text}
      </Stack>
    );
  };

  const renderPlaceholder = (): JSX.Element => {
    const selectedOption = calendarPeriodIcons.find(
      option => option.key === calendarView
    );

    const placeholderLabel = selectedOption?.text || "";
    const placeHolderIcon = selectedOption?.name || "";
    return (
      <Stack horizontal verticalAlign="center">
        <FontIcon
          title={placeholderLabel}
          iconName={placeHolderIcon}
          styles={{
            root: {
              width: FontSizes.large,
              color: theme?.palette.themePrimary
            }
          }}
        />
        {placeholderLabel}
      </Stack>
    );
  };

  const renderIconOption = (option: ISelectableOption) => (
    <Stack
      {...dataAttribute(
        DataAttributes.Element,
        `day-week-navigator-${option.text.toLowerCase()}`
      )}
      horizontal
      verticalAlign="center"
    >
      {option.data && option.data.icon && (
        <FontIcon
          styles={{
            root: {
              width: FontSizes.large,
              color: theme.palette.themePrimary
            }
          }}
          iconName={option.data.icon}
          aria-hidden="true"
        />
      )}
      {option.text}
    </Stack>
  );

  const calendarPeriodOptions: IDropdownOption[] = calendarPeriodIcons.map(
    item => ({
      key: item.key,
      text: item.text,
      data: { icon: item.name }
    })
  );

  const onItemChanged = async (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ) => {
    const value = item.key.toString() as CalendarEventView;
    setCalendarView(value);
  };

  return (
    <Stack
      id="day-navigator"
      disableShrink
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: 8 }}
      styles={{ root: { width: "max-content", minWidth: 415 } }}
    >
      <TooltipHost content={todayLabel}>
        <CommandBarButton
          {...dataAttribute(DataAttributes.Element, "booking-today-btn")}
          iconProps={{ iconName: "GoToToday" }}
          styles={{ root: { backgroundColor: "transparent" } }}
          onClick={todayClick}
          ariaLabel={todayLabel}
          text="Today"
        />
      </TooltipHost>

      <TooltipHost content={previousDayLabel}>
        <IconButton
          id="booking-back-btn"
          onMouseDown={backClick}
          iconProps={{
            iconName: "Back"
          }}
        />
      </TooltipHost>

      <TooltipHost content={nextDayLabel}>
        <IconButton
          id="booking-forward-btn"
          onMouseDown={forwardClick}
          iconProps={{
            iconName: "Forward"
          }}
        />
      </TooltipHost>

      <Stack styles={{ root: { position: "relative" } }}>
        <Dropdown
          name="day-week-navigator"
          {...dataAttribute(DataAttributes.Element, "day-week-navigator")}
          onRenderOption={renderIconOption}
          onRenderTitle={renderIconTitle}
          onRenderPlaceholder={renderPlaceholder}
          options={calendarPeriodOptions}
          onChange={onItemChanged}
          styles={mergeStyleSets(getDropdownStyles)}
        />
        {isInitialLoadingCalendarView && <Overlay />}
      </Stack>

      <Heading
        variant="section-sub-heading"
        {...dataAttribute(DataAttributes.Element, "day-week-navigator-text")}
      >
        {todayDateLabel}
      </Heading>
    </Stack>
  );
});
