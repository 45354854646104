import { MouseEvent, useEffect, useState } from "react";

import {
  Badge,
  dataAttribute,
  DataAttributes,
  FontIcon,
  IconButton,
  IPivotItemProps,
  mergeStyles,
  Pivot,
  PivotItem,
  Stack,
  Text,
  useScreenSize,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalHeaderTableTitle } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConfidentialToolTipFontIcon } from "./ConfidentialToolTipFontIcon.tsx";

export interface ClinicalHeaderTableViewData {
  key: string;
  title: string;
  count: number;
  content: JSX.Element;
  backgroundColour?: string;
  badgeColour?: string;
  addAction?: () => void;
  badgeAction: (value: MouseEvent<any> | undefined) => void;
  writePermission?: string;
  collapsible?: boolean;
}

interface ClinicalHeaderTableBaseProps {
  viewData: ClinicalHeaderTableViewData[];
}

export const ClinicalHeaderTableBase: React.FC<
  ClinicalHeaderTableBaseProps
> = ({ viewData }) => {
  const theme = useTheme();
  const { isViewOnlyOrDischarged, clinicalRecord } =
    usePatientRecordScreenContext();

  const collapsible = !!(viewData.length === 1 && viewData[0].collapsible);
  const { height } = useScreenSize();
  const { core } = useStores();
  const [collapsed, setCollapsed] = useState<boolean>(
    collapsible && (height < 800 || viewData[0].count === 0)
  );

  const viewDataLength = viewData.length;
  const viewDataItemCount = viewData[0]?.count;
  useEffect(() => {
    if (viewDataLength === 1 && viewDataItemCount > 0) {
      setCollapsed(false);
    }
  }, [viewDataLength, viewDataItemCount]);

  const renderHeader = (
    item: ClinicalHeaderTableViewData,
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ) => {
    const titleElement =
      viewData.length > 1 && defaultRenderer && link ? (
        defaultRenderer({ ...link })
      ) : (
        <Text bold>{item.title}</Text>
      );

    const iconStyles = mergeStyles({
      padding: "8px 9px",
      color: theme.semanticColors.link,
      cursor: "pointer",
      lineHeight: theme.spacing.m,
      borderRadius: 2,
      selectors: {
        "&:hover": {
          backgroundColor: theme.semanticColors.buttonBackgroundCheckedHovered
        }
      }
    });

    const renderConfidentialIcon = (title: string) => {
      return (
        <ConfidentialToolTipFontIcon
          isShowConfidentialIcon={
            title === ClinicalHeaderTableTitle.Tasks &&
            clinicalRecord?.isExistConfidentialClinicalTasksWithOtherUsers
          }
          content="Some tasks are confidential"
          summaryStyling={
            clinicalRecord?.isExistConfidentialClinicalTasksWithOtherUsers
          }
        />
      );
    };

    return (
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
          <Stack.Item>
            {viewData[0].collapsible && (
              <IconButton
                iconProps={{
                  iconName: collapsed ? "ChevronRight" : "ChevronDown"
                }}
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
          </Stack.Item>
          <Stack.Item align="center">{titleElement}</Stack.Item>
          <Stack.Item align="center">
            <Badge
              {...dataAttribute(
                DataAttributes.Element,
                `${item.key}-count-button`
              )}
              onClick={isViewOnlyOrDischarged ? undefined : item.badgeAction}
              styles={{
                root: [
                  {
                    cursor: isViewOnlyOrDischarged ? "default" : undefined,
                    color: theme.palette.themePrimary,
                    borderRadius: 8,
                    height: 27,
                    backgroundColor: item.badgeColour
                      ? item.badgeColour
                      : theme.palette.neutralLighterAlt,
                    selectors: isViewOnlyOrDischarged
                      ? undefined
                      : {
                          "&:hover": {
                            textDecoration: "underline"
                          }
                        }
                  },
                  theme.fonts.small
                ]
              }}
            >
              {item.count.toString()}
            </Badge>
          </Stack.Item>
          {renderConfidentialIcon(item.title)}
        </Stack>
        {!isViewOnlyOrDischarged &&
          (item.writePermission === undefined ||
            core.hasPermissions(item.writePermission)) &&
          !!item.addAction && (
            <Stack.Item align="center">
              <FontIcon
                {...dataAttribute(
                  DataAttributes.Element,
                  `${item.key}-add-button`
                )}
                iconName="Add"
                onClick={item.addAction}
                className={iconStyles}
              />
            </Stack.Item>
          )}
      </Stack>
    );
  };

  const onRenderItemLink = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ) => {
    if (!link || !defaultRenderer) {
      return null;
    }

    const item = viewData.find(d => d.key === link.itemKey)!;

    return (
      <span style={{ flex: "0 1 100%" }}>
        {renderHeader(item, link, defaultRenderer)}
      </span>
    );
  };

  const renderTableContent = (contentData: ClinicalHeaderTableViewData) => {
    if (collapsed) {
      return null;
    }
    return contentData.content;
  };

  return (
    <>
      {viewData.length > 1 && (
        <Pivot>
          {viewData.map(item => (
            <PivotItem
              headerText={item.title}
              itemKey={item.key}
              key={item.key}
              onRenderItemLink={onRenderItemLink}
            >
              {renderTableContent(item)}
            </PivotItem>
          ))}
        </Pivot>
      )}
      {viewData.length === 1 && (
        <Stack>
          <Stack.Item styles={{ root: { height: 35 } }}>
            {renderHeader(viewData[0])}
          </Stack.Item>
          <Stack.Item>{renderTableContent(viewData[0])}</Stack.Item>
        </Stack>
      )}
    </>
  );
};
