import { To } from "react-router-dom";

import {
  dataAttribute,
  DataAttributes,
  DetailsHeader,
  DetailsListLayoutMode,
  FontWeights,
  IColumn,
  IDetailsFooterProps,
  IDetailsHeaderProps,
  IStyle,
  ITextProps,
  ITheme,
  Stack,
  Text,
  TextProps
} from "@bps/fluent-ui";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { getCalculation } from "@modules/billing/screens/allocation/utils.ts";
import { automationElementStyles } from "@modules/billing/screens/shared-components/invoice-list-base/InvoiceItemListBase.styles.ts";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  getAllocatedAmount,
  getBalanceOwing,
  getGst,
  getOwing,
  getOwingExcGst,
  getTotalExcGst,
  getTotalIncGst
} from "../allocation-field/utils.tsx";
import {
  disableFieldStyle,
  getDetailsHeader,
  getDetailsListStyles,
  getItemListRowStyles
} from "./AllocationListBase.styles.tsx";
import {
  InvoiceTotals,
  ItemListBaseProps,
  PaymentTotals,
  RenderItemProps,
  RenderTextOptions,
  TextInputProps,
  ToggleInputProps,
  TotalField
} from "./AllocationListBase.types.tsx";
import { AllocationsCustomFooter } from "./AllocationsCustomFooter.tsx";

export const AllocationListBase: React.FC<ItemListBaseProps> = props => {
  const defaultLayoutMode: number = DetailsListLayoutMode.justified;

  const renderTextInput = (textInputProps: TextInputProps) => (
    <>
      <SpinNumberInputField
        key={textInputProps.index}
        name={`${props.name}[${textInputProps.index}].${textInputProps.name}`}
        min={textInputProps.min ?? 0}
        step={textInputProps.step ?? 1}
        precision={textInputProps.precision}
        prefix={textInputProps.prefix}
        parse={value => Number(value)}
        styles={textInputProps.disabled ? disableFieldStyle : undefined}
        disabled={textInputProps.disabled}
      />

      <FieldSpy
        name={`${props.name}[${textInputProps.index}].${textInputProps.name}`}
        onChange={(value, values) => {
          textInputProps.onChange &&
            textInputProps.onChange(value, values, textInputProps.index);
        }}
      />
    </>
  );

  const renderCheckbox = (checkboxProps: ToggleInputProps) => (
    <>
      <CheckboxField
        id={`${props.name}-${checkboxProps.index}-${checkboxProps.name}`}
        name={`${props.name}[${checkboxProps.index}].${checkboxProps.name}`}
        disabled={checkboxProps.disabled}
      />
      <FieldSpy
        name={`${props.name}[${checkboxProps.index}]`}
        onChange={(value, values, prev) => {
          const { checked, total } = value;
          const { checked: prevChcked, total: prevTotal } = prev;
          //check isEqual to deterimine if it was a user click or not
          if (checked !== prevChcked && total === prevTotal) {
            checkboxProps.onChange &&
              checkboxProps.onChange(checked, values, checkboxProps.index);
          }
        }}
      />
    </>
  );

  const renderStaticText = (options: RenderTextOptions): JSX.Element => {
    const baseProps: TextProps = {
      block: true,
      styles: (_props: ITextProps, theme: ITheme) => ({
        root: options.styles && options.styles(theme)
      })
    };

    const props = options.dataAttributeElement
      ? {
          ...baseProps,
          ...dataAttribute(DataAttributes.Element, options.dataAttributeElement)
        }
      : baseProps;

    return <Text {...props}>{options.value}</Text>;
  };

  const renderLink = (options: {
    path: To;
    value: string;
    styles?: (theme: ITheme) => IStyle;
  }): JSX.Element => (
    <Stack
      styles={(_props, theme) => ({
        root: [
          { height: "100%", width: "100%", whiteSpace: "initial" },
          options.styles && options.styles(theme)
        ]
      })}
      verticalAlign="start"
    >
      <Navigate to={options.path} key={options.value}>
        {options.value}
      </Navigate>
    </Stack>
  );

  const renderItems = {
    text: renderStaticText,
    input: renderTextInput,
    checkbox: renderCheckbox,
    link: renderLink
  } as RenderItemProps;

  const getFieldValue = (field: TotalField): number => {
    switch (field.key) {
      case PaymentTotals.totalExcGst:
        return getCalculation({
          items: props.items,
          iterator: getTotalExcGst
        });
      case PaymentTotals.totalIncGst:
        return getCalculation({
          items: props.items,
          iterator: getTotalIncGst
        });

      case PaymentTotals.gst:
        return getCalculation({
          items: props.items,
          iterator: getGst
        });

      case InvoiceTotals.owing:
        return getCalculation({
          items: props.items,
          iterator: getOwing
        });

      case PaymentTotals.owingExcGst:
        return getCalculation({
          items: props.items,
          iterator: getOwingExcGst
        });

      case PaymentTotals.balanceOwing:
        return getCalculation({
          items: props.items,
          iterator: getBalanceOwing
        });

      case PaymentTotals.allocated:
        return getCalculation({
          items: props.items,
          iterator: getAllocatedAmount
        });
      case PaymentTotals.unallocated:
        return props.options.unallocatedAmount ?? 0;

      case PaymentTotals.total:
        const unallocated: number = props.options.total ?? 0;

        const allocated: number = getCalculation({
          items: props.items,
          iterator: getAllocatedAmount
        });
        return unallocated + allocated;

      case PaymentTotals.itemCount:
        return props.items.length;

      default:
        return 0;
    }
  };

  const formatValue = (field: TotalField): string => {
    const value = getFieldValue(field);
    if (field.key === PaymentTotals.itemCount) {
      return value.toString();
    }

    return currencyFormat(value, {
      currency: ""
    });
  };

  const { options, items, name, getColumns, ...listProps } = props;

  return (
    <ShimmeredDetailsList
      setKey={name}
      {...listProps}
      detailsListStyles={getDetailsListStyles(
        options.footer,
        options.invalidItemsIndexes
      )}
      items={items}
      columns={getColumns(renderItems).map(c => ({
        ...c,
        onRender: (item, index) => (
          <div
            className={automationElementStyles}
            {...dataAttribute(DataAttributes.Element, c.key)}
          >
            {c.onRender ? c.onRender(item, index) : c.name}
          </div>
        )
      }))}
      layoutMode={defaultLayoutMode}
      bordersVariant="bottomHeader"
      onRenderRow={(detailsRowProps, defaultRender) => {
        if (!detailsRowProps || !defaultRender) {
          return null;
        }
        return defaultRender!({
          ...detailsRowProps,
          styles: getItemListRowStyles(detailsRowProps, options)
        });
      }}
      onRenderDetailsHeader={(detailsHeaderProps: IDetailsHeaderProps) => {
        const hasHeaderOptions: boolean = !!options.extraHeader;

        const columns: IColumn[] = [];
        detailsHeaderProps.columns.forEach((column: IColumn): void => {
          const newCol = { ...column, onRender: undefined };
          columns.push(newCol);
        });

        if (props) {
          return (
            <>
              {options.extraHeader && (
                <Stack
                  horizontal
                  tokens={{ childrenGap: 80 }}
                  styles={{ root: { marginBottom: 8 } }}
                >
                  {options.extraHeader.header}
                  {options.extraHeader.fields.map((field: TotalField) => (
                    <Stack
                      key={field.title}
                      horizontal
                      tokens={{ childrenGap: 10 }}
                    >
                      <Text
                        variant="large"
                        styles={{
                          root: {
                            fontWeight: field.boldHeading
                              ? FontWeights.semibold
                              : undefined
                          }
                        }}
                      >
                        {field.title}
                      </Text>
                      {!field.hideHeaderValue && (
                        <Text
                          {...dataAttribute(
                            DataAttributes.Element,
                            field.dataAttr
                          )}
                          variant="large"
                          styles={{
                            root: { fontWeight: FontWeights.semibold }
                          }}
                        >
                          {formatValue(field)}
                        </Text>
                      )}
                    </Stack>
                  ))}
                </Stack>
              )}
              <DetailsHeader
                {...detailsHeaderProps}
                styles={getDetailsHeader(
                  hasHeaderOptions,
                  options.invalidItemsIndexes
                )}
              />
            </>
          );
        }
        return null;
      }}
      onRenderDetailsFooter={
        options.footer // if a footer is required
          ? (detailsFooterProps: IDetailsFooterProps) => {
              const columns: IColumn[] = [];
              detailsFooterProps.columns.forEach((column: IColumn): void => {
                const newCol = { ...column, onRender: undefined };
                columns.push(newCol);
              });
              return (
                <AllocationsCustomFooter
                  columns={columns}
                  items={items}
                  detailsFooterProps={detailsFooterProps}
                  columnOptions={props.columnOptions}
                />
              );
            }
          : undefined
      }
    />
  );
};
