import { PropsWithChildren } from "react";

import { BillingStatuses } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { InvoiceViewContext } from "@modules/billing/screens/invoice/context/InvoiceViewContext.tsx";
import { InvoiceViewHelper } from "@modules/billing/screens/invoice/context/InvoiceViewHelper.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const InvoiceViewDataFetcher: React.FC<
  PropsWithChildren<{ invoiceId: string }>
> = ({ children, invoiceId }) => {
  const getInvoiceData = async (
    root: RootStore
  ): Promise<InvoiceViewHelper> => {
    const { acc, billing, booking, comms, core } = root;

    const invoice = await billing.getInvoice(invoiceId);

    const getAdjustedInvoice = async () => {
      if (invoice?.status !== BillingStatuses.adjusted) {
        return undefined;
      }

      return root.billing.getAdjustedInvoice(invoice.number);
    };

    const [adjustedInvoice, contactPreference] = await Promise.all([
      getAdjustedInvoice(),
      comms.getContactPreference(invoice.accountId),
      invoice.calendarEventId && core.hasPermissions(Permission.ClaimRead)
        ? booking.getCalendarEvent(invoice.calendarEventId)
        : undefined,
      invoice.claimId &&
      core.hasPermissions(
        [Permission.ClaimRead, Permission.AppointmentTypeRead],
        "and"
      )
        ? acc.getClaim(invoice.claimId)
        : undefined
    ]);

    return new InvoiceViewHelper(root, {
      invoice,
      adjustedInvoice,
      contactPreference
    });
  };

  return (
    <DataFetcher<InvoiceViewHelper>
      fetch={getInvoiceData}
      refetchId={invoiceId}
      fallback={null}
    >
      {helper => (
        <InvoiceViewContext.Provider value={helper}>
          {children}
        </InvoiceViewContext.Provider>
      )}
    </DataFetcher>
  );
};
