import { useForm, useFormState } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  Heading,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { OutboundCommChannel } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import {
  AddressType,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { communicationComparer } from "@stores/core/models/Communication.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import {
  checkIfCanSet,
  ConsentCommsPreference,
  ConsentCommType,
  consentPreferenceFields,
  getCommTypeCount,
  getPreference,
  getSMSOverEmailPreference,
  PatientEditFormField
} from "./commPreferenceUtils.ts";
import { ContactMethodAddress } from "./ContactMethodAddress.tsx";
import { ContactMethodCommunications } from "./ContactMethodCommunications.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

type EditContactMethodsProps = {
  country: Country;
  onRemoveCommunication?: (
    index: number,
    values: PatientEditFormValues
  ) => void;
};

export const EditContactMethods: React.FC<EditContactMethodsProps> = ({
  country,
  onRemoveCommunication
}) => {
  const reactFinalForm = useForm<PatientEditFormValues>();
  const { initialValues, values } = useFormState<PatientEditFormValues>();
  const addContactMethod = (type: CommunicationType) => () => {
    const communications = values.communications;
    return reactFinalForm.change(
      patientFormNameOf("communications"),
      [
        ...communications.map(comms => ({
          ...comms,
          isNew: false
        })),
        { type, isNew: true, id: newGuid() }
      ].sort(communicationComparer)
    );
  };

  const setConsentPreference = (
    field: PatientEditFormField,
    fieldValueToSet?: string
  ) => {
    const { value, initialValue, name } = field;

    checkIfCanSet(value, initialValue, fieldValueToSet) &&
      reactFinalForm.change(name, fieldValueToSet);
  };

  const onChangeCommunications = (
    communications: CommunicationFieldValue[]
  ) => {
    const { Email, Mobile } = CommunicationType;
    const mobileCount = getCommTypeCount(communications, Mobile);
    const emailCount = getCommTypeCount(communications, Email);

    const consentPreference: ConsentCommsPreference = {
      SMSOverEmail: getSMSOverEmailPreference(mobileCount, emailCount),
      EmailOnly: getPreference(emailCount, OutboundCommChannel.Email),
      SMSOnly: getPreference(mobileCount, OutboundCommChannel.Sms)
    };

    reactFinalForm.batch(() => {
      Object.entries(consentPreferenceFields).forEach(preferenceField => {
        const [consentCommType, fieldNames] = preferenceField;
        fieldNames.forEach(fieldName => {
          const { typeFieldName, valueFieldName } = fieldName;
          const { outboundComm, eraseValue } =
            consentPreference[consentCommType as ConsentCommType];
          setConsentPreference(
            {
              name: typeFieldName,
              value: values[typeFieldName as string],
              initialValue: initialValues[typeFieldName as string]
            },
            outboundComm
          );
          eraseValue &&
            initialValues[valueFieldName as string] === undefined &&
            reactFinalForm.change(valueFieldName, undefined);
        });
      });
    });
  };

  const addAddress = (type: AddressType, locationCountry: string) => () => {
    return reactFinalForm.mutators.push(patientFormNameOf("addresses"), {
      street1: "",
      country: locationCountry,
      type,
      isNew: true,
      id: newGuid()
    } as AddressFieldValue);
  };

  return (
    <div
      id={`${PatientCardIds.contactMethods}-edit`}
      style={{ paddingRight: 16 }}
    >
      <Stack
        styles={(props, theme) => {
          return {
            root: {
              borderBottom: "1px solid",
              borderColor: theme.palette.neutralLighterAlt
            }
          };
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { lineHeight: "22px", marginBottom: 6 } }}
        >
          <Heading variant="section-heading-light">
            {Labels.contactMethods}
          </Heading>
          <DefaultButton
            {...dataAttribute(DataAttributes.Element, "add-more-contacts")}
            iconProps={{ iconName: "Add" }}
            text="Add more"
            menuProps={{
              calloutProps: {
                ...dataAttribute(
                  DataAttributes.Element,
                  "add-more-contacts-list"
                )
              },
              styles: { container: { width: 152 } },
              shouldFocusOnMount: true,
              items: [
                {
                  key: "mobile",
                  text: "Mobile",
                  onClick: addContactMethod(CommunicationType.Mobile)
                },
                {
                  key: "homePhone",
                  text: "Home phone",
                  onClick: addContactMethod(CommunicationType.HomePhone)
                },
                {
                  key: "workPhone",
                  text: "Work phone",
                  onClick: addContactMethod(CommunicationType.WorkPhone)
                },
                {
                  key: "afterHoursPhone",
                  text: "After hours phone",
                  onClick: addContactMethod(CommunicationType.AfterHours)
                },
                {
                  key: "email",
                  text: "Email",
                  onClick: addContactMethod(CommunicationType.Email)
                },
                {
                  key: "fax",
                  text: "Fax",
                  onClick: addContactMethod(CommunicationType.Fax)
                },
                {
                  key: "physicalAddress",
                  text: "Physical address",
                  onClick: addAddress(AddressType.Physical, country ?? "")
                },
                {
                  key: "postalAddress",
                  text: "Postal address",
                  onClick: addAddress(AddressType.Postal, country ?? "")
                }
              ]
            }}
          />
        </Stack>
        <ContactMethodCommunications
          name={patientFormNameOf("communications")}
          onRemove={onRemoveCommunication}
        />
        <FieldSpy
          name={patientFormNameOf("communications")}
          onChange={onChangeCommunications}
        />
        <ContactMethodAddress
          country={country}
          name={patientFormNameOf("addresses")}
        />
      </Stack>
    </div>
  );
};
