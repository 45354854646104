import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

import { DefaultButton, Heading, Stack } from "@bps/fluent-ui";
import { ClinicalActivityFormValues } from "@shared-types/clinical/clinical-activity-values.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { ClinicalActivityFormFields } from "./ClinicalActivityFormFields.tsx";
import { ClinicalActivityValidator } from "./ClinicalActivityValidator.tsx";

export interface ClinicalActivityDialogProps {
  title?: string;
  hideActivityTypeOption?: boolean;
  clinicalActivity?: ClinicalActivity;
  clinicalActivities?: ClinicalActivity[];
  onDismiss: () => void;
  hidden?: boolean | undefined;
  onSubmit: (values: ClinicalActivityFormValues) => void;
  initialValues?: ClinicalActivityFormValues;
  clinicalRecordId?: string;
}

const NEW_DIALOG_TITLE = "New clinical notification";
const EDIT_DIALOG_TITLE = "Edit clinical notification";

export const ClinicalActivityDialog: FunctionComponent<ClinicalActivityDialogProps> =
  observer(
    ({
      hidden,
      clinicalActivity,
      clinicalActivities,
      clinicalRecordId,
      initialValues,
      title,
      hideActivityTypeOption,
      onDismiss,
      onSubmit
    }) => {
      const root = useStores();
      const addNewAfterSubmit = useRef<boolean>(false);
      if (hidden) return null;

      const validator = new ClinicalActivityValidator(
        clinicalActivity,
        root,
        clinicalActivities
      );

      const onSubmitSucceeded = (
        _v: ClinicalActivityFormValues,
        form: FormApi<ClinicalActivityFormValues>
      ) => {
        root.notification.success("Clinical activity has been recorded.");
        if (addNewAfterSubmit.current) {
          form.restart();
          addNewAfterSubmit.current = false;
        } else {
          onDismiss();
        }
      };

      const getDialogTitle = () => {
        if (title) {
          return title;
        }

        if (clinicalActivity) {
          if (!clinicalRecordId && clinicalActivity?.patientId) {
            const contact = root.practice.contactsMap.get(
              clinicalActivity?.patientId
            );

            return contact
              ? `${EDIT_DIALOG_TITLE} - ${contact.fullName}`
              : EDIT_DIALOG_TITLE;
          }
          return EDIT_DIALOG_TITLE;
        }

        return NEW_DIALOG_TITLE;
      };

      return (
        <SubmissionFormDialog<ClinicalActivityFormValues>
          dialogName="New clinical notification"
          onSubmit={onSubmit}
          onSubmitSucceeded={onSubmitSucceeded}
          validate={validator.validate}
          initialValues={initialValues}
          buttonsProps={{
            disableSubmitOnPristine: true,
            disableSubmitOnFormInvalid: true,
            submitButtonProps: { text: "Save" },
            hideButtonsSeparator: true,
            extraActionsBetween: (form, isPrimaryDisabled: boolean) => {
              return (
                <Stack horizontal styles={{ root: { width: "100%" } }}>
                  <DefaultButton
                    text="Save & start another"
                    disabled={isPrimaryDisabled}
                    onClick={() => {
                      addNewAfterSubmit.current = true;
                      form.submit();
                    }}
                  />
                </Stack>
              );
            }
          }}
          dialogProps={{
            onDismiss,
            minWidth: 600,
            dialogContentProps: {
              title: (
                <Heading
                  styles={{
                    root: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  }}
                  variant="modal-heading"
                >
                  {getDialogTitle()}
                </Heading>
              )
            }
          }}
          render={() => (
            <ClinicalActivityFormFields
              clinicalActivity={clinicalActivity}
              hideActivityTypeOption={hideActivityTypeOption}
            />
          )}
        />
      );
    }
  );
