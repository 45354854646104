import { FunctionComponent } from "react";

import {
  dataAttribute,
  DataAttributes,
  ITextProps,
  ITheme,
  Stack,
  Text
} from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

interface BookingEventSubTextProps {
  bookingType: string;
  userName?: string;
  appointmentTypeName?: string;
  locationName?: string | undefined;
}

export const BookingEventSubText: FunctionComponent<
  BookingEventSubTextProps
> = ({ bookingType, userName, appointmentTypeName, locationName }) => {
  const { core } = useStores();

  const subTextTemplates = [
    [
      "Appointment",
      `${appointmentTypeName} with ${userName}`,
      "booking-event-callout-appointment-type"
    ],
    ["Meeting", `Meeting with ${userName}`],
    ["Break", "Break", ""],
    ["Unavailable", ""]
  ];

  const getTextStyles = (_: ITextProps, theme: ITheme) => ({
    root: {
      fontSize: 12,
      color: theme.palette.neutralSecondary
    }
  });

  const template = subTextTemplates.find(x => x[0] === bookingType);
  const text = template ? template[1] : "";
  const testId = template ? template[2] : "";
  const locationNameText = ` at ${locationName}`;

  return (
    <Stack verticalAlign="start">
      <Text
        styles={getTextStyles}
        {...(testId && dataAttribute(DataAttributes.Element, testId))}
      >
        {text}
      </Text>
      {core.hasMultiLocationOrgUnit && (
        <Text styles={getTextStyles}>{locationNameText}</Text>
      )}
    </Stack>
  );
};
