import { FormApi } from "final-form";
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  toJS
} from "mobx";

import { HttpStatusCode } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { UpsertInvoiceItemNewDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { AppointmentStatusCode } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  AddEncounterDto,
  DiagnosisDataItemDto,
  EncounterLocation,
  EncounterStatus,
  EncounterTimerStatus,
  EncounterType,
  EpisodeOfCareDto,
  PatchEncounterDto,
  PermanentClinicalTab,
  RFV_CD_TYPE_CODE_OTHER,
  TodaysNotesHeading
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  PatchPatientDto,
  RelationshipDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import {
  getAddInvoiceItemNewDto,
  getInvoiceItemFormValues
} from "@modules/billing/screens/shared-components/utils/invoice.utils.ts";
import { AppInsightsClinicalRecordHelper } from "@modules/clinical/screens/context/AppInsightsClinicalRecordHelper.ts";
import { PatientRecordScreenHelper } from "@modules/clinical/screens/context/PatientRecordScreenHelper.ts";
import { ClaimReviewStatus } from "@modules/clinical/screens/patient-record/components/claim-review/ClaimReviewEnums.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimReview } from "@stores/acc/models/ClaimReview.ts";
import { Service } from "@stores/billing/models/Service.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import {
  getClinicalActivityDtos,
  getClinicalTaskDtos
} from "@stores/clinical/utils/utils.ts";

import { ConsultDetailDialogValues } from "../ConsultDetailDialog.tsx";
import { AutoFocusElement } from "../ConsultDetailDialogFields.tsx";

export class EncounterFormHelper extends AppInsightsClinicalRecordHelper {
  private disposer: IReactionDisposer;

  constructor(
    protected root: IRootStore,
    public helpers: {
      parentHelper: PatientRecordScreenHelper;
      openReasonForVisitDialog: (recordId: string) => void;
    }
  ) {
    super(root);
    this.disposer = reaction(
      () => this.root.clinical.ui.closingClinicalRecordId,
      id => {
        if (
          id &&
          this.helpers.parentHelper.clinicalRecord.id &&
          this.helpers.parentHelper.clinicalRecord.id === id
        ) {
          this.setIsEnding(true);
          this.setCloseOnEnd(true);
          this.closeForm();
        }
      }
    );
  }

  subscribeToCalendarEventChanges = () => {
    this.root.booking.hub.onEntityEvent(
      Entity.CalendarEvent,
      this.onCalendarEventChanged
    );
  };

  unsubscribeFromCalendarEventChanges = () => {
    this.root.booking.hub.unsubscribe(
      Entity.CalendarEvent,
      this.onCalendarEventChanged
    );
  };

  onCalendarEventChanged = async (message: EntityEventData) => {
    if (
      message.id === this.calendarEventId &&
      message.action === EventAction.StatusUpdate
    ) {
      await this.root.booking.getCalendarEvent(this.calendarEventId, {
        ignoreCache: true
      });
    }
  };

  @action
  openReasonForVisitDialog() {
    this.openConsultDetailDialog();
  }

  get clinical() {
    return this.root.clinical;
  }

  get core() {
    return this.root.core;
  }

  get acc() {
    return this.root.acc;
  }

  get booking() {
    return this.root.booking;
  }

  get practice() {
    return this.root.practice;
  }

  @computed
  get clinicalRecord() {
    return this.helpers.parentHelper.clinicalRecord;
  }

  @computed
  get encounter() {
    return this.clinicalRecord?.openEncounter;
  }

  @computed get calendarEvent() {
    return this.clinicalRecord?.calendarEvent;
  }

  @computed get claim() {
    return this.calendarEvent?.claim;
  }

  @computed get noChargeComment() {
    const patientId = this.clinicalRecord?.id;

    return patientId
      ? this.calendarEvent?.getPropertyOfAttendee("noChargeComment", patientId)
      : undefined;
  }

  @computed get confidential() {
    return !!this.encounter?.secGroupId;
  }
  @computed get providerComment() {
    return this.calendarEvent?.providerComment;
  }

  @computed get invoiceItems() {
    return this.clinicalRecord?.invoiceItems;
  }

  @computed get invoiceItemsAreFinalised() {
    return !!(
      this.clinicalRecord?.invoiceItems.length &&
      this.clinicalRecord?.invoiceItems.every(x => !!x.transactionId)
    );
  }

  @computed get invoiceItemsWithServices() {
    return (
      this.invoiceItems?.filter(invoiceItem => {
        if (invoiceItem.service) {
          const service = new Service(this.root, invoiceItem.service);

          return service.isActive;
        }
        return false;
      }) || []
    );
  }

  @computed get reasonForVisits() {
    return (
      this.clinicalRecord?.clinicalData?.reasonForVisit?.reasonForVisits ?? []
    );
  }

  dispose = () => {
    this.disposer();
  };

  // "Ending" refers to when a user choses to end the consult to complete the record's notes at another time.
  // "Finalising" refers to finalising an encounter so that the notes are marked as complete.

  @computed get isFinalising() {
    return this.helpers.parentHelper.isFinalising;
  }

  set isFinalising(value: boolean) {
    runInAction(() => {
      this.helpers.parentHelper.isFinalising = value;
    });
  }

  @observable isEnding: boolean = false;
  @observable closeOnEnd: boolean = false;

  @action setIsEnding = (value: boolean) => {
    this.isEnding = value;
  };

  @action setCloseOnEnd = (value: boolean) => {
    this.closeOnEnd = value;
  };

  @observable hasEncounterInProgressWarning = false;
  @observable notesCompleted = false;

  @computed get reasonForVisit() {
    return this.reasonForVisits?.map(rfv => `${rfv.code}.${rfv.originalText}`);
  }

  @computed get isRecordUpdate() {
    return (
      this.clinicalRecord?.openEncounter?.type === EncounterType.RecordUpdate
    );
  }

  @computed get isRecordUpdateWithCalendarEvent() {
    return this.isRecordUpdate && !!this.clinicalRecord?.calendarEvent;
  }

  @action
  setNotesCompleted = (value: boolean) => {
    this.notesCompleted = value;
  };

  toEncounterRequest = (
    values: EncounterFormValues
  ): AddEncounterDto | PatchEncounterDto => {
    const visitDateTime = DateTime.fromJSDateAndTime(
      values.startDate ?? DateTime.jsDateNow(),
      values.startTime
    ).toISO();

    const encounterType = values.type;
    const encounterLocation = values.location;
    const practiceLocationId = values.practiceLocationId;

    if (this.encounter) {
      return {
        id: this.encounter.id,
        eTag: this.encounter.eTag,
        visitDateTime,
        encounterType,
        encounterLocation,
        orgUnitId: values.practiceLocationId,
        secGroupId: values.confidential
          ? this.core.user?.privateSecGroupId
          : undefined
      };
    }

    return {
      visitDateTime,
      encounterType,
      encounterLocation,
      patientId: this.clinicalRecord?.id,
      userId: this.root.core.userId,
      orgUnitId: practiceLocationId ?? this.root.core.locationId,
      secGroupId: values.confidential
        ? this.core.user?.privateSecGroupId
        : undefined
    };
  };

  public updateEncounterClinicalNote = async (
    mainFreeText: string | undefined,
    notesHeadings: TodaysNotesHeading[],
    values: EncounterFormValues | undefined
  ) => {
    if (!this.encounter) {
      await this.saveEncounter(values);
    }

    const notesData = this.clinicalRecord?.toClinicalNoteRequest(
      mainFreeText,
      notesHeadings
    );
    if (!this.encounter) {
      throw new Error("Cannot get encounter");
    }

    runInAction(() => {
      this.clinicalRecord.saving = true;
      this.clinicalRecord.lastSaved = DateTime.jsDateNow();
    });

    try {
      const clinicalNote = await this.root.clinical.updateEncounterClinicalNote(
        notesData,
        this.encounter!.id
      );

      this.clinicalRecord?.setEncounterNotes(clinicalNote);
    } finally {
      runInAction(() => {
        this.clinicalRecord.saving = false;
      });
    }
  };

  public stopEncounterTimer = async (encounterId: string) => {
    if (!this.core.hasPermissions(Permission.EncounterTimerAllowed)) return;
    try {
      const tmr = await this.clinical.getEncounterTimer(encounterId);
      if (tmr.timerStatus !== EncounterTimerStatus.Stopped) {
        await this.clinical.callEncounterTimer({
          id: encounterId,
          since: DateTime.now().toISO(),
          timerStatus: EncounterTimerStatus.Stopped
        });
      }
    } catch (error) {
      if (error.response && error.response.status === HttpStatusCode.NotFound) {
        return;
      }
      throw error;
    }
  };

  public saveEncounter = async (
    formValues: EncounterFormValues | undefined,
    isFinalising: boolean = false
  ) => {
    let values = { ...formValues };

    if (!values) {
      values = this.encounterToInitialValues;
    }

    const encounterData = this.toEncounterRequest(values);

    if (isFinalising) {
      encounterData.status = EncounterStatus.Closed;
    }

    // if we are not finalising but have and existing encounter (End consult path)
    if (!isFinalising && this.encounter) {
      encounterData.status = EncounterStatus.Committed;
    }

    let encounter: Encounter;

    const isNewEncounter = !this.encounter;
    if (isNewEncounter) {
      encounter = await this.clinicalRecord?.addEncounter(
        encounterData as AddEncounterDto
      );
    } else {
      encounter = await this.root.clinical.updateEncounter(
        encounterData as PatchEncounterDto
      );
    }

    if (!encounter) {
      throw new Error("Cannot get encounter");
    }
    if (isFinalising && this.root.core.isNZTenant) {
      await this.saveCompleteClaimReviews();
    }

    return encounter;
  };

  private saveCompleteClaimReviews = async () => {
    const conditions =
      await this.helpers.parentHelper.clinicalRecord.loadConditions();

    const claims = (await conditions
      .map(x => x.claim)
      .filter(x => x)) as Claim[];

    await Promise.all(claims.map(x => x.loadClaimReview()));

    const claimReviews = (await claims
      .map(x => x.claimReview)
      .filter(x => x)) as ClaimReview[];

    const claimReviewsToBeMarkedAsCompleted = claimReviews.filter(
      x => x.statusCode !== ClaimReviewStatus.completed && x.clinicalDocumentId
    );

    await Promise.all(
      claimReviewsToBeMarkedAsCompleted.map(x =>
        this.root.acc.patchClaimReview({
          ...x.dto,
          statusCode: ClaimReviewStatus.completed
        })
      )
    );
  };

  private saveInvoiceItems = async (
    invoiceItems: InvoiceItemFormValue[] = []
  ) => {
    const orgUnitId =
      this.helpers.parentHelper.encounterFormApi?.getState().values
        .practiceLocationId;

    const upsertItems: UpsertInvoiceItemNewDto[] = invoiceItems.map(x =>
      getAddInvoiceItemNewDto({
        item: x,
        baseProps: this.clinicalRecord.getInvoiceItemBaseProps(orgUnitId),
        locationId: this.core.locationId
      })
    );

    if (!!this.invoiceItems.length) {
      return this.clinicalRecord.updateInvoice(upsertItems);
    }
    return this.clinicalRecord?.createInvoiceItems(upsertItems, orgUnitId);
  };

  onSubmit = async (args: {
    values: EncounterFormValues;
    form: FormApi<EncounterFormValues>;
    trackEvent?: (eventData: Object) => void;
  }) => {
    if (this.isFinalising) {
      await this.submitForm(args);
    }

    if (this.isEnding) {
      await this.closeForm();
    }
    return;
  };

  private closeForm = async () => {
    const values =
      this.helpers.parentHelper.encounterFormApi?.getState().values;

    const encounterId = this.encounter?.id;
    const reasonForVisitValues = {
      reasonForVisitClinicalDataKeys:
        this.clinicalRecord?.clinicalData?.reasonForVisit?.reasonForVisits.map(
          rfv => rfv.code
        )
    };

    if (
      !this.isRecordUpdate &&
      !reasonForVisitValues.reasonForVisitClinicalDataKeys?.length
    ) {
      this.openReasonForVisitDialog();
      return;
    }

    if (
      !this.isRecordUpdate &&
      this.invoiceItemsWithServices.length === 0 &&
      !this.noChargeComment &&
      !this.invoiceItemsAreFinalised
    ) {
      this.openAddServicesDialog();
      return;
    }

    const encounter = await this.saveEncounter(values);
    if (
      this.core.hasPermissions(Permission.MedicalHistoryDiagnosisSyncAllowed)
    ) {
      await this.updateEpisodeOfCare();
      await this.clinicalRecord.loadConditions();
      await this.clinicalRecord.loadEpisodeOfCare();
    }

    if (
      encounter &&
      (encounter.status === EncounterStatus.Closed ||
        encounter.status === EncounterStatus.Committed)
    ) {
      await this.stopEncounterTimer(encounter.id);
    }

    if (
      this.clinicalRecord?.calendarEventId &&
      this.clinicalRecord.claimId &&
      this.clinicalRecord.episodeOfCare?.id
    ) {
      await this.createClaimAppointmentLink(
        this.clinicalRecord.calendarEventId,
        this.clinicalRecord.claimId,
        this.clinicalRecord.episodeOfCare.id
      );
    }

    if (this.clinicalRecord?.calendarEventId) {
      await this.root.booking.updateCalendarEventAppointmentStatus(
        this.clinicalRecord?.calendarEventId,
        {
          appointmentStatus:
            !!this.invoiceItems.length &&
            //not draft
            this.invoiceItems[0].transactionId
              ? AppointmentStatusCode.Completed
              : AppointmentStatusCode.Finalised,
          calendarEventId: this.clinicalRecord?.calendarEventId
        }
      );
    }

    if (this.closeOnEnd) {
      await this.clinicalRecord?.close(encounterId);
      this.root.clinical.ui.resetClosingClinicalRecordId();
    }

    this.setIsEnding(false);
    this.setCloseOnEnd(false);
  };

  private submitForm = async (args: {
    values: EncounterFormValues;
    form: FormApi<EncounterFormValues>;
    trackEvent?: (eventData: Object) => void;
  }) => {
    const encounterId = this.encounter?.id;

    const reasonForVisitValues = {
      reasonForVisitClinicalDataKeys:
        this.clinicalRecord?.clinicalData?.reasonForVisit?.reasonForVisits.map(
          rfv => rfv.code
        ),
      reasonForVisitClinicalDataOriginalText:
        this.clinicalRecord?.clinicalData?.reasonForVisit?.reasonForVisits.find(
          rfv => !!rfv.originalText
        )?.originalText
    };

    if (
      this.isRecordUpdateWithCalendarEvent &&
      this.clinicalRecord?.calendarEventId &&
      !reasonForVisitValues.reasonForVisitClinicalDataKeys?.length
    ) {
      if (this.invoiceItems.length === 0) {
        await this.root.booking.updateCalendarEventAppointmentStatus(
          this.clinicalRecord?.calendarEventId,
          {
            appointmentStatus: AppointmentStatusCode.Completed,
            calendarEventId: this.clinicalRecord?.calendarEventId
          }
        );
      } else {
        this.openReasonForVisitDialog();
        return;
      }
    }

    if (
      !this.isRecordUpdate &&
      !reasonForVisitValues.reasonForVisitClinicalDataKeys?.length
    ) {
      this.openReasonForVisitDialog();
      return;
    }

    if (
      !this.isRecordUpdate &&
      this.invoiceItemsWithServices.length === 0 &&
      !this.noChargeComment &&
      !this.invoiceItemsAreFinalised
    ) {
      this.openAddServicesDialog();
      if (!this.encounter) {
        await this.saveEncounter(args.values);
      }
      return;
    }

    if (
      this.clinicalRecord?.calendarEventId &&
      this.clinicalRecord.claimId &&
      this.clinicalRecord.episodeOfCare?.id
    ) {
      await this.createClaimAppointmentLink(
        this.clinicalRecord.calendarEventId,
        this.clinicalRecord.claimId,
        this.clinicalRecord.episodeOfCare.id
      );
    }

    //report data to App-Insight
    if (args.trackEvent !== undefined) {
      await this.trackEventEncounterCompleted(args.trackEvent);
    }

    await Promise.all([
      this.closePatientTabs(),
      this.completeDischarges(),
      this.addAndUpdateClinicalTasks(),
      this.addAndUpdateClinicalActivities(),
      this.updateEpisodeOfCare(),
      this.addAndUpdatedRelationships()
    ]);

    const encounter = await this.saveEncounter(args.values, true);

    if (
      encounter &&
      (encounter.status === EncounterStatus.Closed ||
        encounter.status === EncounterStatus.Committed)
    ) {
      await this.stopEncounterTimer(encounter.id);
    }

    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord?.id,
      PermanentClinicalTab.TodaysNotes
    );

    // Finalise process may have been kicked off due to an existing encounter,
    // which means the dialog will be left open (in case of RFV or service dialog
    // cancellations). Ensure this flag is set to the default of false for next
    // time

    this.helpers.parentHelper.setShowEncounterExistsPrompt(false);
    await this.clinicalRecord?.close(encounterId);
  };

  @action
  handleFinaliseNotesAndServicesClick = () => {
    const isFormUnsaved = this.checkIfFormUnsaved();
    if (!isFormUnsaved) {
      this.isFinalising = true;
    }
  };

  @action
  handleSaveUpdateClick = () => {
    const isFormUnsaved = this.checkIfFormUnsaved();
    if (!isFormUnsaved) {
      this.isFinalising = true;
    }
  };

  @action
  checkIfFormUnsaved = () => {
    const tabsCondition = this.clinicalRecord?.tabCondition;
    const unsavedData = tabsCondition?.unsavedData.length ?? 0;
    this.isUnsavedFormsDialogVisible = unsavedData > 0;

    return this.isUnsavedFormsDialogVisible;
  };

  public get encounterToInitialValues(): EncounterFormValues {
    const now = DateTime.jsDateNow();
    const persistentValues =
      this.clinicalRecord.clinicalRecordFormsValuesStash.persistedValues[
        PermanentClinicalTab.TodaysNotes
      ];

    let encounterFormValues: EncounterFormValues | undefined;

    if (!this.encounter) {
      encounterFormValues = toJS<EncounterFormValues>({
        startDate: now,
        startTime: DateTime.fromJSDate(now).toTimeInputFormat(),
        type: EncounterType.Consultation,
        location: EncounterLocation.Practice,
        reasonForVisit: this.reasonForVisit,
        ...persistentValues
      });
    } else {
      encounterFormValues = toJS<EncounterFormValues>({
        startDate: this.encounter.startDateTime.toJSDate() || now,
        startTime: (this.encounter.startDateTime || now).toTimeInputFormat(),
        type: this.encounter.type || EncounterType.Consultation,
        location: this.encounter.location || EncounterLocation.Practice,
        confidential: !!this.encounter.secGroupId,
        reasonForVisit: this.reasonForVisit,
        practiceLocationId: this.encounter.orgUnitId,
        ...persistentValues
      });
    }

    if (
      (this.root.core.isNZTenant &&
        this.helpers.parentHelper.isClaimReviewOpen) ||
      this.isRecordUpdateWithCalendarEvent
    ) {
      encounterFormValues.type = EncounterType.RecordUpdate;
    }

    return encounterFormValues;
  }

  @action onCancelledReasonForVisitModal = () => {
    this.isFinalising = false;
    this.isEnding = false;
    this.closeOnEnd = false;
  };

  @action openEncounterInProgressWarning = () => {
    this.hasEncounterInProgressWarning = true;
  };

  @action dismissEncounterInProgressWarning = () => {
    this.hasEncounterInProgressWarning = false;
  };

  @action
  onDiscard = () => {
    if (this.encounter) {
      this.clinical.ui.isConfirmationDialogVisible = true;
    } else {
      this.clinicalRecord?.close();
    }
  };

  // Todays Notes Date Time Dialog

  /**
   * Determines whether the consult detail dialog should be visible.
   * undefined means not visible, an AutoFocusElement value means that
   * it should be visible with that element autofocussed.
   */
  @observable consultDetailDialogVisibleWithFocus:
    | AutoFocusElement
    | undefined = undefined;

  /**
   * Opens the consult detail dialog, with the specified element in focus (defaults to the first field; the date field.)
   * @param autoFocusElement
   */
  @action openConsultDetailDialog = (autoFocusElement?: AutoFocusElement) => {
    // Uses date as the default focus element.
    this.consultDetailDialogVisibleWithFocus =
      autoFocusElement || AutoFocusElement.Date;
  };

  @action closeConsultDetailDialog = () => {
    this.consultDetailDialogVisibleWithFocus = undefined;
  };

  // NoChargeCommentDialog

  @observable isNoChargeCommentDialogVisible = false;

  @action setNoChargeDialogVisibility = (value: boolean) => {
    this.isNoChargeCommentDialogVisible = value;
  };

  setNoChargeComment = async (noChargeComment: string) => {
    await Promise.all([
      noChargeComment && this.invoiceItems.length
        ? this.saveInvoiceItems([])
        : undefined,
      this.updateCalendarEvent(noChargeComment)
    ]);
  };

  // ProviderCommentDialog

  @observable isProviderCommentDialogVisible = false;

  @action openProviderCommentDialog = () => {
    this.isProviderCommentDialogVisible = true;
  };

  @action closeProviderCommentDialog = () => {
    this.isProviderCommentDialogVisible = false;
  };

  setProviderComment = async (providerComment: string) => {
    return this.updateProviderComment(providerComment);
  };

  updateCalendarEvent = async (noChargeComment: string | undefined) => {
    const calendarEventCreated =
      await this.clinicalRecord.createCalendarEventIfRequired(
        noChargeComment,
        this.helpers.parentHelper.encounterFormApi?.getState().values
          .practiceLocationId
      );

    if (this.calendarEvent && !calendarEventCreated) {
      //get latest ETag.
      await this.root.booking.getCalendarEvent(this.calendarEvent.id, {
        ignoreCache: true
      });

      const patientId = this.clinicalRecord?.id;
      if (patientId && this.calendarEvent.getAttendee(patientId)) {
        const attendees = this.calendarEvent.dto.attendees.map(attendee => {
          if (attendee.attendeeId === patientId) {
            return {
              ...attendee,
              noChargeComment
            };
          }

          return attendee;
        });

        await this.root.booking.updateCalendarEvent({
          id: this.calendarEvent.id,
          attendees
        });
      }
    }
  };

  updateProviderComment = async (providerComment: string | undefined) => {
    // When we start consult from Address book, and we don't have any appointment we can't save or update no charge comment. Here we want to check if there is an appointment. If no we want to create a new one.
    await this.clinicalRecord.createCalendarEventIfRequired();
    if (this.calendarEvent?.id) {
      await this.root.booking.addProviderComment(
        this.calendarEvent.id,
        providerComment
      );
    }
  };

  // AddServicesModal
  @observable isAddServicesDialogVisible = false;

  @action openAddServicesDialog = () => {
    this.isAddServicesDialogVisible = true;
  };

  @action closeAddServicesDialog = () => {
    this.isAddServicesDialogVisible = false;
  };

  @action cancelAddServicesDialog = () => {
    this.isFinalising = false;
    this.isEnding = false;
    this.closeOnEnd = false;
    this.closeAddServicesDialog();
    this.clinical.ui.resetClosingClinicalRecordId();
  };

  getInitialServicesSelected = async (): Promise<InvoiceItemFormValue[]> => {
    await this.root.billing.getInvoiceSettings();
    if (!this.invoiceItems.length) {
      return [];
    }

    return getInvoiceItemFormValues({
      invoiceItems: this.invoiceItems,
      gstPercent: this.root.billing.gstPercent
    });
  };

  onSubmitServices = async (items: InvoiceItemFormValue[]) => {
    try {
      await Promise.all([
        this.saveInvoiceItems(items),
        this.noChargeComment && items.length
          ? this.setNoChargeComment("") // clear the no charge comment if there are service items
          : undefined
      ]);

      this.closeAddServicesDialog();
    } catch (e) {
      this.root.notification.error(
        "The services could not be saved.  Please try again."
      );
      throw new Error("The services could not be saved.  Please try again.");
    }
  };

  // UnsavedFormsDialog

  @observable isUnsavedFormsDialogVisible = false;

  @action closeUnsavedFormsDialog = () => {
    this.isUnsavedFormsDialogVisible = false;
  };

  // InvoicedServicesDialog

  @observable isInvoicedServicesDialogVisible = false;

  @action
  setInvoicedServicesDialogVisible = (isVisible: boolean) => {
    this.isInvoicedServicesDialogVisible = isVisible;
  };

  addAndUpdatedRelationships = async () => {
    if (this.clinicalRecord?.stashedClinicalData?.patientDemographicUpdate) {
      const updatedRelationships: RelationshipDto[] = [];

      const attorneys =
        this.clinicalRecord?.stashedClinicalData.patientDemographicUpdate
          ?.powerOfAttorneys;

      if (attorneys && attorneys.length > 0) {
        attorneys.forEach(attorney => {
          updatedRelationships.push({
            relatedContactId: attorney.id,
            relationship: RelationshipType.PowerOfAttorney,
            hasRelationship: true
          });
        });
      }

      const hasEnduringPowerOfAttorney =
        this.clinicalRecord?.stashedClinicalData.patientDemographicUpdate
          ?.isEnduringPowerOfAttorney?.observed;

      if (hasEnduringPowerOfAttorney === false) {
        updatedRelationships.push({
          relatedContactId: undefined,
          relationship: RelationshipType.PowerOfAttorney,
          hasRelationship: false
        });
      }

      const carers =
        this.clinicalRecord?.stashedClinicalData?.patientDemographicUpdate
          ?.careGivers;

      if (carers && carers.length > 0) {
        carers.forEach(carer => {
          updatedRelationships.push({
            relatedContactId: carer.id,
            relationship: RelationshipType.Carer,
            hasRelationship: true,
            metadata: {
              careType: carer.careGiverTypes.toString(),
              relationshipTypeCode: RelationshipType.Carer
            }
          });
        });
      }

      const hasACareGiver =
        this.clinicalRecord?.stashedClinicalData.patientDemographicUpdate
          ?.hasACareGiver?.observed;

      if (hasACareGiver === false) {
        updatedRelationships.push({
          relatedContactId: undefined,
          relationship: RelationshipType.Carer,
          hasRelationship: false
        });
      }

      const careRecipients =
        this.clinicalRecord?.stashedClinicalData.patientDemographicUpdate
          ?.careRecipients;

      if (careRecipients && careRecipients.length > 0) {
        careRecipients.forEach(recipient => {
          updatedRelationships.push({
            relatedContactId: recipient.id,
            relationship: RelationshipType.CarerOf,
            hasRelationship: true,
            metadata: {
              relationshipTypeCode: RelationshipType.CarerOf,
              careType: recipient.careRecipientTypes.toString()
            }
          });
        });
      }

      const isACarer =
        this.clinicalRecord?.stashedClinicalData.patientDemographicUpdate
          ?.isACarer?.observed;

      if (isACarer === false) {
        updatedRelationships.push({
          relatedContactId: undefined,
          relationship: RelationshipType.CarerOf,
          hasRelationship: false
        });
      }

      if (this.clinicalRecord?.patient) {
        const existingRelationships =
          this.clinicalRecord?.patient?.relationships?.filter(
            item =>
              item.relationship !== RelationshipType.PowerOfAttorney &&
              item.relationship !== RelationshipType.Carer &&
              item.relationship !== RelationshipType.CarerOf
          );

        if (existingRelationships) {
          updatedRelationships.push(...existingRelationships);
        }

        const relationshipStatus =
          this.clinicalRecord?.stashedClinicalData?.patientDemographicUpdate
            ?.relationshipStatus ??
          this.clinicalRecord?.patient.relationshipStatus;

        const occupation =
          this.clinicalRecord?.stashedClinicalData?.patientDemographicUpdate
            .occupation ?? this.clinicalRecord?.patient.occupation;

        const patientRequest: PatchPatientDto = {
          id: this.clinicalRecord?.id,
          relationships: updatedRelationships,
          relationshipStatus,
          occupation
        };

        await this.root.practice.updatePatient(patientRequest);
      }
    }
  };

  addAndUpdateClinicalTasks = async () => {
    const clinicalTasks = getClinicalTaskDtos(
      this.clinicalRecord?.clinicalData?.clinicalTask?.clinicalTasks,
      false
    );

    const deletedClinicalTasks = getClinicalTaskDtos(
      this.clinicalRecord?.clinicalData?.clinicalTask?.deletedItems,
      true
    );

    const mergedClinicalTasks = [...clinicalTasks, ...deletedClinicalTasks];
    if (mergedClinicalTasks.length > 0) {
      await this.root.clinical.updateClinicalTask(
        this.clinicalRecord?.id,
        mergedClinicalTasks
      );
    }
  };

  addAndUpdateClinicalActivities = async () => {
    const clinicalActivities = getClinicalActivityDtos(
      this.clinicalRecord?.clinicalData?.clinicalActivity?.clinicalActivities,
      false
    );

    const deletedClinicalActivities = getClinicalActivityDtos(
      this.clinicalRecord?.clinicalData?.clinicalActivity?.deletedItems,
      true
    );

    const mergedClinicalActivities = [
      ...clinicalActivities,
      ...deletedClinicalActivities
    ];
    if (mergedClinicalActivities.length > 0) {
      await this.root.clinical.updateClinicalActivity(
        this.clinicalRecord?.id,
        mergedClinicalActivities
      );
    }
  };

  updateEpisodeOfCare = async () => {
    const episodeOfCare =
      this.clinicalRecord?.episodeOfCare &&
      (await this.clinical.getEpisodeOfCare(
        this.clinicalRecord.episodeOfCare.id
      ));

    if (episodeOfCare) {
      let diagnoses: DiagnosisDataItemDto[] | undefined;
      if (
        this.core.hasPermissions(Permission.MedicalHistoryDiagnosisSyncAllowed)
      ) {
        const medicalHistoriesForCondition =
          this.clinicalRecord.medicalHistories.filter(
            x => x.episodeOfCareId === episodeOfCare.id
          );
        diagnoses = medicalHistoriesForCondition.map(x => {
          const diagnosis: DiagnosisDataItemDto = {
            diagnosisCode: x.diagnosisCode,
            diagnosisSide: x.diagnosisSide,
            isPrimaryDiagnosis: x.isPrimary
          };
          return diagnosis;
        });
      } else {
        diagnoses = this.clinicalRecord?.clinicalData?.diagnoses?.diagnoses;
      }

      const currentInjuryData =
        this.clinicalRecord?.clinicalData?.currentInjury;

      const newEpisodeOfCareData: EpisodeOfCareDto = {
        ...episodeOfCare
      };

      if (diagnoses && diagnoses.length > 0)
        newEpisodeOfCareData.diagnoses = diagnoses;

      newEpisodeOfCareData.injuryDate =
        currentInjuryData?.dateOfInjury ?? episodeOfCare.injuryDate;

      await this.clinical.updateEpisodeOfCare(
        newEpisodeOfCareData,
        episodeOfCare.id
      );
    }
  };

  completeDischarges = async () => {
    // Update discharge status to COMPLETED
    if (this.encounter && this.encounter.episodeOfCareId) {
      const dischargesToComplete =
        this.clinicalRecord?.clinicalData?.discharge?.dataItems
          ?.filter(x => x.dischargeStatus === DischargeStatus.ReadyToFinalise)
          .map(x => x.businessRoleCode);

      const dischargePromises = dischargesToComplete?.map(async x => {
        this.clinicalRecord?.updateDischargeStatus(
          DischargeStatus.Completed,
          x
        );
      });

      if (dischargePromises) await Promise.all(dischargePromises);
    }
  };

  closePatientTabs = async () => {
    const tabsCondition = this.clinicalRecord?.tabCondition;
    if (tabsCondition) {
      for (const tab of tabsCondition.safeToClose) {
        if (this.root.clinical.ui.tabs.currentPatientRecordTab) {
          this.root.clinical.ui.tabs.currentPatientRecordTab.removeTab(tab.id);
        }
      }
    }
  };

  getConsultDetailDialogInitialValues = (formValues: Record<string, any>) => {
    const todaysNotesDateTimeDialogValues: ConsultDetailDialogValues = {
      ...formValues,
      type: formValues ? formValues.type : undefined,
      reasonForVisit: this.reasonForVisit ?? [],
      otherText: this.reasonForVisits?.find(
        rfv => rfv.code === RFV_CD_TYPE_CODE_OTHER
      )?.originalText
    };
    return todaysNotesDateTimeDialogValues;
  };

  createClaimAppointmentLink = async (
    calendarEventId: string,
    claimId: string,
    episodeOfCareId: string
  ) => {
    if (this.core.hasPermissions(Permission.CalendarEventWrite)) {
      const claimAppts = await this.acc.getClaimAppointmentDtos({
        calendarEventId
      });

      const claimApptsToDelete =
        claimAppts?.length > 0
          ? claimAppts.filter(x => x.claimId !== claimId)
          : undefined;

      if (!!claimApptsToDelete) {
        await Promise.all(
          claimApptsToDelete.map(x => this.acc.deleteClaimAppointmentDto(x.id))
        );
      }

      const claimAppt =
        claimAppts?.length > 0
          ? claimAppts.find(x => x.claimId === claimId)
          : undefined;

      if (!claimAppt) {
        await this.acc.addClaimAppointment({
          claimId,
          calendarEventId
        });
      }

      const calendarEvent = await this.booking.getCalendarEvent(
        calendarEventId,
        { ignoreCache: true }
      );

      //createCalendarEventIfRequired will already have set this so we don't need to update it again unless needed.
      if (calendarEvent.reason?.episodeOfCareId !== episodeOfCareId) {
        const provider = calendarEvent.userId
          ? await this.practice.getProvider(calendarEvent.userId)
          : undefined;

        await this.booking.updateCalendarEvent({
          id: calendarEvent.id,
          providerContractType: provider?.contractTypes?.length
            ? provider.contractTypes[0]
            : undefined,
          reason: { episodeOfCareId }
        });
      }
    }
  };
}
