import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  SplitButton,
  Stack,
  Text
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { OutboundCommType } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { Payment } from "@stores/billing/models/Payment.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { EmailConfirmationModal } from "@ui-components/email-confirmation-modal/EmailConfirmationModal.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { closeInvoiceOrPaymentPage } from "../invoice/components/utils.ts";
import { CancelTransactionDialog } from "../shared-components/CancelTransactionDialog.tsx";
import { OptedOutTooltip } from "../shared-components/OptedOutTooltip.tsx";
import { RefundSubmissionDialog } from "../shared-components/RefundConfirmationDialog.tsx";
import { PaymentViewContext } from "./context/PaymentViewContext.tsx";

interface PaymentViewButtonProps {
  payment: Payment;
}

const CANCEL_TEXT = "Cancel payment";

const PaymentViewButtonsBase: React.FC<PaymentViewButtonProps> = observer(
  ({ payment }) => {
    const { routing, billing, notification } = useStores();

    const [refundDialogVisible, setRefundDialogVisible] = useState(false);

    const close = () => {
      closeInvoiceOrPaymentPage(routing);
    };

    const {
      contact,
      emailDialogVisible,
      setEmailDialogVisible,
      handlePaymentViewOpenPdf
    } = useContext(PaymentViewContext);

    const handleGetPdf = async () => {
      await handlePaymentViewOpenPdf(payment.id);
    };

    const [showDialog, setShowDialog] = useState(false);

    const handleCancel = async (reason: string) => {
      await billing.cancelPayment(payment.id, reason);
      close();
      notification.success(
        notificationMessages.paymentCancelled(payment.number)
      );
    };

    const onClear = () => {
      setRefundDialogVisible(false);
    };

    const onSucceeded = async (refundPaymentMethod: PaymentMethod) => {
      await billing.refundUnallocatedCredit(payment, refundPaymentMethod);
      closeInvoiceOrPaymentPage(routing, "replace");
      onClear();
    };

    const isEmailOrPdfDisabled =
      payment.items.length === 0 || payment.isCancelled;

    return (
      <>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {!payment.isCancelled && (
            <SplitButton
              primary
              disabled={!payment.unallocated}
              disableContextMenuButton={!!payment.allocated}
              items={[
                {
                  key: "cancel",
                  text: CANCEL_TEXT,
                  onClick: () => setShowDialog(true)
                }
              ]}
              text="Refund"
              onClick={() => setRefundDialogVisible(true)}
            />
          )}
          {!payment.isCancelled && payment.items.length > 0 && (
            <When permission={Permission.PaymentCreate}>
              <OptedOutTooltip contact={contact}>
                <DefaultButton
                  text="Send email"
                  iconProps={{ iconName: "Mail" }}
                  onClick={() => setEmailDialogVisible(true)}
                  disabled={isEmailOrPdfDisabled || contact.billingOptedOut}
                />
              </OptedOutTooltip>
              <DefaultButton
                {...dataAttribute(
                  DataAttributes.Element,
                  "get-payment-invoice-pdf-button"
                )}
                text="PDF"
                iconProps={{ iconName: "pdf" }}
                onClick={handleGetPdf}
                disabled={isEmailOrPdfDisabled}
              />
            </When>
          )}
          <DefaultButton text="Close" onClick={close} />
          <CancelTransactionDialog
            hidden={!showDialog}
            subTextJSX={
              <Text>{`Cancelling payment ${payment.number} of ${currencyFormat(
                payment.total
              )} `}</Text>
            }
            transactionType="payment"
            onCancel={() => setShowDialog(false)}
            onConfirm={reason => handleCancel(reason)}
          />
          <RefundSubmissionDialog
            refundDialogVisible={refundDialogVisible}
            onDismiss={onClear}
            onSucceeded={(refundPaymentMethod: PaymentMethod) =>
              onSucceeded(refundPaymentMethod)
            }
            refundTotal={payment.total - (payment.allocated || 0)}
            refundItemNumber={payment.number}
          />
        </Stack>
        {emailDialogVisible && (
          <EmailConfirmationModal
            accountContactId={payment.accountId}
            onDismiss={() => {
              setEmailDialogVisible(false);
            }}
            onSubmit={async ({ email }) => {
              await billing.sendPaymentEmail(payment.id, email);
              setEmailDialogVisible(false);
              close();
            }}
            commType={OutboundCommType.Receipt}
          />
        )}
      </>
    );
  }
);

export const PaymentViewButtons = withFetch(
  x => [x.billing.ref.transactionCancelReason.load()],
  PaymentViewButtonsBase
);
