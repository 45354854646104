import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Heading } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useAppointmentTypeScreenContext } from "@modules/settings/screens/appointments-types/context/AppointmentTypeScreenContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { OnlineSettingsFormFields } from "./OnlineSettingsFormFields.tsx";
import { OnlineSettingsFormValidator } from "./OnlineSettingsFormValidator.ts";
import { OnlineSettingsValues } from "./OnlineSettingsValues.types.ts";

export const OnlineSettingsDialog: FC = observer(() => {
  const validator = new OnlineSettingsFormValidator();
  const {
    omniAppointmentType = { name: "", duration: undefined, id: undefined },
    bhbAppointmentType,
    closeOnlineSettingModal,
    updateBhbAppointmentTypeSorting
  } = useAppointmentTypeScreenContext();

  const { name, duration } = omniAppointmentType;
  const { core, bhb, notification } = useStores();
  const bhbNewPatientMinuteDuration =
    bhbAppointmentType?.newPatientDuration &&
    bhbAppointmentType.newPatientDuration / 60;

  const newPatientMinuteDuration = bhbNewPatientMinuteDuration || duration;

  const initialValues: OnlineSettingsValues = bhbAppointmentType
    ? {
        newPatientMinuteDuration,
        isAvailableExistingPatients:
          !!bhbAppointmentType.isAvailableExistingPatients,
        isAvailableNewPatients: !!bhbAppointmentType.isAvailableNewPatients,
        name: bhbAppointmentType.onlineName || undefined,
        description: bhbAppointmentType.onlineDescription || undefined,
        additionalInformation: bhbAppointmentType.emailContent || undefined,
        sortOrder: bhbAppointmentType.sortOrder || undefined
      }
    : {
        newPatientMinuteDuration: 15
      };

  const getAppointmentTypeId = async () => {
    if (omniAppointmentType.id) {
      const result = await bhb.getAppointmentTypeForLocation(
        omniAppointmentType.id
      );
      return result?.id;
    }
    return undefined;
  };

  const onSubmit = async (
    values: OnlineSettingsValues,
    form: FormApi<OnlineSettingsValues, Partial<OnlineSettingsValues>>
  ) => {
    const id = await getAppointmentTypeId();
    if (!id) {
      notification.error(
        "Appointment type does not exist for online settings."
      );
      return;
    }

    await bhb.putAppointmentTypeForLocation({
      id,
      onlineName: values.name,
      onlineDescription: values.description,
      isAvailableExistingPatients: values.isAvailableExistingPatients,
      isAvailableNewPatients: values.isAvailableNewPatients,
      newPatientMinuteDuration: !!form.getFieldState("newPatientMinuteDuration")
        ?.dirty
        ? values.newPatientMinuteDuration
        : bhbNewPatientMinuteDuration,
      emailContent: values.additionalInformation,
      emailContentEnabled: !!values.additionalInformation,
      isCancellable:
        values.isAvailableExistingPatients || values.isAvailableNewPatients,
      sortOrder:
        values.isAvailableExistingPatients || values.isAvailableNewPatients
          ? values.sortOrder
          : null
    });

    const appTypes = await bhb.getAppointmentTypesForLocation();

    if (appTypes !== undefined) {
      updateBhbAppointmentTypeSorting(appTypes, id);
    }
  };

  const dialogName = `${
    bhbAppointmentType ? "Edit" : "New"
  } online settings dialog`;

  return (
    <SubmissionFormDialog
      dialogName={dialogName}
      dialogProps={{
        minWidth: 560,
        onDismiss: closeOnlineSettingModal,
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {`'${name}' online settings`}
            </Heading>
          ),
          showCloseButton: true
        }
      }}
      onSubmit={onSubmit}
      onSubmitSucceeded={closeOnlineSettingModal}
      initialValues={initialValues}
      validate={validator.validate}
      buttonsProps={{
        submitButtonProps: {
          text: "Save"
        },
        hideButtonsSeparator: true
      }}
      styles={{ fields: { overflowY: "hidden" } }}
      hideButtons={!core.hasPermissions(Permission.BhbWrite)}
    >
      {() => <OnlineSettingsFormFields defaultOnlineName={name} />}
    </SubmissionFormDialog>
  );
});
