import { observer } from "mobx-react-lite";
import React from "react";

import { FontSizes, Stack, Text, useTheme } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { CommPreference } from "@stores/comms/models/CommPreference.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { getUserStylesSet } from "../../../../users/components/UserScreens.styles.tsx";
import { ClinicalReminderPreferenceFields } from "./ClinicalReminderPreferenceFields.tsx";
import { CommunicationPreferencesValues } from "./CommunicationPreferences.types.ts";

interface PracticeDetailsFormProps {
  commsPreferences?: CommPreference[];
}
export const nameOfCommPreferences =
  nameOfFactory<CommunicationPreferencesValues>();

export const CommunicationPreferencesForm: React.FC<PracticeDetailsFormProps> =
  observer(({ commsPreferences }) => {
    const { comms, notification, core } = useStores();

    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const initialValues: Partial<CommunicationPreferencesValues> = {
      isApptConfirmation:
        commsPreferences && commsPreferences.length > 0
          ? !!commsPreferences[0].preferences?.appointmentConfirmation?.isActive
          : false,
      allowReminderFreeText:
        !!comms.clinicalReminderPreference?.allowReminderFreeText
    };

    const clinicalReminderPreference = comms.clinicalReminderPreference?.dto;

    const onSubmit = async (values: CommunicationPreferencesValues) => {
      const data = {
        preferenceData: {
          appointmentConfirmation: {
            isActive: values.isApptConfirmation
          }
        }
      };

      if (commsPreferences && commsPreferences.length > 0) {
        await comms.patchCommPreference({
          ...data,
          id: commsPreferences[0].id
        });
      } else {
        await comms.addCommPreference(data);
      }
      if (
        clinicalReminderPreference &&
        core.hasPermissions(Permission.ClinicalReminderPreferenceAllowed)
      ) {
        const updatedClinReminderPreferences = {
          id: clinicalReminderPreference.id,
          clinicalReminderReasons:
            clinicalReminderPreference.clinicalReminderReasons,
          allowReminderFreeText: !!values.allowReminderFreeText,
          eTag: clinicalReminderPreference.eTag
        };
        await comms.patchClinicalReminderPreference(
          updatedClinReminderPreferences
        );
      }
    };

    return (
      <SubmissionForm<CommunicationPreferencesValues>
        formName="communications-preferences"
        onSubmitSucceeded={() => {
          notification.success("Communications preference has been updated.");
        }}
        readOnly={!core.hasPermissions(Permission.OrgUnitSettingWrite)}
        autoFocus={false}
        onSubmit={onSubmit}
        initialValues={initialValues}
        buttonsProps={{
          disableCancelOnPristine: true,
          styles: {
            root: formFooter
          }
        }}
      >
        {() => {
          return (
            <Stack
              styles={{
                root: formFields
              }}
              tokens={{ childrenGap: 8 }}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 16 }}
                styles={{ root: { paddingBottom: 10 } }}
              >
                <Text
                  styles={{
                    root: {
                      fontSize: FontSizes.size18,
                      width: 250
                    }
                  }}
                >
                  Appointments
                </Text>
              </Stack>

              <When permission={Permission.ApptConfirmationsAllowed}>
                <Stack
                  tokens={{ childrenGap: 5 }}
                  styles={{ root: { width: 250 } }}
                >
                  <Text
                    styles={{
                      root: {
                        fontSize: FontSizes.size14,
                        fontWeight: 600
                      }
                    }}
                  >
                    Confirmation
                  </Text>
                  <Text
                    styles={{
                      root: {
                        fontSize: FontSizes.size14
                      }
                    }}
                  >
                    Send confirmation of appt
                  </Text>
                  <ToggleField
                    styles={{ root: { marginBottom: 0 } }}
                    onText="Yes"
                    offText="No"
                    name={nameOfCommPreferences("isApptConfirmation")}
                  />
                </Stack>
              </When>

              <When permission={Permission.ClinicalReminderPreferenceAllowed}>
                <ClinicalReminderPreferenceFields />
              </When>
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  });
