import React from "react";

import { IColumn } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { InvoiceItemsTotal } from "@modules/billing/screens/invoice/components/InvoiceForm.types.tsx";
import { allocationNameOf } from "@modules/billing/screens/shared-components/allocation-form/components/AllocationForm.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { AllocationListBase } from "./AllocationListBase.tsx";
import {
  FooterOptions,
  HeaderOptions,
  PaymentTotals,
  RenderItemProps
} from "./AllocationListBase.types.tsx";
import { AllocationsListItem, ColumnOptions } from "./types.ts";
import {
  allocatedColumn,
  allocationDateColumn,
  changeTypeColumn,
  codeColumn,
  commentColumn,
  creditNumberColumn,
  descriptionColumn,
  feeColumn,
  invoiceNumberColumn,
  owingColumn,
  patientNameColumn,
  providerNameColumn,
  serviceDateColumn
} from "./utils.tsx";

export const allocationsListItemNameOf = nameOfFactory<AllocationsListItem>();
export interface PaymentAllocationsProp {
  allocations: AllocationsListItem[];
  unallocatedAmount: number;
  total?: number;
  accountId?: string;
  hideOwing?: boolean;
  columnOptions?: ColumnOptions;
  headerOptions?: HeaderOptions;
}

export const AllocationsList: React.FunctionComponent<
  PaymentAllocationsProp
> = ({
  allocations,
  unallocatedAmount,
  accountId,
  total,
  hideOwing,
  columnOptions,
  headerOptions
}): JSX.Element => {
  const { routing, core } = useStores();

  const columnFilterPredicate = (col: IColumn) => {
    return (
      !(
        col.key === allocationsListItemNameOf("changeType") &&
        !columnOptions?.filtersToShow?.changeType
      ) &&
      !(
        col.key === allocationsListItemNameOf("comment") &&
        !columnOptions?.filtersToShow?.comment
      ) &&
      !(col.key === allocationsListItemNameOf("owing") && hideOwing) &&
      !(
        col.key === allocationsListItemNameOf("paymentNumber") &&
        (!columnOptions?.filtersToShow?.paymentNumber ||
          !core.hasPermissions(Permission.AccountHistoryAllowed))
      ) &&
      !(
        col.key === allocationsListItemNameOf("creditNumber") &&
        (!columnOptions?.filtersToShow?.creditNumber ||
          !core.hasPermissions(Permission.CreditAllowed))
      ) &&
      !(
        col.key === allocationsListItemNameOf("createdDate") &&
        !columnOptions?.filtersToShow?.createdDate
      )
    );
  };

  const getColumns = (renderItem: RenderItemProps): IColumn[] => {
    return [
      creditNumberColumn(allocations, routing, accountId),
      allocationDateColumn(),
      invoiceNumberColumn(allocations, routing, accountId),
      patientNameColumn(),
      providerNameColumn(),
      serviceDateColumn(),
      codeColumn(renderItem),
      descriptionColumn(renderItem),
      commentColumn(),
      feeColumn(renderItem),
      allocatedColumn(
        renderItem,
        columnOptions?.headingOptions?.allocationName
      ),
      owingColumn({ renderItem }),
      changeTypeColumn(renderItem)
    ].filter(columnFilterPredicate);
  };

  const footer: FooterOptions = {
    fields: [
      {
        key: PaymentTotals.totalIncGst,
        title: InvoiceItemsTotal.totalIncGst,
        dataAttr: "billing-totals-total-inc-gst"
      }
    ]
  };

  return (
    <AllocationListBase
      name={allocationNameOf("allocations")}
      getColumns={getColumns}
      items={allocations}
      options={{
        footer,
        extraHeader: headerOptions,
        unallocatedAmount,
        total
      }}
    />
  );
};
