import { observer } from "mobx-react-lite";
import { useField, useForm } from "react-final-form";

import { Spinner } from "@bps/fluent-ui";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { LocationsSelect } from "@ui-components/selects/LocationsSelect.tsx";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";

/**
 * This component is only used when the appointment form is almost completely filled out, but only the location is missing.
 *  This usually happens when the user double clicks a timeslot outside of a provider's working hours.  Once the user picks
 *  a location, the form will get updated and this input will no longer show.
 *
 *  This differs from the other ProviderLocationsSelect which is just used to filter the next available timeslots.
 */

export const ProviderLocationSelect = observer(() => {
  const form = useForm<AppointmentFormValues>();

  const {
    input: { value: providerId }
  } = useField<AppointmentFormValues["providerId"]>(
    appointmentFormNameOf("providerId"),
    {
      subscription: { value: true }
    }
  );

  const { core, booking } = useStores();

  if (!core.hasMultiLocationOrgUnit) return null;

  return (
    <UserFetcher userId={providerId} fallback={<Spinner />}>
      {(user: User) => {
        const userOrgUnits = user.availableOrgUnitIds;

        return (
          <LocationsSelect
            onFilter={options =>
              options.filter(option => userOrgUnits.includes(option.key))
            }
            placeholder="Select location"
            label="Locations"
            hideSearchOption
            hideClearButton
            selectedKeys={undefined}
            required
            onChangeSelectedKeys={(selected: string) => {
              form.change(appointmentFormNameOf("orgUnitId"), selected);
              booking.ui.setSecondColumnContent(undefined);
            }}
          />
        );
      }}
    </UserFetcher>
  );
});
