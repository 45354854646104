import React from "react";

import {
  DefaultButton,
  Heading,
  IButtonStyles,
  mergeStyleSets,
  Stack
} from "@bps/fluent-ui";

import { quickColourList } from "./constants.ts";

export interface QuickColoursProps {
  onColourSelected: (colour: string) => void;
  buttonStyles?: IButtonStyles;
  orientVertically?: boolean;
}

export const QuickColours: React.FC<QuickColoursProps> = ({
  onColourSelected,
  buttonStyles,
  orientVertically
}) => {
  const renderButtonContent = (name: string, colour: string) => {
    return (
      <Stack
        key={name}
        horizontal
        styles={{ root: { whiteSpace: "nowrap", width: "100%" } }}
        onClick={() => {
          onColourSelected(colour);
        }}
      >
        <div
          style={{
            width: 16,
            height: 16,
            backgroundColor: colour,
            marginRight: 4
          }}
        />
        <Stack.Item>{name}</Stack.Item>
      </Stack>
    );
  };

  return (
    <Stack>
      <Heading styles={{ root: { marginTop: 13, marginBottom: 14.5 } }}>
        Quick colours
      </Heading>
      <Stack
        verticalFill={orientVertically}
        horizontal={orientVertically !== true}
        styles={{ root: { flexWrap: "wrap" } }}
      >
        {quickColourList.map(color => (
          <DefaultButton
            key={color.color}
            onRenderText={() => renderButtonContent(color.name, color.color)}
            styles={mergeStyleSets(
              {
                root: {
                  border: 0,
                  minWidth: 0,
                  paddingRight: 8,
                  paddingLeft: 8
                }
              },
              { ...buttonStyles }
            )}
          />
        ))}
      </Stack>
    </Stack>
  );
};
