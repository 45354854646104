import { observer } from "mobx-react-lite";
import { useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { sum } from "@libs/utils/utils.ts";
import { getMessageBarText } from "@modules/billing/screens/credit-notes/components/utils.ts";
import {
  BillingHeading,
  BillingHeadingProps
} from "@modules/billing/screens/shared-components/BillingHeading.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { allocationNameOf } from "../shared-components/allocation-form/components/AllocationForm.types.ts";
import { RefundSubmissionDialog } from "../shared-components/RefundConfirmationDialog.tsx";
import { CreditNoteHelper, DialogType } from "./context/CreditNoteHelper.ts";

interface AllocationDefaultFormHeaderProps
  extends Pick<BillingHeadingProps, "breadcrumbProps"> {
  number: string;
  close: () => void;
  resetting?: boolean;
  dateOverride?: React.ReactNode;
  helper: CreditNoteHelper;
}

export const CreditNoteAllocationFormHeader: React.FC<AllocationDefaultFormHeaderProps> =
  observer(({ close, resetting, breadcrumbProps, dateOverride, helper }) => {
    const {
      fields: { value: allocations }
    } = useFieldArray(allocationNameOf("allocations"));

    const paidItems = helper.getPaidRefundItems(allocations);
    const owingItems = helper.getOwingAllocationItems(allocations);

    const form = useForm();
    const { invoice } = helper;

    const refundedTotal = sum("amount", paidItems);
    return (
      <BillingHeading
        breadcrumbProps={breadcrumbProps}
        date={DateTime.jsDateNow()}
        dateOverride={dateOverride}
        buttons={
          <>
            <FormSubmitButtons
              hideButtonsSeparator
              submitButtonProps={{
                disabled: resetting || (!!owingItems && paidItems.length === 0),
                onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                  helper.submitDialogType(DialogType.refund);
                },
                text: "Refund"
              }}
              cancelButtonProps={{
                name: "Close",
                onClick: close
              }}
              styles={{ root: { paddingTop: 0, margin: 0 } }}
              extraActionsBetween={() => (
                <Stack.Item styles={{ root: { flexShrink: 0 } }}>
                  <DefaultButton
                    {...dataAttribute(DataAttributes.Element, "save-draft")}
                    text="Credit"
                    disabled={allocations.every(x => !x.checked)}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      helper.submitDialogType(DialogType.credit);
                    }}
                    type="submit"
                  />
                </Stack.Item>
              )}
            />
            <RefundSubmissionDialog
              refundDialogVisible={helper.dialogType === DialogType.refund}
              onSucceeded={paymentMethod => {
                helper.onRefundSubmit(paymentMethod);
                form.submit();
              }}
              refundItemType="invoice"
              onDismiss={() => helper.submitDialogType(undefined)}
              refundItemNumber={invoice.number}
              refundTotal={refundedTotal}
              messageBar={
                owingItems.length > 0 && (
                  <MessageBar messageBarType={MessageBarType.warning}>
                    <Stack>
                      {getMessageBarText(owingItems, paidItems).map(x => (
                        <Text key={x.key} variant="small">
                          {x.text}
                        </Text>
                      ))}
                    </Stack>{" "}
                  </MessageBar>
                )
              }
            />
          </>
        }
      />
    );
  });
