import { ClinicalReminderReason } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { required } from "@libs/validation/fieldValidators.ts";
import { ValidationMessages } from "@libs/validation/validation.constants.ts";
import { Validator } from "@libs/validation/Validator.ts";

const nameOf = nameOfFactory<ClinicalReminderReason>();
export class ClinicalReminderPreferenceValidator extends Validator<ClinicalReminderReason> {
  constructor(
    clinicalReminderReasons: ClinicalReminderReason[],
    initialValues?: ClinicalReminderReason
  ) {
    super();

    this.forField(nameOf("reason"), [
      required(),
      val => {
        if (initialValues?.reason === val) return false;
        return clinicalReminderReasons.some(x => x.reason === val)
          ? ValidationMessages.noDuplicateClinicalReminderPreferenceAllowed
          : false;
      }
    ]);
  }
}
