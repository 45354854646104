import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  Heading,
  ITextFieldStyles,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { PersonalDetailsFormValidator } from "@modules/acc/screens/claim/validators/PersonalDetailsFormValidator.tsx";
import { AddressField } from "@modules/acc/screens/shared-components/AddressField.tsx";
import { ClaimCard } from "@modules/acc/screens/shared-components/ClaimCard.tsx";
import { PersonalDetailsValuesType } from "@shared-types/acc/personal-details-values.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useCardFormLayoutContext } from "@ui-components/card-form-layout/context/CardFormLayoutHelperContext.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { patientAddressFieldNames, patientFieldNames } from "../claim.utils.ts";
import {
  ClaimFormDataAttributes,
  ClaimFormLabels,
  ClaimsCardIds,
  ClaimSectionHeadings
} from "./ClaimFormEnums.ts";

const personalFields: (keyof PersonalDetailsValuesType)[] = ["patient"];

export const PersonalDetailsFormSectionBase: FC = observer(() => {
  const {
    acc: { ref }
  } = useStores();

  const { selectedMenuItemId } = useCardFormLayoutContext();

  const [hideMessageBar, setHideMessageBar] = useState(false);

  const getLevelTwoEthnicities = () =>
    ref.levelTwoEthnicities.values.map(({ code, text }) => ({
      key: code,
      name: text
    }));

  return (
    <ClaimCard
      id={ClaimsCardIds.personalDetails}
      openOnRender={
        !selectedMenuItemId ||
        selectedMenuItemId === ClaimsCardIds.personalDetails
      }
      errorProps={{ fields: personalFields }}
      heading={ClaimSectionHeadings.personalDetails}
      iconName="contact"
      statusValidator={new PersonalDetailsFormValidator()}
      subHeading={
        <FieldCondition
          when={patientFieldNames("patientSex")}
          is={(value: string) => !value}
        >
          {!hideMessageBar && (
            <MessageBar
              {...dataAttribute(
                DataAttributes.Element,
                ClaimFormDataAttributes.personalDetailsMessageBar
              )}
              styles={{
                root: { zIndex: 1 },
                innerText: {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  span: {
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "space-between"
                  }
                }
              }}
              messageBarType={MessageBarType.warning}
            >
              <span>
                Not all patient data could be imported due to conflicts with ACC
                data capture requirements
              </span>
              <FontIcon
                styles={{
                  root: { fontSize: FontSizes.size10, cursor: "pointer" }
                }}
                iconName="Clear"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e?.stopPropagation();
                  setHideMessageBar(true);
                }}
              />
            </MessageBar>
          )}
        </FieldCondition>
      }
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Heading variant="section-heading-light">
            {ClaimFormLabels.basicInformation}
          </Heading>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientFirstName
            )}
            width="50%"
            name={patientFieldNames("patientFirstName")}
            label={ClaimFormLabels.firstName}
            required
          />
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientMiddleName
            )}
            width="50%"
            name={patientFieldNames("patientMiddleName")}
            label={ClaimFormLabels.middleName}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientLastName
            )}
            name={patientFieldNames("patientLastName")}
            label={ClaimFormLabels.lastName}
            required
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <DatePickerField
            styles={
              { icon: { visibility: "hidden" } } as Partial<ITextFieldStyles>
            }
            name={patientFieldNames("patientDateOfBirth")}
            label={ClaimFormLabels.dateOfBirth}
            required
          />
          <DropdownField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientSex
            )}
            name={patientFieldNames("patientSex")}
            placeholder="Select sex"
            required
            label={ClaimFormLabels.sex}
            options={ref.accSexes.keyTextValues}
            styles={{ root: { width: 120 } }}
          />
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientNhiNumber
            )}
            name={patientFieldNames("patientNhiNumber")}
            label={ClaimFormLabels.nhi}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientMobileNumber
            )}
            name={patientFieldNames("patientMobileNumber")}
            label={ClaimFormLabels.patientMobileNumber}
          />
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientHomePhoneNumber
            )}
            name={patientFieldNames("patientHomePhoneNumber")}
            label={ClaimFormLabels.patientHomePhoneNumber}
          />
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientWorkPhoneNumber
            )}
            name={patientFieldNames("patientWorkPhoneNumber")}
            label={ClaimFormLabels.patientWorkPhoneNumber}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 8 }}>
          <TextInputField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientEmail
            )}
            name={patientFieldNames("patientEmail")}
            label={ClaimFormLabels.patientEmail}
          />

          <StaticPickerField
            {...dataAttribute(
              DataAttributes.Element,
              ClaimFormDataAttributes.patientEthnicityCode
            )}
            name={patientFieldNames("patientEthnicityCode")}
            label={ClaimFormLabels.patientEthnicityCode}
            fetchDataSource={getLevelTwoEthnicities}
            required
            fieldItemStyles={{ root: { flexGrow: 2.8, flexBasis: 0 } }}
          />
        </Stack>
        <Heading
          variant="section-heading-light"
          styles={{ root: { "&&": { marginTop: 24 } } }}
        >
          Home address
        </Heading>
        <AddressField
          required
          fieldNames={{
            street1: patientAddressFieldNames("street1"),
            street2: patientAddressFieldNames("street2"),
            addressType: patientAddressFieldNames("type"),
            suburb: patientAddressFieldNames("suburb"),
            city: patientAddressFieldNames("city"),
            postCode: patientAddressFieldNames("postCode"),
            country: patientAddressFieldNames("country")
          }}
        />
      </Stack>
    </ClaimCard>
  );
});

export const PersonalDetailsFormSection = withFetch(
  x => [x.acc.ref.levelTwoEthnicities.load(), x.acc.ref.accSexes.load()],
  PersonalDetailsFormSectionBase
);
