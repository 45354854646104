import { observer } from "mobx-react-lite";
import { REJECTED } from "mobx-utils";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Spinner,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime, unique } from "@bps/utils";
import { useNextAvailableAppointment } from "@modules/booking/appInsights/useNextAvailableAppointment.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import type { SettledProvidersResult } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormHelper.ts";
import { getUsersAvailability } from "@modules/booking/screens/booking-calendar/components/utils.tsx";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";
import { BookableUsersSelect } from "@ui-components/selects/BookableUsersSelect.tsx";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { NextAvailableFieldDatePicker } from "./NextAvailableFieldDatePicker.tsx";
import { contentBoxStyles, textStyles } from "./NextAvailableFields.styles.ts";
import { ProviderLocationSelect } from "./ProviderLocationSelect.tsx";
import { ProviderLocationsSelect } from "./ProviderLocationsSelect.tsx";
import { ProviderTimeSlots } from "./ProviderTimeSlots.tsx";
import { ProviderTimeSlotsError } from "./ProviderTimeSlotsError.tsx";

export const NextAvailableFields: React.FunctionComponent = observer(() => {
  const {
    selectedProviders,
    setProviders,
    sortSelectedProviders,
    isAllProvidersSelected,
    setAllProvidersSelected,
    fetchAvailableSlots
  } = useContext(AppointmentFormContext);

  const { core, booking } = useStores();

  const {
    input: { value: duration }
  } = useField(appointmentFormNameOf("duration"), {
    subscription: { value: true }
  });

  const {
    input: { value: appointmentTypeId }
  } = useField(appointmentFormNameOf("appointmentTypeId"), {
    subscription: { value: true }
  });

  const {
    input: { value: startDate }
  } = useField<Date>(appointmentFormNameOf("startDate"), {
    subscription: { value: true }
  });

  const {
    input: { value: startTime }
  } = useField<Date>(appointmentFormNameOf("startTime"), {
    subscription: { value: true }
  });

  const {
    input: { value: providerId }
  } = useField<Date>(appointmentFormNameOf("providerId"), {
    subscription: { value: true }
  });

  const {
    input: { value: orgUnitId }
  } = useField<string>(appointmentFormNameOf("orgUnitId"), {
    subscription: { value: true }
  });

  const onFilter = async (users: User[]): Promise<User[]> => {
    return await getUsersAvailability(users, booking, orgUnitIds);
  };

  const currentClickedProvider =
    booking.ui.currentAppointment?.initialValues?.providerId;

  const [pickerDate, setPickerDate] = useState<Date | undefined>(startDate);

  const [orgUnitIds, setOrgUnitIds] = useState<string[]>(
    currentClickedProvider
      ? core.userMap.get(currentClickedProvider)?.availableOrgUnitIds ?? []
      : [orgUnitId] ?? []
  );

  const [localSelectedProviders, setLocalSelectedProviders] = useState<
    string[]
  >([...selectedProviders]);

  const [localDuration, setLocalDuration] = useState<number>(duration);

  const setProvidersHandler = useDebounce(setProviders);
  const setLocalDurationHandler = useDebounce(setLocalDuration);
  const setProvidersHandlerMemo = useCallback(setProvidersHandler, [
    setProvidersHandler
  ]);

  const setLocalDurationHandlerMemo = useCallback(setLocalDurationHandler, [
    setLocalDurationHandler
  ]);

  useEffect(() => {
    // debounce bookableUsers selection to reduce number of api calls
    setProvidersHandlerMemo(localSelectedProviders);
  }, [localSelectedProviders, setProvidersHandlerMemo]);

  useEffect(() => {
    // debounce duration to reduce number of api calls
    setLocalDurationHandlerMemo(duration);
  }, [duration, setLocalDurationHandlerMemo]);

  useNextAvailableAppointment();

  const setProvidersAndOrgUnits = (providersIds: string[]) => {
    setLocalSelectedProviders(providersIds);

    const orgUnits = providersIds
      .map(x => core.userMap.get(x)?.availableOrgUnitIds ?? [])
      .flatMap(x => x);

    setOrgUnitIds(unique(orgUnits));
  };

  const today = DateTime.today().toJSDate();

  const invalidDurationOrType =
    duration === 0 || Number.isNaN(duration) || !appointmentTypeId;

  const onlyOrgUnitRequired =
    !orgUnitId && !!startDate && !!startTime && !!providerId;

  return (
    <SecondColumnWrapper heading="Appointment details">
      {!invalidDurationOrType ? (
        <Stack
          {...dataAttribute(DataAttributes.Element, "next-available-section")}
          tokens={{ childrenGap: 16 }}
          styles={{
            root: {
              width: "100%"
            }
          }}
        >
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <NextAvailableFieldDatePicker
                value={pickerDate}
                onChange={setPickerDate}
                onlyOrgUnitRequired={onlyOrgUnitRequired}
              />
              <BookableUsersSelect
                onFilter={onFilter}
                selectedKeys={localSelectedProviders}
                onChangeSelectedKeys={setProvidersAndOrgUnits}
                multiSelect
                showCountCoin
                hideCountCoinWhenAllSelected
                selectAllButtonText="All providers"
                showAllSelected
                label="Provider"
                required
                styles={{ root: { flexGrow: 1 } }}
                isAllSelected={isAllProvidersSelected}
                onAllSelected={setAllProvidersSelected}
                disabled={onlyOrgUnitRequired}
              />
            </Stack>
            {onlyOrgUnitRequired ? (
              <ProviderLocationSelect />
            ) : (
              <ProviderLocationsSelect
                orgUnitIds={orgUnitIds}
                setOrgUnitIds={setOrgUnitIds}
              />
            )}
          </Stack>

          <Stack
            styles={(props, theme) => ({
              root: {
                borderBottom: `1px solid ${theme.palette.neutralLighter}`
              }
            })}
          />

          {!onlyOrgUnitRequired && (
            <Stack styles={contentBoxStyles} tokens={{ childrenGap: 16 }}>
              {selectedProviders?.length > 0 && orgUnitIds.length > 0 ? (
                <DataFetcher<SettledProvidersResult[]>
                  refetchId={
                    selectedProviders.join() +
                    orgUnitIds.join() +
                    localDuration +
                    pickerDate?.toDateString()
                  }
                  fallback={<Spinner />}
                  fetch={async () => {
                    const startDate =
                      pickerDate && pickerDate !== today ? pickerDate : today;
                    return await fetchAvailableSlots(
                      startDate,
                      duration,
                      orgUnitIds
                    );
                  }}
                >
                  {settledResults => {
                    const sortedSettledResults = sortSelectedProviders(
                      settledResults,
                      pickerDate
                    );
                    return (
                      <>
                        {pickerDate &&
                          sortedSettledResults.map(item =>
                            item.status === REJECTED ? (
                              <ProviderTimeSlotsError
                                key={item.providerId}
                                providerId={item.providerId}
                                error={item.reason}
                              />
                            ) : (
                              <ProviderTimeSlots
                                key={item.providerId}
                                pickerStartDate={pickerDate}
                                settledResult={item}
                                orgUnitIds={orgUnitIds}
                              />
                            )
                          )}
                      </>
                    );
                  }}
                </DataFetcher>
              ) : (
                <Text
                  {...dataAttribute(
                    DataAttributes.Element,
                    "next-available-select-provider"
                  )}
                  styles={textStyles}
                >
                  Select provider and date to see available times
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack
          verticalAlign="center"
          styles={{
            root: { width: "100%", height: "100%" }
          }}
          tokens={{ childrenGap: 16 }}
        >
          <Text id="invalid-duration-or-type" styles={textStyles}>
            Type and duration fields are required to continue
          </Text>
        </Stack>
      )}
    </SecondColumnWrapper>
  );
});
