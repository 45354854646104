import { FilterBarProps } from "@bps/fluent-ui";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import {
  RecentPatientFilterKeys,
  recentPatientFilterOptions
} from "./RecentPatientsFilter.types.ts";

type RecentPatientsFilterProps = Pick<
  FilterBarProps<RecentPatientsFilterState>,
  "children"
>;

interface RecentPatientsFilterState {
  patientIds?: string[];
  eventCode?: string;
  incompleteNotesOnly?: true;
}

export const RecentPatientsFilter: React.FC<RecentPatientsFilterProps> = ({
  children
}) => {
  return (
    <FilterBar<RecentPatientsFilterState>
      items={[
        {
          type: "contactPicker",
          name: "patientIds",
          stickItem: true,
          props: {
            inputProps: {
              id: "recent-patients-search",
              placeholder: "Search for patient"
            },
            styles: { root: { maxWidth: 250, minWidth: 250 } }
          }
        },
        {
          type: "optionsSelect",
          name: "eventCode",
          props: {
            id: "recent-patients-events",
            hideSearchOption: true,
            options: recentPatientFilterOptions,
            placeholder: "Event",
            calloutWidth: 200
          }
        }
      ]}
      presets={[
        {
          text: "Incomplete notes",
          name: "incompleteNotesOnly",
          id: "recent-patients-incomplete",
          iconName: "BpDocEdit",
          tooltip: "Only show Incomplete notes",
          tooltipPresses: "Only showing Incomplete notes",
          valuesToBeSetOnToggleOn: {
            eventCode: RecentPatientFilterKeys.ConsultIncomplete
          },
          valuesToBeSetOnToggleOff: {
            eventCode: undefined
          }
        }
      ]}
    >
      {children}
    </FilterBar>
  );
};
