import { FormApi } from "final-form";
import { computed, observable, runInAction } from "mobx";

import {
  PatchBhbLocationDto,
  ShowAvailabilityEnum,
  UpdateBhbAppointmentTypeDto
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import {
  BhbOnlineSettingsFormValues,
  DurationUnit
} from "../online-settings/BhbOnlineSettings.types.ts";
import { getDurationValue, getValueInMinutes } from "../utils.ts";

export class BhbConfigHelper {
  constructor(private root: IRootStore) {}

  @observable
  locationId: string;

  @computed
  get location() {
    return this.bhb.locationMap.get(this.locationId);
  }

  @computed
  get appointmentTypes() {
    return Array.from(this.root.bhb.appointmentTypesMap.values()).filter(
      t => t.isAvailableExistingPatients || t.isAvailableNewPatients
    );
  }

  private get bhb() {
    return this.root.bhb;
  }

  private get routing() {
    return this.root.routing;
  }

  getLocation = async () => {
    const location = await this.bhb.getLocation();
    if (location) {
      runInAction(() => {
        this.locationId = location.id;
      });
    }
    return location;
  };

  getAppointmentTypes = () => this.bhb.getAppointmentTypesForLocation();

  get nonCancellableAppointmentTypes() {
    return this.appointmentTypes.filter(t => !t.isCancellable);
  }

  patchLocation = (location: Omit<PatchBhbLocationDto, "eTag">) =>
    this.bhb.patchLocation(location);

  getLocationPatchFromValues = (values: BhbOnlineSettingsFormValues) => {
    const minimumTimeToCancel = getValueInMinutes(
      values.minimumTimeToCancelValue,
      values.minimumTimeToCancelUnit
    );

    const minimumTimeToBook = getValueInMinutes(
      values.minimumTimeToBookValue,
      values.minimumTimeToBookUnit
    );

    const location: Omit<PatchBhbLocationDto, "eTag"> = {
      id: this.locationId,
      onlineBookingsEnabled: values.onlineBookingsEnabled,
      allowCancellationsEnabled: values.allowCancellationsEnabled,
      minimumTimeToCancel,
      showAvailability: values.showAvailability,
      minimumTimeToBook,
      maximumAppointmentsPerDay: values.limitMaximumAppointmentsPerDay
        ? values.maximumAppointmentsPerDay
        : undefined,
      hasCancelRestrictions: true,
      parkingAccess: values.parkingAccess ?? undefined,
      disabilityAccess: values.disabilityAccess ?? undefined,
      url: values.url,
      policy: values.policy ?? undefined,
      disabilityAccessEnabled: !!values.disabilityAccess,
      parkingAccessEnabled: !!values.parkingAccess,
      bookingPolicyEnabled: !!values.policy,
      logoUrl: values.logoUrl,
      emergencyMessage:
        values.emergencyMessage?.trim() !== "<p><br></p>" // Empty state of react-quill
          ? values.emergencyMessage
          : undefined,
      emergencyMessageEnabled: values.emergencyMessageEnabled
    };

    return location;
  };

  getAppointmentTypesToUpdate = (
    selectedTypes: string[],
    initialValues: Partial<BhbOnlineSettingsFormValues>
  ) => {
    return this.appointmentTypes
      .filter(
        at =>
          (selectedTypes.includes(at.id) && at.isCancellable) || // Has been selected
          (!selectedTypes.includes(at.id) && // Has been unselected
            initialValues.nonCancellableAppointmentTypes?.includes(at.id))
      )
      .map(at => {
        const updated: Omit<UpdateBhbAppointmentTypeDto, "eTag"> = {
          ...at.dto,
          isCancellable: !at.isCancellable
        };

        return updated;
      });
  };

  onlineSettingsFormSubmit = async (
    values: BhbOnlineSettingsFormValues,
    form: FormApi<BhbOnlineSettingsFormValues>
  ) => {
    const location = this.getLocationPatchFromValues(values);

    const appointmentTypesToUpdate = this.getAppointmentTypesToUpdate(
      values.nonCancellableAppointmentTypes,
      form.getState().initialValues
    );

    await Promise.all([
      this.patchLocation(location),
      appointmentTypesToUpdate.map(t =>
        this.bhb.putAppointmentTypeForLocation(t)
      )
    ]);
  };

  convertMinimumTimeToBookToValues = (minimumTimeToBook: number) => {
    let minimumTimeToBookUnit: DurationUnit = "minutes";
    let minimumTimeToBookValue = 1;
    const minutesInDay = 60 * 24;
    const minutesInHour = 60 * 1;

    if (minimumTimeToBook) {
      if (
        minimumTimeToBook / minutesInDay ===
        Math.floor(minimumTimeToBook / minutesInDay)
      ) {
        minimumTimeToBookUnit = "days";
        minimumTimeToBookValue = minimumTimeToBook / minutesInDay;
      } else if (
        minimumTimeToBook / minutesInHour ===
        Math.floor(minimumTimeToBook / minutesInHour)
      ) {
        minimumTimeToBookUnit = "hours";
        minimumTimeToBookValue = minimumTimeToBook / minutesInHour;
      } else {
        minimumTimeToBookUnit = "minutes";
        minimumTimeToBookValue = minimumTimeToBook;
      }
    }

    return { minimumTimeToBookUnit, minimumTimeToBookValue };
  };

  get onlineSettingsFormInitialValues() {
    if (this.location) {
      const {
        unitValue: minimumTimeToCancelValue,
        unit: minimumTimeToCancelUnit
      } = getDurationValue(this.location.minimumTimeToCancel);

      const { minimumTimeToBookUnit, minimumTimeToBookValue } =
        this.convertMinimumTimeToBookToValues(this.location.minimumTimeToBook);

      const initialValues: BhbOnlineSettingsFormValues = {
        onlineBookingsEnabled: this.location.onlineBookingsEnabled ?? undefined,

        allowCancellationsEnabled:
          this.location.allowCancellationsEnabled ?? undefined,
        minimumTimeToCancelValue,
        minimumTimeToCancelUnit,
        nonCancellableAppointmentTypes: this.nonCancellableAppointmentTypes.map(
          t => t.id
        ),

        showAvailability:
          this.location.showAvailability ?? ShowAvailabilityEnum.oneWeek,
        minimumTimeToBookValue,
        minimumTimeToBookUnit,
        limitMaximumAppointmentsPerDay:
          !!this.location.maximumAppointmentsPerDay,
        maximumAppointmentsPerDay: this.location.maximumAppointmentsPerDay ?? 1,
        parkingAccess: this.location.parkingAccess ?? undefined,
        disabilityAccess: this.location.disabilityAccess ?? undefined,
        policy: this.location.policy ?? undefined,
        url: this.location.url ?? undefined,
        logoUrl: this.location.logoUrl ?? undefined,
        hasLogoUrl: !!this.location.logoUrl && this.location.logoUrl !== null,
        emergencyMessage: this.location.emergencyMessage ?? undefined,
        emergencyMessageEnabled:
          this.location.emergencyMessageEnabled ?? undefined
      };

      return initialValues;
    }

    return undefined;
  }
}
