import { action, observable } from "mobx";

import { flatten, IGroup, PivotItem } from "@bps/fluent-ui";
import {
  compareDatesOrUndefinedPredicate,
  DateTime,
  isDefined
} from "@bps/utils";
import {
  AnalysisAndPlanClinicalDataDto,
  ClinicalDataType,
  EpisodeOfCareDto,
  GoalDataItemDto,
  GoalsClinicalDataDto,
  GoalsDataItemDto,
  PatientTreatmentPlanDataItemDto,
  TreatmentAndEducationClinicalDataDto,
  TreatmentPlanClinicalDataDto,
  TreatmentPlanDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  ConditionItem,
  ConditionSubGroup,
  ManagementGroups
} from "./management/ManagementForm.Types.ts";
import {
  EncounterTreatments,
  TreatmentGroups
} from "./treatment/TreatmentForm.types.ts";
import { TandMSectionKeys } from "./TreatmentAndManagement.Types.ts";

export class TreatmentAndManagementSidePanelHelper {
  constructor(
    public clinicalRecord: ClinicalRecord,
    public root: RootStore,
    public isViewOnly: boolean
  ) {}
  get clinical() {
    return this.root.clinical;
  }

  get core() {
    return this.root.core;
  }

  @observable
  pageSelected: TandMSectionKeys = TandMSectionKeys.Management;

  @action
  setPageSelected = (value: TandMSectionKeys) => {
    this.pageSelected = value;
  };

  handleViewClick = (item: PivotItem): void => {
    const selectedValue =
      item.props && (item.props.itemKey as TandMSectionKeys | undefined);

    if (selectedValue === this.pageSelected || selectedValue === undefined) {
      return;
    }
    this.setPageSelected(selectedValue);
  };

  onCreateTreatmentClick = () => {
    if (!this.isViewOnly) {
      this.root.clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.TreatmentAndEducation
      });
    }
  };

  onCreateManagementClick = () => {
    if (!this.isViewOnly) {
      this.root.clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.PatientTreatmentPlan
      });
    }
  };

  getTreatments = async (): Promise<TreatmentGroups> => {
    const encounters = await this.clinical.getEncounters({
      patientId: this.clinicalRecord.id
    });

    const promises = encounters.map(x => this.getEncounterClinicalData(x.id));

    const respond = await Promise.all(promises);
    const responses = flatten(respond.filter(isDefined));

    const treatmentData: TreatmentAndEducationClinicalDataDto[] = [];
    const analysisData: AnalysisAndPlanClinicalDataDto[] = [];

    responses.forEach(response => {
      if (response.treatmentAndEducation) {
        treatmentData.push(response.treatmentAndEducation);
      }

      if (response.analysisAndPlan) {
        analysisData.push(response.analysisAndPlan);
      }
    });

    const { groups, items } = this.getTreatmentGroups(
      encounters,
      treatmentData,
      analysisData
    );

    return { groups, items };
  };

  getEncounterClinicalData = async (encounterId: string) => {
    const data = await this.clinical.getEncounterClinicalData(
      { encounterId },
      { ignoreCache: true }
    );

    if ((data && data.treatmentAndEducation) || data.analysisAndPlan) {
      return data;
    }
    return undefined;
  };

  getTreatmentGroups = (
    encounters: Encounter[],
    treatmentAndEduData: TreatmentAndEducationClinicalDataDto[],
    analysisData: AnalysisAndPlanClinicalDataDto[]
  ) => {
    let startIndex = 0;
    const items = [];
    const groups: IGroup[] = [];
    for (const group of encounters) {
      const groupTreatmentItems = treatmentAndEduData.filter(
        x => x.createLog?.createdEncounterId === group.id
      );

      const groupAnalysisItems = analysisData.filter(
        x => x.createLog?.createdEncounterId === group.id
      );

      const dataItems = this.createItems(
        groupTreatmentItems[0],
        groupAnalysisItems[0]
      );

      if (dataItems) {
        groups.push({
          key: group.id,
          name: group.startDateTime.toDayDefaultFormat(),
          count: dataItems.length,
          startIndex,
          isCollapsed: true
        } as IGroup);

        for (const item of dataItems) {
          items.push(item);
          startIndex += 1;
        }
      }
    }

    return {
      groups,
      items
    };
  };

  createItems = (
    treatmentData: TreatmentAndEducationClinicalDataDto,
    analysisData: AnalysisAndPlanClinicalDataDto
  ) => {
    if (!treatmentData && !analysisData) return undefined;

    const items: EncounterTreatments[] = [];
    if (treatmentData) {
      if (treatmentData.treatments) {
        treatmentData.treatments.forEach(treatment => {
          const treatmentText = this.getTreatmentText(treatment.treatment);
          if (treatmentText)
            items.push({ title: treatmentText, comment: treatment.comment });
        });
      }

      if (treatmentData.otherTreatments) {
        items.push({
          title: "Other treatments",
          comment: treatmentData.otherTreatments
        });
      }

      if (
        treatmentData.educationOptions &&
        treatmentData.educationOptions.length > 0
      ) {
        const eduOptionText = this.getEduOptionText(
          treatmentData.educationOptions
        );
        items.push({ title: "Education", comment: eduOptionText });
      }

      if (treatmentData.otherComment) {
        items.push({
          title: "Other education",
          comment: treatmentData.otherComment
        });
      }

      if (treatmentData.educationComment) {
        items.push({
          title: "Education comments",
          comment: treatmentData.educationComment
        });
      }
    }

    if (analysisData) {
      const analysisText = this.getAnalysisText(analysisData);
      items.push({
        title: "Analysis",
        comment: analysisText
      });
    }

    return items;
  };

  getEduOptionText = (options: string[]) => {
    let optionsText: string = "";
    let count = 0;
    options.forEach(option => {
      if (option !== "OTH") {
        const eduText = this.root.clinical.ref.educationOptions.values.filter(
          x => x.code === option
        );
        if (eduText && eduText[0]) {
          optionsText += eduText[0].text;
        }
        count += 1;

        if (options.length > count) {
          optionsText += ", ";
        }
      } else {
        count += 1;
      }
    });

    return optionsText;
  };

  getAnalysisText = (data: AnalysisAndPlanClinicalDataDto) => {
    const MAX_CHAR_DISPLAY_SINGLE_LINE = 50;
    let analysisText: string = "";
    if (data.rxAnalysis) {
      const optionText = this.root.clinical.ref.rxAnalysisOptions.values.filter(
        x => x.code === data.rxAnalysis
      );
      if (optionText && optionText.length > 0) {
        analysisText += `${optionText[0].text}. `;
      }
    }

    if (data.analysis) {
      analysisText += data.analysis;
    }

    if (analysisText.length > MAX_CHAR_DISPLAY_SINGLE_LINE) {
      return `${analysisText.substring(0, MAX_CHAR_DISPLAY_SINGLE_LINE)}...`;
    }
    return analysisText;
  };

  getTreatmentText = (treatmentCode: string | undefined) => {
    if (treatmentCode) {
      const treatment = this.root.clinical.ref.treatmentOptions.values.filter(
        x => x.code === treatmentCode
      );
      return treatment[0].text;
    }
    return undefined;
  };

  getManagementData = async (): Promise<ManagementGroups> => {
    if (this.core.hasPermissions(Permission.PatientTreatmentPlanAllowed)) {
      const treatmentPlansData = Array.from(
        this.clinicalRecord.clinicalData?.patientTreatmentPlan
          ?.treatmentPlans ?? []
      );

      const filteredTreatmentPlans = treatmentPlansData.filter(
        x => !x.isDeleted
      );

      const treatmentPlans = filteredTreatmentPlans.sort((a, b) =>
        compareDatesOrUndefinedPredicate(
          a.createLog?.createdDateTime,
          b.createLog?.createdDateTime,
          true
        )
      );

      const episodesOfCare = await this.clinical.getPatientEpisodesOfCare(
        this.clinicalRecord.id
      );

      const { groups, items } = this.getPlanGroups(
        treatmentPlans,
        episodesOfCare
      );

      const cleanedGroups = groups.filter(
        x => x.children && x.children.length > 0 && x.level === 1
      );

      return { groups: cleanedGroups, items };
    } else {
      const episodesOfCare = await this.clinical.getPatientEpisodesOfCare(
        this.clinicalRecord.id
      );

      const episodeOfCareIds = episodesOfCare.map(x => x.id);

      if (this.clinicalRecord.episodeOfCare) {
        if (!episodeOfCareIds.includes(this.clinicalRecord.episodeOfCare.id)) {
          episodeOfCareIds.push(this.clinicalRecord.episodeOfCare.id);
        }
      }

      const EpisodeOfCareData =
        await this.clinical.getEpisodesOfCareScopedClinicalData({
          patientId: this.clinicalRecord.id,
          episodesOfCareIds: episodeOfCareIds,
          types: [ClinicalDataType.TreatmentPlan, ClinicalDataType.Goals]
        });

      const encounters = await this.clinical.getEncounters({
        patientId: this.clinicalRecord.id,
        episodeOfCareIds
      });

      const treatmentPlanData: TreatmentPlanClinicalDataDto[] = [];
      const goalsData: GoalsClinicalDataDto[] = [];

      EpisodeOfCareData.forEach(response => {
        if (response.treatmentPlan) {
          treatmentPlanData.push(response.treatmentPlan);
        }

        if (response.goals) {
          goalsData.push(response.goals);
        }
      });

      if (this.clinicalRecord.stashedClinicalData?.treatmentPlan) {
        treatmentPlanData.push(
          this.clinicalRecord.stashedClinicalData.treatmentPlan
        );
      }

      if (this.clinicalRecord.stashedClinicalData?.goals) {
        goalsData.push(this.clinicalRecord.stashedClinicalData.goals);
      }

      const { groups, items } = this.getManagementGroups(
        episodesOfCare,
        encounters,
        { treatmentPlanData, goalsData }
      );

      const cleanedGroups = groups.filter(
        x => x.children && x.children.length > 0 && x.level === 1
      );

      return { groups: cleanedGroups, items };
    }
  };

  getManagementGroups = (
    episodesOfCare: EpisodeOfCareDto[],
    encounters: Encounter[],
    data: {
      treatmentPlanData: TreatmentPlanClinicalDataDto[];
      goalsData: GoalsClinicalDataDto[];
    }
  ) => {
    const { treatmentPlanData, goalsData } = data;
    let startIndex = 0;
    const groups: IGroup[] = [];
    const items: ConditionItem[] = [];

    for (const episodeOfCare of episodesOfCare) {
      const linkedEncounters = encounters.filter(
        x => x.episodeOfCareId === episodeOfCare.id
      );

      if (linkedEncounters && linkedEncounters.length > 0) {
        const eocSubGroups = this.getEpisodeOfCareItems(linkedEncounters, {
          treatmentPlanData,
          goalsData
        });

        if (eocSubGroups && eocSubGroups.length > 0) {
          const subGroups: IGroup[] = [];
          let subGroupStartIndex = startIndex;
          let totalNumItems: number = 0;

          eocSubGroups.forEach(subGroup => {
            if (subGroup.heading && subGroup.items.length > 0) {
              subGroups.push({
                key: subGroup.key,
                name: subGroup.heading,
                count: subGroup.items.length,
                isCollapsed: false,
                startIndex: subGroupStartIndex,
                level: 2
              });

              items.push(...subGroup.items);
              subGroupStartIndex += subGroup.items.length;
              totalNumItems += subGroup.items.length;
            }
          });

          const subGroupOperation = subGroupStartIndex > startIndex;

          groups.push({
            key: episodeOfCare.id,
            name: this.getEoCHeading(episodeOfCare),
            count: items.length,
            isCollapsed: true,
            startIndex,
            children: subGroups,
            level: 1
          });
          if (!subGroupOperation) {
            items.push(...items);
          }

          startIndex += totalNumItems;
        }
      }
    }

    return {
      groups,
      items
    };
  };

  getEpisodeOfCareItems = (
    linkedEncounters: Encounter[],
    data: {
      treatmentPlanData: TreatmentPlanClinicalDataDto[];
      goalsData: GoalsClinicalDataDto[];
    }
  ) => {
    const eocSubGroups: ConditionSubGroup[] = [];
    const { treatmentPlanData, goalsData } = data;

    const linkedEncounterIds = linkedEncounters.map(x => x.id);
    const conditionGoals = goalsData.filter(x =>
      linkedEncounterIds.includes(x.createLog?.createdEncounterId!)
    );

    const conditionTreatmentPlan = treatmentPlanData.filter(x =>
      linkedEncounterIds.includes(x.createLog?.createdEncounterId!)
    );

    let goals: GoalsDataItemDto | undefined;
    let treatmentPlan: TreatmentPlanDataItemDto | undefined;

    if (conditionGoals && conditionGoals.length > 0) {
      if (this.core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
        goals = conditionGoals[0].dataItems?.find(
          d =>
            d.businessRoleCode ===
            this.clinicalRecord.openEncounter?.businessRole
        );
      }
    } else {
      goals =
        conditionGoals[0]?.dataItems && conditionGoals[0]?.dataItems?.length > 0
          ? conditionGoals[0]?.dataItems[0]
          : undefined;
    }

    if (conditionTreatmentPlan && conditionTreatmentPlan.length > 0) {
      if (this.core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
        treatmentPlan = conditionTreatmentPlan[0].dataItems?.find(
          d =>
            d.businessRoleCode ===
            this.clinicalRecord.openEncounter?.businessRole
        );
      }
    } else {
      treatmentPlan =
        conditionTreatmentPlan[0]?.dataItems &&
        conditionTreatmentPlan[0]?.dataItems?.length > 0
          ? conditionTreatmentPlan[0]?.dataItems[0]
          : undefined;
    }

    if (goals) {
      const goalSubGroup: ConditionSubGroup = {
        key: goals.createLog?.createdEncounterId!,
        heading: "Goals",
        items: this.getGoalItems(goals)
      };
      eocSubGroups.push(goalSubGroup);
    }

    if (treatmentPlan) {
      const treatmentPlanSubGroup: ConditionSubGroup = {
        key: treatmentPlan.createLog?.createdEncounterId!,
        heading: "Treatment plan",
        items: this.getTreatmentPlanItems(treatmentPlan)
      };
      eocSubGroups.push(treatmentPlanSubGroup);
    }

    return eocSubGroups;
  };

  getGoalItems = (goals: GoalsDataItemDto) => {
    const goalItems: ConditionItem[] = [];
    if (
      goals.claimGoals &&
      goals.claimGoals[0].goals &&
      goals.claimGoals[0].goals.length > 0
    ) {
      goals.claimGoals[0]?.goals.forEach(goal => {
        if (goal.goal) {
          const title = goal.goal;
          let comment = "";
          if (goal.startDate) {
            comment += `Start: ${DateTime.fromISO(
              goal.startDate
            ).toDayDefaultFormat()}; `;
          }

          if (!goal.isAchieved && goal.endDate) {
            const endDate = DateTime.fromISODateAndTime(
              goal.endDate,
              undefined
            );

            const now = DateTime.now();

            const weeks = Math.ceil(endDate.diff(now, "weeks").weeks);

            if (endDate <= now) {
              comment += `Due ${weeks} weeks ago`;
            } else {
              comment += `Due in ${weeks} weeks`;
            }
          }

          if (goal.isAchieved && goal.achievedDate) {
            comment += `Achieved: ${DateTime.fromISO(
              goal.achievedDate
            ).toDayDefaultFormat()}`;
          }

          const item: ConditionItem = {
            title,
            comment
          };

          goalItems.push(item);
        }
      });
    }
    return goalItems;
  };

  getTreatmentPlanItems = (treatmentPlan: TreatmentPlanDataItemDto) => {
    const treatmentPlanItems: ConditionItem[] = [];
    if (treatmentPlan.treatments && treatmentPlan.treatments.length > 0) {
      treatmentPlan.treatments.forEach(treatment => {
        const treatmentText = this.getTreatmentText(treatment.treatment);
        if (treatmentText) {
          treatmentPlanItems.push({
            title: treatmentText,
            comment: treatment.comment
          });
        }
      });
    }

    if (treatmentPlan.otherTreatments) {
      treatmentPlanItems.push({
        title: "Other treatements",
        comment: treatmentPlan.otherTreatments
      });
    }

    if (
      treatmentPlan.educationOptions &&
      treatmentPlan.educationOptions.length > 0
    ) {
      const eduOptionText = this.getEduOptionText(
        treatmentPlan.educationOptions
      );
      treatmentPlanItems.push({ title: "Education", comment: eduOptionText });
    }

    if (treatmentPlan.otherEducationComment) {
      treatmentPlanItems.push({
        title: "Other education",
        comment: treatmentPlan.otherEducationComment
      });
    }

    if (treatmentPlan.educationComment) {
      treatmentPlanItems.push({
        title: "Education comments",
        comment: treatmentPlan.educationComment
      });
    }

    if (treatmentPlan.plan) {
      treatmentPlanItems.push({
        title: "Plan",
        comment: treatmentPlan.plan
      });
    }

    return treatmentPlanItems;
  };

  getPlanGroups = (
    treatmentPlans: PatientTreatmentPlanDataItemDto[],
    episodesOfCare: EpisodeOfCareDto[]
  ) => {
    let startIndex = 0;
    const groups: IGroup[] = [];
    const items: ConditionItem[] = [];

    for (const plan of treatmentPlans) {
      const episodeOfCare = episodesOfCare.find(x => x.id === plan.linkId);
      const eocSubGroups = this.getPlanEpisodeOfCareItems(plan);

      if (eocSubGroups && eocSubGroups.length > 0) {
        const subGroups: IGroup[] = [];
        let subGroupStartIndex = startIndex;
        let totalNumItems: number = 0;

        eocSubGroups.forEach(subGroup => {
          if (subGroup.heading && subGroup.items.length > 0) {
            subGroups.push({
              key: subGroup.key,
              name: subGroup.heading,
              count: subGroup.items.length,
              isCollapsed: false,
              startIndex: subGroupStartIndex,
              level: 2
            });

            items.push(...subGroup.items);
            subGroupStartIndex += subGroup.items.length;
            totalNumItems += subGroup.items.length;
          }
        });

        const subGroupOperation = subGroupStartIndex > startIndex;

        groups.push({
          key: plan.id ?? "UnsavedPlan",
          name: this.getEoCHeading(episodeOfCare),
          count: items.length,
          isCollapsed: true,
          startIndex,
          children: subGroups,
          level: 1
        });

        if (!subGroupOperation) {
          items.push(...items);
        }

        startIndex += totalNumItems;
      }
    }

    return {
      groups,
      items
    };
  };

  getPlanEpisodeOfCareItems = (
    treatmentPlan: PatientTreatmentPlanDataItemDto
  ): ConditionSubGroup[] => {
    const eocSubGroups: ConditionSubGroup[] = [];

    const isMultiProviderAllowed = this.core.hasPermissions(
      Permission.MultiProviderClaimsAllowed
    );

    const businessRoleCode = this.clinicalRecord.openEncounter?.businessRole;

    if (
      !isMultiProviderAllowed ||
      (isMultiProviderAllowed &&
        treatmentPlan.businessRoleCode === businessRoleCode)
    ) {
      if (treatmentPlan.goals && treatmentPlan.goals.length > 0) {
        const goalItems = this.getPlanGoalItems(treatmentPlan.goals);
        if (goalItems.length > 0) {
          const goalSubGroup: ConditionSubGroup = {
            key: `${treatmentPlan.createLog?.createdEncounterId!}-goals`,
            heading: "Goals",
            items: goalItems
          };
          eocSubGroups.push(goalSubGroup);
        }
      }

      const treatmentPlanItems =
        this.getPatientTreatmentPlanItems(treatmentPlan);
      if (treatmentPlanItems.length > 0) {
        const treatmentPlanSubGroup: ConditionSubGroup = {
          key: `${treatmentPlan.createLog?.createdEncounterId!}-treatmentPlan`,
          heading: "Treatment Plan",
          items: treatmentPlanItems
        };
        eocSubGroups.push(treatmentPlanSubGroup);
      }
    }

    return eocSubGroups;
  };

  getPlanGoalItems = (goals: GoalDataItemDto[]): ConditionItem[] => {
    const goalItems: ConditionItem[] = [];

    goals.forEach(goal => {
      if (goal.goal) {
        const title = goal.goal;
        let comment = "";

        if (goal.startDate) {
          comment += `Start: ${DateTime.fromISO(
            goal.startDate
          ).toDayDefaultFormat()}; `;
        }

        if (!goal.isAchieved && goal.endDate) {
          const endDate = DateTime.fromISO(goal.endDate);
          const now = DateTime.now();

          const weeks = Math.ceil(endDate.diff(now, "weeks").weeks);
          if (endDate <= now) {
            comment += `Due ${Math.abs(weeks)} week${
              weeks < -1 ? "s" : ""
            } ago`;
          } else {
            comment += `Due in ${weeks} week${weeks > 1 ? "s" : ""}`;
          }
        }

        if (goal.isAchieved && goal.achievedDate) {
          comment += `Achieved: ${DateTime.fromISO(
            goal.achievedDate
          ).toDayDefaultFormat()}`;
        }

        goalItems.push({ title, comment });
      }
    });

    return goalItems;
  };

  getPatientTreatmentPlanItems = (
    plan: PatientTreatmentPlanDataItemDto | undefined
  ): ConditionItem[] => {
    const treatmentPlanItems: ConditionItem[] = [];
    if (plan) {
      if (plan.treatments && plan.treatments.length > 0) {
        plan.treatments.forEach(treatment => {
          const treatmentText = this.getTreatmentText(treatment.treatment);
          if (treatmentText) {
            treatmentPlanItems.push({
              title: treatmentText,
              comment: treatment.comment
            });
          }
        });
      }

      if (plan.otherTreatments) {
        treatmentPlanItems.push({
          title: "Other treatements",
          comment: plan.otherTreatments
        });
      }

      if (plan.educationOptions && plan.educationOptions.length > 0) {
        const eduOptionText = this.getEduOptionText(plan.educationOptions);
        treatmentPlanItems.push({ title: "Education", comment: eduOptionText });
      }

      if (plan.otherEducationComment) {
        treatmentPlanItems.push({
          title: "Other education",
          comment: plan.otherEducationComment
        });
      }

      if (plan.educationComment) {
        treatmentPlanItems.push({
          title: "Education comments",
          comment: plan.educationComment
        });
      }

      if (plan.plan) {
        treatmentPlanItems.push({
          title: "Plan",
          comment: plan.plan
        });
      }
    }

    return treatmentPlanItems;
  };

  getEoCHeading = (episodeOfCare: EpisodeOfCareDto | undefined) => {
    if (episodeOfCare) {
      const condition = this.clinicalRecord.conditions.filter(
        x => x.episodeOfCareId === episodeOfCare.id
      );

      let diagnosisText: string = "Undiagnosed";
      if (episodeOfCare.diagnoses && episodeOfCare.diagnoses.length > 0) {
        const primaryDiagnosis = episodeOfCare.diagnoses.filter(
          x => x.isPrimaryDiagnosis
        );
        if (primaryDiagnosis && primaryDiagnosis.length > 0) {
          const originalText = primaryDiagnosis[0].diagnosisCode?.originalText;
          if (originalText) {
            diagnosisText = originalText;
          }
        }
      }
      if (condition && condition[0].claim) {
        const claimText = condition[0].claim.claimNumber ?? "Not lodged";
        diagnosisText += `, ${claimText}`;
      }
      return diagnosisText;
    }
    return "";
  };
}
