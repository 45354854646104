import { toJS } from "mobx";
import * as pbi from "powerbi-client";
import React, { useEffect, useRef, useState } from "react";

import {
  confirm,
  DefaultButton,
  Heading,
  IconButton,
  SplitButton,
  Stack,
  Tile,
  useMeasure,
  useTheme
} from "@bps/fluent-ui";
import {
  bpTableHostStatus,
  initialReportDefinition,
  IReportColumn,
  ReportDefinition,
  SlicerState,
  usePowerBiBpTableHost,
  usePowerBiHost
} from "@bps/titanium-powerbi-helper";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  PresetReportDefinition,
  ReportMetadataInterface,
  ReportType,
  SaveType
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { csvExports } from "@modules/reports/screens/csv/xero.ts";
import { downloadCsv } from "@modules/reports/utils/csvUtils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

import { downloadExcelUtils } from "../../../utils/downloadExcelUtils.ts";
import { useReportScreenContext } from "../../context/ReportsScreenContext.tsx";
import { getReportScreenStylesSet } from "../../ReportsScreen.styles.ts";
import { ReportSaveModal } from "../ReportSaveModal.tsx";
import { ReportColumnChooserFormDialog } from "./ReportColumnChooserFormDialog.tsx";
import { ReportExportButtons } from "./ReportExportButtons.tsx";
import { ReportLoadingSpinner } from "./ReportLoadingSpinner.tsx";

interface ReportDisplayInterface {
  embeddedConfig: pbi.models.IReportEmbedConfiguration;
  metadata: ReportMetadataInterface;
}

export const ReportDisplay: React.FC<ReportDisplayInterface> = ({
  embeddedConfig,
  metadata
}) => {
  const {
    selectedReport,
    deleteUserDefinedReport,
    deletePublishedReport,
    saveMyReport,
    savePublished,
    printReport,
    updateReportVisualsPosition,
    isAdmin
  } = useReportScreenContext();

  const [size, setSize] = useMeasure();

  const onReportReady = async (report: pbi.Report) => {
    if (!metadata.BpTable) {
      report.on("loaded", async () => {
        selectedReport?.definition &&
          SlicerState.setSlicerState(report!, {
            ...initialReportDefinition,
            ...toJS(selectedReport.definition, { recurseEverything: true }),
            rowVisibilityTypes:
              selectedReport.baseReport?.metadata?.RowVisibilityTypes ?? []
          });
        setLoading(false);
      });
    }
  };

  const { divRef, report, error } = usePowerBiHost(
    embeddedConfig,
    onReportReady
  );

  const { notification } = useStores();
  const [saveNewReport, setSaveNewReport] = useState<PresetReportDefinition>();

  const [renameNewReport, setRenameNewReport] =
    useState<PresetReportDefinition>();

  const saveType = useRef<SaveType>(SaveType.none);
  const [columnsEdit, setColumnsEdit] = useState<ReportDefinition>();
  const [reportDefinition, setReportDefinition] =
    useState<PresetReportDefinition>({
      ...initialReportDefinition,
      baseReportName: "",
      rowVisibility: "All",
      ...selectedReport?.definition,
      type: ReportType.None
    });

  const renameMyReport = async (renamed: PresetReportDefinition) => {
    const isConfirmed = await confirm({
      maxWidth: 560,
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Rename"
      },
      dialogContentProps: {
        title: "Rename Report",
        subText: `Are you sure you want to rename this report to ${renamed.name}?`
      }
    });
    if (isConfirmed) {
      saveMyReport(renamed, saveType.current, report.current!);
    }
  };

  useEffect(() => {
    selectedReport?.definition &&
      setReportDefinition(selectedReport.definition);
  }, [selectedReport?.definition]);

  const saveClicked: (saveTypeRequested: SaveType) => void = async (
    saveTypeRequested: SaveType
  ) => {
    saveType.current = saveTypeRequested;
    if (!metadata.BpTable) {
      const slicers = await SlicerState.getSlicerState(report.current!);
      if (selectedReport?.definition) {
        if (saveTypeRequested === SaveType.update) {
          confirmUpdateMyReport({ ...selectedReport.definition, slicers });
        } else {
          setSaveNewReport({ ...selectedReport.definition, slicers });
        }
      }
    } else {
      requestTableConfig();
    }
  };

  const confirmUpdateMyReport = async (updated: PresetReportDefinition) => {
    const isConfirmed = await confirm({
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Save"
      },
      dialogContentProps: {
        title: "Save Report",
        subText: `Are you sure you want to update '${updated.name}'?`
      }
    });
    if (isConfirmed) {
      if (updated.type === ReportType.Published) {
        savePublished(updated, saveType.current, report.current!);
      } else {
        saveMyReport(updated, saveType.current, report.current!);
      }
    }
  };

  const confirmDeleteMyReport = async (doomed: PresetReportDefinition) => {
    const isConfirmed = await confirm({
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Delete"
      },
      dialogContentProps: {
        title: "Delete Report",
        subText: `Are you sure you want to delete '${doomed.name}'?`
      }
    });
    if (isConfirmed) {
      if (doomed.type === ReportType.Published) {
        deletePublishedReport(doomed);
      } else if (doomed.type === ReportType.UserDefined) {
        deleteUserDefinedReport(doomed);
      }
    }
  };

  const onConfigurationDataReady = async (tableData: ReportDefinition) => {
    const { viewColumns, sorts, dateRange, reportDates, availableViewColumns } =
      tableData;

    const slicerDefinitions = await SlicerState.getSlicerState(report.current!);

    const slicers = slicerDefinitions;
    const data = {
      ...reportDefinition,
      viewColumns: viewColumns.map(x => ({ ...x, powerBiColumn: undefined })),
      sorts,
      dateRange,
      reportDates,
      slicers,
      availableViewColumns,
      rows: []
    };

    switch (saveType.current) {
      case SaveType.saveNew:
        setSaveNewReport(data);
        break;
      case SaveType.update:
        confirmUpdateMyReport(data);
        break;
      case SaveType.rename:
        setRenameNewReport(data);
        break;
    }

    return data;
  };

  const onTableReady = (data: ReportDefinition) => {
    const rd = toJS(reportDefinition, { recurseEverything: true });

    const reportDates =
      rd.reportDates && rd.reportDates?.userDefinedDateRange
        ? {
            ...rd.reportDates,
            startDate: rd.reportDates.startDate,
            endDate: rd.reportDates.endDate,
            minDate: rd.reportDates.startDate,
            maxDate: rd.reportDates.endDate
          }
        : undefined;

    const combinedDefinition: ReportDefinition = {
      ...initialReportDefinition,
      name: rd.name,
      sorts: rd?.sorts ? rd.sorts : [],
      rowVisibilityTypes: data.rowVisibilityTypes,
      viewColumns:
        rd?.viewColumns && !!rd?.viewColumns.length
          ? rd?.viewColumns
          : [...data.availableViewColumns.filter((_, i) => i < 6)],
      availableViewColumns: data.availableViewColumns,
      dateRange: rd.dateRange,
      reportDates
    };

    SlicerState.setSlicerState(report.current!, {
      ...initialReportDefinition,
      ...rd!
    });

    const result = reportDefinition ? combinedDefinition : data;

    return result;
  };

  const onReportDataReady = (data: ReportDefinition) => {
    if (data.tag === "print") {
      const rd = toJS(reportDefinition, { recurseEverything: true });
      printReport({ ...data, name: rd.name }, report.current!, true);
    }
  };

  const onAllDataReady = async (data: ReportDefinition) => {
    const csv = csvExports.find(x => x.matchesMetadata === data.tag);
    if (csv) {
      notification.warn(
        notificationMessages.dataFileIsBeingGenerated(
          csv.fileDescription ?? "CSV file"
        )
      );
      return downloadCsv(csv, data);
    }
    return null;
  };

  const requestCsvData = (tag: string) => {
    requestAllData(tag);
  };

  const onExcelDataReady = async (data: ReportDefinition) => {
    try {
      notification.warn(notificationMessages.excelIsBeingGenerated);
      const rd = toJS(reportDefinition, { recurseEverything: true });
      const name = rd.name ?? rd.title;
      const excelDefinition = { ...data, name };
      downloadExcelUtils(excelDefinition, report.current!);
    } catch (error) {
      notification.error(error);
      throw error;
    }
  };

  const onRowDoubleClicked = (data: ReportDefinition) => {
    window.open(`/${data.clickMetadata}/${data.clickedRowId}`, "_blank");
  };

  const onSetColumnsButtonClicked = (data: ReportDefinition) => {
    return setColumnsEdit({ ...data, name: reportDefinition.name });
  };

  const [loading, setLoading] = useState(true);

  const onTableAcknowledged = async () => {
    await updateReportVisualsPosition(size, report.current);
    setLoading(false);
  };

  const updateDebounced = useDebounce(
    async () => await updateReportVisualsPosition(size, report.current)
  );
  useEffect(() => {
    updateDebounced();
  }, [size, report, updateDebounced]);

  const { table, state } = usePowerBiBpTableHost("bpTable", {
    onTableReady,
    onConfigurationDataReady,
    onReportDataReady,
    onExcelDataReady,
    onSetColumnsButtonClicked,
    onTableAcknowledged,
    onAllDataReady,
    onRowDoubleClicked
  });

  const {
    requestAllData,
    requestReportData,
    requestTableConfig,
    setColumnConfig,
    requestExcelData
  } = table;

  const { status, hostingError, requestPending } = state;

  const saveColumns = (columns: IReportColumn[], groups: IReportColumn[]) => {
    const originalReport = toJS(reportDefinition, { recurseEverything: true });
    const viewColumns = columns.map(x => {
      return { ...x, powerBiColumn: undefined, onRender: undefined };
    });

    const viewGroups: IReportColumn[] = groups.map(x => {
      const {
        key,
        viewIndex,
        minWidth,
        powerBiIndex,
        powerBiName,
        name,
        settings
      } = x;
      return {
        powerBiIndex,
        powerBiName,
        name,
        key,
        viewIndex,
        minWidth,
        settings: {
          ...settings,
          aggregation: "none",
          group: false
        },
        powerBiColumn: undefined,
        onRender: undefined
      };
    });

    const newReportDefinition: PresetReportDefinition = {
      ...originalReport,
      sorts: [
        ...groups.map(x => ({
          columnName: x.name,
          group: true
        })),
        ...originalReport.sorts.filter(x => !x.group)
      ],
      reportDates: undefined,
      dateRange: undefined,
      viewColumns: [...viewGroups, ...viewColumns]
    };

    setColumnConfig({
      viewColumns: newReportDefinition.viewColumns,
      sorts: newReportDefinition.sorts,
      rows: [],
      columns: [],
      availableViewColumns: [],
      id: newReportDefinition.id,
      reportId: newReportDefinition.id,
      name: newReportDefinition.name,
      slicers: [],
      rowVisibilityTypes: []
    });
  };

  const theme = useTheme();
  const { reportHeading, fullSize } = getReportScreenStylesSet(theme);

  const disableButtons = () => {
    if (!metadata.BpTable) {
      return false;
    }

    return (
      status !== bpTableHostStatus.ConnectedToTable ||
      loading ||
      !!requestPending
    );
  };
  return (
    <Stack styles={fullSize}>
      {loading && <ReportLoadingSpinner />}
      <Stack
        horizontal
        styles={{
          root: {
            width: "100%",
            justifyContent: "space-between"
          }
        }}
      >
        <Heading variant="section-heading-light" styles={reportHeading}>
          {selectedReport?.name}
          {(!metadata.BpTable ||
            status === bpTableHostStatus.ConnectedToTable) &&
            !loading &&
            selectedReport?.definition?.type === ReportType.UserDefined && (
              <IconButton
                iconProps={{ iconName: "edit" }}
                styles={{
                  root: {
                    width: 20,
                    height: 20,
                    marginLeft: 16
                  }
                }}
                text="edit"
                onClick={() => saveClicked(SaveType.rename)}
              />
            )}
          {hostingError}
          {error}
        </Heading>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          {!loading && (
            <>
              {!!metadata.BpTable && (
                <>
                  <ReportExportButtons
                    selectedReport={selectedReport}
                    requestAllData={requestCsvData}
                    disabled={disableButtons()}
                  />
                  <DefaultButton
                    onClick={() => requestReportData("print")}
                    disabled={disableButtons()}
                  >
                    PDF
                  </DefaultButton>
                  <DefaultButton
                    text="Excel"
                    onClick={() => requestExcelData("Excel")}
                    disabled={disableButtons()}
                  />
                </>
              )}

              {selectedReport?.baseReport?.metadata?.BpTable &&
                (selectedReport?.definition?.type === ReportType.UserDefined ||
                (selectedReport?.definition?.type === ReportType.Published &&
                  isAdmin) ? (
                  <>
                    <SplitButton
                      text="Save"
                      primary
                      items={[
                        {
                          key: "SaveAs",
                          text: "Save a copy",
                          onClick: () => saveClicked(SaveType.saveNew)
                        }
                      ]}
                      onClick={() => saveClicked(SaveType.update)}
                      disabled={disableButtons()}
                    />
                    <DefaultButton
                      onClick={() =>
                        confirmDeleteMyReport(selectedReport.definition!)
                      }
                      disabled={disableButtons()}
                    >
                      Delete
                    </DefaultButton>
                  </>
                ) : (
                  <DefaultButton
                    primary
                    onClick={() => saveClicked(SaveType.saveNew)}
                    disabled={disableButtons()}
                  >
                    Save As
                  </DefaultButton>
                ))}
            </>
          )}
        </Stack>
      </Stack>
      {/* CSSTransition component doesn't work here as the div (difRef) needed by Power Bi to load the Power Bi report is not in the DOM yet */}
      <Tile
        styles={{
          root: {
            width: "100%",
            height: "100%",
            opacity: loading ? "0" : "1",
            transition: "opacity 0.2s linear"
          }
        }}
      >
        <div
          ref={ref => {
            divRef.current = ref;
            ref && setSize(ref);
          }}
          style={{ height: "100%" }}
        />
      </Tile>

      {selectedReport &&
        selectedReport.baseReport &&
        saveNewReport &&
        report.current && (
          <ReportSaveModal
            onClose={async values => {
              if (values) {
                const { reportName, isPublic, rowVisibility } = values;
                const newReport: PresetReportDefinition = {
                  ...saveNewReport,
                  name: reportName,
                  title: reportName,
                  rowVisibility,
                  type: isPublic ? ReportType.Published : ReportType.UserDefined
                };
                if (isPublic) {
                  const savedReport = await savePublished(
                    newReport,
                    saveType.current,
                    report.current
                  );
                  savedReport &&
                    setReportDefinition({
                      ...newReport,
                      id: savedReport.id,
                      type: ReportType.Published
                    });
                } else {
                  const savedReport = await saveMyReport(
                    newReport,
                    saveType.current,
                    report.current
                  );
                  savedReport &&
                    setReportDefinition({
                      ...newReport,
                      id: savedReport.id,
                      type: ReportType.UserDefined
                    });
                }
              }
              setSaveNewReport(undefined);
            }}
            rename={false}
            reportDefinition={renameNewReport ? renameNewReport : saveNewReport}
            isAdmin={isAdmin}
          />
        )}
      {selectedReport && renameNewReport && report.current && (
        <ReportSaveModal
          onClose={result => {
            result &&
              renameMyReport({
                ...renameNewReport,
                name: result.reportName,
                title: result.reportName
              });
            setRenameNewReport(undefined);
          }}
          rename={true}
          isAdmin={isAdmin}
          reportDefinition={renameNewReport}
        />
      )}

      {columnsEdit && (
        <ReportColumnChooserFormDialog
          reportDefinition={columnsEdit}
          onClose={() => {
            setColumnsEdit(undefined);
          }}
          saveColumns={saveColumns}
          isAdmin={isAdmin}
        />
      )}
    </Stack>
  );
};
