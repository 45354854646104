import { Separator, Stack } from "@bps/fluent-ui";
import { groupBy } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { capitalizeSentence, sum } from "@libs/utils/utils.ts";
import { PaymentMethodDetails } from "@modules/billing/screens/shared-components/PaymentMethodText.tsx";
import { TotalsDetails } from "@modules/billing/screens/shared-components/TotalsDetails.tsx";
import { Payment } from "@stores/billing/models/Payment.ts";

interface PaymentDetailsProps {
  payment: Payment;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ payment }) => {
  const allocationWithItems = payment.allocationSpenders
    ? groupBy(payment.allocationSpenders, x => x.transaction.number)
    : [];

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <PaymentMethodDetails heading="Payment methods" methods={payment.items} />
      <Separator vertical />
      <TotalsDetails
        heading="Allocated to"
        rowOptions={allocationWithItems.map(x => {
          const [number, items] = x;
          return {
            id: number,
            text: (
              <>
                {capitalizeSentence(items[0].itemType)} {number}
              </>
            ),
            amount: sum("amount", items),
            path: routes.accounts.allocations.allocation.path({
              id: items[0].transaction.id
            }),
            permission: Permission.AccountHistoryAllowed
          };
        })}
      />
    </Stack>
  );
};
