import { Heading, Stack } from "@bps/fluent-ui";
import { ClinicalReminderReason } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DateRange } from "@ui-components/form/DatePickerOnInField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { ClinicalReminderPreferenceValidator } from "../Validator/ClinicalReminderPreferenceDialogValidator.tsx";

interface ClinicalReminderPreferenceDialogProps {
  onDismiss: () => void;
  selectedClinicalReminder?: ClinicalReminderReason;
}

export const ClinicalReminderPreferenceDialog: React.FC<
  ClinicalReminderPreferenceDialogProps
> = ({ onDismiss, selectedClinicalReminder }) => {
  const { comms, core } = useStores();

  const nameOf = nameOfFactory<ClinicalReminderReason>();

  const clinicalReminderPreference = comms.clinicalReminderPreference;

  const clinicalReminderReasons =
    clinicalReminderPreference?.clinicalReminderReasons ?? [];

  const onSubmit = async (values: ClinicalReminderReason) => {
    let updatedReasons: ClinicalReminderReason[] = [...clinicalReminderReasons];

    if (selectedClinicalReminder !== undefined) {
      const updatedIndex = clinicalReminderReasons?.findIndex(
        x => x.reason === selectedClinicalReminder?.reason
      );

      updatedReasons[updatedIndex] = values;
    } else {
      values.tenant = core.tenantDetails?.country;
      updatedReasons = [...clinicalReminderReasons, values];
    }

    if (clinicalReminderPreference) {
      const PatchClinicalReminderPreference = {
        id: clinicalReminderPreference?.id,
        eTag: clinicalReminderPreference?.eTag,
        clinicalReminderReasons: updatedReasons
      };
      await comms.patchClinicalReminderPreference(
        PatchClinicalReminderPreference
      );
    }
  };

  const validator = new ClinicalReminderPreferenceValidator(
    clinicalReminderReasons,
    selectedClinicalReminder
  );

  return (
    <SubmissionFormDialog<ClinicalReminderReason>
      onSubmit={onSubmit}
      dialogName="Create reminder"
      initialValues={selectedClinicalReminder ?? { isActive: true }}
      onSubmitSucceeded={onDismiss}
      validate={validator.validate}
      dialogProps={{
        dialogContentProps: {
          title: (
            <Heading variant="modal-heading">
              {!selectedClinicalReminder
                ? "New clinical reminder"
                : "Edit reminder"}
            </Heading>
          ),
          showCloseButton: true
        },
        maxWidth: 640,
        minWidth: 640,
        onDismiss
      }}
    >
      {() => (
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack tokens={{ childrenGap: 6 }}>
            <Stack horizontal verticalAlign="end">
              <Heading hasAsterisk>Reminder reason</Heading>

              {selectedClinicalReminder && (
                <Stack grow horizontalAlign="end">
                  <ToggleField
                    name={nameOf("isActive")}
                    label="Active"
                    inlineLabel
                    styles={{
                      label: { fontWeight: "normal", margin: 8 },
                      root: { marginBottom: -14 }
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Stack grow>
              <TextInputField name={nameOf("reason")} required />
            </Stack>
          </Stack>

          <CheckboxField
            label="Clinically significant"
            name={nameOf("clinicallySignificant")}
          />
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
            <SpinNumberInputField
              label="Default Interval"
              name={nameOf("interval")}
            />
            <ButtonsGroupSingleChoiceField
              name={nameOf("frequency")}
              options={[
                { key: DateRange.days, text: DateRange.days },
                { key: DateRange.weeks, text: DateRange.weeks },
                {
                  key: DateRange.months,
                  text: DateRange.months
                },
                { key: DateRange.years, text: DateRange.years }
              ]}
            />
          </Stack>
        </Stack>
      )}
    </SubmissionFormDialog>
  );
};
