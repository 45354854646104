import React from "react";

import { Card, Spinner } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { SettingsLabels } from "@modules/settings/screens/shared-components/SettingsLabels.ts";
import { BookingTenantSettings } from "@stores/booking/models/BookingTenantSettings.ts";
import { CommPreference } from "@stores/comms/models/CommPreference.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { BookingTenantSettingsForm } from "./components/BookingTenantSettingsForm.tsx";
import { CommunicationPreferencesForm } from "./components/CommunicationPreferencesForm.tsx";

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

const CommunicationSettingsCardBase: React.FC = React.memo(() => {
  const getTenantSettings = async ({
    booking
  }: RootStore): Promise<BookingTenantSettings | undefined> => {
    return await booking.getTenantSettings();
  };

  const getCommPreferences = async ({
    comms
  }: RootStore): Promise<CommPreference[]> => {
    return await comms.getCommPreferences();
  };

  return (
    <Card
      iconName="Megaphone"
      headingLevel="section-heading"
      heading={SettingsLabels.communications}
      styles={cardStyles}
    >
      <When
        permission={Permission.PreReleaseBookingTenantSettingsAllowed}
        else={
          <DataFetcher fetch={getCommPreferences} fallback={<Spinner />}>
            {(commsPreferences: CommPreference[]) => (
              <CommunicationPreferencesForm
                commsPreferences={commsPreferences}
              />
            )}
          </DataFetcher>
        }
      >
        <DataFetcher fetch={getTenantSettings} fallback={<Spinner />}>
          {() => <BookingTenantSettingsForm />}
        </DataFetcher>
      </When>
    </Card>
  );
});

export const CommunicationSettingsCard = withFetch(
  x => [
    x.core.ref.australianStates.load(),
    x.core.ref.timeZones.load(),
    x.comms.loadClinicalReminderPreferenceByTenant()
  ],
  CommunicationSettingsCardBase
);
