import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { NotificationsStore } from "stores/notifications/NotificationsStore.ts";

import {
  confirm,
  ContextualMenuItemType,
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ClaimAdjustmentContext } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentContext.ts";
import { ClaimAdjustmentHelper } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.ts";
import { ModalKeys } from "@modules/acc/screens/claim-adjustment/context/ClaimAdjustmentHelper.types.ts";
import {
  ClaimFormPrint,
  ClaimFormPrintType
} from "@modules/acc/screens/claim/components/ClaimFormPrint.tsx";
import {
  canClaimBeLodged,
  getClaimFormValues,
  isClaimStatusDeletable
} from "@modules/acc/screens/claim/components/utils.ts";
import { ClaimConditionHelperDataFetcher } from "@modules/acc/screens/shared-components/ClaimConditionHelperDataFetcher.tsx";
import { SendACC45EmailModal } from "@modules/acc/screens/shared-components/SendACC45EmailModal.tsx";
import { ConditionModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/ConditionModal.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { useFormMenu } from "@modules/forms/components/forms-context-menu/useFormMenu.tsx";
import { AccStore } from "@stores/acc/AccStore.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClaimAdjustmentDialogForm } from "../../claim-adjustment/components/ClaimAdjustmentDialogForm.tsx";
import {
  isClaimAdjustmentAllowed,
  sortClaimAdjustments
} from "../../claim-adjustment/components/utils.ts";
import { ClaimDeleteConfirmDialog } from "./claim-delete/ClaimDeleteConfirmDialog.tsx";

interface ClaimsContextualMenuProps {
  claim: Claim;
  displayConditionSetup?: boolean;
  newApptMenuItem?: IContextualMenuItem;
  hideChangeToPrivate?: boolean;
}

export const deleteClaimAdjustmentHandler = async (
  stores: { acc: AccStore; notification: NotificationsStore },
  claimAdjustment: ClaimAdjustment,
  onSucceedFn?: () => void
) => {
  const { id, formattedNumber } = claimAdjustment;
  const { acc, notification } = stores;
  const isConfirmed = await confirm({
    maxWidth: 560,
    cancelButtonProps: {
      text: "Cancel"
    },
    confirmButtonProps: {
      text: "Confirm"
    },
    dialogContentProps: {
      title: "Delete",
      subText: `Do you want to delete ${formattedNumber}?`
    }
  });
  if (isConfirmed) {
    try {
      if (onSucceedFn) onSucceedFn();
      await acc.deleteClaimAdjustment(id);
      notification.success(
        notificationMessages.claimAdjustmentDeleted(formattedNumber)
      );
    } catch (e) {
      notification.error(e.message);
    }
  }
};

export const ClaimsContextualMenu: React.FC<ClaimsContextualMenuProps> =
  observer(
    ({
      claim,
      displayConditionSetup,
      newApptMenuItem,
      hideChangeToPrivate
    }) => {
      const [showConditionModal, setShowConditionModal] = useState(false);
      const [deletingClaim, setDeletingClaim] = useState<Claim | undefined>();

      const root = useStores();
      const { routing, acc, notification, core } = root;

      const claimAdjustmentHelper = useRef(
        new ClaimAdjustmentHelper(root, claim)
      );

      const getSingleClaimAdjustmentMenuItem = (
        claim: Claim,
        claimAdjustment: ClaimAdjustment
      ): IContextualMenuItem[] => {
        const singleClaimAdjustmentMenuItems: IContextualMenuItem[] = [];
        singleClaimAdjustmentMenuItems.push({
          key: "editClaimAdjustment",
          onRenderContent: () => (
            <Text styles={{ root: { marginLeft: 4 } }}>
              {core.hasPermissions(Permission.ClaimWrite) ? "Edit" : "View"}
            </Text>
          ),
          onClick: () => {
            routing.push(
              {
                pathname: routes.claimAdjustment.edit.path({
                  id: claimAdjustment.id,
                  claimId: claim.id
                })
              },
              { claimsFilter: routing.location.search }
            );
          }
        });
        if (core.hasPermissions(Permission.ClaimWrite)) {
          // TODO: when Lodge is implemented, disabled will need to be set appropriately
          singleClaimAdjustmentMenuItems.push({
            key: "lodgeClaimAdjustment",
            text: "Lodge",
            disabled: true,
            iconProps: { iconName: "Blocked" }
          });

          if (core.hasPermissions(Permission.ClaimDelete)) {
            singleClaimAdjustmentMenuItems.push({
              key: "divider1",
              itemType: ContextualMenuItemType.Divider
            });
            singleClaimAdjustmentMenuItems.push({
              key: "Acc32Delete",
              onRenderContent: () => (
                <Text styles={{ root: { marginLeft: 4 } }}>Delete</Text>
              ),
              onClick: () => {
                deleteClaimAdjustmentHandler(
                  { acc, notification },
                  claimAdjustment
                );
              }
            });
          }

          singleClaimAdjustmentMenuItems.push({
            key: "divider2",
            itemType: ContextualMenuItemType.Divider
          });
        }

        return singleClaimAdjustmentMenuItems;
      };

      const listAcc32s = (claim: Claim): IContextualMenuItem[] => {
        const sortedAdjustments = sortClaimAdjustments(claim.claimAdjustments);
        const Acc32MenuItems = sortedAdjustments.map(x => ({
          key: `editClaimAdjustment ${x.id}`,
          text: x.formattedNumber,
          subMenuProps: {
            items: getSingleClaimAdjustmentMenuItem(claim, x)
          }
        }));
        return Acc32MenuItems;
      };

      const formMenus = useFormMenu(claim.patientId, {
        claimId: claim.id,
        filterForms: template => template.contextJson.includes("ClaimId")
      });

      const getACC45MenuItems = (): IContextualMenuItem[] => {
        const acc45MenuItems: IContextualMenuItem[] = [];
        acc45MenuItems.push({
          key: "editAcc45",
          onRenderContent: () => (
            <Text styles={{ root: { marginLeft: 4 } }}>
              {core.hasPermissions(Permission.ClaimWrite) &&
              isClaimStatusDeletable(claim.claimStatus!)
                ? "Edit"
                : "View"}
            </Text>
          ),
          onClick: () => {
            routing.push(
              { pathname: routes.claims.edit.path({ id: claim.id }) },
              { claimsFilter: routing.location.search }
            );
          }
        });
        if (claim.claimStatus !== ClaimStatuses.Incomplete) {
          acc45MenuItems.push({
            ...dataAttribute(
              DataAttributes.Element,
              "claims-contextual-menu-acc45-btn-lodge"
            ),
            key: "lodgeAcc45",
            text: "Lodge",
            disabled: !canClaimBeLodged(claim),
            iconProps: {
              iconName: canClaimBeLodged(claim)
                ? "EntitlementRedemption"
                : "Blocked"
            },
            onClick: () => {
              routing.push(
                routes.claims.management.edit.path({ id: claim.id }),
                routing.getStateWithFromQuery()
              );
            }
          });
        }

        if (claim.isNotIncompleteOrReady || claim.isNotDeclinedOrError) {
          acc45MenuItems.push({
            key: "dividerPrintClaim1",
            itemType: ContextualMenuItemType.Divider
          });

          acc45MenuItems.push({
            key: "printClaim",
            disabled: !claim.isClaimStatusesAllowToPrintOnlineForm,
            onRenderContent: () => (
              <ClaimFormPrint
                values={getClaimFormValues(claim)}
                printType={ClaimFormPrintType.Patient}
              />
            )
          });
        }

        if (!claim.isNotVerifiedOrNotAvailable) {
          acc45MenuItems.push({
            key: "printSummary",
            disabled: claim.isClaimStatusesAllowToPrintOnlineForm,
            onRenderContent: () => (
              <ClaimFormPrint
                values={getClaimFormValues(claim)}
                printType={ClaimFormPrintType.Summary}
              />
            )
          });
        }

        if (core.hasPermissions(Permission.ClaimWrite)) {
          acc45MenuItems.push(
            {
              key: "emailACC45",
              disabled: claim.isClaimStatusesAllowToPrintOnlineForm,
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                acc.ui.setCurrentClaimToEmail(claim);
                acc.ui.setIsEmailACC45DialogVisible(true);
              },
              onRenderContent: () => (
                <SendACC45EmailModal
                  patientId={claim.patientId!}
                  patientName={claim.patientFullName}
                  claimDto={claim.dto}
                />
              )
            },
            {
              key: "dividerPrintClaim2",
              itemType: ContextualMenuItemType.Divider
            }
          );
        }

        return [...acc45MenuItems, ...formMenus];
      };

      const getClaimMenuItems = (): IContextualMenuItem[] => {
        const claimMenuItems: IContextualMenuItem[] = [];
        if (displayConditionSetup) {
          claimMenuItems.push({
            key: "setup",
            text: "Condition setup",
            onClick: () => setShowConditionModal(true)
          });
        }
        claimMenuItems.push(
          {
            key: "claimManagement",
            text: "Summary",
            onClick: () => {
              routing.push(
                {
                  pathname: routes.claims.management.edit.path({ id: claim.id })
                },
                { claimsFilter: routing.location.search }
              );
            }
          },
          {
            key: "dividerPrintClaim3",
            itemType: ContextualMenuItemType.Divider
          }
        );

        if (newApptMenuItem) {
          claimMenuItems.push(newApptMenuItem, {
            key: "dividerNewLinkedAppt",
            itemType: ContextualMenuItemType.Divider
          });
        }

        if (!claim.referralIn && !claim.isNotVerifiedOrNotAvailable) {
          claimMenuItems.push({
            ...dataAttribute(
              DataAttributes.Element,
              "claims-contextual-menu-acc45-btn"
            ),
            key: "editClaim",
            text: "ACC45",
            subMenuProps: {
              items: getACC45MenuItems()
            }
          });
          if (
            !!claim.claimStatus &&
            !isClaimAdjustmentAllowed(claim.claimStatus)
          ) {
            claimMenuItems.push({
              key: "dividerMakePrivate",
              itemType: ContextualMenuItemType.Divider
            });
          }
        }

        if (isClaimAdjustmentAllowed(claim.claimStatus!)) {
          if (claim.claimAdjustments.length > 0) {
            const subMenuItems = [...listAcc32s(claim)];

            if (core.hasPermissions(Permission.ClaimWrite)) {
              subMenuItems.push({
                key: "dividerNewClaimAdj",
                itemType: ContextualMenuItemType.Divider
              });

              subMenuItems.push({
                key: "newClaimAdjustment",
                onRenderContent: () => (
                  <Text styles={{ root: { marginLeft: 4 } }}>New ACC32</Text>
                ),
                onClick: () => {
                  claimAdjustmentHelper.current.setOpenModal(
                    ModalKeys.AdjustmentModal
                  );
                }
              });
            }
            claimMenuItems.push({
              key: "editClaimAdjustment",
              text: `ACC32 (${claim.claimAdjustments.length})`,
              subMenuProps: {
                items: subMenuItems
              }
            });
          } else if (claim.claimAdjustments.length > 0) {
            claimMenuItems.push({
              key: "editClaimAdjustment",
              text: `ACC32 (${claim.claimAdjustments.length})`,
              subMenuProps: {
                items: getSingleClaimAdjustmentMenuItem(
                  claim,
                  claim.claimAdjustments[0]
                )
              }
            });
          } else if (core.hasPermissions(Permission.ClaimWrite)) {
            claimMenuItems.push({
              key: "dividerNewAcc32",
              itemType: ContextualMenuItemType.Divider
            });

            claimMenuItems.push({
              key: "newClaimAdjustment",
              text: "New ACC32",
              onClick: () => {
                claimAdjustmentHelper.current.setOpenModal(
                  ModalKeys.AdjustmentModal
                );
              }
            });
          }
        }

        if (isClaimStatusDeletable(claim.claimStatus!) && !claim.private) {
          if (
            core.hasPermissions(Permission.ClaimWrite) &&
            claim.isPostInitialConsultDate &&
            claim.claimStatus !== ClaimStatuses.NotVerified &&
            !hideChangeToPrivate
          ) {
            claimMenuItems.push({
              key: "makePrivate",
              onRenderContent: () => (
                <Text styles={{ root: { marginLeft: 4 } }}>
                  Change to private
                </Text>
              ),
              disabled: !isClaimStatusDeletable(claim.claimStatus!),
              onClick: () => setShowConditionModal(true)
            });
          } else if (core.hasPermissions(Permission.ClaimDelete)) {
            claimMenuItems.push({
              key: "deleteClaim",
              onRenderContent: () => (
                <Text styles={{ root: { marginLeft: 4 } }}>Delete</Text>
              ),
              disabled: !isClaimStatusDeletable(claim.claimStatus!),
              onClick: () => {
                setDeletingClaim(claim);
              }
            });
          }
        }

        if (
          !claim.isDischarged &&
          core.hasPermissions(Permission.ConditionDischargeOnBehalfAllowed)
        ) {
          claimMenuItems.push({
            key: "discharge",
            text: "Discharge",
            onClick: () => {
              acc.discharge(claim.id).catch((error: Error) => {
                notification.error(error);
              });
            }
          });
        }

        return claimMenuItems;
      };

      return (
        <Stack horizontalAlign="center" verticalAlign="center" horizontal>
          <TooltipHost content="More">
            <IconButton
              {...dataAttribute(
                DataAttributes.Element,
                "claims-contextual-menu-btn"
              )}
              onMouseDown={e => e.stopPropagation()}
              menuIconProps={{ iconName: "More" }}
              menuProps={{
                items: getClaimMenuItems()
              }}
              styles={{
                root: { width: "32px", height: "36px", padding: 0 },
                flexContainer: { width: "32px", height: "36px" }
              }}
            />
          </TooltipHost>
          <ClaimAdjustmentContext.Provider
            value={claimAdjustmentHelper.current}
          >
            <ClaimAdjustmentDialogForm claim={claim} />
          </ClaimAdjustmentContext.Provider>
          {showConditionModal && (
            <ClaimConditionHelperDataFetcher claim={claim} showModal={true}>
              {helper => (
                <ConditionContext.Provider value={helper}>
                  <ConditionModal
                    onDismiss={() => setShowConditionModal(false)}
                  />
                </ConditionContext.Provider>
              )}
            </ClaimConditionHelperDataFetcher>
          )}
          {deletingClaim && (
            <ClaimDeleteConfirmDialog
              deletingClaim={deletingClaim}
              setDeletingClaim={setDeletingClaim}
            />
          )}
        </Stack>
      );
    }
  );
