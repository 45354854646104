import { DateTime, groupBy, newGuid, unique } from "@bps/utils";
import { RefData } from "@libs/api/ref-data/RefData.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  CommTypePreferencesDto,
  OPTOUT,
  OutboundCommChannel,
  OutboundCommChannelMapping,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import {
  AddressDto,
  AddressType,
  CommunicationDto,
  CommunicationType,
  ContactStatus,
  ContactType,
  ExternalProviderDto,
  InternalProviderDto,
  RelationshipDto,
  RelationshipMetadataDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { employerRelationships } from "@shared-types/practice/employer.relationship.constant.ts";
import { ContactPreferences } from "@stores/comms/models/ContactPreferences.tsx";
import { CoreStore } from "@stores/core/CoreStore.ts";
import {
  communicationComparer,
  formatCommunication
} from "@stores/core/models/Communication.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { filterPatientEmployers } from "@stores/practice/utils/practice.utils.ts";
import { PatientSetting } from "@stores/user-experience/models/PatientSetting.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { accountRelationships } from "../../constants/account-relationships.constant.ts";
import {
  ContactEditFormValues,
  EmployerFieldValue,
  ExternalProviderFieldValue,
  InternalProviderFieldValue,
  PatientEditFormValues as PatientFormValues
} from "./PatientEditFormValues.tsx";
import {
  RelationshipFieldValue,
  relationshipFromContactId,
  toSingleRelationshipDto,
  toSingleRelationshipFieldValue
} from "./relationships/utils.ts";

interface ContactDetails {
  contact: Contact | undefined;
  contactPreferences: ContactPreferences | undefined;
  patientSetting: PatientSetting | undefined;
  isAddPatient: boolean;
}

export const defaultCommunication = (
  type: CommunicationType,
  id: string
): CommunicationFieldValue => {
  return {
    type,
    value: undefined,
    preferred: false,
    id
  };
};

export const defaultDVA = {
  fund: undefined,
  number: undefined,
  familyNumber: undefined,
  expiry: undefined
};
export const defaultHealthFund = {
  number: undefined,
  cardColor: undefined,
  disability: undefined
};
export const defaultCSC = {
  number: undefined,
  startDate: undefined,
  expiry: undefined
};

export const defaultAddress = (
  type: AddressType,
  id: string,
  country: Country
) => {
  const types = getAddressTypes(type);
  return { type, types, street1: "", id, country };
};

const hasRelatedContact = (
  relationship: RelationshipFieldValue
): relationship is RelationshipFieldValue & { relatedContactId: string } => {
  return !!relationship.relatedContactId;
};

export const toInternalProviderDto = (
  providers: InternalProviderFieldValue[]
): InternalProviderDto[] => {
  return [...providers]
    .filter(prov => !!prov.userId)
    .map(
      prov => ({ userId: prov.userId, lead: prov.lead }) as InternalProviderDto
    );
};

export const toExternalProviderDto = (
  providers: ExternalProviderFieldValue[]
): ExternalProviderDto[] => {
  return [...providers]
    .filter(prov => !!prov.contactId)
    .map(prov => ({ contactId: prov.contactId }) as ExternalProviderDto);
};

export const toRelationshipDto = (
  relationships: RelationshipFieldValue[],
  existingRelationships: RelationshipDto[] = []
): RelationshipDto[] => {
  const relationshipDtos: RelationshipDto[] = relationships
    .filter(hasRelatedContact)
    .reduce((dtos: RelationshipDto[], rel) => {
      const types: RelationshipType[] = [
        ...rel.professional,
        ...rel.family,
        ...rel.special,
        ...rel.account
      ];

      if (!types.length) {
        types.push(RelationshipType.General);
      }

      types.forEach(type => {
        const existingRelationship = existingRelationships.find(
          r =>
            r.relatedContactId === rel.relatedContactId &&
            r.relationship === type
        );

        let metadata: RelationshipMetadataDto | undefined =
          existingRelationship?.metadata;

        if (type === RelationshipType.AccountHolder) {
          metadata = {
            isPrimary: true,
            relationshipTypeCode: RelationshipType.AccountHolder
          };
        }

        dtos.push({
          relatedContactId: rel.relatedContactId,
          relationship: type,
          hasRelationship: true,
          metadata
        });
      });

      return dtos;
    }, []);

  // retain all account holder for relationships
  relationshipDtos.push(
    ...existingRelationships.filter(
      rel => rel.relationship === RelationshipType.AccountHolderFor
    )
  );

  const uniqueRelationships = unique(
    relationshipDtos.map(rel => JSON.stringify(rel))
  );

  return uniqueRelationships.map(rel => JSON.parse(rel));
};

export const toRelationshipFieldValue = (
  relationships: RelationshipDto[],
  practice: PracticeStore
): RelationshipFieldValue[] => {
  return groupBy(
    //exclude employer relationships as these are dealt with separately now
    //exclude accountHolder relationships as these are dealt with separately now
    relationships.filter(
      x =>
        !employerRelationships.includes(x.relationship) &&
        !accountRelationships.includes(x.relationship)
    ),
    x => x.relatedContactId
  )
    .map(([relatedContactId, relationshipDto]) => {
      const contact = practice.contactsMap.get(relatedContactId!);
      const type = contact ? contact.type : undefined;
      return relationshipFromContactId({
        contactId: relatedContactId!,
        type: relationshipDto[0].relationship,
        metadata: relationshipDto[0].metadata!,
        contactType: type,
        types: relationshipDto.map(x => x.relationship)
      });
    })
    .filter(x => x.type);
};
export const toAccountHolderFieldValue = (
  relationships: RelationshipDto[],
  practice: PracticeStore
): RelationshipFieldValue[] => {
  const all = relationships
    .filter(
      x =>
        x.relationship &&
        x.relatedContactId &&
        x.relationship === RelationshipType.AccountHolder
    )
    .map(x => {
      const result = toSingleRelationshipFieldValue(x);
      const contact = practice.contactsMap.get(x.relatedContactId!);
      result.contactName = contact?.name;
      return result;
    });

  const primary = all.filter(x => x.metadata?.isPrimary);
  const secondary = all
    .filter(x => !x.metadata?.isPrimary)
    .sort((a, b) =>
      a.contactName &&
      b.contactName &&
      a.contactName.toLowerCase() > b.contactName.toLowerCase()
        ? 1
        : -1
    );
  return [...primary, ...secondary];
};

export const toInternalProviderFieldValue = (
  providers: InternalProviderDto[]
): InternalProviderFieldValue[] => {
  return providers.map(prov => ({
    userId: prov.userId,
    lead: prov.lead,
    id: prov.userId
  }));
};

const toEmployerFieldValue = (employers: RelationshipDto[]) => {
  return employers.map(emp => {
    const employer: EmployerFieldValue = {
      animationId: newGuid(),
      ...emp
    };
    return employer;
  });
};

export const toRelationshipDtoFromEmployerField = (
  employers: EmployerFieldValue[]
): RelationshipDto[] => {
  return employers.filter(e => e.relatedContactId);
};

export const toRelationshipDtoFromAccountHoldersField = (
  accountHolders: RelationshipFieldValue[]
): RelationshipDto[] => {
  return accountHolders.map(toSingleRelationshipDto);
};

export const toExternalProviderFieldValue = (
  providers: ExternalProviderDto[]
): ExternalProviderFieldValue[] => {
  return providers.map(prov => {
    return {
      contactId: prov.contactId,
      id: prov.contactId
    };
  });
};

export const toAddressFieldValue = (
  addresses: AddressDto[],
  addressType: AddressType,
  defaultCountry: Country
): AddressFieldValue[] => {
  return addresses.length && addresses.filter(x => x.street1)
    ? addresses.map(addr => ({
        ...addr,
        id: newGuid(),
        types: getAddressTypes(addr.type)
      }))
    : [defaultAddress(addressType, newGuid(), defaultCountry)];
};

export const toCommunicationFieldValue = (params: {
  communications: CommunicationDto[];
  core: CoreStore;
  communicationComparer: (
    a: { type: CommunicationType },
    b: { type: CommunicationType }
  ) => number;
  defaultCommunicationTypes?: CommunicationType[];
}): CommunicationFieldValue[] => {
  const {
    core,
    communications,
    communicationComparer,
    defaultCommunicationTypes
  } = params;
  if (communications.length && communications.filter(x => x.value)) {
    return communications.sort(communicationComparer).map(comms => ({
      id: newGuid(),
      ...formatCommunication(comms, core)
    }));
  } else {
    if (defaultCommunicationTypes && defaultCommunicationTypes.length > 0) {
      const defaultCommunications: CommunicationFieldValue[] = [];
      defaultCommunicationTypes.forEach(x => {
        defaultCommunications.push(defaultCommunication(x, newGuid()));
      });
      return defaultCommunications;
    } else {
      return [defaultCommunication(CommunicationType.Mobile, newGuid())];
    }
  }
};

export const toCommunicationDto = (
  communications: CommunicationFieldValue[] | undefined
): CommunicationDto[] => {
  if (!communications) {
    return [];
  }
  return communications
    .filter(c => c.value)
    .map<CommunicationDto>(com => ({
      type: com.type,
      preferred: com.preferred,
      value: com.value!
    }));
};

export const getAddressTypes = (addressType: AddressType) => {
  return addressType === AddressType.Both
    ? [AddressType.Physical, AddressType.Postal]
    : [addressType];
};

const getAppointmentReminderType = (
  contactPreferences?: ContactPreferences
) => {
  return contactPreferences?.apptRemPreferences?.contactHasOptedOut
    ? OPTOUT
    : contactPreferences?.apptRemPreferences?.preferredCommChannelTypeCode;
};

const getAppointmentReminderValue = (
  contactPreferences?: ContactPreferences,
  communications?: CommunicationDto[]
) => {
  if (!communications || !contactPreferences) {
    return undefined;
  }

  const mobileCommunications = communications.filter(
    x => x.type === CommunicationType.Mobile
  );
  if (
    !contactPreferences.apptRemPreferences &&
    mobileCommunications.length === 1
  ) {
    return 0;
  }

  const index = mobileCommunications.findIndex(
    x =>
      x.value ===
      contactPreferences.apptRemPreferences?.preferredCommAddressValue
  );
  return index >= 0 ? index : undefined;
};

const getAppointmentConfirmationType = (
  contactPreferences?: ContactPreferences
) => {
  return contactPreferences?.apptConfirmPreferences?.contactHasOptedOut
    ? OPTOUT
    : contactPreferences?.apptConfirmPreferences?.preferredCommChannelTypeCode;
};

const getAppointmentConfirmationValue = (
  contactPreferences?: ContactPreferences,
  communications?: CommunicationDto[]
) => {
  if (!communications || !contactPreferences) {
    return undefined;
  }

  const comms = communications.filter(
    x => x.type === contactPreferences.apptConfirmPreferences?.commTypeCode
  );
  if (comms.length === 1) {
    return 0;
  }

  const index = comms.findIndex(
    x =>
      x.value ===
      contactPreferences?.apptConfirmPreferences?.preferredCommAddressValue
  );
  return index >= 0 ? index : undefined;
};

const getPreferredCommsValue = (
  contactPreferences?: CommTypePreferencesDto,
  communications?: CommunicationDto[]
) => {
  if (!communications || !contactPreferences) {
    return undefined;
  }

  const code =
    contactPreferences.preferredCommChannelTypeCode === OutboundCommChannel.Sms
      ? CommunicationType.Mobile
      : contactPreferences.preferredCommChannelTypeCode;

  const comms = communications.filter(x => x.type === code);
  if (comms?.length === 1) {
    return 0;
  }

  const index = comms.findIndex(
    x => x.value === contactPreferences.preferredCommAddressValue
  );

  return index >= 0 ? index : undefined;
};

const getInvoiceCommunicationType = (
  contactPreferences?: ContactPreferences
) => {
  return contactPreferences?.invoiceCommunicationPreferences?.contactHasOptedOut
    ? OPTOUT
    : contactPreferences?.invoiceCommunicationPreferences
        ?.preferredCommChannelTypeCode;
};

const getFormNotifyType = (contactPreferences?: ContactPreferences) => {
  return contactPreferences?.formNotifyPreferences?.contactHasOptedOut
    ? OPTOUT
    : contactPreferences?.formNotifyPreferences?.preferredCommChannelTypeCode;
};

export const getPatientFormValues = (
  contactDetails: ContactDetails,
  stores: {
    core: CoreStore;
    practice: PracticeStore;
    userExperience: UserExperienceStore;
  }
): PatientFormValues => {
  const { core, practice } = stores;
  const isNewPatientForm =
    contactDetails.isAddPatient || !contactDetails.contact;

  const searchValue = practice.ui.demographicInitialValues;
  const { country } = core.tenantDetails!;

  if (contactDetails.isAddPatient || !contactDetails.contact) {
    return {
      type: ContactType.Patient,
      addresses: searchValue?.addresses ?? [
        defaultAddress(AddressType.Physical, newGuid(), country)
      ],
      firstName: searchValue?.firstName,
      lastName: searchValue?.lastName,
      ethnicities: [],
      communications: searchValue?.communications ?? [
        defaultCommunication(CommunicationType.Mobile, newGuid())
      ],
      internalProvider: [],
      externalProvider: [],
      relationships: [],
      status: ContactStatus.Active,
      medicare: {},
      entitlements: [],
      nhi: "",
      accountHolders: [],
      employers: [],
      birthDate: searchValue?.birthDate ?? undefined
    };
  }

  return {
    type: ContactType.Patient,
    id: contactDetails.contact.id,
    title: contactDetails.contact.title,
    firstName: contactDetails.contact.firstName,
    middleName: contactDetails.contact.middleName,
    lastName: contactDetails.contact.lastName,
    nickName: contactDetails.contact.nickName,
    birthDate: contactDetails.contact.birthDate?.toJSDate(),
    dateOfDeath: contactDetails.contact.dateOfDeath?.toJSDate(),
    gender: contactDetails.contact.gender,
    sex: contactDetails.contact.sex,
    status: contactDetails.contact.status,
    ethnicities: contactDetails.contact.ethnicities,
    addresses: toAddressFieldValue(
      contactDetails.contact.addresses,
      AddressType.Physical,
      country
    ),
    medicare: contactDetails.contact.medicare,
    healthInsurance: contactDetails.contact.healthInsurance
      ? {
          ...contactDetails.contact.healthInsurance,
          expiry: DateTime.fromISO(
            contactDetails.contact.healthInsurance.expiry
          )?.toJSDate()
        }
      : undefined,
    dva: contactDetails.contact.dva,
    csc: contactDetails.contact.csc
      ? {
          ...contactDetails.contact.csc,
          startDate: DateTime.fromISO(
            contactDetails.contact.csc.startDate
          )?.toJSDate(),
          expiry: DateTime.fromISO(
            contactDetails.contact.csc.expiry
          )?.toJSDate()
        }
      : undefined,
    entitlements: contactDetails.contact.patientEntitlements,
    nhi: contactDetails.contact.nhi?.number,
    communications: toCommunicationFieldValue({
      communications: contactDetails.contact.communications,
      core,
      communicationComparer
    }),
    relationshipStatus: contactDetails.contact.relationshipStatus,
    interpreterLanguage: !isNewPatientForm
      ? contactDetails.contact?.interpreterLanguage
      : undefined,

    appointmentReminderType: getAppointmentReminderType(
      contactDetails.contactPreferences
    ),
    appointmentReminderValue: getAppointmentReminderValue(
      contactDetails.contactPreferences,
      contactDetails.contact.communications
    ),
    appointmentConfirmationType: getAppointmentConfirmationType(
      contactDetails.contactPreferences
    ),
    appointmentConfirmationValue: getPreferredCommsValue(
      contactDetails.contactPreferences?.apptConfirmPreferences,
      contactDetails.contact.communications
    ),
    invoiceCommunicationType: getInvoiceCommunicationType(
      contactDetails.contactPreferences
    ),
    invoiceCommunicationValue: getPreferredCommsValue(
      contactDetails.contactPreferences?.invoiceCommunicationPreferences,
      contactDetails.contact.communications
    ),
    formNotifyType: getFormNotifyType(contactDetails.contactPreferences),
    formNotifyValue: getPreferredCommsValue(
      contactDetails.contactPreferences?.formNotifyPreferences,
      contactDetails.contact.communications
    ),
    occupation: contactDetails.contact.occupation,
    internalProvider: toInternalProviderFieldValue(
      contactDetails.contact.internalProviders
    ),
    externalProvider: toExternalProviderFieldValue(
      contactDetails.contact.externalProviders
    ),
    relationships: toRelationshipFieldValue(
      contactDetails.contact.relationships,
      practice
    ),
    accountHolders: toAccountHolderFieldValue(
      contactDetails.contact.relationships,
      practice
    ),
    interpreterNeeded: !!contactDetails.contact.interpreterLanguage,
    profilePictureUrl: contactDetails.contact.profilePictureUrl,
    employers: toEmployerFieldValue(
      filterPatientEmployers(contactDetails.contact.relationships)
    ),
    pronounObjective: contactDetails.contact.pronounObjective,
    pronounPossessive: contactDetails.contact.pronounPossessive,
    pronounSubjective: contactDetails.contact.pronounSubjective
  };
};

export const getContactFormValues = (
  contactDetails: Partial<
    Omit<ContactDetails, "patientNotices" | "patientSetting">
  >,
  isAddContact: boolean,
  stores: { core: CoreStore; practice: PracticeStore }
): ContactEditFormValues => {
  const { core, practice } = stores;

  const searchValue = practice.ui.demographicInitialValues;
  const { country } = core.tenantDetails!;

  if (isAddContact || !contactDetails.contact) {
    return {
      status: ContactStatus.Active,
      firstName: searchValue?.firstName,
      lastName: searchValue?.lastName,
      type: ContactType.Individual,
      addresses: [defaultAddress(AddressType.Physical, newGuid(), country!)],
      communications: [
        defaultCommunication(CommunicationType.Mobile, newGuid())
      ],
      relationships: [],
      employers: []
    };
  }

  return {
    id: contactDetails.contact.id,
    type: contactDetails.contact.type,
    firstName: contactDetails.contact.firstName,
    lastName: contactDetails.contact.lastName,
    title: contactDetails.contact.title,
    gender: contactDetails.contact.gender,
    status: contactDetails.contact.status,
    disciplines: contactDetails.contact.disciplines,
    pronounSubjective: contactDetails.contact.pronounSubjective,
    pronounObjective: contactDetails.contact.pronounObjective,
    pronounPossessive: contactDetails.contact.pronounSubjective,
    addresses: toAddressFieldValue(
      contactDetails.contact.addresses,
      AddressType.Physical,
      country
    ),
    communications: toCommunicationFieldValue({
      communications: contactDetails.contact.communications,
      core,
      communicationComparer
    }),
    relationships: toRelationshipFieldValue(
      contactDetails.contact.relationships,
      practice
    ),
    appointmentReminderType: getAppointmentReminderType(
      contactDetails.contactPreferences
    ),
    appointmentReminderValue: getAppointmentReminderValue(
      contactDetails.contactPreferences,
      contactDetails.contact.communications
    ),
    appointmentConfirmationType: getAppointmentConfirmationType(
      contactDetails.contactPreferences
    ),
    appointmentConfirmationValue: getAppointmentConfirmationValue(
      contactDetails.contactPreferences
    ),
    invoiceCommunicationType: getInvoiceCommunicationType(
      contactDetails.contactPreferences
    ),
    invoiceCommunicationValue: getPreferredCommsValue(
      contactDetails.contactPreferences?.invoiceCommunicationPreferences,
      contactDetails.contact.communications
    ),
    formNotifyType: getFormNotifyType(contactDetails.contactPreferences),
    formNotifyValue: getPreferredCommsValue(
      contactDetails.contactPreferences?.formNotifyPreferences,
      contactDetails.contact.communications
    ),
    profilePictureUrl: contactDetails.contact.profilePictureUrl,
    employers: toEmployerFieldValue(
      filterPatientEmployers(contactDetails.contact.relationships)
    )
  };
};

export const languageImportance = (languages: RefData): number => {
  switch (languages.text) {
    case "Arabic":
      return 1;
    case "Cantonese":
      return 2;
    case "Greek":
      return 3;
    case "Hindi":
      return 4;
    case "Italian":
      return 5;
    case "Mandarin":
      return 6;
    case "Punjabi":
      return 7;
    case "Spanish":
      return 8;
    case "Vietnamese":
      return 9;
    default:
      return 100;
  }
};

const getCommTypePreference = (
  commTypeCode: OutboundCommType,
  values: PatientFormValues | ContactEditFormValues
): CommTypePreferencesDto => {
  const getPreferredCommChannelTypeCode = () => {
    switch (commTypeCode) {
      case OutboundCommType.ApptReminder:
        return values.appointmentReminderType;
      case OutboundCommType.ApptConfirmation:
        return values.appointmentConfirmationType;
      case OutboundCommType.Invoice:
        return values.invoiceCommunicationType;
      case OutboundCommType.FormNotify:
        return values.formNotifyType;
      default:
        return values.appointmentReminderType;
    }
  };

  const getAddressValue = (type?: string, value?: number) => {
    const comms =
      type &&
      values.communications.filter(
        x => x.type === OutboundCommChannelMapping[type]
      );
    return comms &&
      typeof value === "number" &&
      value >= 0 &&
      value < comms.length
      ? comms[value]
      : undefined;
  };

  const getPreferredCommAddressValue = () => {
    switch (commTypeCode) {
      case OutboundCommType.ApptReminder:
        return getAddressValue(
          values.appointmentReminderType,
          values.appointmentReminderValue
        );
      case OutboundCommType.ApptConfirmation:
        return getAddressValue(
          values.appointmentConfirmationType,
          values.appointmentConfirmationValue
        );
      case OutboundCommType.Invoice:
        return getAddressValue(
          values.invoiceCommunicationType,
          values.invoiceCommunicationValue
        );
      case OutboundCommType.FormNotify:
        return getAddressValue(values.formNotifyType, values.formNotifyValue);
      default:
        return getAddressValue(
          values.appointmentReminderType,
          values.appointmentReminderValue
        );
    }
  };

  const addressValue = getPreferredCommAddressValue();
  if (getPreferredCommChannelTypeCode() === OPTOUT) {
    return {
      commTypeCode,
      contactHasOptedOut: true
    };
  } else {
    return {
      commTypeCode,
      preferredCommChannelTypeCode: getPreferredCommChannelTypeCode(),
      preferredCommAddressValue: addressValue?.value,
      contactHasOptedOut: false
    };
  }
};

export const getCommTypePreferences = (
  values: PatientFormValues | ContactEditFormValues
): CommTypePreferencesDto[] => {
  const commsPreferences: CommTypePreferencesDto[] = [];
  if (values.appointmentReminderType) {
    commsPreferences.push(
      getCommTypePreference(OutboundCommType.ApptReminder, values)
    );
  }

  if (values.appointmentConfirmationType) {
    commsPreferences.push(
      getCommTypePreference(OutboundCommType.ApptConfirmation, values)
    );
  }

  if (values.invoiceCommunicationType) {
    commsPreferences.push(
      getCommTypePreference(OutboundCommType.Invoice, values)
    );
  }

  if (values.formNotifyType) {
    commsPreferences.push(
      getCommTypePreference(OutboundCommType.FormNotify, values)
    );
  }

  return commsPreferences;
};
