import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  DetailsList,
  FontIcon,
  FontSizes,
  Heading,
  IColumn,
  Link,
  Separator,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ClinicalReminderReason } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";

import { ClinicalReminderPreferenceDialog } from "./ClinicalReminderPreferenceDialog.tsx";
import { nameOfCommPreferences } from "./CommunicationPreferencesForm.tsx";

export interface ClinicalReminderPreferenceFilterBarProps {
  searchText?: string;
  isActive: string[];
}

export const ClinicalReminderPreferenceFields: React.FC = observer(() => {
  const { comms } = useStores();

  const [
    showClinicalReminderPreferenceDialog,
    setShowClinicalReminderPreferenceDialog
  ] = useState<boolean>(false);

  const [selectedClinicalReminder, setSelectedClinicalReminder] = useState<
    ClinicalReminderReason | undefined
  >(undefined);

  const columns: IColumn[] = [
    {
      name: "Reason",
      key: "reason",
      minWidth: 100,
      onRender: (clinicalReminderReason: ClinicalReminderReason) => {
        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Link
              onClick={() => {
                setShowClinicalReminderPreferenceDialog(true);
                setSelectedClinicalReminder(clinicalReminderReason);
              }}
            >
              {`${clinicalReminderReason.reason} `}
            </Link>
            {clinicalReminderReason.clinicallySignificant && (
              <TextBadge
                badgeColor={TextBadgeColor.blue}
                badgeSize={TextBadgeSize.small}
              >
                <FontIcon
                  iconName="UserOptional"
                  styles={{
                    root: {
                      fontSize: FontSizes.size16,

                      verticalAlign: "middle"
                    }
                  }}
                />
              </TextBadge>
            )}
          </Stack>
        );
      }
    },
    {
      name: "Default Interval",
      key: "defaultInterval",
      minWidth: 120,
      onRender: clinicalReminderReason => {
        if (
          !clinicalReminderReason.interval &&
          !clinicalReminderReason.frequency
        ) {
          return "";
        }

        return (
          <Text>{`${clinicalReminderReason.interval ?? ""} ${
            clinicalReminderReason.frequency ?? ""
          }`}</Text>
        );
      }
    },
    {
      name: "Status",
      key: "defaultInterval",
      minWidth: 120,
      onRender: clinicalReminderReason => {
        return (
          <Text>{clinicalReminderReason.isActive ? "Active" : "Inactive"}</Text>
        );
      }
    }
  ];

  // Filter for items
  const filterItems = (
    filterProps: ClinicalReminderPreferenceFilterBarProps
  ) => {
    let items = Array.from(
      comms.clinicalReminderPreference?.clinicalReminderReasons ?? []
    );

    items.sort((a: ClinicalReminderReason, b: ClinicalReminderReason) => {
      if (a.isActive && !b.isActive) {
        return -1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else {
        return 0;
      }
    });

    const searchText = filterProps.searchText;

    if (searchText) {
      items = items.filter(x =>
        x.reason.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    }

    if (filterProps.isActive && filterProps.isActive.length > 0) {
      items = items.filter(x =>
        filterProps.isActive.includes(x.isActive ? "Active" : "Inactive")
      );
    }

    return items;
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <Separator styles={{ root: { marginBottom: 24 } }} />
        <Heading variant="section-heading-light">Clinical reminders</Heading>
        <CheckboxField
          label="Allow free-text reminders"
          name={nameOfCommPreferences("allowReminderFreeText")}
        />

        <FilterBar<ClinicalReminderPreferenceFilterBarProps>
          initialValues={{ isActive: ["Active"], searchText: "" }}
          items={[
            {
              name: "searchText",
              stickItem: true,
              type: "searchBox",
              props: {
                ...dataAttribute(DataAttributes.Element, "search-box"),
                placeholder: "Search reason",
                styles: {
                  root: {
                    width: 400
                  }
                }
              }
            },
            {
              type: "optionsSelect",
              name: "isActive",
              props: {
                placeholder: "Status",
                label: "Status",
                options: [
                  { key: "Active", text: "Active" },
                  { key: "Inactive", text: "Inactive" }
                ],
                hideSearchOption: true,
                calloutWidth: 130,
                multiSelect: true
              }
            }
          ]}
        >
          {filtered => {
            return (
              <DetailsList
                styles={{
                  contentWrapper: {
                    maxHeight: "40vh",
                    overflowX: "hidden"
                  }
                }}
                items={filterItems(filtered.values)}
                columns={columns}
                cellStyleProps={{
                  cellLeftPadding: 0,
                  cellRightPadding: 7,
                  cellExtraRightPadding: 0
                }}
              />
            );
          }}
        </FilterBar>

        <ActionButton
          iconProps={{ iconName: "Add" }}
          title="Add another"
          onClick={() => {
            setShowClinicalReminderPreferenceDialog(true);
          }}
          styles={{ root: { width: 130 } }}
        >
          Add another
        </ActionButton>
      </Stack>
      {showClinicalReminderPreferenceDialog && (
        <ClinicalReminderPreferenceDialog
          selectedClinicalReminder={selectedClinicalReminder}
          onDismiss={() => {
            setShowClinicalReminderPreferenceDialog(false);
            setSelectedClinicalReminder(undefined);
          }}
        />
      )}
    </>
  );
});
