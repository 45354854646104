import { IStackItemStyles, ITheme } from "@bps/fluent-ui";

export const getReportScreenStylesSet = (theme: ITheme) => {
  const noReportStyle: IStackItemStyles = {
    root: {
      width: "100%",
      marginTop: 48,
      alignContent: "center",
      justifyContent: "center",
      color: theme.palette.neutralSecondary,
      fontStyle: "italic"
    }
  };

  const groupList = {
    headingRender: {
      headerCount: { display: "none" },
      title: { fontSize: 14, paddingLeft: 0 },
      expand: { width: 48, height: 43 }
    }
  };

  const reportButton = {
    root: {
      width: "100%",
      borderWidth: 0,
      textAlign: "left",
      fontSize: 12,
      height: 43
    },
    label: {
      fontWeight: "400",
      lineHeight: 16
    },
    flexContainer: {
      justifyContent: "left"
    }
  };

  const reportButtonNone = {
    root: {
      textAlign: "left",
      fontSize: 12,
      height: 43,
      fontStyle: "italic",
      paddingLeft: 60,
      justifyContent: "center"
    }
  };

  const fullSize = {
    root: {
      width: "100%",
      height: "100%"
    }
  };

  const mainHeading = { root: { marginBottom: 21 } };
  const reportHeading = { root: { marginBottom: 21, fontSize: 20 } };
  const listTile = {
    root: {
      width: 218,
      padding: 0,
      marginRight: 16,
      height: "100%",
      overflowY: "auto"
    }
  };

  return {
    noReportStyle,
    groupList,
    reportButton,
    mainHeading,
    reportHeading,
    listTile,
    fullSize,
    reportButtonNone
  };
};
