import { FormApi } from "final-form";
import { FC } from "react";
import { FormSpy, useForm } from "react-final-form";

import {
  focusElement,
  FontIcon,
  IPersonaProps,
  mergeStyleSets,
  MessageBar,
  Stack
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ContactType,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { DemographicProfilePictureField } from "@modules/practice/screens/contact-details/shared-components/edit/DemographicProfilePictureField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";
import { ContactPickerField } from "@ui-components/pickers/contact-picker/ContactPickerField.tsx";

import { OrganisationCardIds } from "../../../shared-components/types/organisation-card-ids.enum.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import { OrganisationLabels } from "../organisation.types.ts";
import {
  EditOrganisationFormValues,
  orgEditFormNameOf
} from "./EditOrganisationFormValues.tsx";

export interface EditOrganisationHeaderProps {
  organisation: Contact | undefined;
}

const EditOrganisationHeaderBase: FC<EditOrganisationHeaderProps> = ({
  organisation
}) => {
  const { practice, core } = useStores();
  const {
    ui: { selectedEditCard }
  } = practice;

  const getLinkOrganizationData = (
    form: FormApi<EditOrganisationFormValues>,
    suggestionItem?: IPersonaProps,
    contact?: Contact
  ) => {
    if (!suggestionItem || !contact) {
      return null;
    }

    const { organisationCategories, name } = contact;

    form.batch(() => {
      if (!form.getState().values.organisationCategories?.length) {
        form.change(
          orgEditFormNameOf("organisationCategories"),
          organisationCategories
        );
      }
      if (!form.getState().values.organisationName) {
        form.change(orgEditFormNameOf("organisationName"), name);
      }
    });

    return suggestionItem;
  };

  const { change } = useForm<EditOrganisationFormValues>();

  const headerIsVisible =
    selectedEditCard || selectedEditCard === PatientCardIds.patientHeader;

  const nameInputRef = headerIsVisible ? focusElement : undefined;

  return (
    <Stack
      id={`${OrganisationCardIds.OrganisationHeader}-edit`}
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          paddingRight: 16,
          paddingTop: 21
        }
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="start">
        <FormSpy<EditOrganisationFormValues>>
          {({ values, form }) => {
            return (
              <>
                <DemographicProfilePictureField
                  onRenderNoImageInitials={(props, defaultRendering) => {
                    if (!organisation && !values.organisationName)
                      return (
                        <FontIcon
                          styles={{ root: { fontSize: 58, fontWeight: 100 } }}
                          iconName="EMI"
                        />
                      );

                    return defaultRendering!(props);
                  }}
                  styles={({ theme }) => ({
                    container: {
                      "& .ms-Persona-initials": {
                        backgroundColor:
                          !organisation && !values.organisationName
                            ? theme.palette.neutralSecondaryAlt
                            : undefined
                      }
                    }
                  })}
                  contact={organisation}
                  imageInitials={values.organisationName?.[0]}
                />

                <Stack
                  grow
                  verticalAlign="center"
                  tokens={{ childrenGap: 8 }}
                  verticalFill
                >
                  <ContactPickerField
                    name={orgEditFormNameOf("linkOrganizationId")}
                    label={OrganisationLabels.LinkedRelatedOrganisation}
                    required={false}
                    filter={{
                      types: [ContactType.Organisation]
                    }}
                    resultFilterPredicate={result =>
                      result.id !== form.getState().values.id
                    }
                    onItemSelected={(suggestionItem, item) =>
                      getLinkOrganizationData(form, suggestionItem, item)
                    }
                    placeholder={OrganisationLabels.SelectOrganisation}
                    iconName="Search"
                    styles={mergeStyleSets({
                      input: { width: 395 }
                    })}
                  />
                  {values.linkOrganizationId && (
                    <ContactFetcher contactId={values.linkOrganizationId}>
                      {contact => {
                        const linkRelationships = contact.relationships.filter(
                          x => x.relationship === RelationshipType.PartOf
                        );

                        const relationshipLength =
                          linkRelationships.findIndex(
                            a => a.relatedContactId === values.id
                          ) < 0
                            ? linkRelationships.length + 1
                            : linkRelationships.length;
                        return (
                          relationshipLength > 0 && (
                            <MessageBar
                              className="org-header-message-bar"
                              styles={({ theme }) => ({
                                root: {
                                  backgroundColor:
                                    theme.palette.neutralLighterAlt,
                                  maxWidth: "565px"
                                }
                              })}
                            >
                              {`${contact.name} has ${relationshipLength}
                                  linked or related organisations`}
                            </MessageBar>
                          )
                        );
                      }}
                    </ContactFetcher>
                  )}
                </Stack>
              </>
            );
          }}
        </FormSpy>
      </Stack>
      <Stack
        styles={{
          root: {
            display: "grid",
            columnGap: "8px",
            gridAutoFlow: "column",
            gridTemplateColumns: "1fr 1fr"
          }
        }}
      >
        <TextInputField
          name={orgEditFormNameOf("organisationName")}
          label="Name"
          required={true}
          componentRef={nameInputRef}
        />
        <StaticTagPickerField
          fetchDataSource={() =>
            practice.ref.organisationCategories.keyNameValues
          }
          excludeSelectedItems
          label="Categories"
          name={orgEditFormNameOf("organisationCategories")}
          styles={{
            text: { flexGrow: 0 }
          }}
        />
      </Stack>
      {core.hasPermissions(Permission.OrgsRedesignAllowed) && (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <YesNoToggleField
            alignWithInputs
            styles={{
              root: { minWidth: 110 }
            }}
            label={OrganisationLabels.HealthcareOrg}
            name={orgEditFormNameOf("isHealthProvider")}
            placeholder={OrganisationLabels.Email}
          />
          <FieldSpy
            name={orgEditFormNameOf("isHealthProvider")}
            onChange={checked => {
              if (!checked) {
                change("disciplines", []);
              }
            }}
          />
          <FieldCondition
            when={orgEditFormNameOf("isHealthProvider")}
            is={true}
          >
            <StaticTagPickerField
              fetchDataSource={() =>
                practice.ref.contactDisciplines.keyNameValues
              }
              excludeSelectedItems
              label="Disciplines"
              name={orgEditFormNameOf("disciplines")}
              formItemFieldStyles={{ root: { flex: 1 } }}
            />
          </FieldCondition>
        </Stack>
      )}
    </Stack>
  );
};

export const EditOrganisationHeader = withFetch(
  x => [
    x.practice.ref.organisationCategories.load(),
    x.practice.ref.contactDisciplines.load()
  ],
  EditOrganisationHeaderBase
);
