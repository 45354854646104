import { Country } from "@libs/enums/country.enum.ts";
import {
  email,
  maxLength,
  phone,
  predicate,
  required
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { LocationDetailsValues } from "../practice-locations/LocationDetails.types.ts";

export class LocationDetailsValidator extends Validator<LocationDetailsValues> {
  constructor(core: CoreStore) {
    super();
    const requiresCityCondition = (val: any, address: LocationDetailsValues) =>
      !!core.ref.countries.values.find(
        c => c.code === address?.country && !c.isStateRequired
      );

    const requiresStateCondition = (val: any, address: LocationDetailsValues) =>
      !!core.ref.countries.values.find(
        c => c.code === address?.country && c.isStateRequired
      );

    const isNotNewZealandPredicate = () =>
      core.tenantDetails?.country !== Country.NewZealand;

    this.forField("practiceName", [required(), maxLength(100)]);
    this.forField("hpiFacilityId", [maxLength(8)]);
    this.forField("city", [maxLength(60)]);
    this.forField("address1", [required(), maxLength(100)]);
    this.forField("address2", [maxLength(100)]);
    this.forField("suburb", [
      predicate(isNotNewZealandPredicate, required()),
      maxLength(60)
    ]);
    this.forField("postCode", [
      predicate(isNotNewZealandPredicate, required()),
      maxLength(10)
    ]);
    this.forField("country", [required()]);
    this.forField("city", predicate(requiresCityCondition, required()));
    this.forField("state", predicate(requiresStateCondition, required()));
    this.forField("phone", [required(), maxLength(50), phone]);
    this.forField("email", [email()]);
    this.forField("timezone", [required()]);
  }
}
