import { FunctionComponent } from "react";

import { AppointmentCancellationReason } from "@stores/booking/models/AppointmentCancellationReason.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

interface CancelAppointmentFormFieldsProps {
  reasonLabel?: string;
}

export const CancelAppointmentFormFields: FunctionComponent<
  CancelAppointmentFormFieldsProps
> = ({ reasonLabel }) => {
  const loadAppointmentCancellationReasons = async ({
    booking
  }: RootStore): Promise<AppointmentCancellationReason[]> => {
    const appointmentCancellationReasons =
      await booking.getAppointmentCancellationReasons({ isInactive: false });
    return appointmentCancellationReasons;
  };

  const { userExperience } = useStores();

  const captureApptCancellationReason =
    userExperience.orgUnitSettingForLocation?.preferenceSetting
      .captureApptCancellationReason;

  return (
    <DataFetcher<AppointmentCancellationReason[]>
      fetch={loadAppointmentCancellationReasons}
      noExceptionsHandlers
    >
      {(data: AppointmentCancellationReason[] | undefined, loading, error) => {
        const cancellationReasonOptions =
          data?.map(reason => ({
            key: reason.id,
            text: reason.text
          })) ?? [];

        const otherReasonTypeId = cancellationReasonOptions.find(
          option => option.text === "Other"
        )?.key;

        return (
          <>
            <OptionsSelectField
              label={reasonLabel ?? "Reasons for cancellation"}
              options={cancellationReasonOptions}
              name="cancellationReasonId"
              required={captureApptCancellationReason}
              loading={loading}
              errorMessage={error?.message}
            />
            <FieldCondition when="cancellationReasonId" is={otherReasonTypeId}>
              <TextInputField name="cancellationText" required />
            </FieldCondition>
          </>
        );
      }}
    </DataFetcher>
  );
};
