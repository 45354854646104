import { observer } from "mobx-react-lite";

import { HideStack, Stack } from "@bps/fluent-ui";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";

import { BookingCalendarJumpInput } from "./BookingCalendarJumpInput.tsx";
import { BookingCalendarLocationSelect } from "./BookingCalendarLocationSelect.tsx";
import { BookingCalendarScreenCommands } from "./BookingCalendarScreenCommands.tsx";
import { CalendarEventViewSelector } from "./CalendarEventViewSelector.tsx";
import { DayNavigator } from "./DayNavigator.tsx";

export const NAV_CONTAINER_HEIGHT = 44;
export const LARGE_SCREEN_BREAKPOINT = 1610;

export const BookingCalendarNavigation = observer(() => {
  const { hideLeftSide } = useBookingCalendarScreenContext();

  return (
    <Stack
      horizontal
      horizontalAlign={!hideLeftSide ? "space-between" : "end"}
      verticalAlign="center"
      tokens={{ childrenGap: 16 }}
      styles={{
        root: {
          minHeight: NAV_CONTAINER_HEIGHT
        }
      }}
    >
      <HideStack when={hideLeftSide}>
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <BookingCalendarLocationSelect />
          <DayNavigator />
          <BookingCalendarJumpInput />
        </Stack>
      </HideStack>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 24 }}
        grow
        styles={{ root: { overflow: "hidden" } }}
      >
        <CalendarEventViewSelector />
        <BookingCalendarScreenCommands />
      </Stack>
    </Stack>
  );
});
