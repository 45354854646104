import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useState } from "react";
import { useField } from "react-final-form";

import { Spinner, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";
import { DatePicker } from "@ui-components/pickers/DatePicker.tsx";
import { BookableUsersSelect } from "@ui-components/selects/BookableUsersSelect.tsx";

import { SecondColumnWrapper } from "../../SecondColumnWrapper.tsx";
import {
  appointmentFormNameOf,
  GroupApptSlots
} from "../AppointmentForm.types.ts";
import { contentBoxStyles } from "../next-available-fields/NextAvailableFields.styles.ts";
import { ProviderAppointments } from "./ProviderAppointments.tsx";

export const NextGroupAppt: React.FunctionComponent = observer(() => {
  const {
    selectedProviders,
    setProviders,
    isAllProvidersSelected,
    setAllProvidersSelected,
    getUpcomingGroupAppointments
  } = useContext(AppointmentFormContext);

  const {
    input: { value: startDate }
  } = useField<Date>(appointmentFormNameOf("startDate"), {
    subscription: { value: true }
  });

  const {
    input: { value: appointmentType }
  } = useField<string>(appointmentFormNameOf("appointmentTypeId"), {
    subscription: { value: true }
  });

  const [pickerDate, setPickerDate] = useState<Date | undefined>(startDate);
  const [localSelectedProviders, setLocalSelectedProviders] = useState<
    string[]
  >([...selectedProviders]);

  const setProvidersHandler = useDebounce(setProviders);
  const setProvidersHandlerMemo = useCallback(setProvidersHandler, [
    setProvidersHandler
  ]);

  useEffect(() => {
    // debounce bookableUsers selection to reduce number of api calls
    setProvidersHandlerMemo(localSelectedProviders);
  }, [localSelectedProviders, setProvidersHandlerMemo]);

  const today = DateTime.today().toJSDate();

  return (
    <SecondColumnWrapper heading="Appointment details">
      {appointmentType ? (
        <Stack
          styles={{
            root: {
              width: "100%"
            }
          }}
        >
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                paddingBottom: 24
              }
            }}
          >
            <DatePicker
              calendarProps={{ minDate: today }}
              value={pickerDate}
              onChange={setPickerDate}
              textFieldProps={{ label: "Date" }}
            />
            <BookableUsersSelect
              selectedKeys={localSelectedProviders}
              onChangeSelectedKeys={setLocalSelectedProviders}
              multiSelect
              showCountCoin
              hideCountCoinWhenAllSelected
              selectAllButtonText="All providers"
              showAllSelected
              label="Provider"
              required
              styles={{ root: { flexGrow: 1 } }}
              isAllSelected={isAllProvidersSelected}
              onAllSelected={setAllProvidersSelected}
            />
          </Stack>

          <Stack styles={contentBoxStyles} tokens={{ childrenGap: 16 }}>
            {selectedProviders?.length > 0 && pickerDate ? (
              <DataFetcher<GroupApptSlots[]>
                refetchId={
                  selectedProviders.join() + pickerDate + appointmentType
                }
                fallback={<Spinner />}
                fetch={async () => {
                  return await getUpcomingGroupAppointments(
                    pickerDate,
                    selectedProviders,
                    appointmentType
                  );
                }}
              >
                {groupApptSlots => {
                  return (
                    <>
                      {pickerDate &&
                        groupApptSlots.map(item => (
                          <ProviderAppointments
                            key={item.providerId}
                            pickerStartDate={pickerDate}
                            providerAppointments={item}
                          />
                        ))}
                    </>
                  );
                }}
              </DataFetcher>
            ) : (
              <Text
                styles={{
                  root: {
                    fontSize: 14,
                    fontStyle: "italic",
                    padding: "8px",
                    alignSelf: "center",
                    paddingTop: "8px"
                  }
                }}
              >
                Select provider and date to see available times
              </Text>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack
          styles={{
            root: { width: "100%", height: "100%", justifyContent: "center" }
          }}
          tokens={{ childrenGap: 16 }}
        >
          <Text
            id="invalid-duration-or-type"
            styles={{
              root: {
                fontSize: 14,
                fontStyle: "italic",
                padding: "8px",
                alignSelf: "center",
                paddingTop: "8px"
              }
            }}
          >
            Type field required to continue
          </Text>
        </Stack>
      )}
    </SecondColumnWrapper>
  );
});
