import { observer } from "mobx-react-lite";
import { FunctionComponent, Suspense, useEffect, useState } from "react";

import {
  CenteredLargeSpinner,
  Link,
  MessageBar,
  MessageBarType,
  Separator,
  Spinner,
  Stack,
  Tile
} from "@bps/fluent-ui";
import { PermanentClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientNoticesContextProvider } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import { PatientNoticesModel } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { useNewClinicalTheme } from "../../../hooks/useNewClinicalTheme.tsx";
import {
  MEDIUM_TREE_VIEW_WIDTH,
  SMALL_TREE_VIEW_WIDTH
} from "../types/tree-widths.constants.ts";
import { ReverseDischargeDialog } from "./claims/ReverseDischargeDialog.tsx";
import { EncounterDeletedElsewhereDialog } from "./clinical-form/EncounterDeletedElsewhereDialog.tsx";
import { EncounterDiscardDialog } from "./clinical-form/EncounterDiscardDialog.tsx";
import { EncounterExistsPrompt } from "./clinical-form/EncounterExistsPrompt.tsx";
import { EncounterFinalisedElsewhereDialog } from "./clinical-form/EncounterFinalisedElsewhereDialog.tsx";
import { ClinicalRecordNotifications } from "./ClinicalRecordNotifications.tsx";
import { EncounterDetails } from "./EncounterDetails.tsx";
import { EncounterDock } from "./EncounterDock.tsx";
import { EncounterTimer } from "./EncounterTimer.tsx";
import { PatientDemographicsHeader } from "./PatientDemographicsHeader.tsx";
import { PatientRecordContent } from "./PatientRecordContent.tsx";
import { ClinicalSidePanel, EncounterForm } from "./PatientRecordLazy.tsx";
import { QuickAccess } from "./quickAccess/QuickAccess.tsx";

const MAIN_WIDTH = "calc(100% - 466px)";
const LARGE_WIDTH = "calc(50% - 8px)";
const MAX_WIDTH = "calc(100% - 48px)";

const getLeftPanelWidth = (sidePanelSize: TreeViewSize | undefined) => {
  if (sidePanelSize === TreeViewSize.Expanded) return LARGE_WIDTH;
  if (sidePanelSize === TreeViewSize.Default) return MAIN_WIDTH;
  return MAX_WIDTH;
};

const getRightPanelWidth = (sidePanelSize: TreeViewSize | undefined) => {
  if (sidePanelSize === TreeViewSize.Expanded) return LARGE_WIDTH;
  if (sidePanelSize === TreeViewSize.Default) return MEDIUM_TREE_VIEW_WIDTH;
  return SMALL_TREE_VIEW_WIDTH;
};

export const PatientRecordDetails: FunctionComponent = observer(() => {
  const { clinical, core, practice } = useStores();

  const { tabs } = clinical.ui;
  const theme = useNewClinicalTheme();

  const {
    isViewOnly,
    isViewOnlyOrDischarged,
    clinicalRecord,
    patientId,
    isDischargeCompleted
  } = usePatientRecordScreenContext();

  useEffect(() => {
    // Clear patientClinicalTasksPromise to set again
    // when switch btw clinical record tabs.
    return () => {
      clinicalRecord?.patientClinicalTasksPromise.clear();
    };
  }, [clinicalRecord?.patientClinicalTasksPromise]);

  const [hiddenReverseDischargeDialog, setHiddenReverseDischargeDialog] =
    useState<boolean>(true);

  const handleReverseDischargeConfirmed = async (comments?: string) => {
    const businessRole = clinicalRecord?.openEncounter?.businessRole;

    await clinicalRecord.reverseDischarge(businessRole, comments);
    setHiddenReverseDischargeDialog(true);
  };

  if (!clinicalRecord) return null;

  const useNewClincialLayout = core.hasPermissions(
    Permission.UIRedesignAllowed
  );
  let content: JSX.Element | null = null;
  const warning = (
    <>
      <Stack horizontal verticalAlign="center">
        This condition is discharged.
        <Link
          styles={{ root: { paddingLeft: 3, paddingRight: 3 } }}
          onClick={() => setHiddenReverseDischargeDialog(false)}
        >
          Reverse the discharge
        </Link>
        to edit.
      </Stack>
      <ReverseDischargeDialog
        hidden={hiddenReverseDischargeDialog}
        onConfirm={handleReverseDischargeConfirmed}
        onCancel={() => setHiddenReverseDischargeDialog(true)}
      />
    </>
  );

  if (
    tabs.currentPatientRecordTab &&
    tabs.currentPatientRecordTab.activeTabType !==
      PermanentClinicalTab.TodaysNotes
  ) {
    content = <PatientRecordContent clinicalRecord={clinicalRecord} />;
  } else if (isViewOnlyOrDischarged) {
    const encounter = clinicalRecord?.openEncounter;

    if (encounter) {
      content = <EncounterDetails clinicalRecord={clinicalRecord} />;
    }
  } else {
    content = (
      <Suspense fallback={<CenteredLargeSpinner />}>
        <EncounterForm />
      </Suspense>
    );
  }

  const sidePanelSize =
    clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

  const notesArea = () => {
    return (
      <Stack
        styles={{
          root: {
            overflowY: "hidden"
          }
        }}
        grow
      >
        <Tile
          styles={{
            root: { flexGrow: 1, overflowY: "hidden", padding: 16 },
            content: {
              padding: 0,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column"
            }
          }}
        >
          {content}
        </Tile>
        {isDischargeCompleted && !isViewOnly && (
          <MessageBar messageBarType={MessageBarType.warning}>
            {warning}
          </MessageBar>
        )}
      </Stack>
    );
  };

  return (
    <PatientNoticesContextProvider
      value={
        new PatientNoticesModel(practice, {
          patientId,
          withSystemNotices: false
        })
      }
    >
      <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
        <ClinicalRecordNotifications />
        <Stack
          grow
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          styles={{ root: { width: "100%", height: "90%" } }}
        >
          <Stack.Item
            styles={{
              root: {
                minWidth: getLeftPanelWidth(sidePanelSize)
              }
            }}
            grow
          >
            <Stack
              tokens={{ childrenGap: theme.spacing.s1 }}
              styles={{ root: { height: "100%" } }}
              horizontal
            >
              <Stack>
                <When permission={Permission.QuickAccessAllowed}>
                  {!isViewOnly && <QuickAccess />}
                </When>
                <PatientDemographicsHeader
                  topPanel={() => (
                    <When permission={Permission.EncounterTimerAllowed}>
                      {!isViewOnly && (
                        <>
                          <EncounterTimer />
                          <Separator styles={{ root: { padding: 0 } }} />
                        </>
                      )}
                    </When>
                  )}
                />
              </Stack>
              {useNewClincialLayout && (
                <Stack
                  tokens={{ childrenGap: theme.spacing.s1 }}
                  styles={{ root: { maxHeight: "100%", minHeight: "100%" } }}
                  grow
                >
                  <Stack
                    styles={{
                      root: {
                        height: "100%",
                        overflowY: "auto"
                      }
                    }}
                    horizontal
                    tokens={{ childrenGap: theme.spacing.s1 }}
                  >
                    {notesArea()}
                    <Stack.Item
                      styles={{
                        root: {
                          width: getRightPanelWidth(sidePanelSize),
                          minWidth: getRightPanelWidth(sidePanelSize)
                        }
                      }}
                    >
                      <Tile
                        styles={{
                          root: {
                            padding: 0,
                            height: "100%"
                          },
                          content: {
                            flexGrow: 1,
                            flexShrink: 0,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "hidden",
                            overflowX: "auto"
                          }
                        }}
                      >
                        <DataFetcher<UserSetting | undefined>
                          key={core.userId}
                          fetch={({ userExperience }) =>
                            userExperience.getCurrentUserSetting()
                          }
                          fallback={<Spinner />}
                        >
                          {() => {
                            return (
                              <Suspense fallback={<CenteredLargeSpinner />}>
                                <ClinicalSidePanel
                                  clinicalRecord={clinicalRecord}
                                />
                              </Suspense>
                            );
                          }}
                        </DataFetcher>
                      </Tile>
                    </Stack.Item>
                  </Stack>
                  <EncounterDock />
                </Stack>
              )}
              {!useNewClincialLayout && <>{notesArea()}</>}
              <EncounterExistsPrompt />
            </Stack>
          </Stack.Item>

          {!useNewClincialLayout && (
            <Stack.Item
              styles={{
                root: {
                  height: "100%",
                  width: getRightPanelWidth(sidePanelSize),
                  minWidth: getRightPanelWidth(sidePanelSize)
                }
              }}
            >
              <Tile
                styles={{
                  root: {
                    padding: 0,
                    height: "100%"
                  },
                  content: {
                    flexGrow: 1,
                    flexShrink: 0,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "hidden",
                    overflowX: "auto"
                  }
                }}
              >
                <DataFetcher<UserSetting | undefined>
                  key={core.userId}
                  fetch={({ userExperience }) =>
                    userExperience.getCurrentUserSetting()
                  }
                  fallback={<Spinner />}
                >
                  {() => {
                    return (
                      <Suspense fallback={<CenteredLargeSpinner />}>
                        <ClinicalSidePanel clinicalRecord={clinicalRecord} />
                      </Suspense>
                    );
                  }}
                </DataFetcher>
              </Tile>
            </Stack.Item>
          )}
        </Stack>
        {!useNewClincialLayout && (
          <Stack
            horizontal
            tokens={{ childrenGap: 16 }}
            styles={{ root: { width: "100%", display: "flex" } }}
          >
            <EncounterDock />
          </Stack>
        )}

        <EncounterDeletedElsewhereDialog />
        <EncounterDiscardDialog />
        <EncounterFinalisedElsewhereDialog />
      </Stack>
    </PatientNoticesContextProvider>
  );
});
