import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  FontIcon,
  FontSizes,
  IconButton,
  Shimmer,
  Stack,
  StackItem,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { HttpStatusCode } from "@bps/http-client";
import { DateTime, Duration, TIME_FORMATS } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import {
  ConsultEncounterTypes,
  EncounterTimerStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";

export const EncounterTimer: React.FC = observer(() => {
  const { clinical } = useStores();

  const [encounter, setEncounter] = useState<Encounter | null>(null);
  const [loading, setLoading] = useState(true);
  const [timerStart, setTimerStart] = useState(DateTime.now());
  const [timerDuration, setTimerDuration] = useState(Duration.fromMillis(0));
  const [timerState, setTimerState] = useState<EncounterTimerStatus>(
    EncounterTimerStatus.Paused
  );

  const { core } = useStores();

  const isTimerEnabled = !!core.user?.userSetting?.clinicalView?.timerEnabled;

  const theme = useTheme();
  const setTimerLook = (
    status: EncounterTimerStatus,
    milliseconds: number,
    since: DateTime
  ) => {
    switch (status) {
      case EncounterTimerStatus.Paused:
      case EncounterTimerStatus.Stopped:
        setTimerState(status);
        setTimerStart(DateTime.now().minus(Duration.fromMillis(milliseconds)));
        setTimerDuration(Duration.fromMillis(milliseconds));
        break;
      case EncounterTimerStatus.Running:
        setTimerState(status);
        const initialDuration = DateTime.now()
          .diff(since.minus(Duration.fromMillis(milliseconds)))
          .toMillis();
        setTimerStart(since.minus(Duration.fromMillis(milliseconds)));
        setTimerDuration(Duration.fromMillis(initialDuration));
        break;
    }
  };

  useEffect(() => {
    const { hub } = clinical;

    const onEncounterTimerEvent = async (event: EntityEventData<string>) => {
      if (timerState === EncounterTimerStatus.Stopped) return;

      const activeEncounter = clinical.activeRecordEncounterId;

      if (
        event.action === EventAction.Update &&
        activeEncounter &&
        activeEncounter === event.id
      ) {
        const tmr = await clinical.getEncounterTimer(activeEncounter);
        if (tmr && tmr.timerStatus !== timerState) {
          setTimerLook(
            tmr.timerStatus,
            tmr.elapsedTime,
            DateTime.fromISO(tmr.activeStateDateTime)
          );
        }
      }
    };

    // subscribe listener on mount
    hub.onEntityEvent(Entity.EncounterTimer, onEncounterTimerEvent);

    // unsubscribe listener on unmount
    return () => {
      hub.unsubscribe(Entity.EncounterTimer, onEncounterTimerEvent);
    };
  }, [clinical, timerState]);

  useEffect(() => {
    const activeEncounterId = clinical.activeRecordEncounterId;
    setEncounter(null);
    if (activeEncounterId) {
      setLoading(true);
      clinical
        .getEncounter(activeEncounterId)
        .then(enc => {
          setEncounter(enc);
          return clinical
            .getEncounterTimer(enc.id)
            .then(tmr => {
              setTimerLook(
                tmr.timerStatus,
                tmr.elapsedTime,
                DateTime.fromISO(tmr.activeStateDateTime)
              );
            })
            .catch(error => {
              if (error.httpStatusCode === HttpStatusCode.NotFound) {
                // Timer does not exist, create it
                return clinical
                  .callEncounterTimer({
                    id: enc.id,
                    since: DateTime.now().toISO(),
                    timerStatus:
                      enc.type &&
                      ConsultEncounterTypes.includes(enc.type) &&
                      isTimerEnabled
                        ? EncounterTimerStatus.Running
                        : EncounterTimerStatus.Paused
                  })
                  .then(newTmr => {
                    setTimerLook(
                      newTmr.timerStatus,
                      newTmr.elapsedTime ?? 0,
                      DateTime.fromISO(newTmr.since)
                    );
                  });
              }
              return undefined;
            });
        })
        .catch(() => undefined)
        .finally(() => setLoading(false));
    } else {
      setLoading(false); // Ensure loading is set to false when there's no activeEncounterId
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinical.activeRecordEncounterId]);

  useEffect(() => {
    if (encounter && timerState === EncounterTimerStatus.Running) {
      const intervalId = window.setInterval(() => {
        setTimerDuration(
          Duration.fromMillis(DateTime.now().diff(timerStart).toMillis())
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [encounter, timerState, timerStart]);

  const renderContent = () => {
    if (loading) return <Shimmer />;
    if (!encounter) return "No encounter";

    return encounter && encounter.isClosed
      ? "Closed encounter"
      : appointmentInfo;
  };

  const handlePauseResume = () => {
    if (clinical.activeRecordEncounterId) {
      clinical
        .callEncounterTimer({
          id: clinical.activeRecordEncounterId,
          timerStatus:
            timerState === EncounterTimerStatus.Paused
              ? EncounterTimerStatus.Running
              : EncounterTimerStatus.Paused,
          since: DateTime.now().toISO()
        })
        .then(tmr => {
          setTimerLook(
            tmr.timerStatus,
            (tmr.elapsedTime ?? 0) * 1000,
            DateTime.fromISO(tmr.since)
          );
        });
    }
  };

  const formattedTime = timerDuration.toFormat("hh:mm:ss");
  const toolTipText = (status: EncounterTimerStatus) => {
    switch (status) {
      case EncounterTimerStatus.Paused:
        return "Paused. Resume";
      case EncounterTimerStatus.Running:
        return "Pause";
      case EncounterTimerStatus.Stopped:
        return "Stopped";
    }
  };

  const controlIcon = (status: EncounterTimerStatus): string => {
    switch (status) {
      case EncounterTimerStatus.Paused:
        return "MSNVideosSolid";
      case EncounterTimerStatus.Running:
        return "CirclePause";
      case EncounterTimerStatus.Stopped:
        return "";
    }
  };

  const controlText = (status: EncounterTimerStatus): string => {
    const statusTextMap: { [key in EncounterTimerStatus]: string } = {
      [EncounterTimerStatus.Paused]: "",
      [EncounterTimerStatus.Running]: "",
      [EncounterTimerStatus.Stopped]: " (Stopped)"
    };

    return statusTextMap[status];
  };

  const hasAppointment = encounter && encounter.calendarEvent;
  const infoIcon = hasAppointment ? "Calendar" : "BpTimerOff";
  const infoIconColor = hasAppointment
    ? theme.palette.neutralSecondaryAlt
    : theme.palette.neutralDark;

  const infoText = hasAppointment
    ? `Appt. until ${encounter?.calendarEvent?.endDateTime.toFormat(
        TIME_FORMATS.DEFAULT_TIME_FORMAT
      )}`
    : "No appt.";

  const appointmentInfo = encounter && (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <StackItem>
          <FontIcon
            iconName={infoIcon}
            styles={{
              root: {
                color: infoIconColor,
                verticalAlign: "middle",
                lineHeight: "1"
              }
            }}
          />
        </StackItem>
        <StackItem styles={{ root: { fontSize: FontSizes.size12 } }}>
          {infoText}
          {controlText(timerState)}
        </StackItem>
      </Stack>
      <Stack horizontal verticalAlign="center">
        <TooltipHost content={toolTipText(timerState)}>
          <IconButton
            iconProps={{ iconName: controlIcon(timerState) }}
            onClick={() => handlePauseResume()}
            styles={{
              root: {
                color: theme.palette.themePrimary,
                fontSize: FontSizes.size16,
                verticalAlign: "middle",
                lineHeight: "1"
              }
            }}
          />
        </TooltipHost>
        <Text
          styles={{
            root: {
              fontSize: FontSizes.size18,
              color:
                timerState === EncounterTimerStatus.Paused
                  ? theme.palette.neutralSecondaryAlt
                  : theme.palette.neutralPrimary
            }
          }}
        >
          <span> {formattedTime}</span>
        </Text>
      </Stack>
    </Stack>
  );

  return <>{renderContent()}</>;
});
