import { Observer } from "mobx-react-lite";
import { useState } from "react";

import {
  DefaultButton,
  Heading,
  PrimaryButton,
  ScrollablePane,
  Stack
} from "@bps/fluent-ui";
import { DateTime, upsertItem } from "@bps/utils";
import {
  ClinicalActivityClinicalDataDto,
  ClinicalActivityClinicalDataItemDto,
  ClinicalDataType,
  ClinicalTaskClinicalDataDto,
  ClinicalTaskClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalTaskFilterValues } from "@shared-types/clinical/clinical-task-filter-values.type.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { ClinicalTaskCompleteDialog } from "./ClinicalTaskCompleteDialog.tsx";
import { ClinicalTaskDeleteDialog } from "./ClinicalTaskDeleteDialog.tsx";
import { ClinicalTaskFilter } from "./ClinicalTaskFilter.tsx";
import { ClinicalTaskTableWrapper } from "./ClinicalTaskTableWrapper.tsx";
import { ClinicalTaskButtonLabels } from "./types/clinical-task-button-labels.enum.ts";
import {
  findRelatedClinicalActivity,
  findRelatedClinicalDataItem,
  updateActivityClinicalDataBasedOnTask
} from "./utils.ts";

interface ClinicalTaskListComponentProps {
  clinicalRecord: ClinicalRecord;
  compact: boolean;
}

export enum ClinicalTaskListTestElements {
  TileCreateButton = "no-data-tile-link"
}

export const defaultFilters: ClinicalTaskFilterValues = {
  statuses: [
    ClinicalTaskStatus.Today,
    ClinicalTaskStatus.Overdue,
    ClinicalTaskStatus.Upcoming
  ],
  priorities: []
};

export const ClinicalTaskList: React.FunctionComponent<
  ClinicalTaskListComponentProps
> = ({ clinicalRecord }) => {
  const { clinical, core } = useStores();

  const [dialogVisible, setDialogVisible] = useState({
    add: false,
    edit: false,
    delete: false,
    complete: false
  });

  const [filter, setFilter] =
    useState<ClinicalTaskFilterValues>(defaultFilters);

  const [selectedClinicalTasks, setSelected] = useState<ClinicalTask[]>([]);

  const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

  const incompleteSelectedClinicalTasks = () => {
    return selectedClinicalTasks?.filter(x => !x.isCompleted);
  };

  const handleClose = () => {
    clinical.ui.closePatientRecordContentForm(
      clinicalRecord.id,
      ClinicalDataType.ClinicalTask
    );
  };

  const handleCancelDialog = () => {
    setDialogVisible(preState => {
      if (preState.add) return { ...preState, add: false };
      return { ...preState, edit: false };
    });
  };

  const handleCompleteConfirmed = async (notes?: string) => {
    // Update clinical task(s) from ClinicalData
    const clinicalTask = clinicalRecord.clinicalData?.clinicalTask || {
      clinicalTasks: [],
      eTag: undefined
    };

    const clinicalActivity: ClinicalActivityClinicalDataDto = clinicalRecord
      .clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      eTag: undefined
    };

    incompleteSelectedClinicalTasks()?.forEach(task => {
      const item: ClinicalTaskClinicalDataItemDto = {
        id: task.id,
        patientId: task.patientId,
        taskType: task.taskType,
        dueDate: task.dueDate,
        priority: task.priority,
        comment: task.comment,
        deletedComment: task.deletedComment,
        isCompleted: true,
        completionNotes: notes,
        isSystemGenerated: task.isSystemGenerated,
        completedBy: core.userId,
        completedDate: DateTime.now().toISO()
      };
      clinicalTask.clinicalTasks = upsertItem({
        item,
        array: clinicalTask.clinicalTasks ?? [],
        predicate: x => x.id === task.id
      });

      // Find an associated clinical activity, if it exists
      if (
        clinicalRecord.core.hasPermissions([
          Permission.ClinActivityRead,
          Permission.ClinActivityWrite
        ])
      ) {
        const associatedItem = findRelatedClinicalDataItem(
          item,
          clinicalActivity.clinicalActivities
        );
        if (associatedItem) {
          const convertedActivity = updateActivityClinicalDataBasedOnTask(
            item,
            clinical,
            associatedItem
          );

          clinicalActivity.clinicalActivities = upsertItem({
            item: convertedActivity,
            array: clinicalActivity.clinicalActivities ?? [],
            predicate: x => x.id === convertedActivity.id
          });
        }
      }
    });

    //Save the clinical task
    await clinicalRecord.saveClinicalData({
      clinicalTask,
      clinicalActivity
    });

    // Reload clinical tasks
    clinicalRecord.setPatientClinicalTasksPromise();
    clinicalRecord.loadClinicalActivities();

    completeCancel(true);
  };

  const handleDeleteConfirmed = async (
    reasonForDelete: string,
    reasonForDeleteComment?: string
  ) => {
    // Update clinical task(s) from ClinicalData
    const clinicalTask: ClinicalTaskClinicalDataDto = clinicalRecord
      .clinicalData?.clinicalTask || {
      clinicalTasks: [],
      deletedItems: [],
      eTag: undefined
    };

    clinicalTask.clinicalTasks = clinicalTask.clinicalTasks ?? [];
    clinicalTask.deletedItems = clinicalTask.deletedItems ?? [];

    // Get store clinical tasks (source of truth)
    const storeClinicalTasks = await clinical.getPatientClinicalTasks(
      clinicalRecord.id
    );

    incompleteSelectedClinicalTasks()?.forEach(task => {
      // Add the deleted task to DeletedItem list if it exists in the source of truth, otherwise hard deletion on ClinicalData
      if (storeClinicalTasks.some(s => s.id === task.id)) {
        const item: ClinicalTaskClinicalDataItemDto = {
          id: task.id,
          patientId: task.patientId,
          taskType: task.taskType,
          dueDate: task.dueDate,
          priority: task.priority,
          comment: task.comment,
          isCompleted: task.isCompleted,
          completionNotes: task.completionNotes,
          reasonForDelete,
          deletedComment: reasonForDeleteComment,
          isSystemGenerated: task.isSystemGenerated
        };
        clinicalTask.deletedItems?.push(item);
      }
    });

    // Remove the deleted task from the ClinicalTasks
    clinicalTask.clinicalTasks = clinicalTask.clinicalTasks.filter(
      x => !incompleteSelectedClinicalTasks()?.some(s => s.id === x.id)
    );

    // For each of the clinical tasks, find their clinical activity equivilant and determine if they already exist or if they're new.

    // Get store clinical activities (source of truth)
    const storeClinicalActivities = await clinical.getPatientClinicalActivities(
      clinicalRecord.id
    );

    const clinicalActivity: ClinicalActivityClinicalDataDto = clinicalRecord
      .clinicalData?.clinicalActivity || {
      clinicalActivities: [],
      deletedItems: [],
      eTag: undefined
    };

    clinicalActivity.clinicalActivities =
      clinicalActivity.clinicalActivities ?? [];
    clinicalActivity.deletedItems = clinicalActivity.deletedItems ?? [];

    const itemCodesToDelete: string[] = [];

    incompleteSelectedClinicalTasks()?.forEach(task => {
      // Add the deleted task to DeletedItem list if it exists in the source of truth, otherwise hard deletion on ClinicalData
      // Find a clinical activity that should be associated with this.

      const associatedActivity = findRelatedClinicalDataItem(
        task,
        clinicalActivity.clinicalActivities
      );

      if (associatedActivity) {
        itemCodesToDelete.push(associatedActivity.id);
      }

      const associatedActivityFromStore = findRelatedClinicalActivity(
        task,
        storeClinicalActivities
      );
      if (associatedActivityFromStore) {
        const deletedClinicalActivity: ClinicalActivityClinicalDataItemDto = {
          id: associatedActivityFromStore.id,
          patientId: associatedActivityFromStore.patientId,
          activityType: associatedActivityFromStore.activityType,
          descriptionCode: associatedActivityFromStore.descriptionCode,
          isSystemGenerated: associatedActivityFromStore.isSystemGenerated,
          deletedComment: reasonForDeleteComment,
          reasonForDelete
        };

        itemCodesToDelete.push(associatedActivityFromStore.id);
        clinicalActivity.deletedItems?.push(deletedClinicalActivity);
      }
    });

    clinicalActivity.clinicalActivities =
      clinicalActivity.clinicalActivities.filter(
        x => !itemCodesToDelete.find(y => x.id === y)
      );

    //Save the clinical task
    await clinicalRecord.saveClinicalData({
      clinicalTask,
      clinicalActivity
    });

    setSelected([]);

    // Reload clinical tasks
    clinicalRecord.setPatientClinicalTasksPromise();
    clinicalRecord.loadClinicalActivities();
    deleteCancel(true);
  };

  const loadClinicalTaskListItems = async (
    filterSearch: ClinicalTaskFilterValues
  ) => {
    setFilter(filterSearch);
    setSelected([]);
  };

  const deleteCancel = (deselectAll: boolean) => {
    setDialogVisible({ ...dialogVisible, delete: false });
    if (deselectAll) setSelected([]);
  };

  const handleDeleteCancel = () => {
    deleteCancel(false);
  };

  const completeCancel = (deselectAll: boolean) => {
    setDialogVisible({ ...dialogVisible, complete: false });
    if (deselectAll) setSelected([]);
  };

  const handleCompleteCancel = () => {
    completeCancel(false);
  };

  return (
    <>
      <Heading variant="section-heading">
        {ClinicalTaskButtonLabels.Title}
      </Heading>
      <ClinicalTaskFilter
        onSearch={loadClinicalTaskListItems}
        filter={filter}
        clinicalRecord={clinicalRecord}
      />
      <Stack
        styles={{
          root: { position: "relative", flexGrow: 1, height: "100%" }
        }}
      >
        <ScrollablePane>
          <ClinicalTaskTableWrapper
            setSelected={setSelected}
            showLockIcon={true}
            clinicalRecord={clinicalRecord}
            filter={filter}
            compact={false}
            showClinicalTaskDialog={dialogVisible.add || dialogVisible.edit}
            clinicalTaskDialogOnDismiss={handleCancelDialog}
            clinicalTask={
              dialogVisible.edit && selectedClinicalTasks.length
                ? selectedClinicalTasks[0]
                : undefined
            }
          />
        </ScrollablePane>
      </Stack>

      {!isViewOnlyOrDischarged && (
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{
            childrenGap: 8
          }}
          styles={{
            root: {
              paddingTop: 10
            }
          }}
        >
          {/* Track selectedClinicalTasks (from PatientRecordScreenHelper) mutations to disable/enable buttons  */}
          <Observer>
            {() => (
              <>
                <Stack.Item>
                  <When permission={Permission.ClinTaskWrite}>
                    <PrimaryButton
                      disabled={clinical.ui.clinicalTaskActionDisabled}
                      onClick={() =>
                        setDialogVisible({ ...dialogVisible, add: true })
                      }
                      text={ClinicalTaskButtonLabels.AddTask}
                    />
                  </When>
                </Stack.Item>
                <Stack.Item>
                  <When permission={Permission.ClinTaskWrite}>
                    <DefaultButton
                      disabled={
                        (selectedClinicalTasks
                          ? !selectedClinicalTasks.some(x => !x.isCompleted)
                          : true) || clinical.ui.clinicalTaskActionDisabled
                      }
                      onClick={() =>
                        setDialogVisible({ ...dialogVisible, complete: true })
                      }
                      text={ClinicalTaskButtonLabels.Complete}
                    />
                  </When>
                </Stack.Item>
                <Stack.Item>
                  <When permission={Permission.ClinTaskWrite}>
                    <DefaultButton
                      disabled={
                        (selectedClinicalTasks
                          ? selectedClinicalTasks.length !== 1 ||
                            selectedClinicalTasks[0].isCompleted
                          : true) || clinical.ui.clinicalTaskActionDisabled
                      }
                      onClick={() =>
                        setDialogVisible({ ...dialogVisible, edit: true })
                      }
                      text={ClinicalTaskButtonLabels.Edit}
                    />
                  </When>
                </Stack.Item>
                <Stack.Item>
                  <When permission={Permission.ClinTaskDelete}>
                    <DefaultButton
                      disabled={
                        (selectedClinicalTasks
                          ? !selectedClinicalTasks.some(x => !x.isCompleted)
                          : true) || clinical.ui.clinicalTaskActionDisabled
                      }
                      onClick={() =>
                        setDialogVisible({ ...dialogVisible, delete: true })
                      }
                      text={ClinicalTaskButtonLabels.Delete}
                    />
                  </When>
                </Stack.Item>
                <Stack.Item>
                  <DefaultButton
                    onClick={handleClose}
                    text={ClinicalTaskButtonLabels.Close}
                  />
                </Stack.Item>
              </>
            )}
          </Observer>
        </Stack>
      )}
      <ClinicalTaskDeleteDialog
        hidden={!dialogVisible.delete}
        selectedCount={incompleteSelectedClinicalTasks()?.length ?? 0}
        onConfirm={handleDeleteConfirmed}
        onCancel={handleDeleteCancel}
      />
      <ClinicalTaskCompleteDialog
        hidden={!dialogVisible.complete}
        selectedCount={incompleteSelectedClinicalTasks()?.length ?? 0}
        onConfirm={handleCompleteConfirmed}
        onCancel={handleCompleteCancel}
      />
    </>
  );
};
