import { EMPTY_GUID } from "@libs/constants/constants.ts";
import {
  ClinicalActivityClinicalDataItemDto,
  ClinicalActivityMetadataItem,
  ClinicalTaskClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { ClinicalTask } from "@stores/clinical/models/ClinicalTask.ts";

import {
  ClinicalActivityDescriptionCode,
  ClinicalActivityType
} from "../clinical-activity/types/clinical-activity.type.ts";

export const findRelatedClinicalDataItem = (
  clinicalTask: ClinicalTaskClinicalDataItemDto,
  clinicalActivities?: ClinicalActivityClinicalDataItemDto[]
) => {
  const clinicalActivitiesAssociated = clinicalActivities?.filter(
    x =>
      x.patientId === clinicalTask.patientId &&
      x.activityType === ClinicalActivityType.Task &&
      !x.reasonForDelete &&
      x.activityStatus !== ClinicalActivityStatus.Completed &&
      x.dueInVisits === clinicalTask.dueInVisits &&
      x.dueDate === clinicalTask.dueDate &&
      // Check if the description code matches the task type, or if it's other and the free text matches.
      (x.descriptionCode === clinicalTask.taskType ||
        (x.descriptionCode === ClinicalActivityDescriptionCode.Other &&
          x.freeText &&
          x.freeText === clinicalTask.taskType))
  );
  if (clinicalActivitiesAssociated && clinicalActivitiesAssociated.length > 0) {
    return clinicalActivitiesAssociated[0];
  }

  return undefined;
};

export const findRelatedClinicalActivity = (
  clinicalTask: ClinicalTask,
  clinicalActivities?: ClinicalActivity[]
) => {
  const clinicalActivitiesAssociated = clinicalActivities?.filter(
    x =>
      x.patientId === clinicalTask.patientId &&
      x.activityType === ClinicalActivityType.Task &&
      !x.reasonForDelete &&
      x.activityStatus !== ClinicalActivityStatusText.Completed &&
      x.dueInVisits === clinicalTask.dueInVisits &&
      x.dueDate === clinicalTask.dueDate &&
      // Check if the description code matches the task type, or if it's other and the free text matches.
      (x.descriptionCode === clinicalTask.taskType ||
        (x.descriptionCode === ClinicalActivityDescriptionCode.Other &&
          x.freeText &&
          x.freeText === clinicalTask.taskType))
  );
  if (clinicalActivitiesAssociated && clinicalActivitiesAssociated.length > 0) {
    return clinicalActivitiesAssociated[0];
  }

  return undefined;
};

export const updateActivityClinicalDataBasedOnTask = (
  clinicalTask: ClinicalTaskClinicalDataItemDto,
  clinical: ClinicalStore,
  associatedActivity?: ClinicalActivityClinicalDataItemDto
): ClinicalActivityClinicalDataItemDto => {
  const newMetadata: ClinicalActivityMetadataItem[] = [];

  // TEMP - Map the metadata
  if (clinicalTask.claimNumber) {
    newMetadata.push({ key: "ClaimNumber", value: clinicalTask.claimNumber });
  }

  let noMatchingDescription = false;

  const description = matchTaskTypeWithActivityDescription(
    clinicalTask.taskType,
    clinical
  );

  if (!description) {
    noMatchingDescription = true;
  }

  const result: ClinicalActivityClinicalDataItemDto = {
    id: associatedActivity?.id ?? EMPTY_GUID,
    descriptionCode: description ?? ClinicalActivityDescriptionCode.Other,
    activityPriority: clinicalTask.priority,
    activityType: ClinicalActivityType.Task,
    activityStatus: clinicalTask.isCompleted
      ? ClinicalActivityStatus.Completed
      : ClinicalActivityStatus.InProgress,
    patientId: clinicalTask.patientId,
    dueDate: clinicalTask.dueDate,
    dueInVisits: clinicalTask.dueInVisits,
    remainingVisits: clinicalTask.remainingVisits,
    comment: clinicalTask.comment,
    lockedBy: clinicalTask.lockedBy,
    deletedComment: clinicalTask.deletedComment,
    isSystemGenerated: clinicalTask.isSystemGenerated,
    businessRole: clinicalTask.businessRole,
    secGroupId: clinicalTask.secGroupId,
    completedBy: clinicalTask.completedBy,
    completedDate: clinicalTask.completedDate,
    freeText: noMatchingDescription ? clinicalTask.taskType : undefined,
    metadata: newMetadata
  };

  return result;
};

export const matchTaskTypeWithActivityDescription = (
  activityTaskCode: string | undefined,
  clinical: ClinicalStore
) => {
  if (!activityTaskCode) {
    return undefined;
  }

  const descriptions = clinical.ref.clinicalActivityDescriptions.keyTextValues;

  const attemptedMatches = descriptions.filter(x => x.key === activityTaskCode);

  if (attemptedMatches && attemptedMatches.length > 0) {
    return attemptedMatches[0].key;
  } else {
    return undefined;
  }
};
