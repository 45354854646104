export enum Labels {
  conditions = "Conditions",
  dva = "DVA",
  edit = "Edit",
  forms = "Forms",
  people = "People",
  remove = "Remove",
  method = "Method",
  status = "Status",
  billTo = "Bill to",
  profile = "Profile",
  notices = "Notices",
  primary = "Primary",
  account = "Account",
  clear = "Clear",
  addMore = "Add more",
  location = "Location",
  medicare = "Medicare",
  language = "Language",
  billedTo = "Billed to",
  sentForms = "Sent forms",
  employers = "Employers",
  preferred = "Preferred",
  newPerson = "New person",
  primaryJob = "Primary job",
  newPatient = "New patient",
  healthFund = "Health fund",
  reminder = "Appt reminder",
  destination = "Destination",
  interpreter = "Interpreter",
  newProvider = "New provider",
  contactInfo = "Contact info",
  notification = "Notification",
  appointments = "Appointments",
  dateOfDeath = "Date of death",
  personalInfo = "Personal info",
  leadProvider = "Lead provider",
  csc = "Community Services Card",
  relationships = "Relationships",
  relatedPerson = "Related person",
  newIndividual = "New individual",
  noDobRecorded = "No DOB recorded",
  noNhiRecorded = "No NHI recorded",
  noSexRecorded = "No sex recorded",
  noGenderRelatedInformationRecorded = "No gender related information recorded",
  contactMethods = "Contact methods",
  confirmation = "Appt confirmation",
  invoice = "Billing correspondence",
  healthProviders = "Health providers",
  newOrganisation = "New organisation",
  markAsPreferred = "Mark as preferred",
  moreAppointments = "More appointments",
  organisationInfo = "Organisation info",
  preferredContact = "Preferred contact",
  internalProvider = "Internal provider",
  externalProvider = "External provider",
  organisationType = "Organisation type",
  accountHolderFor = "Account holder for",
  internalProviders = "Internal providers",
  externalProviders = "External providers",
  relationshipStatus = "Relationship status",
  cardsEntitlements = "Cards & entitlements",
  occupation = "Your job title or occupation",
  healthProviderCard = "Health provider card",
  noCategoryRecorded = "No category recorded",
  appointmentReminder = "Appointment reminder",
  relatedOrganisation = "Related organisation",
  noEthnicityRecorded = "No ethnicity recorded",
  accContracts = "ACC provider contract details",
  appointmentConfirmation = "Appointment confirmation",
  communicationPreferences = "Communication preferences",
  commsPreferences = "Comms preferences",
  addingAccountHolderFromExistingRelationships = "Adding an account holder from existing relationships? ",
  emailFormLabel = "Send 'Personal details' form to this email & set it as preferred for forms.",
  smsFormLabel = "Send 'Personal details' form to this mobile number & set it as preferred for forms."
}
