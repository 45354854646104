import { useContext, useEffect, useRef } from "react";
import { Field, useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  IDropdown,
  IExtendedBasePicker,
  IFocusZone,
  ITag,
  ITextField,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { EncounterType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { TimePickerField } from "@ui-components/form/TimePickerField.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ConsultDetailDialogValues } from "./ConsultDetailDialog.tsx";
import { nameOf } from "./EncounterFormFields.tsx";
import { EncounterFormContext } from "./models/EncounterFormContext.ts";
import { ReasonForVisitPickerField } from "./ReasonForVisitPickerField.tsx";
import { getTimePickerProps } from "./utils.ts";

/**
 * Enum for setting which field gets focussed when the dialog is opened.
 */
export enum AutoFocusElement {
  Date = 1,
  Time = 2,
  Via = 3,
  Location = 4,
  Reason = 5
}

export const encounterTypes = [
  {
    key: EncounterType.Consultation,
    linkText: "in person consult",
    text: "In person consult"
  },
  {
    key: EncounterType.PhoneConsultation,
    linkText: "phone consult",
    text: "Phone"
  },
  {
    key: EncounterType.VideoConsultation,
    linkText: "video consult",
    text: "Video"
  }
];

export const RFV_CD_TYPE_CODE_OTHER = "0.Other";

export const ConsultDetailDialogFields: React.FC = () => {
  const {
    consultDetailDialogVisibleWithFocus,
    clinicalRecord,
    isEnding,
    isFinalising,
    isRecordUpdate
  } = useContext(EncounterFormContext);

  const { clinical, core, booking } = useStores();
  const { input: startDateInput } = useField(nameOf("startDate"));
  const form = useForm<ConsultDetailDialogValues>();
  const userId = core.userId;
  const fetchUsersOrgUnits = async () => {
    return await booking.getUserAvailability(userId);
  };

  const reasonForVisits =
    clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits;

  const timePickerProps = getTimePickerProps(startDateInput.value);

  const datePickerField = useRef<ITextField>(null);
  const timePickerField = useRef<ITextField>(null);
  const viaChoiceField = useRef<IFocusZone>(null);
  const locationField = useRef<IDropdown>(null);
  const reasonPickerField = useRef<IExtendedBasePicker<ITag>>();

  // This focusses the correct field when the dialog is opened by clicking a navigation link in the today's notes header.
  useEffect(() => {
    switch (consultDetailDialogVisibleWithFocus) {
      case AutoFocusElement.Date:
        datePickerField.current?.focus();
        break;
      case AutoFocusElement.Time:
        timePickerField.current?.focus();
        break;
      case AutoFocusElement.Via:
        viaChoiceField.current?.focus();
        break;
      case AutoFocusElement.Location:
        locationField.current?.focus();
        break;
      case AutoFocusElement.Reason:
        reasonPickerField.current?.focus();
        break;
    }
  }, [consultDetailDialogVisibleWithFocus]);

  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      styles={{ root: { overflow: "hidden" } }}
    >
      <When permission={Permission.ConfidentialDataAllowed}>
        <YesNoToggleField
          {...dataAttribute(DataAttributes.Element, "confidential")}
          label="Confidential"
          styles={{ root: { marginBottom: 0 } }}
          name={nameOf("confidential")}
        />
      </When>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <DatePickerField
          label="Date"
          textFieldComponentRef={datePickerField}
          name={nameOf("startDate")}
          maxDate={DateTime.jsDateNow()}
          styles={{
            field: { minWidth: 100 }
          }}
        />
        <TimePickerField
          label="Time"
          componentRef={timePickerField}
          suggestionInterval={15}
          max={timePickerProps.maxTime}
          hideSuggestions={timePickerProps.isStartDateAfterToday}
          name={nameOf("startTime")}
          styles={{
            subComponentStyles: {
              textField: { root: { width: 100 } }
            }
          }}
        />
      </Stack>

      {!isRecordUpdate && (
        <>
          <ButtonsGroupSingleChoiceField
            label="Via"
            componentRef={viaChoiceField}
            name={nameOf("type")}
            options={encounterTypes}
          />
          <FieldCondition
            when={nameOf("type")}
            is={(val: string) => {
              return val === EncounterType.Consultation;
            }}
          >
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack styles={{ root: { flex: 1 } }}>
                <DropdownField
                  label="At"
                  componentRef={locationField}
                  name={nameOf("location")}
                  options={clinical.ref.encounterLocations.keyTextValues}
                  placeholder="--Select Location--"
                />
              </Stack>
              {core.hasMultiLocationOrgUnit && (
                <DataFetcher fetch={() => fetchUsersOrgUnits()}>
                  {userOrgUnits => (
                    <LocationsSelectField
                      label="Location"
                      required
                      showIcon={false}
                      hideSearchOption
                      name={nameOf("practiceLocationId")}
                      hideClearButton={true}
                      showAllSelected={false}
                      showDefaultLocationBadge
                      placeholder="Select location"
                      fieldItemStyle={{ root: { flex: 1 } }}
                      styles={{ root: { flex: 1 } }}
                      onFilter={options =>
                        options.filter(
                          option =>
                            (userOrgUnits &&
                              userOrgUnits.availableOrgUnitIds.includes(
                                option.key
                              )) ||
                            option.data?.isDefault
                        )
                      }
                    />
                  )}
                </DataFetcher>
              )}
            </Stack>
          </FieldCondition>
          <FieldSpy
            name={nameOf("type")}
            onChange={() => {
              form.change(nameOf("location"), undefined);
            }}
          />
        </>
      )}

      <ReasonForVisitPickerField
        label="Reason"
        required={isEnding || isFinalising}
        validateOnInitialize={isEnding || isFinalising}
        componentRef={refObject => {
          if (refObject) {
            reasonPickerField.current = refObject;
          }
        }}
        codedFields={reasonForVisits?.map(p => {
          return {
            code: `${p.code}.${p.originalText}`,
            text: p.originalText
          };
        })}
        name={nameOf("reasonForVisit")}
      />
      <Field name={nameOf("reasonForVisit")}>
        {({ input }) =>
          input.value.includes(RFV_CD_TYPE_CODE_OTHER) && (
            <TextInputField
              name={nameOf("otherText")}
              placeholder="Insert other reason for consult"
              maxLength={100}
            />
          )
        }
      </Field>
    </Stack>
  );
};
