import { Sex } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

import backFootUrl from "./assets/backFoot.svg";
import bottomFootUrl from "./assets/bottomFoot.svg";
import centralNervousSystemCranial from "./assets/centralNervousSystemCranial.svg";
import dermatomesFaceUrl from "./assets/dermatomeFace.svg";
import dermatomesBackUrl from "./assets/dermatomesBack.svg";
import dermatomesFrontUrl from "./assets/dermatomesFront.svg";
import dermatomesSideUrl from "./assets/dermatomeSide.svg";
import dermatomesSpineUrl from "./assets/dermatomeSpine.svg";
import eyeLeftUrl from "./assets/eyeleft.svg";
import eyeRightUrl from "./assets/eyeright.svg";
import femaleAbdomenUrl from "./assets/femaleAbdomen.svg";
import femaleBackUrl from "./assets/femaleback.svg";
import femaleBackHipUrl from "./assets/femaleBackHip.svg";
import femaleBackKneeUrl from "./assets/femaleBackKnee.svg";
import femaleFrontUrl from "./assets/femalefront.svg";
import femaleFrontHipUrl from "./assets/femaleFrontHip.svg";
import femaleFrontKneeUrl from "./assets/femaleFrontKnee.svg";
import femaleLeftElbowUrl from "./assets/femaleLeftElbow.svg";
import femalLeftShldrBackUrl from "./assets/femaleLeftShoulderBack.svg";
import femalLeftShldrFrontUrl from "./assets/femaleLeftShoulderFront.svg";
import femalLeftShldrSideUrl from "./assets/femaleLeftShoulderSide.svg";
import femaleLeftSideUrl from "./assets/femaleleftside.svg";
import femaleLeftSideHipUrl from "./assets/femaleLeftSideHip.svg";
import femaleLeftSideKneeUrl from "./assets/femaleLeftSideKnee.svg";
import femaleRightElbowUrl from "./assets/femaleRightElbow.svg";
import femalRightShldrBackUrl from "./assets/femaleRightShoulderBack.svg";
import femalRightShldrFrontUrl from "./assets/femaleRightShoulderFront.svg";
import femalRightShldrSideUrl from "./assets/femaleRightShoulderSide.svg";
import femaleRightSideUrl from "./assets/femalerightside.svg";
import femaleRightSideHipUrl from "./assets/femaleRightSideHip.svg";
import femaleRightSideKneeUrl from "./assets/femaleRightSideKnee.svg";
import FrontFootUrl from "./assets/frontFoot.svg";
import handsWristsUrl from "./assets/handsWrists.svg";
import leftInsideFootUrl from "./assets/leftInsideFoot.svg";
import leftOutsideFootUrl from "./assets/leftOutsideFoot.svg";
import maleAbdomenUrl from "./assets/maleAbdomen.svg";
import maleBackUrl from "./assets/maleback.svg";
import maleBackHipUrl from "./assets/maleBackHip.svg";
import maleBackKneeUrl from "./assets/maleBackKnee.svg";
import maleFrontUrl from "./assets/malefront.svg";
import maleFrontHipUrl from "./assets/maleFrontHip.svg";
import maleFrontKneeUrl from "./assets/maleFrontKnee.svg";
import maleLeftElbowUrl from "./assets/maleLeftElbow.svg";
import maleLeftShldrBackUrl from "./assets/maleLeftShoulderBack.svg";
import maleLeftShldrFrontUrl from "./assets/maleLeftShoulderFront.svg";
import maleLeftShldrSideUrl from "./assets/maleLeftShoulderSide.svg";
import maleLeftSideUrl from "./assets/maleleftside.svg";
import maleLeftSideHipUrl from "./assets/maleLeftSideHip.svg";
import maleLeftSideKneeUrl from "./assets/maleLeftSideKnee.svg";
import maleRightElbowUrl from "./assets/maleRightElbow.svg";
import maleRightShldrBackUrl from "./assets/maleRightShoulderBack.svg";
import maleRightShldrFrontUrl from "./assets/maleRightShoulderFront.svg";
import maleRightShldrSideUrl from "./assets/maleRightShoulderSide.svg";
import maleRightSideUrl from "./assets/malerightside.svg";
import maleRightSideHipUrl from "./assets/maleRightSideHip.svg";
import maleRightSideKneeUrl from "./assets/maleRightSideKnee.svg";
import nBLeftElbowUrl from "./assets/nBLeftElbow.svg";
import nBLeftShldrBackUrl from "./assets/nBLeftShoulderBack.svg";
import nBLeftShldrFrontUrl from "./assets/nBLeftShoulderFront.svg";
import nBLeftShldrSideUrl from "./assets/nBLeftShoulderSide.svg";
import nBRightElbowUrl from "./assets/nBRightElbow.svg";
import nBRightShldrBackUrl from "./assets/nBRightShoulderBack.svg";
import nBRightShldrFrontUrl from "./assets/nBRightShoulderFront.svg";
import nBRightShldrSideUrl from "./assets/nBRightShoulderSide.svg";
import rightInsideFootUrl from "./assets/rightInsideFoot.svg";
import rightOutsideFootUrl from "./assets/rightOutsideFoot.svg";
import spinalCordBackUrl from "./assets/spinalCordBack.svg";
import spinalCordFrontUrl from "./assets/spinalCordFront.svg";
import spinalCordSideUrl from "./assets/spinalCordSide.svg";
import topFootUrl from "./assets/topFoot.svg";
import {
  BackgroundImage,
  BackgroundImageGroup
} from "./DrawingCanvas.types.ts";

export enum BackgroundImageTypes {
  LeftEye = "LeftEyeDiagram",
  RightEye = "RightEyeDiagram",
  MaleFront = "MaleFrontDiagram",
  MaleBack = "MaleBackDiagram",
  FemaleFront = "FemaleFrontDiagram",
  FemaleBack = "FemaleBackDiagram",
  FemaleLeftSide = "FemaleLeftSideDiagram",
  MaleLeftSide = "MaleLeftSideDiagram",
  FemaleRightSide = "FemaleRightSideDiagram",
  MaleRightSide = "MaleRightSideDiagram",
  SpinalCordFront = "SpinalCordFrontDiagram",
  SpinalCordSide = "SpinalCordSideDiagram",
  SpinalCordBack = "SpinalCordBackDiagram",
  HandsWrists = "HandsWristsDiagram",
  MaleFrontKnee = "MaleFrontKneeDiagram",
  MaleBackKnee = "MaleBackKneeDiagram",
  MaleLeftSideKnee = "MaleLeftSideKneeDiagram",
  MaleRightSideKnee = "MaleRightSideKneeDiagram",
  FemaleFrontKnee = "FemaleFrontKneeDiagram",
  FemaleBackKnee = "FemaleBackKneeDiagram",
  FemaleLeftSideKnee = "FemaleLeftSideKneeDiagram",
  FemaleRightSideKnee = "FemaleRightSideKneeDiagram",
  FootBack = "BackFootDiagram",
  FootFront = "FrontFootDiagram",
  FootBottom = "BottomFootDiagram",
  FootTop = "TopFootDiagram",
  FootInsideLeft = "LeftInsideFootDiagram",
  FootInsideRight = "RightInsideFootDiagram",
  FootOutsideLeft = "LeftOutsideFootDiagram",
  FootOutsideRight = "RightOutsideFootDiagram",
  MaleFrontHip = "MaleFrontHipDiagram",
  MaleBackHip = "MaleBackHipDiagram",
  MaleLeftSideHip = "MaleLeftSideHipDiagram",
  MaleRightSideHip = "MaleRightSideHipDiagram",
  FemaleFrontHip = "FemaleFrontHipDiagram",
  FemaleBackHip = "FemaleBackHipDiagram",
  FemaleLeftSideHip = "FemaleLeftSideHipDiagram",
  FemaleRightSideHip = "FemaleRightSideHipDiagram",

  NBRightShldrSide = "NonBinaryRightShoulderSideDiagram",
  NBRightShldrFront = "NonBinaryRightShoulderFrontDiagram",
  NBRightShldrBack = "NonBinaryRightShoulderBackDiagram",
  NBLeftShldrSide = "NonBinaryLeftShoulderSideDiagram",
  NBLeftShldrFront = "NonBinaryLeftShoulderFrontDiagram",
  NBLeftShldrBack = "NonBinaryLeftShoulderBackDiagram",

  MaleRightShldrSide = "MaleRightShoulderSideDiagram",
  MaleRightShldrFront = "MaleRightShoulderFrontDiagram",
  MaleRightShldrBack = "MaleRightShoulderBackDiagram",
  MaleLeftShldrSide = "MaleLeftShoulderSideDiagram",
  MaleLeftShldrFront = "MaleLeftShoulderFrontDiagram",
  MaleLeftShldrBack = "MaleLeftShoulderBackDiagram",

  FemaleRightShldrSide = "FemaleRightShoulderSideDiagram",
  FemaleRightShldrFront = "FemaleRightShoulderFrontDiagram",
  FemaleRightShldrBack = "FemaleRightShoulderBackDiagram",
  FemaleLeftShldrSide = "FemaleLeftShoulderSideDiagram",
  FemaleLeftShldrFront = "FemaleLeftShoulderFrontDiagram",
  FemaleLeftShldrBack = "FemaleLeftShoulderBackDiagram",

  MaleLeftElbow = "MaleLeftElbowDiagram",
  MaleRightElbow = "MaleRightElbowDiagram",

  FemaleLeftElbow = "FemaleLeftElbowDiagram",
  FemaleRightElbow = "FemaleRightElbowDiagram",

  NBLeftElbow = "NonBinaryLeftElbowDiagram",
  NBRightElbow = "NonBinaryRightElbowDiagram",

  FemaleAbdomen = "FemaleAbdomenDiagram",
  MaleAbdomen = "MaleAbdomenDiagram",

  DermatomesSpine = "DermatomesSpine",
  DermatomesBack = "DermatomesBack",
  DermatomesFront = "DermatomesFront",
  DermatomesFace = "DermatomesFace",
  DermatomesSide = "DermatomesSide",

  CentralNervousSystemCranial = "CentralNervousSystemCranial"
}

const commonName = {
  fullBody: {
    front: "Body front",
    back: "Body back",
    left: "Body left",
    right: "Body right"
  },
  Knee: {
    front: "Knee front",
    back: "Knee back",
    left: "Knee left",
    right: "Knee right"
  }
} as const;

/**
 * Sample background images.
 * To be removed after the list is served by the backend.
 */
export const backgroundImages: BackgroundImage[] = [
  {
    type: BackgroundImageTypes.LeftEye,
    commonName: "Eye left",
    src: eyeLeftUrl,
    width: 430,
    height: 253,
    group: BackgroundImageGroup.Eye,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.RightEye,
    commonName: "Eye right",
    src: eyeRightUrl,
    width: 430,
    height: 253,
    group: BackgroundImageGroup.Eye,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleFront,
    commonName: commonName.fullBody.front,
    src: maleFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleBack,
    commonName: commonName.fullBody.back,
    src: maleBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleLeftSide,
    commonName: commonName.fullBody.left,
    src: maleLeftSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Male,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.MaleRightSide,
    commonName: commonName.fullBody.right,
    src: maleRightSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Male,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.FemaleFront,
    commonName: commonName.fullBody.front,
    src: femaleFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleBack,
    commonName: commonName.fullBody.back,
    src: femaleBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Female,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleLeftSide,
    commonName: commonName.fullBody.left,
    src: femaleLeftSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Female,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.FemaleRightSide,
    commonName: commonName.fullBody.right,
    src: femaleRightSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.FullBody,
    sex: Sex.Female,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.SpinalCordFront,
    commonName: "Spinal cord front",
    src: spinalCordFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Spine,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.SpinalCordSide,
    commonName: "Spinal cord side",
    src: spinalCordSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Spine,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.SpinalCordBack,
    commonName: "Spinal cord back",
    src: spinalCordBackUrl,
    width: 486,
    height: 700,
    group: BackgroundImageGroup.Spine,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.HandsWrists,
    commonName: "Hands wrists",
    src: handsWristsUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Wrist,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleFrontKnee,
    commonName: "Female front knee",
    src: femaleFrontKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleBackKnee,
    commonName: "Female back knee",
    src: femaleBackKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Female,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleLeftSideKnee,
    commonName: "Female left side knee",
    src: femaleLeftSideKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Female,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.FemaleRightSideKnee,
    commonName: "Female right side knee",
    src: femaleRightSideKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Female,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.MaleFrontKnee,
    commonName: "Male front knee",
    src: maleFrontKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Male,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.MaleBackKnee,
    commonName: "Male back knee",
    src: maleBackKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Male,
    displayOrder: 1
  },

  {
    type: BackgroundImageTypes.MaleLeftSideKnee,
    commonName: "Male left side knee",
    src: maleLeftSideKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Male,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.MaleRightSideKnee,
    commonName: "Male right side knee",
    src: maleRightSideKneeUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Knee,
    sex: Sex.Male,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.FootFront,
    commonName: "Front foot",
    src: FrontFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FootBack,
    commonName: "Back foot",
    src: backFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FootBottom,
    commonName: "Bottom foot",
    src: bottomFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.FootTop,
    commonName: "Top foot",
    src: topFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.FootInsideLeft,
    commonName: "Left inside foot",
    src: leftInsideFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 4
  },
  {
    type: BackgroundImageTypes.FootInsideRight,
    commonName: "Right inside foot",
    src: rightInsideFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 5
  },
  {
    type: BackgroundImageTypes.FootOutsideLeft,
    commonName: "Left outside foot",
    src: leftOutsideFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 6
  },
  {
    type: BackgroundImageTypes.FootOutsideRight,
    commonName: "Right outside foot",
    src: rightOutsideFootUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Foot,
    displayOrder: 7
  },
  {
    type: BackgroundImageTypes.FemaleFrontHip,
    commonName: "Female front hip",
    src: femaleFrontHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleBackHip,
    commonName: "Female back hip",
    src: femaleBackHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Female,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleLeftSideHip,
    commonName: "Female left side hip",
    src: femaleLeftSideHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Female,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.FemaleRightSideHip,
    commonName: "Female right side hip",
    src: femaleRightSideHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Female,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.MaleFrontHip,
    commonName: "Male front hip",
    src: maleFrontHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Male,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.MaleBackHip,
    commonName: "Male back hip",
    src: maleBackHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleLeftSideHip,
    commonName: "Male left side hip",
    src: maleLeftSideHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Male,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.MaleRightSideHip,
    commonName: "Male right side hip",
    src: maleRightSideHipUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Hip,
    sex: Sex.Male,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.FemaleLeftShldrSide,
    commonName: "Female left shoulder side",
    src: femalLeftShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleLeftShldrFront,
    commonName: "Female left shoulder front",
    src: femalLeftShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleLeftShldrBack,
    commonName: "Female left shoulder back",
    src: femalLeftShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.FemaleRightShldrSide,
    commonName: "Female right shoulder side",
    src: femalRightShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.FemaleRightShldrFront,
    commonName: "Female right shoulder front",
    src: femalRightShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 4
  },
  {
    type: BackgroundImageTypes.FemaleRightShldrBack,
    commonName: "Female right shoulder back",
    src: femalRightShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Female,
    displayOrder: 5
  },
  {
    type: BackgroundImageTypes.MaleLeftShldrSide,
    commonName: "Male left shoulder side",
    src: maleLeftShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.MaleLeftShldrFront,
    commonName: "Male left shoulder front",
    src: maleLeftShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleLeftShldrBack,
    commonName: "Male left shoulder back",
    src: maleLeftShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.MaleRightShldrSide,
    commonName: "Male right shoulder side",
    src: maleRightShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.MaleRightShldrFront,
    commonName: "Male right shoulder front",
    src: maleRightShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 4
  },
  {
    type: BackgroundImageTypes.MaleRightShldrBack,
    commonName: "Male right shoulder back",
    src: maleRightShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Male,
    displayOrder: 5
  },
  {
    type: BackgroundImageTypes.NBLeftShldrSide,
    commonName: "Non-Binary left shoulder side",
    src: nBLeftShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.NBLeftShldrFront,
    commonName: "Non-Binary left shoulder front",
    src: nBLeftShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.NBLeftShldrBack,
    commonName: "Non-Binary left shoulder back",
    src: nBLeftShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 2
  },
  {
    type: BackgroundImageTypes.NBRightShldrSide,
    commonName: "Non-Binary right shoulder side",
    src: nBRightShldrSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.NBRightShldrFront,
    commonName: "Non-Binary right shoulder front",
    src: nBRightShldrFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 4
  },
  {
    type: BackgroundImageTypes.NBRightShldrBack,
    commonName: "Non-Binary right shoulder back",
    src: nBRightShldrBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Shoulder,
    sex: Sex.Other,
    displayOrder: 5
  },
  {
    type: BackgroundImageTypes.MaleLeftElbow,
    commonName: "Male left elbow",
    src: maleLeftElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Male,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.MaleRightElbow,
    commonName: "Male right elbow",
    src: maleRightElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleLeftElbow,
    commonName: "Female left elbow",
    src: femaleLeftElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.FemaleRightElbow,
    commonName: "Female right elbow",
    src: femaleRightElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Female,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.NBLeftElbow,
    commonName: "Non-Binary left elbow",
    src: nBLeftElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Other,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.NBRightElbow,
    commonName: "Non-Binary right elbow",
    src: nBRightElbowUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Elbow,
    sex: Sex.Other,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.MaleAbdomen,
    commonName: "Male abdomen",
    src: maleAbdomenUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Abdomen,
    sex: Sex.Male,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.FemaleAbdomen,
    commonName: "Female abdomen",
    src: femaleAbdomenUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Abdomen,
    sex: Sex.Female,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.DermatomesSpine,
    commonName: "Dermatomes spine",
    src: dermatomesSpineUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Dermatomes,
    sex: Sex.Other,
    displayOrder: 3
  },
  {
    type: BackgroundImageTypes.DermatomesBack,
    commonName: "Dermatomes back",
    src: dermatomesBackUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Dermatomes,
    sex: Sex.Other,
    displayOrder: 1
  },
  {
    type: BackgroundImageTypes.DermatomesFront,
    commonName: "Dermatomes front",
    src: dermatomesFrontUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Dermatomes,
    sex: Sex.Other,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.DermatomesFace,
    commonName: "Dermatomes face",
    src: dermatomesFaceUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Dermatomes,
    sex: Sex.Other,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.DermatomesSide,
    commonName: "Dermatomes side",
    src: dermatomesSideUrl,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.Dermatomes,
    sex: Sex.Other,
    displayOrder: 0
  },
  {
    type: BackgroundImageTypes.CentralNervousSystemCranial,
    commonName: "Cranial nerves",
    src: centralNervousSystemCranial,
    width: 486,
    height: 554,
    group: BackgroundImageGroup.CentralNervousSystem,
    sex: Sex.Other,
    displayOrder: 0
  }
];

export const WHITE = "#ffffff";
export const BLACK = "#000000";
const RED = "#B80000";
const YELLOW = "#FCCB00";
const GREEN = "#008B02";
const LIGHTBLUE = "#1273DE";
const PURPLE = "#5300EB";

export const colorPalette = [
  { id: BLACK, label: "black", color: BLACK },
  { id: "#605E5C", label: "grey", color: "#605E5C" },
  { id: "#B1AFAD", label: "light grey", color: "#B1AFAD" },
  { id: WHITE, label: "white", color: WHITE },
  { id: RED, label: "red", color: RED },
  { id: "#DB3E00", label: "orange", color: "#DB3E00" },
  { id: YELLOW, label: "yellow", color: YELLOW },
  { id: GREEN, label: "green", color: GREEN },
  { id: "#006B76", label: "teal", color: "#006B76" },
  { id: LIGHTBLUE, label: "light blue", color: LIGHTBLUE },
  { id: "#004DCF", label: "dark blue", color: "#004DCF" },
  { id: PURPLE, label: "purple", color: PURPLE }
];

export const quickColourList = [
  { name: "Pain", color: RED },
  { name: "Burning/hot", color: YELLOW },
  { name: "Cramp/aching", color: GREEN },
  { name: "Pins & needles", color: LIGHTBLUE },
  { name: "Numbness", color: PURPLE }
];
