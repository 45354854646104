import React from "react";
import { useFormState } from "react-final-form";

import { FontIcon, mergeStyles, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, dayOfWeekName } from "@bps/utils";
import { Frequency } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { toRecurrenceOptions } from "@libs/utils/calendar/calendar.utils.ts";
import { CompoundButtonWithError } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/CompoundButtonWithError.tsx";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { RecurringAppointmentConflicts } from "./RecurringAppointmentConflicts.tsx";

export const RepeatDetailsButton: React.FunctionComponent = () => {
  const { booking } = useStores();

  const theme = useTheme();
  const {
    values: {
      repeat,
      interval,
      frequency,
      dayRecur,
      monthYearRecurrence,
      endScheduleType,
      until,
      count,
      recurrenceOccurred,
      startTime,
      startDate,
      providerId
    }
  } = useFormState<AppointmentFormValues>({ subscription: { values: true } });

  const onClick = () => {
    booking.ui.setSecondColumnContent(
      booking.ui.currentAppointment?.secondColumnContent !==
        SecondColumnContent.repeat
        ? SecondColumnContent.repeat
        : undefined
    );
  };

  const getIntervalText = () => {
    switch (interval) {
      case 1:
        return `every ${getFrequencyText()}`;
      default:
        return `every ${interval!.toString()} ${getFrequencyText()}`;
    }
  };

  const getDays = () => {
    if (dayRecur) {
      const days = dayRecur?.map(dayOfWeekName);
      return days.join(", ");
    } else return "";
  };

  const getFrequencyText = () => {
    switch (frequency) {
      case 5:
        return interval && interval > 1 ? "weeks on" : "";

      case 6:
        return interval && interval > 1 ? "months on the" : "month on the";

      case 7:
        return interval && interval > 1 ? "years on the" : "year on the";
      default:
        return "";
    }
  };

  const showHideRecurringConflicts =
    startDate &&
    startTime &&
    providerId &&
    interval &&
    frequency &&
    ((dayRecur && dayRecur.length > 0) || monthYearRecurrence);

  const getEndSchedduleTypeText = () => {
    switch (endScheduleType) {
      case "after":
        return count
          ? `- ${recurrenceOccurred ?? 1} of ${count} occurrences`
          : "";
      case "ondate":
        return until
          ? `until ${DateTime.fromJSDate(until).toFormat(
              DATE_FORMATS.DAY_TEXT_MONTH_YEAR
            )}`
          : "";
      default:
        return "";
    }
  };

  const getButtonText = () => {
    if (!startDate || !startTime || !providerId) {
      return "Select provider, date, and time to set up repeat";
    } else if (
      interval &&
      frequency &&
      ((dayRecur && dayRecur.length > 0) || monthYearRecurrence)
    ) {
      return getNaturalText();
    } else {
      return "Set up repeat appointment";
    }
  };

  const getNaturalText = () => {
    let naturalText: string = "";
    if (frequency === 5) {
      naturalText = `Repeats ${getIntervalText()} ${getDays()} ${getEndSchedduleTypeText()} `;
    } else if (frequency === 6) {
      const recurrenceOptions = toRecurrenceOptions(
        DateTime.fromJSDate(startDate!),
        Frequency.Month
      );

      const textMonth = recurrenceOptions.find(
        x => x.key === monthYearRecurrence
      );
      naturalText = `Repeats ${getIntervalText()} ${
        monthYearRecurrence ? textMonth?.text : ""
      } ${getEndSchedduleTypeText()} `;
    } else if (frequency === 7) {
      const recoptions = toRecurrenceOptions(
        DateTime.fromJSDate(startDate!),
        Frequency.Year
      );

      const textYear = recoptions.find(x => x.key === monthYearRecurrence);

      naturalText = `Repeats ${getIntervalText()} ${
        monthYearRecurrence ? textYear?.text : ""
      } ${getEndSchedduleTypeText()} `;
    }
    return naturalText;
  };

  const showAsterix = () => {
    if (frequency === 5 && !(dayRecur && dayRecur.length > 0)) return true;
    else if (frequency === 6 && !monthYearRecurrence) return true;
    else return false;
  };

  const showHideDisabledColorText =
    !startDate || !startTime || !providerId
      ? theme.semanticColors.disabledText
      : theme.palette.themePrimary;

  return (
    <CompoundButtonWithError<AppointmentFormValues>
      fields={[
        "frequency",
        "dayRecur",
        "monthYearRecurrence",
        "count",
        "until"
      ]}
      errorMessage="Required fields remain in this section"
      id="appt-form-repeat-button"
      onClick={onClick}
      disabled={!(startDate && startTime && providerId && repeat)}
      styles={{
        root: {
          minHeight: 0,
          maxWidth: "100%",
          margin: 0,
          backgroundColor:
            booking.ui.currentAppointment?.secondColumnContent ===
            SecondColumnContent.repeat
              ? theme.palette.neutralLight
              : undefined
        }
      }}
    >
      <Stack
        verticalFill
        grow
        horizontalAlign="baseline"
        verticalAlign="baseline"
      >
        <Stack
          horizontal
          verticalAlign="center"
          styles={{ root: { width: "100%" } }}
          tokens={{ childrenGap: 8 }}
        >
          <Stack.Item shrink>
            <FontIcon
              className={mergeStyles({
                color: showHideDisabledColorText,
                fontSize: "14px"
              })}
              iconName="History"
            />
          </Stack.Item>
          <Stack.Item grow styles={{ root: { textAlign: "start" } }}>
            <Text
              styles={{
                root: {
                  color:
                    !startDate || !startTime || !providerId
                      ? theme.semanticColors.disabledText
                      : theme.palette.neutralPrimary
                }
              }}
            >
              {getButtonText()}
            </Text>
            {showAsterix() && (
              <Text
                styles={{
                  root: {
                    paddingLeft: 4,
                    color: theme.semanticColors.errorText
                  }
                }}
              >
                *
              </Text>
            )}
          </Stack.Item>
          <Stack.Item>
            <FontIcon
              iconName="ChevronRight"
              className={mergeStyles({
                color: showHideDisabledColorText
              })}
            />
          </Stack.Item>
        </Stack>

        {showHideRecurringConflicts && (
          <Stack.Item
            grow
            styles={{ root: { width: "100%", textAlign: "start" } }}
          >
            <RecurringAppointmentConflicts
              hideInitialStateRrule={true}
              isDetailedConflictVisible={false}
            />
          </Stack.Item>
        )}
      </Stack>
    </CompoundButtonWithError>
  );
};
