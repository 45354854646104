import { useContext } from "react";
import { Field, useField } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  FontIcon,
  FontSizes,
  FontWeights,
  mergeStyles,
  noWrap,
  PersonaSize,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { CompoundButtonWithError } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/CompoundButtonWithError.tsx";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Persona } from "@ui-components/persona/Persona.tsx";
import { LocationOption } from "@ui-components/selects/LocationOption.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { appointmentFormNameOf } from "../AppointmentForm.types.ts";
import { DEFAULT_LOCATION_COLOUR } from "../next-available-fields/ProviderTimeSlots.tsx";
import { formatTimeSpanWithDate } from "../utils.ts";

interface AppointmentDetailsButtonProps {
  disabled?: boolean;
  onClick: () => void;
}
export const AppointmentDetailsButton: React.FC<
  AppointmentDetailsButtonProps
> = ({ disabled, onClick }) => {
  const { core } = useStores();
  const theme = useTheme();
  const { getEndDateTime, getBrowserStartDateTime } = useContext(
    AppointmentFormContext
  );

  const {
    input: { value: duration }
  } = useField(appointmentFormNameOf("duration"), {
    subscription: { value: true }
  });

  const {
    input: { value: startDate }
  } = useField(appointmentFormNameOf("startDate"), {
    subscription: { value: true }
  });

  const {
    input: { value: orgUnitId }
  } = useField(appointmentFormNameOf("orgUnitId"), {
    subscription: { value: true }
  });

  const renderBrowserTime = (
    locationStartTime: string,
    duration: number | string
  ) => {
    const browserStartTime =
      startDate && getBrowserStartDateTime(startDate, locationStartTime);

    const browserEndTime =
      browserStartTime && getEndDateTime(browserStartTime, Number(duration));

    return (
      browserStartTime &&
      browserEndTime &&
      `${browserStartTime.toFormat(
        TIME_FORMATS.DEFAULT_TIME_FORMAT
      )} - ${browserEndTime.toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}`
    );
  };

  return (
    <CompoundButtonWithError<AppointmentFormValues>
      {...dataAttribute(
        DataAttributes.Element,
        "appt-form-provider-info-button"
      )}
      fields={["startTime", "startDate", "providerId", "orgUnitId"]}
      errorMessage="Required fields remain in this section"
      onClick={onClick}
      styles={{ root: { maxWidth: "100%", margin: 0 } }}
      disabled={disabled}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        grow
        styles={{
          root: noWrap
        }}
      >
        <Field name={appointmentFormNameOf("providerId")}>
          {({ input: { value } }) => (
            <DataFetcher<User | undefined>
              key={value}
              fetch={async ({ core }) =>
                value ? await core.getUser(value) : undefined
              }
              fallback={<Spinner />}
            >
              {user => (
                <Persona
                  id={user?.id}
                  onRenderPrimaryText={() => (
                    <Text
                      {...dataAttribute(
                        DataAttributes.Element,
                        "appt-form-provider-name"
                      )}
                      styles={{ root: { fontWeight: FontWeights.semibold } }}
                    >
                      {user?.name ?? "No provider selected"}
                    </Text>
                  )}
                  onRenderSecondaryText={() => {
                    return (
                      <Stack
                        styles={
                          core.hasMultiLocationOrgUnit
                            ? { root: { minHeight: 58 } }
                            : undefined
                        }
                      >
                        <Text id="appt-form-provider-start-date">
                          {startDate
                            ? DateTime.fromJSDate(startDate).toFormat(
                                DATE_FORMATS.DAY_TEXT_MONTH_YEAR
                              )
                            : "No date selected"}
                        </Text>

                        <Field name={appointmentFormNameOf("startTime")}>
                          {({ input: { value } }) => {
                            return (
                              <Text id="appt-form-provider-time">
                                {value && duration
                                  ? `${formatTimeSpanWithDate(value, {
                                      date: startDate
                                    })} - ${formatTimeSpanWithDate(value, {
                                      date: startDate,
                                      duration: Number(duration)
                                    })}`
                                  : "No time selected"}
                              </Text>
                            );
                          }}
                        </Field>
                        <Field name={appointmentFormNameOf("orgUnitId")}>
                          {({ input: { value } }) =>
                            value && orgUnitId ? (
                              <DataFetcher<PracOrgUnit | undefined>
                                refetchId={orgUnitId}
                                fetch={({ practice }) =>
                                  practice.getOrgUnit(value)
                                }
                              >
                                {location =>
                                  core.hasMultiLocationOrgUnit &&
                                  location?.orgUnitLocationData?.nickName ? (
                                    <LocationOption
                                      color={
                                        location?.orgUnitLocationData
                                          ?.appointmentBookMarkerCode ??
                                        DEFAULT_LOCATION_COLOUR
                                      }
                                      text={
                                        location?.orgUnitLocationData?.nickName
                                      }
                                    />
                                  ) : (
                                    <Text>
                                      {core.getLocationName(orgUnitId) ?? ""}
                                    </Text>
                                  )
                                }
                              </DataFetcher>
                            ) : (
                              <Text id="appt-form-location">
                                No location selected
                              </Text>
                            )
                          }
                        </Field>
                        <When permission={Permission.PreRelease}>
                          {!core.practiceAndUserHaveSameTimezone &&
                            value &&
                            duration && (
                              <Field name={nameof("startTime")}>
                                {({ input: { value } }) => (
                                  <Text
                                    id="appt-form-provider-browser-time"
                                    styles={{
                                      root: {
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "70%",
                                        color: theme.palette.themePrimary,
                                        top: 25,
                                        left: 0
                                      }
                                    }}
                                  >
                                    <FontIcon
                                      iconName="System"
                                      styles={{ root: { marginRight: 5 } }}
                                    />
                                    {renderBrowserTime(value, duration)}
                                  </Text>
                                )}
                              </Field>
                            )}
                        </When>
                      </Stack>
                    );
                  }}
                  size={PersonaSize.size56}
                  styles={{
                    primaryText: { fontSize: FontSizes.medium },
                    root: { height: "auto" }
                  }}
                  onRenderInitials={() =>
                    user ? (
                      <>{user.initials}</>
                    ) : (
                      <FontIcon iconName="Medical" />
                    )
                  }
                />
              )}
            </DataFetcher>
          )}
        </Field>

        <FontIcon
          iconName="ChevronRight"
          className={mergeStyles({ color: theme.palette.themePrimary })}
        />
      </Stack>
    </CompoundButtonWithError>
  );
};
