import { observer } from "mobx-react-lite";

import { DefaultButton, IButtonProps, Stack } from "@bps/fluent-ui";
import { closeInvoiceOrPaymentPage } from "@modules/billing/screens/invoice/components/utils.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  PromiseObservableButton,
  PromiseObservableButtonProps
} from "@ui-components/PromiseObservableButton/PromiseObservableButton.tsx";

export interface CreditNoteViewButtonsProps {
  creditNote: CreditNote;
}

export const CreditNoteViewButtons: React.FC<CreditNoteViewButtonsProps> =
  observer(({ creditNote }) => {
    const { routing, billing } = useStores();

    const close = () => {
      closeInvoiceOrPaymentPage(routing, "replace");
    };

    const closeButtonProps: IButtonProps = {
      text: "Close",
      onClick: close
    };

    const primaryButtonProps: PromiseObservableButtonProps = {
      disabled: !creditNote.unallocated,
      primary: true,
      text: "Refund",
      onClick: async () => {
        await billing.refundUnallocatedCredit(creditNote);
        closeInvoiceOrPaymentPage(routing, "replace");
      }
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <PromiseObservableButton {...primaryButtonProps} />
        <DefaultButton {...closeButtonProps} />
      </Stack>
    );
  });
