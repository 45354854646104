import { useForm } from "react-final-form";

import {
  CSSTransition,
  fadeEnter,
  fadeEnterActive,
  fadeExit,
  fadeExitActive,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { formatByPattern } from "@libs/utils/format-by-pattern.utils.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { parseAsDigitsOnly } from "../../../shared-components/utils.ts";
import { PatientEntitlementsLabels } from "../../patient/view/PatientEntitlements.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

type EntitlementsFieldsProps = {
  addCSC: () => void;
};

export const EntitlementsNZFields: React.FC<EntitlementsFieldsProps> = ({
  addCSC
}) => {
  const { getState } = useForm<PatientEditFormValues>();
  const {
    values: { csc: cscValue }
  } = getState();

  const csc = patientFormNameOf("csc");
  const cscFields = (
    key: keyof { number: string; startDate: DateTime; expiry: DateTime }
  ) => (csc ? `${csc}.${key}` : key);

  const cscFormatter = formatByPattern("12345-6789-1234-567");

  return (
    <Stack>
      {cscValue ? (
        <Stack tokens={{ childrenGap: 10 }}>
          <CSSTransition
            in={!!cscValue}
            timeout={300}
            classNames={{
              enter: fadeEnter,
              enterActive: fadeEnterActive,
              exit: fadeExit,
              exitActive: fadeExitActive
            }}
            mountOnEnter={false}
            unmountOnExit
          >
            <Fieldset
              legend={Labels.csc}
              horizontal
              horizontalAlign="start"
              tokens={{ childrenGap: 8 }}
            >
              <TextInputField
                placeholder={PatientEntitlementsLabels.CardNumber}
                autoComplete="cc-csc"
                name={cscFields("number")}
                format={val => (val ? cscFormatter(val.toString()) : "")}
                parse={val =>
                  val
                    ? parseAsDigitsOnly(cscFormatter(val.toString()))
                    : undefined
                }
                styles={{ root: { width: 160 } }}
              />
              <DatePickerField
                name={cscFields("startDate")}
                placeholder={PatientEntitlementsLabels.StartDate}
              />
              <DatePickerField
                name={cscFields("expiry")}
                placeholder={PatientEntitlementsLabels.Expiry}
              />
            </Fieldset>
          </CSSTransition>
        </Stack>
      ) : (
        <NoDataTile
          textProps={{ text: "No cards or entitlements" }}
          linkProps={{
            text: "Add a Community Services Card",
            onClick: () => addCSC()
          }}
        />
      )}
    </Stack>
  );
};
