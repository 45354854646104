import { useContext } from "react";

import {
  FontIcon,
  IContextualMenuItem,
  SplitButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  AppointmentStatusCode,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";

export const CopyThisAppointmentButton = () => {
  const { calendarEvent } = useContext(BookingCalendarEventContext);
  const { booking } = useStores();
  const theme = useTheme();

  const rebook = (weeks: number) => {
    booking.ui.showCalendarEventDialog({
      type: CalendarEventType.Appointment,
      secondColumnContent: SecondColumnContent.nextAvailable,
      initialValues: {
        startDate: calendarEvent.startDateTime.plus({ weeks }).toJSDate(),
        startTime: calendarEvent.startTime.toTimeInputFormat(),
        appointmentTypeId: calendarEvent.appointmentTypeId,
        patientId: calendarEvent.contactId,
        episodeOfCareId: calendarEvent.reason?.episodeOfCareId ?? undefined,
        providerId: calendarEvent?.userId
      }
    });
  };

  const items: IContextualMenuItem[] = [
    { key: "2", text: "Fortnight", onClick: () => rebook(2) },
    { key: "3", text: "3 weeks", onClick: () => rebook(3) },
    { key: "4", text: "4 weeks", onClick: () => rebook(4) },
    { key: "6", text: "6 weeks", onClick: () => rebook(6) }
  ];

  const rebookText =
    calendarEvent.appointmentStatus === AppointmentStatusCode.DidNotAttend
      ? "Reschedule in a week"
      : "Copy this in a week";

  return (
    <Stack verticalAlign="end">
      <SplitButton
        borderless
        items={items}
        onClick={() => rebook(1)}
        styles={{
          root: { paddingLeft: 8, paddingRight: 8 }
        }}
      >
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
          <FontIcon
            iconName="AddEvent"
            styles={{ root: { color: theme.semanticColors.link } }}
          />
          <span>{rebookText}</span>
        </Stack>
      </SplitButton>
    </Stack>
  );
};
