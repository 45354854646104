import { FormApi, ValidationErrors } from "final-form";
import { action, observable } from "mobx";
import { parse, stringify } from "query-string";

import { DateTime, isDefined } from "@bps/utils";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  AddInvoiceDto,
  BillType,
  InvoiceItemDto,
  PaymentStatuses,
  ServiceRuleType,
  ServiceSearchDto,
  TransactionPatientDto,
  UpsertInvoiceItemNewDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { mapContactToTransactionContact } from "@modules/billing/billing.utils.ts";
import { goToDrafts as routeToDrafts } from "@modules/billing/screens/draft-items/hooks/useDraftRoute.ts";
import { InvoiceFormSubmitButtonClicked } from "@modules/billing/screens/invoice/components/InvoiceForm.types.tsx";
import {
  closeInvoiceOrPaymentPage,
  getAddInvoiceDto,
  getAdjustInvoiceDto,
  getEmptyInvoiceItemFormValue,
  getInvoiceFormValuesFromDraftItems,
  getInvoiceFormValuesFromInvoice,
  mapServicesToInvoiceItemFormValues
} from "@modules/billing/screens/invoice/components/utils.ts";
import { ServiceWarningsHelper } from "@modules/billing/screens/shared-components/add-service-modal/context/ServiceWarningsHelper.ts";
import { InvoiceDetailsModalFormValues } from "@modules/billing/screens/shared-components/types/invoice-details-modal-values.type.ts";
import { InvoiceItemFormValue } from "@modules/billing/screens/shared-components/types/invoice-item-form-value.interface.ts";
import { InvoiceFormValues } from "@modules/billing/screens/shared-components/types/invoice-values.interface.ts";
import { InvoiceDetailsModalFormValidator } from "@modules/billing/screens/validators/InvoiceDetailsModalFormValidator.ts";
import { InvoiceFormValidator } from "@modules/billing/screens/validators/InvoiceFormValidator.ts";
import { getAddressFormValueFromContact } from "@modules/practice/screens/shared-components/utils/contact.utils.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { changeFormValues } from "@ui-components/form/FinalForm.tsx";
import { FormApiWithMutators } from "@ui-components/form/submission-form/SubmissionForm.types.ts";

interface SubmittedInvoices {
  invoices?: Invoice[];
  draftItems?: InvoiceItemDto[];
}

interface InvoiceAddDtos {
  invoice?: AddInvoiceDto;
  draftItems?: UpsertInvoiceItemNewDto[];
}

export enum InvoiceNumberSuffix {
  A = "A",
  B = "B"
}

export class InvoiceFormHelper {
  constructor(private root: RootStore) {
    this.serviceWarningsHelper = new ServiceWarningsHelper(this.root);
  }

  @observable public isInvoiceEmailDialogVisible: boolean = false;

  @action public setInvoiceEmailDialogVisible = (value: boolean) => {
    this.isInvoiceEmailDialogVisible = value;
  };

  @observable
  public isAddItemDialogVisible = false;

  @action
  public setIsAddItemDialogVisible = (value: boolean) => {
    this.isAddItemDialogVisible = value;
  };

  @observable
  public isConfirmationDialogVisible = false;

  @action
  public setIsConfirmationDialogVisible = (value: boolean) => {
    this.isConfirmationDialogVisible = value;
  };

  @observable
  public isInvoiceDetailsModalVisible = false;

  @action
  public setIsInvoiceDetailsModalVisible = (value: boolean) => {
    this.isInvoiceDetailsModalVisible = value;
  };

  public setInvoiceEmail = (email: string) => {
    this.invoiceEmail = email;
  };

  public setSubmitButtonClicked = (
    value: InvoiceFormSubmitButtonClicked | undefined
  ) => {
    this.submitButtonClicked = value;
  };

  public setAdjustmentReason = (value: string | undefined) => {
    this.adjustmentReason = value;
  };

  public serviceWarningsHelper;

  public get isAdjustMode() {
    return !!this.root.routing.match(routes.accounts.invoices.adjust);
  }

  public get isFromCalendarEvent() {
    return !!this.root.routing.queryStringParam(
      routes.accounts.invoices.queryKeys.calendarEvent
    );
  }

  private invoiceEmail: string | undefined;

  private submitButtonClicked: InvoiceFormSubmitButtonClicked | undefined;

  private adjustmentReason: string | undefined;

  private get adjustInvoiceId() {
    return this.root.routing.match(routes.accounts.invoices.adjust)?.params.id;
  }

  private get cancelledInvoiceId() {
    return parse(this.root.routing.location.search).cancelledInvoiceId as
      | string
      | undefined;
  }

  // This will be the invoice the new invoice is being based off.
  //  For now it is possible it will have a different status than "draft", though this might change
  private draftItems?: InvoiceItemDto[];

  public isCreatingAccInvoice = (values: InvoiceFormValues): boolean => {
    if (!this.root.core.isNZTenant || this.isAdjustMode) return false;

    return values.invoiceItems.some(
      item =>
        item.serviceSearch?.rules?.some(
          rule => rule.ruleType === ServiceRuleType.ACC
        )
    );
  };

  public onClose = (
    options: { goToDrafts?: boolean } = { goToDrafts: false }
  ) => {
    const { goToDrafts } = options;
    if (this.root.routing.location.state?.from || !goToDrafts) {
      closeInvoiceOrPaymentPage(this.root.routing, "replace");
    } else {
      routeToDrafts(this.root);
    }
  };

  public getClaim = (claimId?: string): Claim | undefined => {
    // No need to load claim as this will have already been done by the claim picker component
    if (claimId) {
      return this.root.acc.claimsMap.get(claimId);
    }

    return undefined;
  };

  public loadServiceWarningsHelper() {
    this.serviceWarningsHelper = new ServiceWarningsHelper(this.root);
  }

  public handleSubmitAction = async (values: InvoiceFormValues) => {
    try {
      let invoices: SubmittedInvoices | undefined;
      if (this.isAdjustMode) {
        const adjustedInvoice = await this.addAdjustInvoice(values);
        invoices = {
          invoices: adjustedInvoice ? [adjustedInvoice] : undefined
        };
      } else {
        const dtos = await Promise.all([
          this.getSurchargeDtos(values),
          this.getSubsidyDtos(values)
        ]);
        invoices = await this.submitInvoice(dtos);
      }

      await this.afterSubmitActions(invoices);
    } finally {
      // hide the adjusting conformation dialog if visible
      this.setIsConfirmationDialogVisible(false);
      this.setInvoiceEmailDialogVisible(false);
    }
  };

  public validateForm = async (
    values: InvoiceFormValues
  ): Promise<ValidationErrors> => {
    const [warningValidation, accountContact] = await Promise.all([
      this.serviceWarningsHelper.validate(values.invoiceItems, {
        claimId: values.claimId
      }),
      values.accountContactId
        ? this.root.practice.getContact(values.accountContactId)
        : undefined
    ]);

    const validationResult = {
      ...new InvoiceFormValidator(this.root, this.isAdjustMode).validate(
        values
      ),
      ...warningValidation,
      ...new InvoiceDetailsModalFormValidator(this.root.core, {
        isAdjustMode: this.isAdjustMode,
        accountContact,
        countries: this.root.core.ref.countries.values,
        country: this.root.core.tenantDetails!.country
      }).validate(values)
    };

    return validationResult;
  };

  public onSubmitInvoiceDetailsModal = async (
    form: FormApi<InvoiceFormValues>,
    values: InvoiceDetailsModalFormValues
  ) => {
    const existingValues = form.getState().values;
    let defaultServicesToAdd: ServiceSearchDto[] = [];

    if (values.calendarEventId) {
      defaultServicesToAdd = await this.getDefaultServicesToAdd(
        values.calendarEventId,
        existingValues.invoiceItems,
        values.patientId
      );
    }

    const { newAddress, accountAddress, ...rest } = values;

    const valuesToUpdate: Partial<InvoiceFormValues> = {
      ...rest,
      accountAddress: newAddress
        ? JSON.stringify(newAddress)
        : values.accountAddress
    };

    if (defaultServicesToAdd.length) {
      const existingItems = existingValues.invoiceItems.filter(
        item => item.serviceId
      ); // remove any existing empty rows

      const serviceDate = this.getServiceDate({
        calendarEventId: values.calendarEventId,
        items: existingValues.invoiceItems
      });

      const itemsToAdd = mapServicesToInvoiceItemFormValues(
        defaultServicesToAdd || [],
        { serviceDate, gstPercent: this.root.billing.gstPercent }
      );

      valuesToUpdate.invoiceItems = [...existingItems, ...itemsToAdd];
    }

    changeFormValues(form, valuesToUpdate);
  };

  public addEmptyServiceRowToForm = (
    form: FormApiWithMutators<InvoiceFormValues>
  ) => {
    const values = form.getState().values;
    const serviceDate = this.getServiceDate({
      calendarEventId: values.calendarEventId,
      items: values.invoiceItems
    });
    form.mutators.push(
      "invoiceItems",
      getEmptyInvoiceItemFormValue(serviceDate)
    );
  };

  public get adjustInvoice(): Invoice | undefined {
    const invoice = this.adjustInvoiceId
      ? this.root.billing.getInvoiceFromMap(this.adjustInvoiceId)
      : undefined;

    return invoice;
  }

  public getFormInitialValues = async (): Promise<InvoiceFormValues> => {
    let initialValues: InvoiceFormValues | undefined;

    await this.root.billing.getInvoiceSettings();

    initialValues = await this.getFormInitialValuesFromInvoice();

    if (!initialValues) {
      initialValues = await this.getFormInitialValuesFromDraftItem();
    }

    if (!initialValues) {
      initialValues = await this.getEmptyFormInitialValues();
    }

    if (!initialValues.invoiceItems.length) {
      initialValues.invoiceItems = [
        getEmptyInvoiceItemFormValue(
          this.getServiceDate({
            calendarEventId: initialValues.calendarEventId
          })
        )
      ];
    }

    return initialValues;
  };

  private getFormInitialValuesFromInvoice = async (): Promise<
    InvoiceFormValues | undefined
  > => {
    const { billing } = this.root;

    const invoiceId = this.adjustInvoiceId || this.cancelledInvoiceId;

    if (!invoiceId) {
      return undefined;
    }

    const [invoice, invoiceNumber] = await Promise.all([
      billing.getInvoice(invoiceId),
      this.isAdjustMode ? undefined : billing.generateInvoiceNumber(),
      billing.getInvoiceSettings()
    ]);

    const values = getInvoiceFormValuesFromInvoice({
      invoice,
      gstPercent: billing.gstPercent,
      isAdjust: this.isAdjustMode
    });

    if (invoiceNumber) {
      values.invoiceNumber = invoiceNumber;
    }

    return values;
  };

  private getFormInitialValuesFromDraftItem = async (): Promise<
    InvoiceFormValues | undefined
  > => {
    const { billing, core, booking, routing } = this.root;

    const routeState = routing.location.state || {};

    const { patientId, locationId } = routeState;

    if (!this.isFromCalendarEvent) {
      return undefined;
    }

    const initialCalendarEventId = this.root.routing.queryStringParam(
      routes.accounts.invoices.queryKeys.calendarEvent
    );

    if (!initialCalendarEventId) {
      return undefined;
    }

    const calendarEvent = await booking.getCalendarEvent(
      initialCalendarEventId
    );

    const [draftItems] = await Promise.all([
      billing.getDraftItems(initialCalendarEventId, patientId),
      core.hasPermissions(Permission.ClaimRead)
        ? calendarEvent.loadClaim()
        : undefined
    ]);

    if (draftItems.length === 0) {
      return undefined;
    }

    this.draftItems = draftItems;

    const invoiceNumber = await billing.generateInvoiceNumber();

    const accountContact = await this.getAccountContact(
      draftItems[0].accountId
    );

    return {
      ...getInvoiceFormValuesFromDraftItems({
        draftItems,
        gstPercent: billing.gstPercent,
        claimId: calendarEvent.claimId,
        accountContact,
        locationId: locationId ?? core.location.id,
        invoiceNumber
      })
    };
  };

  private getEmptyFormInitialValues = async (): Promise<InvoiceFormValues> => {
    const { billing, core, practice, routing } = this.root;

    const routeState = routing.location.state || {};

    const {
      accountAddress,
      accountContactId,
      calendarEventId,
      claimId,
      patientId,
      reference,
      userId,
      locationId
    } = routeState;

    const [patient, calendarEvent, provider] = await Promise.all([
      practice.getContact(patientId, { includeRelationships: true }),
      calendarEventId
        ? this.root.booking.getCalendarEvent(calendarEventId)
        : undefined,
      await core.getUser(userId)
    ]);

    const [invoiceNumber, accountContact, services] = await Promise.all([
      billing.generateInvoiceNumber(),
      practice.getContact(accountContactId ?? patientId),
      calendarEvent
        ? this.getDefaultServicesToAdd(calendarEvent.id, [], patientId)
        : undefined,
      this.root.core.isNZTenant && calendarEvent
        ? calendarEvent.loadClaim()
        : undefined
    ]);

    const serviceDate = this.getServiceDate({ calendarEventId });

    const invoiceItems = mapServicesToInvoiceItemFormValues(services || [], {
      serviceDate,
      gstPercent: this.root.billing.gstPercent
    });

    const transactionPatient = mapContactToTransactionContact(
      patient
    ) as TransactionPatientDto;

    return {
      adjustmentReason: "",
      billType: BillType.Patient,
      accountContactId: accountContact.id,
      accountAddress:
        accountAddress ?? getAddressFormValueFromContact(accountContact),
      accountPhone: "-",
      accountContactType: accountContact.type,
      accountName: accountContact.name,
      accountFirstName: accountContact.firstName,
      accountLastName: accountContact.lastName,
      patientId,
      patientAddress: getAddressFormValueFromContact(patient),
      patientPhone: "-",
      patientFirstName: transactionPatient.firstName,
      patientLastName: transactionPatient.lastName,
      id: "",
      invoiceDate: DateTime.jsDateNow(),
      invoiceItems,
      invoiceNumber,
      locationId,
      owing: "0",
      reference,
      calendarEventId,
      total: "0",
      userFirstName: provider.firstName,
      userId,
      userLastName: provider.lastName,
      userTitle: provider.titleRef?.text,
      claimId
    };
  };

  private getDefaultServicesForCalendarEvent = async (
    calendarEvent: CalendarEvent
  ): Promise<ServiceSearchDto[]> => {
    await calendarEvent.loadAppointmentType();

    const effectiveDate =
      calendarEvent.startDateTime?.toJSDate() || DateTime.jsDateNow();

    const dateString = DateTime.jsDateToISODate(effectiveDate);

    if (calendarEvent.appointmentType?.defaultServiceIds?.length) {
      return this.root.billing.getServiceSearch({
        serviceIds: calendarEvent.appointmentType.defaultServiceIds,
        effectiveDate: dateString
      });
    }

    return [];
  };

  private getServiceDate = (options: {
    items?: Array<{ serviceDate: Date }>;
    calendarEventId?: string;
  }): Date => {
    if (options.items?.length) {
      return options.items[0].serviceDate;
    }

    if (options.calendarEventId) {
      const calendarEvent = this.root.booking.calendarEventsMap.get(
        options.calendarEventId
      );
      if (calendarEvent?.startDateTime) {
        return calendarEvent.startDateTime.startOf("day").toJSDate();
      }
    }

    return DateTime.jsDateNow();
  };

  private getDefaultServicesToAdd = async (
    calendarEventId: string | undefined,
    existingItems: Pick<InvoiceItemFormValue, "serviceId">[] = [],
    patientId?: string
  ) => {
    if (calendarEventId) {
      const calendarEvent =
        await this.root.booking.getCalendarEvent(calendarEventId);

      const services =
        await this.getDefaultServicesForCalendarEvent(calendarEvent);

      const servicesNotOnCurrentInvoice = services.filter(service =>
        existingItems.every(item => item.serviceId !== service.serviceId)
      );

      if (servicesNotOnCurrentInvoice.length > 0) {
        const dratInvoiceItems = await this.root.billing.getDraftItems(
          calendarEventId,
          patientId
        );

        const servicesWithoutExistsIntDrafts =
          servicesNotOnCurrentInvoice.filter(service => {
            return dratInvoiceItems.every(
              item => item.serviceId !== service.serviceId
            );
          });

        return servicesWithoutExistsIntDrafts;
      }
    }

    return [];
  };

  private getAccountContact = async (patientId: string) => {
    const { practice } = this.root;

    const patient = await practice.getContact(patientId, {
      includeRelationships: true
    });

    const accountHolder = patient.primaryAccountHolder?.relatedContactId
      ? practice.getContact(patient.primaryAccountHolder.relatedContactId)
      : undefined;

    return accountHolder || patient;
  };

  private addAdjustInvoice = async (values: InvoiceFormValues) => {
    if (values.id) {
      const dto = getAdjustInvoiceDto(values, this.root.core);
      const episodeOfCareId = await this.getEOCId(values);
      return this.root.billing.adjustInvoice(
        { ...dto, items: dto.items.map(x => ({ ...x, episodeOfCareId })) },
        values.id,
        this.adjustmentReason || ""
      );
    }
    return undefined;
  };

  private addInvoiceItemEtags = (invoice: AddInvoiceDto) => {
    const items = invoice.items.map(addItem => {
      const dratItemMatch = this.draftItems?.find(
        draftItem => draftItem.id === addItem.id
      );
      if (dratItemMatch) {
        return {
          ...addItem,
          eTag: dratItemMatch.eTag
        };
      } else {
        return addItem;
      }
    });

    return { ...invoice, items };
  };

  private getSurchargeDtos = async (
    values: InvoiceFormValues
  ): Promise<InvoiceAddDtos> => {
    const surchargeItems = values.invoiceItems.filter(
      item =>
        !item.serviceSearch?.rules?.some(
          rule => rule.ruleType === ServiceRuleType.ACC
        )
    );

    if (values.accountContactId && surchargeItems.length) {
      const surchargeFormValues: InvoiceFormValues = {
        ...values,
        invoiceItems: surchargeItems
      };

      const accountContact = await this.root.practice.getContact(
        values.accountContactId
      );

      const episodeOfCareId = await this.getEOCId(values);
      if (accountContact.draftItemsEnabled) {
        const draftItems = this.getDraftItemDtos(surchargeFormValues);
        return {
          draftItems: draftItems.map(x => ({
            ...x,
            episodeOfCareId
          }))
        };
      } else {
        const addInvoiceDto = this.addInvoiceItemEtags(
          getAddInvoiceDto(
            {
              ...surchargeFormValues,
              invoiceNumber: `${values.invoiceNumber}${
                this.isCreatingAccInvoice(values) ? InvoiceNumberSuffix.A : ""
              }`
            },
            this.root.core
          )
        );

        return {
          invoice: {
            ...addInvoiceDto,
            items: addInvoiceDto.items.map(x => ({
              ...x,
              episodeOfCareId
            }))
          }
        };
      }
    }
    return {};
  };

  private submitInvoice = async (
    addInvoiceDtos: InvoiceAddDtos[]
  ): Promise<SubmittedInvoices> => {
    const addDraftItems = addInvoiceDtos
      .flatMap(x => x.draftItems)
      .filter(isDefined);

    const addInvoices = addInvoiceDtos
      .flatMap(x => x.invoice)
      .filter(isDefined);

    const invoices = addInvoices.length
      ? await Promise.all(
          addInvoices.map(async inv => this.root.billing.addInvoice(inv))
        )
      : undefined;

    // drafts must be submitted after invoices to avoid deleting draft items the invoice is submitting.
    const draftItems = addDraftItems.length
      ? await this.submitDraftItems(addDraftItems)
      : undefined;

    return { draftItems, invoices };
  };

  private getSubsidyDtos = async (
    values: InvoiceFormValues
  ): Promise<InvoiceAddDtos> => {
    if (!this.isCreatingAccInvoice(values)) {
      return {};
    }

    const accItems = values.invoiceItems.filter(
      item =>
        item.serviceSearch?.rules?.some(
          rule => rule.ruleType === ServiceRuleType.ACC
        )
    );
    if (!!accItems.length && values.claimId) {
      const subsidyFormValues = { ...values, invoiceItems: accItems };
      const claim = await this.root.acc.getClaim(values.claimId);
      if (!claim.insurerContactId) {
        throw new Error("No insurer on the claim");
      }

      const episodeOfCareId = await this.getEOCId(values);
      const insurer = await this.root.practice.getContact(
        claim.insurerContactId
      );

      if (insurer.draftItemsEnabled) {
        const draftItems = this.getDraftItemDtos(subsidyFormValues).map(
          item => ({
            ...item,
            accountId: claim.insurerContactId ?? item.accountId,
            episodeOfCareId
          })
        );
        return { draftItems };
      } else {
        const invoice = this.addInvoiceItemEtags(
          getAddInvoiceDto(
            {
              ...values,
              invoiceNumber: `${values.invoiceNumber}${InvoiceNumberSuffix.B}`,
              accountContactId: insurer.id,
              accountFirstName: insurer.firstName,
              accountLastName: insurer.lastName,
              accountName: insurer.name,
              accountAddress: getAddressFormValueFromContact(insurer),
              accountContactType: insurer.type,
              invoiceItems: accItems
            },
            this.root.core
          )
        );
        return {
          invoice: {
            ...invoice,
            items: invoice.items.map(x => ({
              ...x,
              episodeOfCareId
            }))
          }
        };
      }
    }

    return {};
  };

  private getEOCId = async (values: InvoiceFormValues) => {
    let eocId: string | null | undefined;

    if (values.calendarEventId && !values.claimId) {
      const calendarEvent = await this.root.booking.getCalendarEvent(
        values.calendarEventId
      );
      if (calendarEvent.reason?.episodeOfCareId) {
        const eoc = await this.root.clinical.getEpisodeOfCare(
          calendarEvent.reason?.episodeOfCareId
        );
        if (eoc && eoc.isReferral && eoc.referralNumber) {
          eocId = calendarEvent.reason?.episodeOfCareId;
        }
      }
    }

    return eocId;
  };

  private getDraftItemDtos = (
    values: InvoiceFormValues
  ): UpsertInvoiceItemNewDto[] => {
    let dto = getAddInvoiceDto(values, this.root.core);
    if (!!this.draftItems?.length) {
      dto = this.addInvoiceItemEtags(dto);
    }
    return dto.items;
  };

  private submitDraftItems = async (items: UpsertInvoiceItemNewDto[]) => {
    return await this.root.billing.upsertInvoiceItems({
      items,
      calendarEventId: items[0].calendarEventId,
      patientId: items[0].calendarEventId ? items[0].patientId : undefined
    });
  };

  private afterSubmitActions = async ({
    draftItems = [],
    invoices = []
  }: SubmittedInvoices) => {
    //only go to drafts when no invoices are created.
    const goToDrafts = !invoices.length;
    if (draftItems.length) {
      this.root.notification.success(notificationMessages.draftItemsSaved);
    }

    invoices?.forEach(invoice => {
      if (invoice.number.includes(InvoiceNumberSuffix.B)) {
        this.root.notification.success(
          `Success, subsidy invoice ${invoice.number} (${invoice.accountName}) has been saved`
        );
      } else {
        this.root.notification.success(
          notificationMessages.invoiceSaved(invoice.number)
        );
      }
    });

    if (this.submitButtonClicked === InvoiceFormSubmitButtonClicked.PayNow) {
      if (goToDrafts) {
        return routeToDrafts(this.root);
      }
      return this.root.routing.replaceRetainingState({
        pathname: routes.accounts.allocations.new.pattern,
        search: stringify({
          invoiceId: invoices[0].id,
          accountId: invoices[0].patientId
        })
      });
    }

    if (
      this.adjustInvoice?.paymentStatus === PaymentStatuses.paid ||
      this.adjustInvoice?.paymentStatus === PaymentStatuses.part
    ) {
      this.root.notification.warn(
        notificationMessages.unallocatedPaymentAmount
      );
    }

    if (this.submitButtonClicked === InvoiceFormSubmitButtonClicked.OpenPdf) {
      if (invoices.length > 0)
        await this.root.billing.openInvoicePdf(invoices[0].id);
      else {
        await this.root.billing.openInvoicePdf(draftItems[0].id);
      }
    }

    if (this.submitButtonClicked === InvoiceFormSubmitButtonClicked.SendEmail) {
      await this.root.billing.sendInvoicePdf([
        {
          invoiceId: invoices[0].id,
          email: this.invoiceEmail
        }
      ]);
    }

    this.onClose({ goToDrafts });
  };
}
