import { observer } from "mobx-react-lite";
import { FC } from "react";

import { FilterBarProps } from "@bps/fluent-ui";
import {
  ContactType,
  OrganisationRoleType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IndividualAndOrgContactFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { disciplinesItem } from "./contact-filter-items.ts";
import { IndividualAndOrganisationListFilter } from "./IndividualAndOrganisationListFilter.tsx";

export const NewIndividualAndOrganisationListFilter: FC<
  Pick<FilterBarProps<IndividualAndOrgContactFilter>, "children"> & {
    contactType: ContactType;
  }
> = observer(props => {
  const { practice } = useStores();
  return (
    <IndividualAndOrganisationListFilter
      {...props}
      disciplineItem={disciplinesItem(
        practice.ref.contactDisciplines.keyTextValues
      )}
      presets={
        props.contactType === ContactType.Organisation
          ? [
              {
                text: "Healthcare",
                name: "isHealthProviderOnly",
                id: "isHealthProviderOnly",
                iconName: "Hospital",
                tooltip: "Only show healthcare organisations",
                tooltipPresses: "Only showing healthcare organisations",
                valuesToBeSetOnToggleOn: {
                  organisationRoleTypeCodes: [
                    OrganisationRoleType.NzHealthProvider
                  ]
                },
                valuesToBeSetOnToggleOff: {
                  organisationRoleTypeCodes: []
                }
              }
            ]
          : undefined
      }
    />
  );
});
