import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useField } from "react-final-form";

import { FontSizes, useTheme } from "@bps/fluent-ui";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

import { useTemplateFormContext } from "./template-form/context/TemplateFormContext.tsx";

export const AddTemplatePreview: React.FC = observer(() => {
  const { templateEditor } = useField<string>("templateEditor", {
    subscription: { value: true }
  });

  const { renderTemplate, formattedTemplate } = useTemplateFormContext();
  const debounceRenderTemplate = useDebounce(renderTemplate);

  useEffect(() => {
    debounceRenderTemplate(templateEditor);
  }, [debounceRenderTemplate, templateEditor]);

  const theme = useTheme();

  return (
    <div
      style={{
        maxWidth: 300,
        borderWidth: 5,
        borderRadius: 50,
        borderStyle: "solid",
        borderColor: theme.palette.black,
        padding: "20px 10px",
        marginTop: "25px",
        marginBottom: "25px"
      }}
    >
      <div
        style={{
          minWidth: 260,
          minHeight: "100%",
          fontSize: FontSizes.small,
          whiteSpace: "pre-line",
          padding: 10,
          overflowWrap: "break-word",
          borderRadius: 50
        }}
        dangerouslySetInnerHTML={{ __html: formattedTemplate }}
      />
    </div>
  );
});
