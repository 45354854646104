import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Spinner, Stack, useTheme } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ReactionClinicalDataItemDto,
  ReactionSeverity
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { isPending } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { AddPatientNoticeFormDialog } from "@modules/practice/screens/shared-components/patient-notices/AddPatientNoticeFormDialog.tsx";
import { PatientNoticeCallout } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticeCallout.tsx";
import { usePatientNoticesContext } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import { NoticeType } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { PatientNoticesTable } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTable.tsx";
import { getPriorityColor } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesTableRow.tsx";
import { getHighestNoticePriority } from "@modules/practice/screens/shared-components/patient-notices/utils.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { ClinicalActivityDialog } from "./clinical-activity/ClinicalActivityDialog.tsx";
import { ClinicalActivitySideTable } from "./clinical-activity/ClinicalActivitySideTable.tsx";
import { PatientClinicalActivityFormModel } from "./clinical-activity/PatientClinicalActivityFormModel.ts";
import { ClinicalTaskDialog } from "./clinical-task/ClinicalTaskDialog.tsx";
import { ClinicalTaskTableWrapper } from "./clinical-task/ClinicalTaskTableWrapper.tsx";
import { PatientClinicalTaskFormModel } from "./clinical-task/PatientClinicalTaskFormModel.ts";
import {
  ClinicalHeaderTableBase,
  ClinicalHeaderTableViewData
} from "./ClinicalHeaderTable.tsx";
import { usePatientSummaryContext } from "./patient-summary/helpers/PatientSummaryContext.tsx";
import { PatientSummaryCardCallout } from "./patient-summary/PatientSummaryCardCallout.tsx";
import { getSeverityStylingOptions } from "./reactions/ReactionBadge.tsx";
import { ReactionFormDialog } from "./reactions/ReactionFormDialog.tsx";
import { ReactionListDialog } from "./reactions/ReactionListDialog.tsx";
import { ReactionTable } from "./reactions/ReactionTable.tsx";
import { reactionSeverityComparer } from "./reactions/utils.ts";

const ClinicalHeaderBase: React.FC = observer(() => {
  const { clinical, core } = useStores();

  const { clinicalRecord } = usePatientRecordScreenContext();

  const { setVisibleCallout } = usePatientSummaryContext();

  const { clinicalNotices } = usePatientNoticesContext();

  const theme = useTheme();

  const [addReactionDialogVisible, setAddReactionDialogVisible] =
    useState<boolean>(false);

  const [reactionListDialogVisible, setReactionListDialogVisible] =
    useState<boolean>(false);

  const [addTaskDialogVisible, setAddTaskDialogVisible] =
    useState<boolean>(false);

  const [patientNoticesFormVisible, setPatientNoticesFormVisible] =
    useState<boolean>(false);

  const [addClinicalActivityVisible, setAddClinicalActivityVisible] =
    useState<boolean>(false);

  useEffect(() => {
    // Fetch patientClinicalTasks by setting promise for
    // maybeObservablePromise to share isPending and isRejected state with
    // other components they this data is needed
    // Note: only in a case we have not promised yet.
    // When we unmount PatientRecordDetails we clear these classes to set a new promise btw
    // clinical tabs switching.
    !isPending(clinicalRecord.patientClinicalTasksPromise) &&
      !clinicalRecord.patientClinicalTasksPromise.hasPromise &&
      clinicalRecord.setPatientClinicalTasksPromise();

    !isPending(clinicalRecord.patientClinicalActivitiesPromise) &&
      !clinicalRecord.patientClinicalActivitiesPromise.hasPromise &&
      clinicalRecord.loadClinicalActivities();
  }, [clinicalRecord]);

  const getHighestSeverity = (
    reactions: ReactionClinicalDataItemDto[]
  ): ReactionSeverity | undefined => {
    return (reactions?.map(x => x.severity) || []).sort(
      reactionSeverityComparer
    )[0];
  };

  const reactions = clinicalRecord.clinicalData?.reaction?.reactions || [];
  const isNilKnown = clinicalRecord.clinicalData?.reaction?.nilKnown;
  const highestSeverity = getHighestSeverity(reactions);

  let reactionBadgeColour: string | undefined;
  if (isNilKnown) {
    reactionBadgeColour = theme.semanticColors.successBackground;
  } else if (highestSeverity) {
    reactionBadgeColour = getSeverityStylingOptions(theme, highestSeverity)
      ?.badgeColor;
  } else {
    reactionBadgeColour = getSeverityStylingOptions(
      theme,
      ReactionSeverity.Severe
    )?.badgeColor;
  }

  const createTableComponent = (
    componentData: ClinicalHeaderTableViewData[]
  ) => {
    return (
      <Stack.Item
        grow={1}
        styles={{
          root: {
            alignSelf: "right",
            width: "100%"
          }
        }}
      >
        <ClinicalHeaderTableBase viewData={componentData} />
      </Stack.Item>
    );
  };

  const highestPriority = getHighestNoticePriority(clinicalNotices || []);
  const noticePriorityColour = getPriorityColor(theme.palette, highestPriority);
  const reactionsCount =
    clinicalRecord.clinicalData?.reaction?.reactions?.length ?? 0;

  const reactionView: ClinicalHeaderTableViewData = {
    key: "RCTN",
    title: "Reactions",
    count: reactionsCount,
    content: (
      <Stack styles={{ root: { paddingLeft: 4 } }}>
        <ReactionTable
          onAddReaction={() => setAddReactionDialogVisible(true)}
        />
      </Stack>
    ),
    badgeColour: reactionsCount === 0 ? undefined : reactionBadgeColour,
    addAction: () => {
      setAddReactionDialogVisible(true);
    },
    badgeAction: () => {
      setReactionListDialogVisible(true);
    },
    collapsible: false
  };

  const patientNoticesView: ClinicalHeaderTableViewData = {
    key: "NOTE",
    title: "Notices",
    count: clinicalNotices.length,
    content: (
      <Stack styles={{ root: { paddingLeft: 8 } }}>
        <PatientNoticesTable
          padding={8}
          filter={NoticeType.Clinical}
          noDataMessage="No clinical notices"
          showNoDataTile
          noDataTileStyles={{ root: { minHeight: 75, padding: 0 } }}
        />
      </Stack>
    ),
    badgeColour: noticePriorityColour,
    backgroundColour: theme.palette.neutralLighterAlt,
    addAction: () => {
      setPatientNoticesFormVisible(true);
    },
    badgeAction: evt => {
      setVisibleCallout && setVisibleCallout(evt);
    },
    collapsible: true
  };

  const tasksView: ClinicalHeaderTableViewData = {
    key: "TASK",
    title: "Tasks",
    count:
      clinicalRecord.clinicalTasks.filter(t => !t.isCompleted)?.length ?? 0,
    content: (
      <ClinicalTaskTableWrapper
        clinicalRecord={clinicalRecord}
        compact={true}
        showLockIcon={false}
        showClinicalTaskDialog={false}
        clinicalTaskDialogOnDismiss={() => {}}
        onAddClinicalTaskClick={() => setAddTaskDialogVisible(true)}
        removeHeader
        verticalView
      />
    ),
    addAction: !clinical.ui.clinicalTaskActionDisabled
      ? () => {
          setAddTaskDialogVisible(true);
        }
      : undefined,
    badgeAction: () => {
      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.ClinicalTask
      });
    },
    writePermission: Permission.ClinicalWrite,
    collapsible: true
  };

  const getActivityColor = () => {
    const overdueCount = clinicalRecord.clinicalActivities.filter(
      x => x.activityStatus === ClinicalActivityStatusText.Overdue
    ).length;

    const dueTodayCount = clinicalRecord.clinicalActivities.filter(
      x => x.activityStatus === ClinicalActivityStatusText.Today
    ).length;

    if (overdueCount > 0) {
      return theme.semanticColors.errorBackground;
    }

    if (dueTodayCount > 0) {
      return theme.semanticColors.warningBackground;
    }

    return theme.semanticColors.infoBackground;
  };

  const activityView: ClinicalHeaderTableViewData = {
    key: "ACT",
    title: "Clinical notifications",
    count:
      clinicalRecord.clinicalActivities.filter(
        t => t.activityStatus !== ClinicalActivityStatusText.Completed
      )?.length ?? 0,
    content: (
      <Stack styles={{ root: { paddingLeft: 4 } }}>
        <ClinicalActivitySideTable
          noDataMessage="No clinical notifications"
          clinicalRecord={clinicalRecord}
          showDialogue={() => {
            setAddClinicalActivityVisible(true);
          }}
        />
      </Stack>
    ),
    badgeColour: getActivityColor(),

    addAction: !clinical.ui.clinicalActivityActionDisabled
      ? () => {
          setAddClinicalActivityVisible(true);
        }
      : undefined,
    badgeAction: () => {
      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.ClinicalActivity
      });
    },

    collapsible: true
  };

  const clinicalTaskFormModel = new PatientClinicalTaskFormModel(
    clinicalRecord
  );

  const clinicalActivityFormModel = new PatientClinicalActivityFormModel(
    clinicalRecord
  );

  return (
    <>
      <Stack styles={{ root: { overflowY: "auto" } }}>
        {createTableComponent([reactionView])}

        {core.hasPermissions([
          Permission.ClinActivityRead,
          Permission.ClinActivityAllowed
        ]) ? (
          createTableComponent([activityView])
        ) : (
          <When permission={Permission.ClinTaskRead}>
            {createTableComponent([tasksView])}
          </When>
        )}

        {createTableComponent([patientNoticesView])}
      </Stack>

      <PatientSummaryCardCallout>
        <PatientNoticeCallout />
      </PatientSummaryCardCallout>

      {reactionListDialogVisible && (
        <ReactionListDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => setReactionListDialogVisible(false)}
        />
      )}
      {addReactionDialogVisible && (
        <ReactionFormDialog
          clinicalRecord={clinicalRecord}
          onDismiss={() => setAddReactionDialogVisible(false)}
        />
      )}
      {patientNoticesFormVisible && (
        <AddPatientNoticeFormDialog
          onDismiss={() => setPatientNoticesFormVisible(false)}
        />
      )}
      <ClinicalTaskDialog
        hidden={!addTaskDialogVisible}
        clinicalTasks={clinicalRecord?.clinicalTasks}
        onDismiss={() => {
          setAddTaskDialogVisible(false);
        }}
        initialValues={clinicalTaskFormModel.initialValues}
        onSubmit={clinicalTaskFormModel.onSubmit}
        clinicalRecordId={clinicalRecord.id}
      />
      <ClinicalActivityDialog
        hidden={!addClinicalActivityVisible}
        clinicalRecordId={clinicalRecord.id}
        clinicalActivities={clinicalRecord?.clinicalActivities}
        onSubmit={clinicalActivityFormModel.onSubmit}
        onDismiss={() => {
          setAddClinicalActivityVisible(false);
        }}
        initialValues={clinicalActivityFormModel.initialValues}
      />
    </>
  );
});

export const ClinicalHeader = withFetch(
  x => x.clinical.ref.natureOfReactions.load(),
  ClinicalHeaderBase,
  { fallback: <Spinner /> }
);
