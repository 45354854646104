import { useForm } from "react-final-form";

import {
  calendarStrings,
  CSSTransition,
  fadeEnter,
  fadeEnterActive,
  fadeExit,
  fadeExitActive,
  IStackProps,
  Stack
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  DvaCardColour,
  MedicareDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { formatByPattern } from "@libs/utils/format-by-pattern.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { parseAsDigitsOnly } from "../../../shared-components/utils.ts";
import { PatientEntitlementsLabels } from "../../patient/view/PatientEntitlements.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

export const EntitlementsFields: React.FC = () => {
  const {
    practice: { ref }
  } = useStores();

  const monthOptions = calendarStrings.months.map((month, i) => ({
    key: i + 1,
    text: month
  }));

  const currentYear = DateTime.now().year;

  //calculates next 6 years from currentYear
  //used for expiryYear dropdown
  const nextSixYears = [0, 1, 2, 3, 4, 5].map(i => ({
    key: currentYear + i,
    text: (currentYear + i).toString()
  }));

  const medNo = patientFormNameOf("medicare");
  const medNoFields = (key: keyof MedicareDto) =>
    medNo ? `${medNo}.${String(key)}` : key;

  const medicareFormatter = formatByPattern("1234 56789 1");

  const healthInsurance = patientFormNameOf("healthInsurance");
  const healthInsuranceFields = (
    key: keyof {
      fund: string;
      number: string;
      familyNumber?: string;
      expiry?: DateTime;
    }
  ) => (healthInsurance ? `${healthInsurance}.${key}` : key);

  const dva = patientFormNameOf("dva");
  const dvaFields = (
    key: keyof { number: string; cardColor: string; disability: string }
  ) => (dva ? `${dva}.${key}` : key);

  const shortFieldWidth: IStackProps["styles"] = {
    root: { selectors: { "& > *": { width: 136 } } }
  };

  const { getState } = useForm<PatientEditFormValues>();
  const {
    values: { dva: dvaValue, healthInsurance: healthInsuranceValue }
  } = getState();

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Fieldset legend={Labels.medicare}>
        <Stack
          horizontal
          horizontalAlign="start"
          tokens={{ childrenGap: 8 }}
          styles={shortFieldWidth}
        >
          <TextInputField
            name={medNoFields("number")}
            format={val => (val ? medicareFormatter(val.toString()) : "")}
            parse={val =>
              val
                ? parseAsDigitsOnly(medicareFormatter(val.toString()))
                : undefined
            }
            maskChar="9"
            placeholder="Number"
          />
          <TextInputField
            placeholder={PatientEntitlementsLabels.IRN}
            name={medNoFields("irnNumber")}
            parse={val => (val ? parseAsDigitsOnly(val.toString()) : undefined)}
          />
          <DropdownField
            name={medNoFields("expiryMonth")}
            placeholder="Month"
            options={monthOptions}
          />
          <DropdownField
            name={medNoFields("expiryYear")}
            placeholder="Year"
            options={nextSixYears}
          />
        </Stack>
      </Fieldset>

      <CSSTransition
        in={!!healthInsuranceValue}
        timeout={300}
        classNames={{
          enter: fadeEnter,
          enterActive: fadeEnterActive,
          exit: fadeExit,
          exitActive: fadeExitActive
        }}
        mountOnEnter={false}
        unmountOnExit
      >
        <Fieldset legend={Labels.healthFund}>
          <Stack tokens={{ childrenGap: 8 }}>
            <TextInputField
              placeholder={PatientEntitlementsLabels.FundName}
              name={healthInsuranceFields("fund")}
            />
            <Stack
              horizontal
              horizontalAlign="start"
              tokens={{ childrenGap: 8 }}
              styles={shortFieldWidth}
            >
              <TextInputField
                placeholder={PatientEntitlementsLabels.MemberNumber}
                name={healthInsuranceFields("number")}
              />
              <TextInputField
                placeholder={PatientEntitlementsLabels.FamilyNumber}
                name={healthInsuranceFields("familyNumber")}
              />
              <DatePickerField
                name={healthInsuranceFields("expiry")}
                placeholder={PatientEntitlementsLabels.Expiry}
              />
            </Stack>
          </Stack>
        </Fieldset>
      </CSSTransition>

      <CSSTransition
        in={!!dvaValue}
        timeout={300}
        classNames={{
          enter: fadeEnter,
          enterActive: fadeEnterActive,
          exit: fadeExit,
          exitActive: fadeExitActive
        }}
        mountOnEnter={false}
        unmountOnExit
      >
        <Fieldset legend={Labels.dva}>
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 8 }}
            styles={shortFieldWidth}
          >
            <TextInputField
              placeholder={PatientEntitlementsLabels.CardNumber}
              autoComplete="cc-csc"
              name={dvaFields("number")}
            />
            <DropdownField
              name={dvaFields("cardColor")}
              placeholder={PatientEntitlementsLabels.CardColour}
              options={ref.dvaCardColors.keyTextValues}
            />
          </Stack>
          <FieldCondition
            when={dvaFields("cardColor")}
            is={DvaCardColour.White}
          >
            <TextInputField
              placeholder={PatientEntitlementsLabels.Disability}
              name={dvaFields("disability")}
            />
          </FieldCondition>
        </Fieldset>
      </CSSTransition>
    </Stack>
  );
};
