import { observer } from "mobx-react-lite";

import { ITextStyles, Stack, Text, Tile, useTheme } from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

export const BookingCalendarNoProviders = observer(() => {
  const theme = useTheme();
  const { core } = useStores();
  const { userAvailabilities, orgUnitIds } = useBookingCalendarScreenContext();

  const message1Styles: ITextStyles = {
    root: {
      fontStyle: "italic",
      color: theme.palette.neutralTertiary
    }
  };

  const message2Styles: ITextStyles = {
    root: {
      fontStyle: "italic",
      color: theme.palette.neutralPrimary
    }
  };

  return (
    <Tile styles={{ root: { flexGrow: 1 }, content: { display: "flex" } }}>
      <Stack
        grow
        tokens={{ childrenGap: 32 }}
        horizontalAlign="center"
        verticalAlign="center"
      >
        {userAvailabilities?.length ? (
          <>
            <Text styles={message1Styles}>
              {core.hasMultiLocationOrgUnit && !orgUnitIds.length
                ? "No locations selected"
                : "No provider selected"}
            </Text>
            <Text styles={message2Styles}>
              {core.hasMultiLocationOrgUnit && !orgUnitIds.length
                ? "Choose a location and provider to see their appointments"
                : 'Choose a provider in "Schedule for" to see their appointments'}
            </Text>
          </>
        ) : (
          <>
            <Text styles={message1Styles}>No providers available</Text>
            <Text styles={message2Styles}>
              {"Set up working hours in the "}
              <Navigate to={routes.settings.users.basePath.path({})}>
                user profile
              </Navigate>
              {" to appear on the calendar"}
            </Text>
          </>
        )}
      </Stack>
    </Tile>
  );
});
