import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  confirm,
  Heading,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Country } from "@libs/enums/country.enum.ts";
import {
  CommunicationKind,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { formatCommunicationValue } from "@stores/core/models/Communication.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { PracOrgUnit } from "@stores/practice/models/PracOrgUnit.ts";
import { CountryPickerField } from "@ui-components/CountryPickerField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { ColourSelectField } from "@ui-components/form/selects/ColourSelectField.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { WhenCountry } from "@ui-components/WhenCountry.tsx";

import { PracticeDetailsFormLabels } from "../practice-details/PracticeDetailsForm.types.tsx";
import { LocationLabels } from "../PracticeScreen.types.ts";
import {
  LocationDetailsFormLabels,
  LocationDetailsValues
} from "./LocationDetails.types.ts";
import { NewLocationDetailsFormValidator } from "./NewLocationDetailsFormValidator.ts";
import { LOCATION_LIST_ID } from "./PracticeDetailsLocations.tsx";
import { getInitialValues, onFormSubmit } from "./utils.ts";

const nameOf = nameOfFactory<LocationDetailsValues>();
type LocationDetailsFormProps = {
  pracOrgUnit?: PracOrgUnit;
  locationOrgUnit?: OrgUnit;
};

const COLOUR_SELECT_DESCRIPTION =
  "Used to identify location on appointment book";
export const NewLocationDetailsForm: FC<LocationDetailsFormProps> = observer(
  ({ pracOrgUnit, locationOrgUnit }) => {
    const { core, notification, routing, practice } = useStores();

    const readOnly = !core.hasPermissions(Permission.OrgUnitSettingWrite);

    const parentOrgUnitId = core.location.parentOrgUnit?.id;

    const validator = new NewLocationDetailsFormValidator(core);
    const validateForm = (values: LocationDetailsValues) =>
      validator.validate(values);

    const theme = useTheme();
    const { formFooter, formFields } = getUserStylesSet(theme);

    const country = core.tenantDetails && core.tenantDetails.country;

    const { countries } = core.ref;

    const isStateRequired = (value: string) =>
      countries.values.some(c => c.code === value && c.isStateRequired);

    const isCityRequired = (value: string) =>
      countries.values.some(c => c.code === value && !c.isStateRequired);

    const format = (value: string) =>
      formatCommunicationValue(value, CommunicationKind.Phone, country);

    const initialValues: LocationDetailsValues = getInitialValues(
      pracOrgUnit,
      locationOrgUnit
    );

    const handleSubmit = async (values: LocationDetailsValues) => {
      if (!initialValues.defaultLocation && values.defaultLocation) {
        const isConfirmed = await confirm({
          cancelButtonProps: {
            text: "Cancel"
          },
          confirmButtonProps: {
            text: "Confirm"
          },
          dialogContentProps: {
            title: "Confirm change in default location",
            subText: `Do you want to set ${initialValues.practiceName} as the default location?`
          }
        });

        if (!isConfirmed) return;

        notification.success(
          `${values.practiceName} set as the default location`
        );
      }

      await onFormSubmit(
        values,
        { core, practice, notification },
        parentOrgUnitId
      );
    };

    const isInactiveOrNewLocation =
      initialValues.isInactiveLocation || !initialValues.practiceName;

    const isDisabled = initialValues.defaultLocation || isInactiveOrNewLocation;

    const getTooltipText = (): string | undefined => {
      if (isInactiveOrNewLocation) {
        return "Only active locations can be set as the default location";
      }
      return undefined;
    };

    return (
      <SubmissionForm<LocationDetailsValues>
        initialValues={initialValues}
        formName="location-details"
        onSubmit={handleSubmit}
        readOnly={readOnly}
        disabled={locationOrgUnit?.isInactive}
        delayForSettingFieldsData={100}
        onSubmitSucceeded={() => {
          routing.push(routes.settings.practices.basePath.path({}));
        }}
        buttonsProps={{
          onCancel: form => {
            form.reset();
            routing.push({
              pathname: routes.settings.practices.basePath.path({}),
              hash: LOCATION_LIST_ID
            });
          },
          styles: {
            root: formFooter
          }
        }}
        validate={validateForm}
      >
        {({ form, values }) => {
          return (
            <Stack
              styles={{
                root: formFields
              }}
              tokens={{ childrenGap: 8 }}
            >
              <Stack horizontal horizontalAlign="space-between">
                <Heading variant="section-heading-light">
                  {PracticeDetailsFormLabels.practiceLocation}
                </Heading>
              </Stack>
              <TextInputField
                placeholder={LocationLabels.locationName}
                name={nameOf("practiceName")}
                onRenderLabel={() => (
                  <div>
                    <Heading disabled={locationOrgUnit?.isInactive}>
                      {LocationLabels.locationName}
                    </Heading>
                    <Text
                      variant="xSmall"
                      styles={{
                        root: {
                          color: locationOrgUnit?.isInactive
                            ? theme.semanticColors.disabledText
                            : "inherit"
                        }
                      }}
                    >
                      This name will be used for external communications (eg.
                      appointment reminders)
                    </Text>
                  </div>
                )}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack.Item styles={{ root: { flexGrow: 7.5, flexBasis: 0 } }}>
                  <TextInputField
                    onRenderLabel={() => (
                      <div>
                        <Heading disabled={locationOrgUnit?.isInactive}>
                          {LocationLabels.locationNickname}
                        </Heading>
                        <Text
                          variant="xSmall"
                          styles={{
                            root: {
                              color: locationOrgUnit?.isInactive
                                ? theme.semanticColors.disabledText
                                : "inherit"
                            }
                          }}
                        >
                          This name will be used for internal purposes only
                        </Text>
                      </div>
                    )}
                    placeholder={LocationLabels.locationNickname}
                    name={nameOf("locationNickname")}
                  />
                </Stack.Item>
                <Stack.Item styles={{ root: { flexGrow: 4.5, flexBasis: 0 } }}>
                  <ColourSelectField
                    placeholder={LocationLabels.selectColor}
                    name={nameOf("appointmentBookMarker")}
                    label={LocationLabels.selectColor}
                    description={COLOUR_SELECT_DESCRIPTION}
                    disabled={locationOrgUnit?.isInactive}
                  />
                </Stack.Item>
              </Stack>
              <TextInputField
                placeholder={LocationLabels.email}
                name={nameOf("email")}
                label={LocationLabels.email}
              />
              <TextInputField
                placeholder={LocationLabels.phone}
                name={nameOf("phone")}
                label={LocationLabels.phone}
                format={format}
              />
              <WhenCountry is={Country.NewZealand}>
                <TextInputField
                  placeholder={LocationLabels.hpiFacilityId}
                  name={nameOf("hpiFacilityId")}
                  label={LocationLabels.hpiFacilityId}
                />
              </WhenCountry>
              <Heading
                variant="section-sub-heading"
                disabled={locationOrgUnit?.isInactive}
              >
                {LocationLabels.address}
              </Heading>
              <TextInputField
                placeholder={
                  values.country === Country.NewZealand
                    ? LocationLabels.street
                    : LocationLabels.addressLine1
                }
                name={nameOf("address1")}
              />
              <TextInputField
                placeholder={
                  values.country === Country.NewZealand
                    ? LocationLabels.buildingName
                    : LocationLabels.addressLine2
                }
                name={nameOf("address2")}
              />
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextInputField
                  placeholder={LocationLabels.suburb}
                  name={nameOf("suburb")}
                />
                <FieldCondition when={nameOf("country")} is={isCityRequired}>
                  <TextInputField
                    placeholder={
                      values.country === Country.NewZealand
                        ? LocationLabels.townCity
                        : LocationLabels.city
                    }
                    name={nameOf("city")}
                  />
                </FieldCondition>
                <FieldCondition when={nameOf("country")} is={isStateRequired}>
                  <DropdownField
                    name={nameOf("state")}
                    placeholder={LocationLabels.state}
                    styles={{ root: { width: "100%" } }}
                    options={core.ref.australianStates.keyTextValues}
                  />
                </FieldCondition>
                <FieldSpy
                  name={nameOf("country")}
                  onChange={country =>
                    country &&
                    !isStateRequired(country) &&
                    form.change("state", undefined)
                  }
                />
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <TextInputField
                  placeholder={LocationLabels.postCode}
                  name={nameOf("postCode")}
                />
                <FieldSpy
                  name={nameOf("country")}
                  onChange={value => {
                    if (!value) {
                      form.change(nameOf("country"), Country.NewZealand);
                    }
                  }}
                />
                <CountryPickerField
                  styles={{ container: { width: "100%" } }}
                  name={nameOf("country")}
                  placeholder={LocationLabels.country}
                  calloutProps={{
                    calloutMaxHeight: 500
                  }}
                />
              </Stack>
              <StaticPickerField
                label={LocationLabels.timeZone}
                name={nameOf("timezone")}
                fetchDataSource={() =>
                  core.ref.timeZones.fetchAsKeyNameValues()
                }
              />
              <Stack
                tokens={{ childrenGap: 8 }}
                styles={{ root: { width: 300 } }}
              >
                <Heading
                  variant="section-sub-heading"
                  styles={{ root: { paddingTop: 8 } }}
                  disabled={isInactiveOrNewLocation}
                >
                  {LocationLabels.defaultLocation}
                </Heading>
                <TooltipHost content={getTooltipText()}>
                  <CheckboxField
                    disabled={isDisabled}
                    label={LocationDetailsFormLabels.setDefaultLocation}
                    name={nameOf("defaultLocation")}
                  />
                </TooltipHost>
              </Stack>
            </Stack>
          );
        }}
      </SubmissionForm>
    );
  }
);
