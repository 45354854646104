import { observer } from "mobx-react-lite";
import { useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  DefaultButton,
  PersonaSize,
  Stack,
  Text,
  TextBadge,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ageDescription } from "@modules/practice/screens/contact-details/patient/view/utils.ts";
import { Labels } from "@modules/practice/screens/shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { formatPhone } from "@stores/core/models/Communication.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { PersonaRowCommandButtons } from "./PersonaRowCommandButtons.tsx";

export const PersonaRow: React.FC = observer(() => {
  const { core } = useStores();

  const theme = useTheme();
  const { calendarEvent, onShowEdit } = useContext(BookingCalendarEventContext);

  const { contact, isGroupAppointment, user, type } = calendarEvent;

  const contactPhoneNumber =
    contact?.mobilePhone || contact?.homePhone || contact?.workPhone || "";

  const parentStackStyles = {
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
    padding: "16px 24px"
  };

  if (contact && !isGroupAppointment) {
    return (
      <Stack
        horizontal
        styles={{
          root: {
            ...parentStackStyles
          }
        }}
        horizontalAlign="space-between"
      >
        <DefaultButton
          onClick={onShowEdit(PatientCardIds.patientHeader)}
          {...dataAttribute(
            DataAttributes.Element,
            "booking-event-callout-contact-link"
          )}
          styles={{
            root: {
              display: "flex",
              flex: 1,
              height: "auto",
              padding: "16px 24px",
              margin: "-16px 0 -16px -24px",
              border: 0
            }
          }}
        >
          <Persona
            {...dataAttribute(
              DataAttributes.Element,
              "booking-event-callout-persona"
            )}
            text={contact.preferredName}
            id={contact.id}
            imageInitials={contact.initials}
            imageUrl={contact.profilePictureUrl}
            size={PersonaSize.size40}
            onRenderSecondaryText={() => {
              return (
                <Stack>
                  <Stack.Item>
                    {contactPhoneNumber ? (
                      <>
                        {formatPhone(
                          contactPhoneNumber,
                          core.tenantDetails?.country
                        )}
                      </>
                    ) : null}
                  </Stack.Item>
                  <Stack.Item>
                    {ageDescription(contact) || Labels.noDobRecorded}
                  </Stack.Item>
                </Stack>
              );
            }}
          />
        </DefaultButton>
        <PersonaRowCommandButtons />
      </Stack>
    );
  }

  if (user && (type === CalendarEventType.Unavailable || isGroupAppointment)) {
    const userPersonaText = isGroupAppointment
      ? calendarEvent.groupAppointmentName
      : user?.fullName;

    return (
      <Stack horizontal styles={{ root: { ...parentStackStyles } }}>
        <Persona
          text={userPersonaText}
          id={user.id}
          size={PersonaSize.size40}
          imageInitials={user.initials}
          onRenderSecondaryText={
            !isGroupAppointment && calendarEvent.user?.communications[0]
              ? () => (
                  <Text block>
                    {calendarEvent.user?.communications[0].value}
                  </Text>
                )
              : undefined
          }
        />
        {isGroupAppointment && (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            customColors={{
              backgroundColor: theme.palette.neutralLighter
            }}
          >
            Group appointment
          </TextBadge>
        )}
      </Stack>
    );
  }

  return null;
});
